import React from 'react';
import './progressbar.scss';
import PropTypes from 'prop-types';
import {ReactComponent as ProgressbarSVG} from '../../../assets/images/progress-bar.svg';

const Progressbar = ({roundNumber}) => {

	return (
		<div className="Progressbar">
			<ProgressbarSVG className={'Progressbar-round' + roundNumber}/>
		</div>
	);
};

Progressbar.propTypes = {
	roundNumber: PropTypes.number.isRequired,
};

export default Progressbar;