const taskTemplates = require('../../task-templates');

let taskData = [
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-1a-match-materials',
		title: 'Kend dine materialer',
		type: 'match',
		answerType: 'materials-a1',
		instructions: 'Match de rigtige navne med de rigtige billeder.',
		assignmentNumber: 1,
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'brædder', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'stolper', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'tagplader', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'søm', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'skruer', 
				id: 5,
				answers: [5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'vinkelbeslag', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'hængsel', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'isolering', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'krydsfinerplader', 
				id: 9,
				answers: [9]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'brædder',
				imageSrc: 'set-a-1/materials/boards.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'stolper',
				imageSrc: 'set-a-1/materials/posts.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'tagplader',
				imageSrc: 'set-a-1/materials/roof-tiles.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'søm',
				imageSrc: 'set-a-1/materials/nails.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'skruer',
				imageSrc: 'set-a-1/materials/screws.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'vinkelbeslag',
				imageSrc: 'set-a-1/materials/angle-bracket.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: 'hængsel',
				imageSrc: 'set-a-1/materials/hinges.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'isolering',
				imageSrc: 'set-a-1/materials/insulation.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'krydsfinerplader',
				imageSrc: 'set-a-1/materials/plywood-sheets.svg',
			}),
		],
		texts: [
			{
				bidId: '1a',
				text: 'Når man bygger et legehus skal man kende de forskellige materialer der skal bruges.'
			},
			{
				bidId: '2a',
				text: 'Når man bygger et brændeskur skal man kende de forskellige materialer der skal bruges.'
			},
			{
				bidId: '3a',
				text: 'Når man bygger en basketball bane skal man kende de forskellige materialer der skal bruges.'
			},
			{
				bidId: '4a',
				text: 'Når man bygger et legeplads skal man kende de forskellige materialer der skal bruges.'
			},
			{
				bidId: '5a',
				text: 'Når man bygger et anneks skal man kende de forskellige materialer der skal bruges.'
			},
			{
				bidId: '6a',
				text: 'Når man bygger et legeværelse skal man kende de forskellige materialer der skal bruges.'
			},
			{
				bidId: '7a',
				text: 'Når man bygger et hønsegård skal man kende de forskellige materialer der skal bruges.'
			},
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-1a-match-tools',
		title: 'Kend dit værktøj',
		type: 'match',
		answerType: 'materials-a1',
		instructions: 'Match de rigtige navne med de rigtige billeder.',
		assignmentNumber: 2,
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Hammer', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Skruemaskine', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Stiksav', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Fuksvans', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tommestok', 
				id: 5,
				answers: [5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Vatterpas', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Bænkvinkel', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Blyant', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Knibtang', 
				id: 9,
				answers: [9]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'Hammer',
				imageSrc: 'set-a-1/tools/hammer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'Skruemaskine',
				imageSrc: 'set-a-1/tools/screw-machine.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'Stiksav',
				imageSrc: 'set-a-1/tools/jigsaw.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'Fuksvans',
				imageSrc: 'set-a-1/tools/fox-tail.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'Tommestok',
				imageSrc: 'set-a-1/tools/ruler.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'Vatterpas',
				imageSrc: 'set-a-1/tools/water-level.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: 'Bænkvinkel',
				imageSrc: 'set-a-1/tools/bench-angle.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'Blyant',
				imageSrc: 'set-a-1/tools/pencil.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'Knibtang',
				imageSrc: 'set-a-1/tools/pliers.svg',
			}),
		],
		text: 'Når man er håndværker skal man have styr på sin værktøjskasse.'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 3,
		taskId: 'round-1a-multiple-toolbox',
		title: 'Kend dit værktøj',
		type: 'multiple-choice',
		subtype: 'images',
		layout: 'toolbox',
		text: 'Når man er håndværker skal man have styr på sin værktøjskasse. Tryk på de to ting der IKKE skal med i Tømrerens værktøjskasse.',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Hammer
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Stemmejern
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Nål og tråd
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Fuksvans
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Vinkel
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Vatterpas
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // Svejsemaske
				isCorrect: true,
			}),
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		assignmentNumber: 4,
		taskId: 'round-1a-multiple-craftsman',
		title: 'Kend din håndværker',
		type: 'match',
		instructions: 'Match håndværkerfaget med den rigtige opgave.',
		text: 'Det er godt at vide hvilken håndværker man skal ringe til for at få løst forskellige opgaver.',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tømrer/Snedker', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Blikkenslager', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Smed', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Møbelsneder', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Elektrikker', 
				id: 5,
				answers: [5]
			}),

			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Murer', 
				id: 6,
				answers: [6]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: true,
				text: 'Montage af vinduer og døre',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: true,
				text: 'Tagrender og Zink-beklædning',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: true,
				text: 'Jern konstruktioner og svejsninger',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: true,
				text: 'Fremstille møbler',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: true,
				text: 'Etablering af stikkontakter',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: true,
				text: 'Flisemontage og opmuring af mure',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 16,
		taskId: 'round-1a-multiple-area',
		title: 'Arealet af en figur',
		type: 'multiple-choice',
		image: 'figure-area-3',
		text: 'Billedet viser en rektangel. Hvor stort er arealet af rektangelet?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '80 m2',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '81 m2',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '85 m2',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '90 m2',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '91 m2',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '95 m2',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: '100 m2',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: '101 m2',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 9,
				text: '105 m2',
				isCorrect: false,
			}),
		],
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round1-easy-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};