import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import {pageUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import OrderDndContainer from './order-dnd-container';
import OrderDndItem from './order-dnd-item';
import OrderDndPreview from './order-dnd-preview';
import Popup from 'components/ui/popup/popup';
import './order.scss';
import TaskIntro from '../task-intro/task-intro';

const Order = (props) => {
	const { 
		playerTaskData, 
		taskData,
		handleCompleteTask,
		handleAnswer,
	} = props;

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/**
	 * Adjust height of options container with respect to task intro
	 */
	 const [optionsStyle, setOptionsStyle] = useState(null);
	 const getOptionsStyle = () => {
		 let style = null;
		 const taskIntroElement = document.getElementById('taskIntro');
		 if (taskIntroElement) {
			 const offsetTop = taskIntroElement.clientHeight + taskIntroElement.offsetTop;
			 style = {position: 'absolute', top: offsetTop + 'px'};
		 }
		 return style;
	 };

	/* Get items to be ordered */
	const getOrderedItemIds = () => {
		/* Get items from player data or data file */
		let items = (playerTaskData && playerTaskData.orderedItems
			? playerTaskData.orderedItems // TODO: check it matches?
			: shuffleArray(taskData.items.map((item) => {return {id: item.id, isLocked: false};}))
		);
		return items;
	};

	/* Track ordered item ids (current and prev round) and number of rounds */
	const [orderedItems, setOrderedItems] = useState([]);
	const [roundNumber, setRoundNumber] = useState(1);

	/* Popup with number of correct positions */
	const [popupData, setPopupData] = useState(null);

	/* Update sorted items if new task */
	useEffect(() => {
		setOrderedItems(getOrderedItemIds());
		setRoundNumber(1);
		setOptionsStyle(getOptionsStyle());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Switch items
	 * @param {number} fromIndex 
	 * @param {number} toIndex 
	 */
	const handleSwitchItems = (fromIndex, toIndex) => {
		/* Switch order of items */
		let newOrderedItemIds = JSON.parse(JSON.stringify(orderedItems));
		const itemId = newOrderedItemIds[fromIndex];
		newOrderedItemIds[fromIndex] = newOrderedItemIds[toIndex];
		newOrderedItemIds[toIndex] = itemId;

		setOrderedItems(newOrderedItemIds);
	};

	/**
	 * Confirm order
	 */
	const confirmOrder = () => {
		/* Count errors */
		let errors = 0;
		orderedItems.forEach((item, index) => {
			const itemData = taskData.items.find((i) => {return i.id === item.id;});
			if (itemData && itemData.correctOrderNumbers && itemData.correctOrderNumbers.indexOf(index + 1) < 0) {
				errors += 1;
			}
		});

		if (errors > 0) {
			/* Show popup */
			const popupTextNumber = (roundNumber < 3 ? roundNumber : 3);
			const popupText = pageUiTexts.orderPopup['text' + popupTextNumber];
			const popupTitle = pageUiTexts.orderPopup.title
				.replace('%correctAnswers%', (orderedItems.length - errors))
				.replace('%totalAnswers%', orderedItems.length);
			setPopupData({
				title: popupTitle,
				text: popupText,
				cancelButtonText: pageUiTexts.orderPopup.cancelButtonText,
			});
			// moduleName, isCorrect, selectedOption = null, answer = null
			handleAnswer('order', false, null, null);
		} else {
			/* Complete task */
			completeTask();
		}
	};

	/**
	 * Close popup
	 */
	const handleClosePopup = () => {
		/* Update round number */
		const newRoundNumber = roundNumber + 1;
		setRoundNumber(newRoundNumber);

		/* Lock correctly placed items if round >= 3 */
		if (newRoundNumber >= 3) {
			let newOrderedItemIds = JSON.parse(JSON.stringify(orderedItems));
			newOrderedItemIds.forEach((item, index) => {
				const itemData = taskData.items.find((i) => {return i.id === item.id;});
				if (itemData && itemData.correctOrderNumbers && itemData.correctOrderNumbers.indexOf(index + 1) >= 0) {
					item.isLocked = true;
				}
			});
			setOrderedItems(newOrderedItemIds);
		}

		/* Close popup */
		setPopupData(null);
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {
		/* Errors */ 
		const errors = (roundNumber - 1);

		/* Save completed task */
		handleCompleteTask(
			'order',
			errors,
			[{type: 'streak', isCorrectAnswer: true}],
			{orderedItems: orderedItems}
		);
	};

	return (
		<div className={'Order'}>
			<div id="taskIntro" className="Order-intro">
				<TaskIntro
					text={taskData.text}
					image={taskData.image}
					file={taskData.linkFile}
				/>
			</div>
			<div id="orderItems" className="Order-items" style={optionsStyle}>
				{orderedItems.map((item, index) => {
					const itemData = taskData.items.find((i) => {return i.id === item.id;});
					if (!itemData) return null;
					let isDraggable = true;
					let classes = null;
					const isPlacedCorrect = itemData.correctOrderNumbers.indexOf(index + 1) >= 0;
					if (isCompleted || (item.isLocked && isPlacedCorrect)) {
						isDraggable  = false;
						classes = ['correct'];
					}

					return (
						<div 
							key={index} 
							className="Order-item"
						>
							<div className="Order-itemNumber"><span>{index + 1}</span></div>
							<div className="Order-itemContainer">
								<OrderDndContainer 
									containerIndex={index}
									handleSwitchItems={handleSwitchItems}
								>
									<OrderDndItem 
										isDraggable={isDraggable} 
										itemIndex={index}
										itemData={itemData}
										classes={classes}
									/>
								</OrderDndContainer>
							</div>
						</div>
					);
				})}
				<OrderDndPreview orderedItems={orderedItems} itemsData={taskData.items} />
			</div>
			

			{/* Done button */}
			{!isCompleted && <div className="Order-doneBtn">
				<Button
					classes={['flatBlue']}
					text={pageUiTexts.deliver}
					onClick={confirmOrder}
				/>
			</div>}

			{popupData && <Popup 
				showContinue={false}
				type="order-result"
				message=''
				title={popupData.title}
				text={popupData.text}
				togglePopup={handleClosePopup}
				onCancelButtonClick={handleClosePopup}
				cancelButtonText={popupData.cancelButtonText}
			/>}
		</div>
	);
};

Order.propTypes = {
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleAnswer: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
};

export default Order;
