const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 45,
		taskId: 'round-3-multiple-arbejdsmiljoe-45',
		title: 'Arbejdsmiljø',
		type: 'multiple-choice',
		style: 'singleLine',
		text: 'Hvor mange arbejdsulykker er der registreret i bygge og anlægsbranchen i år 2021',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '2000-2500', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '3000-3500', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '3500-4000', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '4000-4500', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '5000-5500', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '6000-6500', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: '6500-7000', 
				isCorrect: true,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 81,
		taskId: 'round-3-multiple-Y-aksens-Nulpunkt-81',
		title: 'Y-aksens Nulpunkt',
		type: 'multiple-choice',
		style: 'singleLine',
		text: 'I koordinatsystemet her er der 4 rette linjer l,m, n og o. Hvilken linje skærer y-aksen i 0?',
		image: 'koordinatsystem',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'l', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'm', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'n', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'o', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 82,
		taskId: 'round-3-multiple-Hældningstal-82',
		title: 'Hældningstal',
		type: 'multiple-choice',
		style: 'singleLine',
		text: 'I koordinatsystemet her er der 4 rette linjer l,m, n og o. Hvilken linje har hældningstallet 2?',
		image: 'koordinatsystem',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'l', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'm', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'n', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'o', 
				isCorrect: true,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 83,
		taskId: 'round-3-multiple-X-aksens-nulpunkt-83',
		title: 'X-aksens nulpunkt',
		type: 'multiple-choice',
		style: 'singleLine',
		text: 'I koordinatsystemet her er der 4 rette linjer l,m, n og o. Hvilken linje skærer x-aksen i 1?',
		image: 'koordinatsystem',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'l', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'm', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'n', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'o', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 84,
		taskId: 'round-3-input-Cylinder-84',
		title: 'Cylinder',
		text: 'Formel til udregning af en cylinders rumfang: V=π x r² x h. Beregn rumfanget af cylinderen på billedet (Opgiv facit i hele cm3)',
		image: 'cylinder',
		unit: 'cm3',
		answer: 423115
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 85,
		taskId: 'round-3-input-Cylinder-85',
		titles: [
			{
				bidId: 15,
				title: 'Hvad vejer taget?'
			},
			{
				bidId: 16,
				title: 'Hvad vejer konstruktionen?'
			},
			{
				bidId: 17,
				title: 'Hvad vejer lægterne?'
			},
			{
				bidId: 18,
				title: 'Hvad vejer Hylderne?'
			},
			{
				bidId: 19,
				title: 'Hvad vejer gipspladerne'
			},
			{
				bidId: 20,
				title: 'Hvad vejer Grusbelægningen'
			},
			{
				bidId: 21,
				title: 'Hvad vejer Gipspladerne'
			},
		],
		images: [
			{
				bidId: 15,
				image: 'Massefylde for Grantræ: 1 cm3 = 0,37 gram'
			},
			{
				bidId: 16,
				image: 'Massefylde for fyrtræ: 1 cm3 = 0,43 gram'
			},
			{
				bidId: 17,
				image: 'Massefylde for Grantræ: 1 cm3 = 0,37 gram'
			},
			{
				bidId: 18,
				image: 'Massefylde for fyrtræ: 1 cm3 = 0,43 gram'
			},
			{
				bidId: 19,
				image: 'Massefylde for brandgips: 1 cm3 = 0,73 gram'
			},
			{
				bidId: 20,
				image: 'Massefylde for Grus: 1 cm3 = 1,65 gram'
			},
			{
				bidId: 21,
				image: 'Massefylde for Gips: 1 cm3 = 0,52 gram'
			},
		],
		texts: [
			{
				bidId: 15,
				text: 'Under stråene på et stråtag monterer tømreren lægter på taget. Lægterne måler 38x73 mm. Rumfanget på 1 meter lægter er altså: 100x3,8x7,3= 2.774 cm3. Massefylde for Grantræ: 1 cm3 = 0,37 gram. Hvor meget vejer lægterne ialt, hvis der er blevet brugt 850 m lægte på hele taget? (Vægten skal angives i hele kg.)'
			},
			{
				bidId: 16,
				text: 'Tilbygningens bærende konstruktion er lavet af spærtræ. Dimensionerne på spærtræet er: 45x195 mm. Rumfanget på 1 meter spærtræ er altså: 100x4,5x19,5=8,775. Massefylde for fyrtræ: 1 cm3 = 0,43 gram. Hvor meget vejer konstruktionen ialt, hvis der er blevet brugt 450 m spærtræ? (Vægten skal angives i hele kg.)'
			},
			{
				bidId: 17,
				text: 'Under tagbelægningen på alle tage monterer tømreren lægter på taget. Lægterne måler 38x73 mm. Rumfanget på 1 meter lægter er altså: 100x3,8x7,3= 2.774 cm3. Massefylde for Grantræ: 1 cm3 = 0,37 gram. Hvor meget vejer lægterne ialt, hvis der er blevet brugt 850 m lægte på hele taget? (Vægten skal angives i hele kg.)'
			},
			{
				bidId: 18,
				text: 'Hylderne der bliver monteret under denne kontorindretning er lavet af fyrretræ. Dimensionen på hylderne er: 30x420 mm. Rumfanget på 1 meter hylde er altså: 100x3x42=12.600. Massefylde for fyrtræ: 1 cm3 = 0,43 gram. Hvor meget vejer hylderne ialt, hvis der skal monteres 56 meter hylder ialt? (Vægten skal angives i hele kg.)'
			},
			{
				bidId: 19,
				text: 'På en dag monterer en tømrer 33 plader brandgips. En gipsplade har dimensionerne: 15*90*250 mm. Rumfanget på 1 plade er altså: 1,5*90*250=33.750 cm. Massefylde for brandgips: 1 cm3 = 0,73 gram. Hvor mange kg. brandgips har en tømrer monteret på en dag ialt? (Svaret skal angives i hele kg)'
			},
			{
				bidId: 20,
				text: 'Under multibanen skal der udlægges grus. Der skal udlægges et gruslag der er 20 cm tykt. Arealet det skal lægges ud på måler 15x10 m. Massefylde for Grus: 1 cm3 = 1,65 gram. Hvor meget vejer denne grusbelægning ialt? (Svaret skal angives i hele kg.)'
			},
			{
				bidId: 21,
				text: 'På en dag monterer en tømrer 42 plader gips. En gipsplade har dimensionerne: 13x900x240 mm. Rumfanget på 1 plade gips er altså: 1,3x90x240=28.080. Massefylde for Gips: 1 cm3 = 0,52 gram. Hvor mange kg. gips har en tømrer monteret på to dage (svaret skal angives i hele kg.)'
			},
		],
		unit: 'kg',
		subtype: 'range',
		ranges: [
			{
				bidId: 15,
				min: 872,
				max: 873
			},
			{
				bidId: 16,
				min: 1698,
				max: 1699
			},
			{
				bidId: 17,
				min: 872,
				max: 873
			},
			{
				bidId: 18,
				min: 303,
				max: 304
			},
			{
				bidId: 19,
				min: 813,
				max: 814
			},
			{
				bidId: 20,
				min: 49500,
				max: 49501
			},
			{
				bidId: 21,
				min: 1227,
				max: 1228
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 86,
		taskId: 'round-3-input-Massefylde-86',
		titles: [
			{
				bidId: 15,
				title: 'Hvad vejer rygningstræet?'
			},
			{
				bidId: 16,
				title: 'Hvad vejer skruerne?'
			},
			{
				bidId: 17,
				title: 'Hvad vejer Sømmene?'
			},
			{
				bidId: 18,
				title: 'Hvad vejer hyldebærerne?'
			},
			{
				bidId: 19,
				title: 'Hvad vejer brandfugen?'
			},
			{
				bidId: 20,
				title: 'Hvad vejer skruerne?'
			},
			{
				bidId: 21,
				title: 'Hvad vejer værktøjet?'
			},
		],
		images: [
			{
				bidId: 15,
				image: 'Massefylde for egetræ: 1 cm3 = 0,57 gram'
			},
			{
				bidId: 16,
				image: 'Massefylde for jern: 1 cm3 = 7,87 gram'
			},
			{
				bidId: 17,
				image: 'Massefylde for jern: 1 cm3 = 7,87 gram'
			},
			{
				bidId: 18,
				image: 'Massefylde for jern: 1 cm3 = 7,87 gram'
			},
			{
				bidId: 19,
				image: 'Massefylde for fugemasse: 1 cm3 = 0,16 gram'
			},
			{
				bidId: 20,
				image: 'Massefylde for jern: 1 cm3 = 7,87 gram'
			},
			{
				bidId: 21,
				image: 'Massefylde for rustfrit stål: 1 cm3 = 8,65 gram'
			},
		],
		texts: [
			{
				bidId: 15,
				text: 'På toppen af et stråtag sidder der ofte rygningstræer, også kaldet kragtræer. Disse er ofte lavet af egetræ, da egetræ har en høj massefylde. Massefylde for egetræ: 1 cm3 = 0,57 gram. Hvad vejer kragetræerne hvis det på et hus sidder 22 stk. som hver har et rumfang på 18.000 kubikcentimeter? (Svaret skal angives i hele kg.)'
			},
			{
				bidId: 16,
				text: 'Under opbygning af tilbygningen er der blevet brugt 4.500 skruer ialt. Hver skrue har i gennemsnit en volume på 0,25 cm3. Massefylde for jern: 1 cm3 = 7,87 gram. Hvor mange kg skruer er der blevet brugt i alt? (Svaret skal angives i hele kg.)'
			},
			{
				bidId: 17,
				text: 'Under Tagudskiftningen er der blevet brugt 590 skruer ialt. Hver skrue har i gennemsnit en volume på 0,93 cm3. Massefylde for jern: 1 cm3 = 7,87 gram. Hvor mange kg skruer er der blevet brugt i alt? (Svaret skal angives i hele kg.)',
			},
			{
				bidId: 18,
				text: 'De hyldeknægte der skal bruges til at montere hylderne på, har en volume på 600 cm3. Der skal bruges 46 stk ialt. Massefylde for jern: 1 cm3 = 7,87 gram. Hvor meget vejer hyldeknægtene i alt? (Svaret skal angives i hele kg)',
			},
			{
				bidId: 19,
				text: 'Under opgaven bliver der brugt brandfuge til at tætne ved alle samlinger og gennemføringer. Der er i alt opmålt 268 m fuge ialt. Fugerne er 15 mm brede og 20 mm dybe. Massefylde for fugemasse: 1 cm3 = 0,16 gram. Hvor mange kg fuge skal der ialt bruges på opgaven? (Svaret skal angives i hele kg.)',
			},
			{
				bidId: 20,
				text: 'Under Opbygningen af multibanen er der blevet brugt 475 skruer ialt. Hver skrue har i gennemsnit en volume på 0,93 cm3. Massefylde for jern: 1 cm3 = 7,87 gram. Hvor mange kg skruer er der blevet brugt i alt? (Svaret skal angives i hele kg.)',
			},
			{
				bidId: 21,
				text: 'Da du er nødt til at tage nogle special-fremstillede skruer med i flyveren til opgaverne som rejsemontør, skal du vide, hvad din bagage vejer. Skruerne har en massefylde på 3,2 cm3 Og du skal have 866 med til opgaven. Massefylde for rustfrit stål: 1 cm3 = 8,65 gram. Hvad vejer skruerne ialt? (Svaret skal angives i hele kg.)',
			},
		],
		unit: 'kg',
		subtype: 'range',
		ranges: [
			{
				bidId: 15,
				min: 226,
				max: 227
			},
			{
				bidId: 16,
				min: 9,
				max: 10
			},
			{
				bidId: 17,
				min: 4,
				max: 5
			},
			{
				bidId: 18,
				min: 217,
				max: 218
			},
			{
				bidId: 19,
				min: 13,
				max: 14
			},
			{
				bidId: 20,
				min: 3,
				max: 4
			},
			{
				bidId: 21,
				min: 24,
				max: 25
			},
		]
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round3-hard-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};