const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 26,
		taskId: 'round-2-input-opgave-26',
		title: 'Opgave 26',
		images: [
			{
				bidId: 8,
				image: 'badebro2'
			},
			{
				bidId: 9,
				image: 'tilbygning2'
			},
			{
				bidId: 10,
				image: 'skaterRampe2'
			},
			{
				bidId: 11,
				image: 'binding2'
			},
			{
				bidId: 12,
				image: 'typehus2'
			},
			{
				bidId: 13,
				image: 'terrasse2'
			},
			{
				bidId: 14,
				image: 'gips2'
			},
		],
		text: 'På det udleverede tegningsmateriale er der markeret en vinkel der skal udregnes. Beregn vinklen i hele grader',
		unit: 'grader',
		answers: [
			{
				bidId: 8,
				answer: 5
			},
			{
				bidId: 9,
				answer: 15
			},
			{
				bidId: 10,
				answer: 28
			},
			{
				bidId: 11,
				answer: 49
			},
			{
				bidId: 12,
				answer: 25
			},
			{
				bidId: 13,
				answer: 10
			},
			{
				bidId: 14,
				answer: 120
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 27,
		taskId: 'round-2-input-opgave-27',
		title: 'Opgave 27',
		images: [
			{
				bidId: 8,
				image: 'badebro2'
			},
			{
				bidId: 9,
				image: 'tilbygning2'
			},
			{
				bidId: 10,
				image: 'skaterRampe2'
			},
			{
				bidId: 11,
				image: 'binding2'
			},
			{
				bidId: 12,
				image: 'typehus2'
			},
			{
				bidId: 13,
				image: 'terrasse2'
			},
			{
				bidId: 14,
				image: 'gips2'
			},
		],
		texts: [
			{
				bidId: 8,
				text: 'Hvor mange Stolper skal der indkøbes til projektet. Brug tegningsmaterialet til at besvare opgaven'
			},
			{
				bidId: 9,
				text: 'Hvor mange meter spær skal der indkøbes til projektet? Brug tegningsmaterialet til at besvare opgaven'
			},
			{
				bidId: 10,
				text: 'Hvor mange m2 støbekrydsfinér skal der indkøbes til projektet? Brug tegningsmaterialet til at besvare opgaven'
			},
			{
				bidId: 11,
				text: 'Hvor mange meter fuldtømmer (5x5") skal der indkøbes til projektet. Brug tegningsmaterialet til at besvare opgaven'
			},
			{
				bidId: 12,
				text: 'Hvor mange vinkelbeslag skal der indkøbes til projektet? Brug tegningsmaterialet til at besvare opgaven'
			},
			{
				bidId: 13,
				text: 'Hvor mange meter terrassebrædder (32x125 mm) skal der indkøbes ialt til projektet. Brug tegningsmaterialet til at besvare opgaven'
			},
			{
				bidId: 14,
				text: 'Hvor mange kg plastikmaling skal der indkøbes til projektet? Brug tegningsmaterialet til at besvare opgaven'
			},
		],
		units: [
			{
				bidId: 8,
				unit: 'stk'
			},
			{
				bidId: 9,
				unit: 'm'
			},
			{
				bidId: 10,
				unit: 'm2'
			},
			{
				bidId: 11,
				unit: 'm'
			},
			{
				bidId: 12,
				unit: 'stk'
			},
			{
				bidId: 13,
				unit: 'm'
			},
			{
				bidId: 14,
				unit: 'kg'
			},
		],
		answers: [
			{
				bidId: 8,
				answer: 10
			},
			{
				bidId: 9,
				answer: 195
			},
			{
				bidId: 10,
				answer: 13
			},
			{
				bidId: 11,
				answer: 185
			},
			{
				bidId: 12,
				answer: 198
			},
			{
				bidId: 13,
				answer: 390
			},
			{
				bidId: 14,
				answer: 75
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 28,
		taskId: 'round-2-input-opgave-28',
		title: 'Opgave 28',
		image: 'screws',
		text: 'Du skal indkøbe skruer til en sag: 100 stk. 5,0x100, 1000 stk. gipsskruer, 500 stk 5,0x60 mm, Hvad kommer skruerne til at koste i alt?',
		answer: 633.75
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 32,
		taskId: 'round-2-input-opgave-32',
		title: 'Opgave 32',
		images: [
			{
				bidId: 8,
				image: 'badebro3'
			},
			{
				bidId: 9,
				image: 'tilbygning3'
			},
			{
				bidId: 10,
				image: 'skaterRampe3'
			},
			{
				bidId: 11,
				image: 'binding3'
			},
			{
				bidId: 12,
				image: 'typehus3'
			},
			{
				bidId: 13,
				image: 'terrasse3'
			},
			{
				bidId: 14,
				image: 'gips3'
			},
		],
		texts: [
			{
				bidId: 8,
				text: 'Hvor mange kvadratmeter er gangarealet på broen?'
			},
			{
				bidId: 9,
				text: 'Hvor mange kvadratmeter er der indvendigt i Tilbygningen?'
			},
			{
				bidId: 10,
				text: 'Hvor mange kvadratmeter er de to platforme på rampen til sammen?'
			},
			{
				bidId: 11,
				text: 'Hvor mange kvadratmeter dækker bindingværkets plan?'
			},
			{
				bidId: 12,
				text: 'Hvor mange kvadratmeter dækker husets tag over?'
			},
			{
				bidId: 13,
				text: 'Hvor mange kvadratmeter er terrassen?'
			},
			{
				bidId: 14,
				text: 'Hvor mange kvadratmeter dækker konstruktionens plan?'
			}
		],
		unit: '',
		subtype: 'range',
		ranges: [
			{
				bidId: 8,
				min: 25,
				max: 26
			},
			{
				bidId: 9,
				min: 31,
				max: 32
			},
			{
				bidId: 10,
				min: 1,
				max: 2
			},
			{
				bidId: 11,
				min: 48,
				max: 49
			},
			{
				bidId: 12,
				min: 122,
				max: 123
			},
			{
				bidId: 13,
				min: 50,
				max: 51
			},
			{
				bidId: 14,
				min: 100,
				max: 101
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 36,
		taskId: 'round-2-input-opgave-36',
		title: 'Opgave 36',
		images: [
			{
				bidId: 8,
				image: 'badebro'
			},
			{
				bidId: 9,
				image: 'tilbygning'
			},
			{
				bidId: 10,
				image: 'skaterRampe'
			},
			{
				bidId: 11,
				image: 'binding'
			},
			{
				bidId: 12,
				image: 'typehus'
			},
			{
				bidId: 13,
				image: 'terrasse'
			},
			{
				bidId: 14,
				image: 'gips'
			},
		],
		text: 'Hvor mange forskellige materialer er der i konstruktionen på tegningen?',
		answers: [
			{
				bidId: 8,
				answer: 6
			},
			{
				bidId: 9,
				answer: 8
			},
			{
				bidId: 10,
				answer: 8
			},
			{
				bidId: 11,
				answer: 8
			},
			{
				bidId: 12,
				answer: 8
			},
			{
				bidId: 13,
				answer: 8
			},
			{
				bidId: 14,
				answer: 8
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 38,
		taskId: 'round-2-multiple-opgave-38',
		title: 'Opgave 38',
		type: 'multiple-choice',
		style: 'singleLine',
		images: [
			{
				bidId: 8,
				image: 'badebro'
			},
			{
				bidId: 9,
				image: 'tilbygning'
			},
			{
				bidId: 10,
				image: 'skaterRampe'
			},
			{
				bidId: 11,
				image: 'binding'
			},
			{
				bidId: 12,
				image: 'typehus'
			},
			{
				bidId: 13,
				image: 'terrasse'
			},
			{
				bidId: 14,
				image: 'gips'
			},
		],
		text: 'Hvilke Materialer indgår IKKE i projektet',
		optionsList: [
			{
				bidId: 8,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Stolper', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Remme', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Stålreklar', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Bjælker', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Gangbrædder', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Bræddebolte', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 9,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Limtræ', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Vægstolper', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Stålreklar', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Betondæk', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'OSB plader', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '50 mm ringsøm', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 10,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Stige', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Stolper', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Betondæk', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Støbekrydsfiner', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Vinkelbeslag', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Skruer', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 11,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Puds', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '5x5 tømmer', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Undertag', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Skruefundament', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Betondæk', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Taglægter', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 12,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Vægstolper', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Eternitplader', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Vinkelbeslag', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Spær', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Limtræ', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'OSB plader', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 13,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Trykimprægneret træ', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Plastkiler', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Strøer (50x150 mm)', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Stolper (Ø25 cm)', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Galvaniseret stålplade', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Franske skruer (M12x200 mm)', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 14,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Spartelmasse', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Spartelskinne', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Betondæk', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Isolering', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Gipsplader', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Top og Bundskinner', 
						isCorrect: false,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 40,
		taskId: 'round-2-input-geometry-40',
		title: 'Geometri',
		text: 'Formlen her kan bruges til at beregne trekanters areal. Hvor lang er grundlinjen på en trekant, der har højden 6 og arealet 24?',
		image: 'triangleAngle',
		answer: 8,
		unit: ''
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round2-medium-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};