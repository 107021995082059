const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate,{
		assignmentNumber: 50,
		taskId: 'round-3-multiple-choice-diagram-educations-girls',
		title: 'Ungdomsuddannelse',
		text: 'Diagrammet her viser fordelingen af de piger der har søgt ind på en erhvervsuddannelse i 2023. Fordelt på de 4 forskellige Grundforløb. Hvilke grundforløb får flest kvindelige ansøgere?',
		image: 'educations-girls',
		style: 'singleLine',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 1,
				text: 'Teknologi, Byggeri og Transport', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 2,
				text: 'Omsorg, Sundhed og Pædagogik', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 3,
				text: 'Kontor, Handel og Forretningsservice', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 4,
				text: 'Fødevare, Jordbrug og Oplevelser', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate,{
		assignmentNumber: 53,
		taskId: 'round-3-multiple-choice-diagram-educations-highschools-applicants-years',
		title: 'Ungdomsuddannelse',
		text: 'Dette diagram viser antallet af ansøgere og fordelingen mellem Gymnasiale uddannelser og erhvervsuddannelser. Hvilket af de 5 år var antallet af ansøgere til Erhvervsuddannelser højest?',
		image: 'educations-highschools-applicants',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 1,
				text: '2018', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 2,
				text: '2019', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 3,
				text: '2020', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 4,
				text: '2021', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 5,
				text: '2022', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate,{
		assignmentNumber: 54,
		taskId: 'round-3-multiple-choice-diagram-educations-highschools-applicants-years-2',
		title: 'Ungdomsuddannelse',
		text: 'Dette diagram viser antallet af ansøgere og fordelingen mellem Gymnasiale uddannelser og erhvervsuddannelser. Hvilket af de 5 år var antallet af ansøgere til Gymnasiale uddannelser højest?',
		image: 'educations-highschools-applicants',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 1,
				text: '2018', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 2,
				text: '2019', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 3,
				text: '2020', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 4,
				text: '2021', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 5,
				text: '2022', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 60,
		taskId: 'round-3-price-VAT',
		title: 'Hvad er prisen med Moms',
		text: 'Den udregnede pris er uden moms. I danmark skal man betale 25% i Moms. Hvor meget skal kunden betale hvis prisen uden moms er 24500?',
		answer: 30625,
		unit: 'kr',
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round3-hard-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};