const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 16,
		taskId: 'round-1-order-buildingtheory',
		titles: [
			{
				bidId: 1,
				title: 'Bygningsteori'
			},
			{
				bidId: 2,
				title: 'Væg'
			},
			{
				bidId: 3,
				title: 'Gulv'
			},
			{
				bidId: 4,
				title: 'Tag'
			},
			{
				bidId: 5,
				title: 'Væg'
			},
			{
				bidId: 6,
				title: 'Bro'
			},
			{
				bidId: 7,
				title: 'Hegn'
			},
		],
		images: [
			{
				bidId: 1,
				image: 'vinduesudskiftning'
			},
			{
				bidId: 2,
				image: 'butiksindretning'
			},
			{
				bidId: 3,
				image: 'gulv'
			},
			{
				bidId: 4,
				image: 'tag'
			},
			{
				bidId: 5,
				image: 'butiksindretning'
			},
			{
				bidId: 6,
				image: 'bro'
			},
			{
				bidId: 7,
				image: 'hegn-stolpe'
			},
		],
		texts: [
			{
				bidId: 1,
				text: 'Du skal sætte materialerne i denne vindueskonstruktion i den rigtige rækkefølge. Opstil materialerne i den rigtige rækkefølge'
			},
			{
				bidId: 2,
				text: 'Du skal sætte materialerne i denne vægkonstruktion i den rigtige rækkefølge. Opstil materialerne i den rigtige rækkefølge'
			},
			{
				bidId: 3,
				text: 'Du skal sætte materialerne i denne gulvkonstruktion i den rigtige rækkefølge. Opstil materialerne i den rigtige rækkefølge'
			},
			{
				bidId: 4,
				text: 'Du skal sætte materialerne i denne tagkonstruktion i den rigtige rækkefølge. Opstil materialerne i den rigtige rækkefølge'
			},
			{
				bidId: 5,
				text: 'Du skal sætte materialerne i denne vægkonstruktion i den rigtige rækkefølge. Opstil materialerne i den rigtige rækkefølge'
			},
			{
				bidId: 6,
				text: 'Du skal sætte materialerne i denne brokonstruktion i den rigtige rækkefølge. Opstil materialerne i den rigtige rækkefølge'
			},
			{
				bidId: 7,
				text: 'Du skal sætte materialerne i denne hegnskonstruktion i den rigtige rækkefølge. Opstil materialerne i den rigtige rækkefølge'
			},
		],
		itemsList: [
			{
				bidId: 1,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Indfatninger', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Lysningsplade', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Vindueskarm', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Vinduesramme', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Glasrude', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Glasliste', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: 2,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Udvendig træbeklædning', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Afstandslister', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Vindspærre', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Stolper', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Isolering', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Dampspærre', correctOrderNumbers: [6]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 7, text: 'Installationslag', correctOrderNumbers: [7]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 8, text: 'Indvendig beklædning', correctOrderNumbers: [8]
					}),
				]
			},
			{
				bidId: 3,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Betondæk', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Dampspærre', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'opklodsningskiler', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Gulvstrøer', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Isolering', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Undergulv', correctOrderNumbers: [6]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 7, text: 'Gulvpap', correctOrderNumbers: [7]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 8, text: 'Flydende gulv', correctOrderNumbers: [8]
					}),
				]
			},
			{
				bidId: 4,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Tagsten', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Lægter', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Afstandslister', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Undertag', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Spær', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Isolering', correctOrderNumbers: [6]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 7, text: 'Dampspærre', correctOrderNumbers: [7]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 8, text: 'Installationslag', correctOrderNumbers: [8]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 9, text: 'Indvendig beklædning', correctOrderNumbers: [9]
					}),
				]
			},
			{
				bidId: 5,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Udvendig træbeklædning', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Afstandslister', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Vindspærre', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Stolper', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Isolering', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Dampspærre', correctOrderNumbers: [6]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 7, text: 'Installationslag', correctOrderNumbers: [7]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 8, text: 'Indvendig beklædning', correctOrderNumbers: [8]
					}),
				]
			},
			{
				bidId: 6,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Stolpe-beton', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Bærende stolper', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Remme', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Strøer', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Gangbrædder', correctOrderNumbers: [5]
					}),
				]
			},
			{
				bidId: 7,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Stolpe-beton', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Hegns-stolpe', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Rafter til beklædning af hegnet', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Søm til montage af hegn', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Zinktoppe til stolperne', correctOrderNumbers: [5]
					}),
				]
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 18,
		taskId: 'round-1-multiple-bygningsteori',
		style: 'singleLine',
		titles: [
			{
				bidId: 1,
				title: 'Vinduets elementer'
			},
			{
				bidId: 2,
				title: 'Vægkonstruktion'
			},
			{
				bidId: 3,
				title: 'Gulvets elementer'
			},
			{
				bidId: 4,
				title: 'Hvad består et legehus af?'
			},
			{
				bidId: 5,
				title: 'Hvad skal du have med til opgaven'
			},
			{
				bidId: 6,
				title: 'Pak bilen rigtigt'
			},
			{
				bidId: 7,
				title: 'Pak bilen rigtigt'
			},
		],
		type: 'multiple-choice',
		images: [
			{
				bidId: 1,
				image: 'vinduesudskiftning'
			},
			{
				bidId: 2,
				image: 'butiksindretning'
			},
			{
				bidId: 3,
				image: 'gulv'
			},
			{
				bidId: 4,
				image: null
			},
			{
				bidId: 5,
				image: null
			},
			{
				bidId: 6,
				image: null
			},
			{
				bidId: 7,
				image: null
			},
		],
		texts: [
			{
				bidId: 1,
				text: 'Vælg de elementer der IKKE indgår i et vindue'
			},
			{
				bidId: 2,
				text: 'Vælg de elementer der IKKE indgår i en vægkonstruktion'
			},
			{
				bidId: 3,
				text: 'Vælg de elementer der IKKE indgår i en gulvkonstruktion'
			},
			{
				bidId: 4,
				text: 'Vælg de elementer der IKKE indgår i et legehus'
			},
			{
				bidId: 5,
				text: 'Vælg de elementer der IKKE indgår i en efterisoleringsopgave'
			},
			{
				bidId: 6,
				text: 'Vælg de elementer der IKKE indgår i en dykkerbro'
			},
			{
				bidId: 7,
				text: 'Vælg de elementer der IKKE indgår i et Hegn'
			},
		],
		optionsList: [
			{
				bidId: 1,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Glasliste', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Vandnæse', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Anverfer', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Stormkrog', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Thermoglas', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Tætningslister', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Paskvilgreb', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Karmtræ', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Rammetræ', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: 'Mørtel', 
						isCorrect: true,
					}),
				],
			},
			{
				bidId: 2,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Tagsten', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Skruer', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Søm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Afstandslister', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Indvendig beklædning', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Isolering', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Vinkelbeslag', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Bærende stolper', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Vindspærre', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: 'Dampspærre', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 3,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Strøer', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Undergulv', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Gulvvarmeslanger', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Varmefordelingsplader', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Ringsøm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Isolering', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Mørtel', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Linoleum', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Spånplade', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: 'Lamelparket', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 4,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Strøer', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Bjælker', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Montage Skum', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Beklædningsbrædder', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Taglægter', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Gulvbrædder', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Tagbelægning', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Søm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Skruer', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 5,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Fugemørtel', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Boremaskine', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Indblæsningsmaskine', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Isoleringsgranulat', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Bræddebolte', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Støvmaske', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 6,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Stolper', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Gangbrædder', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Strøer', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Remme', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Betonfliser', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Søm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Skruer', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Bræddebolte', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 7,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Stolper', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Rafter', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Betontagsten', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Søm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Skruer', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Afdækningstoppe', 
						isCorrect: false,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 19,
		taskId: 'round-1-order-material-2',
		title: 'Materialpyramiden',
		linkFile: 'materialepyramiden',
		text: `Opstil materialerne i rækkefølge fra mest CO2 forbrug til mindst CO2 forbrug. Det materiale der udleder mest Co2 skal stå øverst.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Aluminiumsplader', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Stenuld', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Betontagsten', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Mursten (tegl enkeltbrændt)', correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Træfiberisolering', correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, text: 'Konstruktionstræ', correctOrderNumbers: [6]
			}),
		]
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round1-hard-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};