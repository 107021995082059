import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './workshop.scss';
import CornerLogo from 'components/ui/corner-logo/corner-logo';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import { pageUiTexts } from 'data/ui-texts';
import { groupData, initialCapacity } from 'data/group-data';
import { bids, consolationTasks } from 'data/bid-data';
import { gameStepsData } from 'data/game-steps-data';
import Assignments from '../assignments/assignments';
import { shopCategories, shopItems } from 'data/workshop-data';
import { dotformatNumber } from 'helpers/text-helper';
import {ReactComponent as Machine0} from 'assets/images/shopItems/machines/machine-0.svg';
import {ReactComponent as Machine1} from 'assets/images/shopItems/machines/machine-1.svg';

const Workshop = ({setBackground, setBoughtItems, updateGroup, group, game}) => {
	const roundNumber = gameStepsData.find((step) => {
		return step.id === game.gameStepId;
	}).roundNumber;

	const groupInfo = groupData.find((data) => {
		return data.id === group.id;
	});

	const [groupStats, setGroupStats] = useState(groupInfo.groupStats);
	
	// Array with the groups won bids
	const [wonBids, setWonBids] = useState([]);
	const [isAssignmentsOpen, setIsAssignmentsOpen] = useState(true);
	const [isShopOpen, setIsShopOpen] = useState(false);
	const [isPopupShown, setIsPopupShown] = useState(true);
	const [popupMessage, setPopupMessage] = useState('');
	const [popupTitle, setPopupTitle] = useState('');
	
	// Shop Variables
	const [currentItem, setCurrentItem] = useState(null);
	const [currentCategory, setCurrentCategory] = useState(shopCategories[0]);
	const [currentPageItems, setCurrentPageItems] = useState([]);
	const [CurrentItemComponent, setCurrentItemComponent] = useState(null);

	/**
	 * Sets current shop category, finds the apropriate list of items and resets current shop item 
	 * @param {Object} category
	 */
	const setShopCategory = (category) => {
		setCurrentCategory(category);
		
		const newList = shopItems.filter((item) => {
			return item.categoryId === category.id;
		});

		setCurrentItem(null);
		setCurrentPageItems(newList);
	};

	/**
	 * Applies item upgrades to group.
	 * Subtracts item cost from group money.
	 * @param {Object} item 
	 */
	const buyUpgrade = (item) => {
		const groupMoney = group.money - item.price;
		const purchasedUpgrades = group.purchasedUpgrades ? group.purchasedUpgrades : [];
		purchasedUpgrades.push(item.id);

		let capacity = group.capacity !== undefined ? group.capacity : initialCapacity;
		let newGroupStats = groupStats;

		item.upgrades.forEach((upgrade) => {
			if (upgrade.id === 'capacity') {
				capacity = capacity + upgrade.value;
			} else {
				let groupStat = newGroupStats.find((gStat) => {
					return gStat.id === upgrade.id;
				});

				groupStat.value += upgrade.value;
			}
		});

		updateGroup({
			money: groupMoney,
			capacity: capacity,
			groupStats: newGroupStats,
			purchasedUpgrades: purchasedUpgrades,
		});
	};

	/**
	 * updates the bought state
	 */
	useEffect(() => {
		if (group) {
			setBoughtItems(group.purchasedUpgrades);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [group.purchasedUpgrades]);

	useEffect(() => {
		setBackground('workshop');
	
		// Find all bids that the group won
		const bidWinners = game.bidWinners;
		const resultingBidList = [];

		if (group.groupStats) {
			setGroupStats(group.groupStats);
		}

		setShopCategory(currentCategory);

		if (bidWinners) {
			// Groups can win multiple bids
			const bidList = bidWinners.filter((bid) => {
				return bid.groupId === group.id;
			});

			// If bidList is not empty, then we find all bid data for the group 
			if (bidList.length !== 0) {
				let foundBid = null;
				bidList.forEach((bid) => {
					foundBid = bids.find((b) => {return b.id === bid.bidId;});

					if (!foundBid) {
						console.error(pageUiTexts.noBidIdInData + ' ' + bid.id);
						return;
					}

					resultingBidList.push(foundBid);
				});

				// If there are more than one winning bid for this group
				// We sort descendingly based on bid value
				if (resultingBidList.length > 1) {
					resultingBidList.sort((a, b) => {
						return b.value - a.value;
					});
				}
				
				let message = '';
				if (resultingBidList.length === 1) {
					message = foundBid.winText;
				} else {
					// Adjusting message with won bid data in case of winning extra bids
					message = resultingBidList[0].winText + '<br /><br />' 
						+ pageUiTexts.wonBidsPopup.messageExtra;
				}
				setPopupTitle(pageUiTexts.wonBidsPopup.title);
				setPopupMessage(message);
			} else {
				// If the group didn't win any bids, they are given this rounds consolation task
				const roundNumber = gameStepsData.find((step) => {
					return step.id === game.gameStepId;
				}).roundNumber;
				const roundConsolationTask = consolationTasks.find((task) => {
					return task.round === roundNumber && task.difficultyId === game.difficulty;
				});
				resultingBidList.push(roundConsolationTask);

				// Adjusting message with won bid data
				setPopupTitle(pageUiTexts.consolationPopupTitle);
				setPopupMessage(roundConsolationTask.message);
			}
		}

		setWonBids(resultingBidList);
	}, [
		group.id,
		game.bidWinners,
		game.difficulty,
		game.gameStepId,
		currentCategory,
		group.groupStats,
		setBackground,
		updateGroup
	]);

	return (
		<div className="Workshop">
			{isPopupShown && <Popup
				title={popupTitle}
				message={popupMessage}
				cancelButtonText={pageUiTexts.wonBidsPopup.cancel}
				onContinueButtonClick={() => {
					setIsPopupShown(false);
				}}
				onCancelButtonClick={() => {setIsPopupShown(false);}}
				showContinue={false}
			/>}

			{/* Assignment window */}
			{isAssignmentsOpen && wonBids && wonBids.length > 0 ? 
				<div className='Workshop-assignmentWindow'>
					<Assignments
						updateGroup={updateGroup}
						bidData={wonBids[0]} 
						roundNumber={roundNumber}
						group={group}
						game={game}
					/>
				</div> : ''
			}

			<div className='Workshop-header'>
				<div className='Workshop-logo'>
					<CornerLogo/>
				</div>

				<div className='Workshop-uiWrapper'>
					<div className='Workshop-headerButtons'>
						<div className='Workshop-headerButton'>
							<Button 
								text={pageUiTexts.assignments}
								classes={['pushable van' + (isAssignmentsOpen ? '' : ' darkBlue')]}
								onClick={() => {setIsShopOpen(false); setIsAssignmentsOpen(!isAssignmentsOpen);}}/>
						</div>
						<div className='Workshop-headerButton'>
							<Button 
								text={pageUiTexts.shop}
								classes={['pushable cart' + (isShopOpen ? '' : ' darkBlue')]}
								onClick={() => {setIsShopOpen(!isShopOpen); setIsAssignmentsOpen(false);}}/>
						</div>
					</div>

					<div className='Workshop-groupInfoWrapper'>
						<div className='Workshop-groupInfobox'>
							<div className='Workshop-groupName'>
								<span>
									{groupInfo.name}
								</span>
							</div>
							<div className='Workshop-cornerWrapper'>
								<div className={'Workshop-corner groupBackground' + group.id}/>
							</div>

							<div className='Workshop-groupStats'>
								<div className={'Workshop-groupCapacity margined groupBackground' + group.id}>
									<span>
										{group.capacity !== undefined && group.capacity !== null ? 
											group.capacity : initialCapacity}
									</span>
								</div>
								{groupStats.map((stats, index) => {
									let notLast = false;
									if (index !== stats.length - 1) {
										notLast = true;
									}

									return (
										<div key={index} className={'Workshop-qualityWrapper' + 
											(notLast ? ' margined' : '')}>
											<div className={'Workshop-qualityIcon ' + stats.id}/>
											<div className='Workshop-qualityValue'>
												<span>{stats.value}</span>
											</div>
										</div>
									);
								})}
							</div>

							<div className='Workshop-cashDisplay'>
								<span>{(group.money ? group.money : 0) + pageUiTexts.currency}</span>
							</div>
						</div>

						{isShopOpen && currentItem ? 
							<div className='Workshop-shopItemWindow'>
								<div className='Workshop-currentItem'>
									{CurrentItemComponent ?
										<CurrentItemComponent
											className={
												'Workshop-currentItemImage ' + 
												currentCategory.imageId + '-' + currentItem.id +
												' group-' + group.id
											}
										/>
										:
										<div className={'Workshop-currentItemImage ' + 
											currentCategory.imageId + '-' + 
											currentItem.id}
										/>
									}
									<div className='Workshop-currentItemName'>
										<span>{currentItem.name}</span>
									</div>
								</div>
								<div className='Workshop-currentItemPrice'>
									<span>{dotformatNumber(currentItem.price) + pageUiTexts.currency}</span>
								</div>
								<div className='Workshop-currentItemUpgrades'>
									{currentItem.upgrades.map((upgrade, index) => {
										return (
											<div key={index} className='Workshop-upgradeWrapper'>
												{upgrade.id === 'capacity' ? 
													<div className={'Workshop-capacityUpgrade groupBackground' + 
														group.id}>
														<span>{upgrade.value}</span>
													</div>
													: 
													<div className='Workshop-groupStatUpgrade'>
														<div className={'Workshop-groupStatIcon ' + upgrade.id}/>
														<div className='Workshop-groupStatValue'>
															<span>{upgrade.value}</span>
														</div>
													</div>
												}
											</div>
										);
									})}
								</div>
								{/* Button is disabled if group does not have the money to purchase the current item */}
								<Button
									isDisabled={currentItem.price > group.money || group.money === undefined}
									classes={['flatBlue']}
									text={pageUiTexts.buy} 
									onClick={() => {buyUpgrade(currentItem);}}
								/>
							</div> : ''
						}
					</div>
				</div>

			</div>
			<div className='Workshop-footer'>
				{/* Shop panels */}
				{isShopOpen ? 
					<div className='Workshop-shopPanel'>
						<div className='Workshop-shopCategoryWrapper'>
							{shopCategories.map((category, index) => {
								let isCurrent = false;

								if (currentCategory) {
									isCurrent = category.id === currentCategory.id;
								}

								return (
									<div key={index}
										className={'Workshop-shopCategory' + 
											(isCurrent ? ' active' : '') +
											(index + 1 === shopCategories.length ? ' last' : '')} 
										onClick={() => {setShopCategory(category);}}>
										<span>{category.name}</span>
									</div>
								);
							})}
						</div>
						<div className='Workshop-shopBlueBorder'/>
						<div className='Workshop-shopInventory'>
							{currentPageItems.map((pageItem, index) => {
								let alreadyPurchased = false;
								if (group.purchasedUpgrades) {
									alreadyPurchased = group.purchasedUpgrades.find((upgrade) => {
										return upgrade === pageItem.id;
									}) !== undefined;
								}

								const imageId = currentCategory.imageId + '-' + pageItem.id;
								let ItemComponent = null;
								if (imageId === 'machine-0') {
									ItemComponent = Machine0;
								} else if (imageId === 'machine-1') {
									ItemComponent = Machine1;
								}

								if (alreadyPurchased) return '';
								return (
									<div key={index} 
										className={'Workshop-shopItem' +
										(index + 1 === currentPageItems.length ? ' last' : '') +
										(currentItem && currentItem.id === pageItem.id ? ' current' : '')}
										onClick={() => {
											setCurrentItemComponent(ItemComponent);
											setCurrentItem(pageItem);
										}}>

										{ItemComponent ?
											<ItemComponent 
												className={'Workshop-itemImage ' + imageId + ' group-' + group.id}
											/>
											:
											<div className={'Workshop-itemImage ' + imageId}/>
										}
										<div className='Workshop-itemPrice'>
											<span>
												{dotformatNumber(pageItem.price) + pageUiTexts.currency + '.'}
											</span>
										</div>
									</div>
								);
							})}
						</div>
					</div> : ''
				}
			</div>
		</div>
	);
};

Workshop.propTypes = {
	setBackground: PropTypes.func.isRequired,
	setBoughtItems: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	group: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
};

export default Workshop;