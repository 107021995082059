import React, {useEffect, useState} from 'react';
import './bid-overview.scss';
import GameCode from 'components/ui/game-code/game-code';
import PropTypes from 'prop-types';
import GroupSidebar from 'components/ui/group-sidebar/group-sidebar';
import Progressbar from 'components/ui/progressbar/progressbar';
import { bids } from 'data/bid-data';
import BidBox from 'components/ui/bid-box/bid-box';
import Popup from 'components/ui/popup/popup';
import { pageUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import { getBidWinners, getRoundBids } from 'helpers/bidding-helper';
import { gameStepsData } from 'data/game-steps-data';

const BidOverview = ({handleGoToGameStep, updateGroup, updateGame, gameCode, groups, game}) => {
	const [isPopupShown, setIsPopupShown] = useState(false);
	const [isResultShown, setIsResultShown] = useState(false);
	const [roundBids, setRoundBids] = useState([]);
	
	useEffect(() => {
		const roundNumber = gameStepsData.find((step) => {
			return step.id === game.gameStepId;
		}).roundNumber;

		const currentBids = getRoundBids(roundNumber, game.difficulty);

		setRoundBids(currentBids);
	}, [game.gameStepId, game.difficulty]);

	const roundNumber = gameStepsData.find((step) => {
		return step.id === game.gameStepId;
	}).roundNumber;

	const getBidResults = () => {
		const bidWinners = getBidWinners(groups, roundNumber, game.difficulty);

		// Rewarding groups with multiple won bids
		groups.forEach((group) => {
			const wonBids = bidWinners.filter((bid) => {
				return bid.groupId === group.id;
			});

			let resultingBidList = [];
			wonBids.forEach((bid) => {
				const foundBid = bids.find((b) => {return b.id === bid.bidId;});

				if (!foundBid) {
					console.error(pageUiTexts.noBidIdInData + ' ' + bid.id);
					return;
				}

				resultingBidList.push(foundBid);
			});

			// Group has won multiple bids, they get a reward for each other than the first
			// The first is the one of highest value, which is used for calculating the base value of each assignment
			let extraBidReward = 0;
			if (resultingBidList.length > 1) {
				
				// Sorting by value, placing highest value bid first
				resultingBidList.sort((a, b) => {
					return b.value - a.value;
				});

				// Summing reward from all won bids except first
				resultingBidList.forEach((bid, index) => {
					if (index === 0) return;
					extraBidReward += bid.value / 2;
				});
			}

			// If a group has bidded on a bid and not won it, their capacity is refunded.
			let refundedCapacity = 0;
			if (group.bids) {
				group.bids.forEach((bid) => {
					const wonBid = wonBids.find((wBid) => {return wBid.bidId === bid.id;});

					if (!wonBid) {
						refundedCapacity += bid.bidValue;
					}
				});
			}

			// Updating group
			updateGroup(game.code + '-' + group.id, {
				money: group.money ? group.money + extraBidReward : extraBidReward,
				totalEarnedMoney: group.totalEarnedMoney + extraBidReward,
				capacity: group.capacity ? group.capacity + refundedCapacity : refundedCapacity,
			});
		});

		// Update game with bid winners
		updateGame({bidWinners: bidWinners});
	};

	const handleShowResults = () => {
		let haveAllGroupsBids = true;

		groups.forEach((group) => {
			if (group.areBidsSaved === undefined || !group.areBidsSaved) {
				haveAllGroupsBids = false;
				return;
			}
		});
		
		if (!haveAllGroupsBids) {
			setIsPopupShown(true);
		} else {
			getBidResults();
			handleGoToGameStep('next'); 
			setIsResultShown(true); 
		}
	};

	return (
		<div className='BidOverview'>
			{isPopupShown && <Popup
				title={pageUiTexts.showResults.title}
				message={pageUiTexts.showResults.message}
				cancelButtonText={pageUiTexts.showResults.cancel}
				onContinueButtonClick={() => {
					getBidResults();
					handleGoToGameStep('next');
					setIsResultShown(true); 
					setIsPopupShown(false);
				}}
				onCancelButtonClick={() => {setIsPopupShown(false);}}
				showContinue={true}
			/>}
			<div className='BidOverview-headerWrapper'>
				<div className='BidOverview-gameCode'>
					<GameCode gameCode={gameCode}/>
				</div>
				<div className='BidOverview-progressbar'>
					<Progressbar roundNumber={roundNumber}/>
				</div>
				<div className='BidOverview-sidebar'>
					{/* We only show the updated capacities for the groups, once the results are shown */}
					<GroupSidebar showUpdatedCapacity={isResultShown} showingVoteFeedback={true} groups={groups}/>
				</div>
			</div>
			<div className='BidOverview-bids'>
				{roundBids.map((bid, index) => {
					let bidWinner = null;
					// Checking if there are currently any bid winners
					if (game.bidWinners) {
						// Finding bid winner for current bid
						const currentBidWinner = game.bidWinners.find((bidWinner) => {
							return bidWinner.bidId === bid.id;
						});

						// If there is a bid winner for current bid, then we get the group id
						if (currentBidWinner) {
							bidWinner = currentBidWinner.groupId;
						}
					}
					
					return (
						<div key={index} className={'BidOverview-bidBox position' + index}>
							<BidBox
								isFacilitator={true}
								groups={groups}
								bidInfo={bid}
								bidWinner={bidWinner}
								isResultShown={isResultShown}
							/>
						</div>
					);
				})}
			</div>

			{isResultShown ?
				<div className='BidOverview-button'>
					<Button
						text={pageUiTexts.continueFromBidding}
						classes={['pushable']}
						isOnRelease={true}
						onClick={() => {handleGoToGameStep('next');}}
					/>
				</div>
				:
				<div className='BidOverview-button'>
					<Button
						text={pageUiTexts.showResultsButton}
						classes={['pushable']}
						isOnRelease={true}
						onClick={() => {
							handleShowResults();
						}}
					/>
				</div>}
		</div>
	);
};

BidOverview.propTypes = {
	handleGoToGameStep: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	updateGame: PropTypes.func.isRequired,
	gameCode: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	game: PropTypes.object.isRequired,
};


export default BidOverview;