const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 27,
		taskId: 'round-2-rectangle-area',
		image: 'area-rectangle-2',
		title: 'Hvad er arealet?',
		text: 'Figuren her er en rektangel. Der er sat mål på tegningen. Hvad er arealet af figuren i kvadratmeter (m2)?',
		answer: 12,
		unit: 'm2',
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 28,
		taskId: 'round-2-rectangle-circumference',
		image: 'area-rectangle-2',
		title: 'Hvad er omkredsen?',
		text: 'Figuren her er en rektangel. Der er sat mål på tegningen. Hvad er omkredsen af figuren i meter (m)?',
		answer: 14,
		unit: 'm',
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 40,
		taskId: 'round-2-wooden-terrace',
		title: 'Træterrasse',
		text: 'I skal regne materialer ud til en anden opgave: En terrasse i har bygget skal have lavet en kant hele vejen rundt. Terrassen er 480 cm lang og 300 cm bred. Hvor mange meter ekstra træ skal i bestille?',
		answer: 15.6,
		unit: 'm',
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round2-hard-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};