import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { gameStepsData } from 'data/game-steps-data';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import Loading from 'components/loading/loading';
import {DndProvider} from 'react-dnd-multi-backend';
// Facilitator Components
import BidOverview 				from 'components/game-steps/facilitator/bid-overview/bid-overview';
import BuildTimer 				from 'components/game-steps/facilitator/build-timer/build-timer';
import GameIntro 				from 'components/game-steps/facilitator/game-intro/game-intro';
import GameOver 				from 'components/game-steps/facilitator/game-over/game-over';
// Student Components
import Attention 				from 'components/game-steps/student/attention/attention';
import BidResult 				from 'components/game-steps/student/bid-result/bid-result';
import Workshop 				from 'components/game-steps/student/workshop/workshop';
import Bid 						from 'components/game-steps/student/bid/bid';
// Shared Components
import Result 					from 'components/game-steps/result/result';
import FinalResult 				from 'components/game-steps/final-result/final-result';
import LogoutButton 			from 'components/ui/logout-button/logout-button';
import FinalAchievement 		from 'components/game-steps/final-achievement/final-achievement';
import PlayButton from 'components/ui/playButton/playButton';

const gameSteps = {
	// facilitator
	bidOverview:		{component: BidOverview},
	buildTimer:			{component: BuildTimer},
	gameIntro: 			{component: GameIntro},
	gameOver:			{component: GameOver},

	// student
	attention: 			{component: Attention},
	bidResult:			{component: BidResult},
	workshop:			{component: Workshop},
	bid:				{component: Bid},

	// Shared
	result:				{component: Result},
	finalResult:		{component: FinalResult},
	finalAchievement:	{component: FinalAchievement}
};

class GameController extends Component {

	/**
	 * Mounting component
	 */
	componentDidMount = () => {
		if (!this.props.isFacilitator && this.props.musicIsPlaying) {
			this.props.setPlayMusic(false);
		}

		if (this.props.game === undefined || this.props.game.gameStepId === undefined) {
			this.props.updateGame({gameStepId: gameStepsData[0].id});
		}

		if (this.props.setBackgroundGroupId && this.props.group) {
			this.props.setBackgroundGroupId(this.props.group.id);
		}
	}

	/**
	 * On component unmount
	 */
	componentWillUnmount = () => {
		if (this.props.setPlayMusic) {
			this.props.setPlayMusic(false);
		}
	}

	/**
	 * Gets the current gamestep and the current gamestep data
	 */
	getCurrentGameStep = () => {
		const gameStepIndex = (gameStepsData.findIndex((step) => {
			return step.id === this.props.game.gameStepId;
		}));

		let newGameStepId = null;
		if (gameStepIndex !== -1) {
			const gameStep = gameStepsData[gameStepIndex];
			newGameStepId = this.props.isFacilitator ? gameStep.facilitatorPage : gameStep.studentPage;
		}
		
		return {currentGameStepId: newGameStepId, data: gameStepsData[gameStepIndex]};
	}

	/**
	 * Go to a specific game step with given id
	 * @param {string} id 
	 */
	handleGoToSpecificGameStep = (id) => {
		if (!this.props.isFacilitator) {
			return;
		}

		const gameStepIndex = (gameStepsData.findIndex((step) => {return step.id === id;}));
		const gameStep = gameStepsData[gameStepIndex];

		if (gameStepIndex !== -1) {
			this.props.updateGame({gameStepId: gameStep.id});
		}
	}

	/**
	 * Go to next / previous game step
	 * @param {string} direction
	 * @returns 
	 */
	handleGoToGameStep = (direction) => {
		if (!this.props.isFacilitator) {
			return;
		}
		const game = this.props.game;

		const gameStepId = (game && gameStepsData.some((step) => {return step.id === game.gameStepId;})
			? game.gameStepId 
			: gameStepsData[0].id
		);
		// Getting current step index from game steps array
		const gameStepIndex = gameStepsData.findIndex((step) => {return step.id === gameStepId;});

		let gameStep;

		/* Next / Previous game step */
		if (direction === 'next') {
			gameStep = gameStepsData[gameStepIndex + 1];
		} else if (direction === 'prev' && gameStepIndex > 0) {
			gameStep = gameStepsData[gameStepIndex - 1];
		}

		if (gameStep) {
			this.props.updateGame({gameStepId: gameStep.id});
		}
	}

	/**
	 * Render component
	 */
	render = () => {
		if (this.props.game === null || this.props.game === undefined) {
			return <Loading 
				handleLogout={this.props.handleLogout}
			/>;
		}

		const gameStep = this.getCurrentGameStep();
		let CurrentComponent = null;

		if (gameSteps.hasOwnProperty(gameStep.currentGameStepId)) {
			CurrentComponent = gameSteps[gameStep.currentGameStepId].component;
		}

		// If we are past the intro screen, we only use a list of all groups that are logged in.
		let groups = this.props.groups;
		if (gameStep.currentGameStepId !== 'gameIntro') {
			groups = groups.filter((group) => {
				return group.isPlaying;
			});
		}

		if (CurrentComponent === null && this.props.isFacilitator) {
			return <Loading 
				handleLogout={this.props.handleLogout}
			/>;
		}

		return (
			<DndProvider options={HTML5toTouch}>
				{(this.props.setPlayMusic && this.props.isFacilitator) &&
					<PlayButton setPlayMusic={this.props.setPlayMusic} musicIsPlaying={this.props.musicIsPlaying}/>
				}
				<LogoutButton handleLogout={() => {this.props.handleLogout();}}/>
				<CurrentComponent
					setBackground={this.props.setBackground}
					setBoughtItems={this.props.setBoughtItems}
					setBackgroundGroupId={this.props.setBackgroundGroupId}
					handleLogout={this.props.handleLogout}
					handleGoToGame={this.props.handleGoToGame}
					handleGoToGameStep={this.handleGoToGameStep}
					handleGoToSpecificGameStep={this.handleGoToSpecificGameStep}
					gameStepData={gameStep.data}
					updateGame={this.props.updateGame}
					gameCode={this.props.game.id}
					game={this.props.game}
					group={this.props.group}
					groups={groups}
					updateGroup={this.props.updateGroup}
					isFacilitator={this.props.isFacilitator}
					setPlayMusic={this.props.setPlayMusic}
					finishCurrentGame={this.props.finishCurrentGame}
				/>
			</DndProvider>
		);
	}
}

GameController.propTypes = {
	setBackground: PropTypes.func.isRequired,
	setBoughtItems: PropTypes.func.isRequired,
	setBackgroundGroupId: PropTypes.func,
	isFacilitator: PropTypes.bool.isRequired,
	handleLogout: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func,
	updateGame: PropTypes.func,
	game: PropTypes.object,
	group: PropTypes.object,
	groups: PropTypes.array,
	updateGroup: PropTypes.func,
	setPlayMusic: PropTypes.func,
	musicIsPlaying: PropTypes.bool,
	finishCurrentGame: PropTypes.func,
};

export default GameController;