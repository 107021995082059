import React, {Component} from 'react';
import PropTypes from 'prop-types';
import apiHelper from 'helpers/api-helper';
import { pageUiTexts } from 'data/ui-texts';
import CreateUser from './create-user';

class CreateUserController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			userCreated: false,
			email: null,
			password: null,
			passwordRepeat: null,
			feedback: null
		};
	};

	/**
	 * Update input fields
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	}

	/**
	 * Handle create user request
	 * @param {obj} event 
 	 */
	handleCreateUser = (event) => {
		if (event) event.preventDefault();
		let email = this.state.email;
		let password = this.state.password;
		let passwordRepeat = this.state.passwordRepeat;
		let error = null;
		if (!email || !password || !passwordRepeat) error = pageUiTexts.missingFields;
		if (!error) {
			if (password && password.length < 8) {
				this.setState({feedback: pageUiTexts.weakPassword});
				return;
			} else if (password && password !== passwordRepeat) {
				this.setState({feedback: pageUiTexts.noPasswordMatch});
				return;
			}
			this.createFirebaseUser(email, password);
		} else {
			this.setState({
				feedback: error,
				isLoading: false
			});
		}
	}


	/**
	 * Create user
	 * @param {string} email 
	 * @param {string} password 
	 */
	createFirebaseUser = (email, password) => {
		this.setState({isLoading: true});
		apiHelper('facilitator/create-facilitator', {
			email: email,
			password: password
		}).then(
			(response)=>{
				if (response.status === 'success') {
					this.setState({userCreated: true, isLoading: false});
				} else {
					let errorMsg  = pageUiTexts.unknownError;
					if (response.error.code === 'auth/invalid-email') {
						errorMsg  = pageUiTexts.invalidEmail;
					}
					if (response.error.code === 'auth/user-not-found') errorMsg  = pageUiTexts.emailNotFound;
					if (response.error.code === 'auth/email-already-exists') {
						errorMsg  = pageUiTexts.duplicatePlayerEmail;
					}
					this.setState({
						isLoading: false,
						feedback: errorMsg,
					});
				}
			},
			(error) => {
				console.error(error);
				this.setState({
					isLoading: false,
					feedback: pageUiTexts.unknownError,
				});
			}
		);
	}

	/**
	 * Render create user component
	 */
	render = () => {
		return (
			<CreateUser
				isLoading={this.state.isLoading}
				userCreated={this.state.userCreated}
				email={this.state.email}
				password={this.state.password}
				passwordRepeat={this.state.passwordRepeat}
				feedback={this.state.feedback}
				handleInput={this.handleInput}
				handleCreateUser={this.handleCreateUser}
				setFacilitatorBoxType={this.props.setFacilitatorBoxType}
			/>
		);		
	}
}

CreateUserController.propTypes = {
	setFacilitatorBoxType: PropTypes.func.isRequired,
};

export default CreateUserController;