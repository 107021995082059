const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 41,
		taskId: 'round-3b-price',
		title: 'Overslag',
		images: null,
		texts: 
		[
			{
				bidId: '15b',
				text: 'En kunde har bedt jer om at give et overslag på en opgave: Materialerne til opgaven koster 12.000.- Du vil gerne tjene 15 % på materialerne. I regner med at bruge 18 timer på opgaven. timelønnen er 485,- Hvor meget skal overslaget lyde på i alt?',
			},
			{
				bidId: '16b',
				text: 'En kunde har bedt jer om at give et overslag på en opgave: Materialerne til opgaven koster 12.000.- Du vil gerne tjene 25 % på materialerne. I regner med at bruge 18 timer på opgaven. timelønnen er 485,- Hvor meget skal overslaget lyde på i alt?',
			},
			{
				bidId: '17b',
				text: 'En kunde har bedt jer om at give et overslag på en opgave: Materialerne til opgaven koster 12.000.- Du vil gerne tjene 20 % på materialerne. I regner med at bruge 18 timer på opgaven. timelønnen er 485,-" Hvor meget skal overslaget lyde på i alt?',
			},
			{
				bidId: '18b',
				text: 'En kunde har bedt jer om at give et overslag på en opgave: Materialerne til opgaven koster 12.000.- Du vil gerne tjene 15 % på materialerne. I regner med at bruge 18 timer på opgaven. timelønnen er 585,- Hvor meget skal overslaget lyde på i alt?',
			},
			{
				bidId: '19b',
				text: 'En kunde har bedt jer om at give et overslag på en opgave: Materialerne til opgaven koster 12.000.- Du vil gerne tjene 15 % på materialerne. I regner med at bruge 18 timer på opgaven. timelønnen er 385,- Hvor meget skal overslaget lyde på i alt?',
			},
			{
				bidId: '20b',
				text: 'En kunde har bedt jer om at give et overslag på en opgave: Materialerne til opgaven koster 12.000.- Du vil gerne tjene 15 % på materialerne. I regner med at bruge 18 timer på opgaven. timelønnen er 465,- Hvor meget skal overslaget lyde på i alt?',
			},
			{
				bidId: '21b',
				text: 'En kunde har bedt jer om at give et overslag på en opgave: Materialerne til opgaven koster 12.000.- Du vil gerne tjene 15 % på materialerne. I regner med at bruge 18 timer på opgaven. timelønnen er 515,- Hvor meget skal overslaget lyde på i alt?',
			},
		],
		unit: 'kr',
		answers: [
			{
				bidId: '15b',
				answer: 22530
			},
			{
				bidId: '16b',
				answer: 23730
			},
			{
				bidId: '17b',
				answer: 23130
			},
			{
				bidId: '18b',
				answer: 24330
			},
			{
				bidId: '19b',
				answer: 20730
			},
			{
				bidId: '20b',
				answer: 22170
			},
			{
				bidId: '21b',
				answer: 23070
			},
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 42,
		taskId: 'round-3b-price2',
		title: 'Tilbud',
		images: null,
		texts: 
		[
			{
				bidId: '15b',
				text: 'En kunde har bedt jer om at give tilbud på en opgave: Der skal købes materialer ind for 25.500 I regner med at der skal bruge 30 timer på opgaven. Timeprisen er 385.- For at være sikker på at lave en god forretning, skal I ligge 15 % ovenpå materialeprisen og 30% ovenpå timelønen. Hvor meget skal tilbudet lyde på i alt?',
			},
			{
				bidId: '16b',
				text: 'En kunde har bedt jer om at give tilbud på en opgave: Der skal købes materialer ind for 35.500 I regner med at der skal bruge 30 timer på opgaven. Timeprisen er 385.- For at være sikker på at lave en god forretning, skal I ligge 15 % ovenpå materialeprisen og 30% ovenpå timelønen. Hvor meget skal tilbudet lyde på i alt?',
			},
			{
				bidId: '17b',
				text: 'En kunde har bedt jer om at give tilbud på en opgave: Der skal købes materialer ind for 25.500 I regner med at der skal bruge 28 timer på opgaven. Timeprisen er 385.- For at være sikker på at lave en god forretning, skal I ligge 15 % ovenpå materialeprisen og 30% ovenpå timelønen. Hvor meget skal tilbudet lyde på i alt?',
			},
			{
				bidId: '18b',
				text: 'En kunde har bedt jer om at give tilbud på en opgave: Der skal købes materialer ind for 17.000 I regner med at der skal bruge 19 timer på opgaven. Timeprisen er 385.- For at være sikker på at lave en god forretning, skal I ligge 15 % ovenpå materialeprisen og 30% ovenpå timelønen. Hvor meget skal tilbudet lyde på i alt?',
			},
			{
				bidId: '19b',
				text: 'En kunde har bedt jer om at give tilbud på en opgave: Der skal købes materialer ind for 5.500 I regner med at der skal bruge 10 timer på opgaven. Timeprisen er 385.- For at være sikker på at lave en god forretning, skal I ligge 15 % ovenpå materialeprisen og 30% ovenpå timelønen. Hvor meget skal tilbudet lyde på i alt?',
			},
			{
				bidId: '20b',
				text: 'En kunde har bedt jer om at give tilbud på en opgave: Der skal købes materialer ind for 2.500 I regner med at der skal bruge 3 timer på opgaven. Timeprisen er 385.- For at være sikker på at lave en god forretning, skal I ligge 15 % ovenpå materialeprisen og 30% ovenpå timelønen. Hvor meget skal tilbudet lyde på i alt?',
			},
			{
				bidId: '21b',
				text: 'En kunde har bedt jer om at give tilbud på en opgave: Der skal købes materialer ind for 55.200 I regner med at der skal bruge 43 timer på opgaven. Timeprisen er 385.- For at være sikker på at lave en god forretning, skal I ligge 15 % ovenpå materialeprisen og 30% ovenpå timelønen. Hvor meget skal tilbudet lyde på i alt?',
			},
		],
		unit: 'kr',
		answers: [
			{
				bidId: '15b',
				answer: 44340
			},
			{
				bidId: '16b',
				answer: 55840
			},
			{
				bidId: '17b',
				answer: 43339
			},
			{
				bidId: '18b',
				answer: 29059.5
			},
			{
				bidId: '19b',
				answer: 11330
			},
			{
				bidId: '20b',
				answer: 4376.5
			},
			{
				bidId: '21b',
				answer: 85001.5
			},
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		assignmentNumber: 48,
		taskId: 'round-3b-environmentally-friendly',
		title: 'Træsorter',
		image: 'woodLongevity',
		text: 'Nogle træsorter har et høj niveau af selvimprægnerende stoffer. Med hjælp fra Skemaet kan i svare på spørgsmålet. Match Levetiden med de rigtige træsorter',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Mindre end 5 år', 
				id: 1,
				answers: [1, 2, 3, 4, 5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '5-10 år', 
				id: 2,
				answers: [6, 7, 8, 9]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '10-15 år', 
				id: 3,
				answers: [10, 11, 12]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '15-25 år', 
				id: 4,
				answers: [13, 14, 15]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Ask',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Bøg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Birk',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				text: 'El',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				text: 'Poppel',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				text: 'Elm',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				text: 'Rødgran',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				text: 'Skovfyr',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				text: 'Douglasgran',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 10,
				text: 'Lærk',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 11,
				text: 'Tuja',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 12,
				text: 'Cypress',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 13,
				text: 'Eg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 14,
				text: 'Teak',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 15,
				text: 'Ceder',
			}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 49,
		taskId: 'round-3b-material-knowledge',
		title: 'Træsorters Hårdhed',
		image: 'woodHardness',
		text: 'Forskellige træsorter har forskellig hårdhed. Det er vigtigt, når man eksempelvis skal lægge gulv. Udfra denne tabel, der viser trætypers hårdhed skal I opsætte disse trætyper i rækkefølge  så den hårdeste træsort står øverst.',
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Teak', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Wenge', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Merbau', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Eg', correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Ask', correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, text: 'Birk', correctOrderNumbers: [6]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 7, text: 'fyr', correctOrderNumbers: [7]
			}),
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-3b-match-wood',
		assignmentNumber: 50,
		title: 'Træsorters Oprindelse',
		answerType: 'wood',
		linkFile: 'woodHardness',
		instructions: '',
		text: 'Træ kan komme fra hele verden. Jo længere træet har rejst før det bliver brugt på byggepladsen, jo flere resourcer er der blevet brugt på transporten. I skal udfra denne Tabel matche de 15 forskellige træsorter med forskellige verdensdele.',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Europa', 
				id: 1,
				answers: [1, 2, 3, 4, 5, 6, 7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Nordamerika', 
				id: 2,
				answers: [8, 9, 10]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'S-Ø Asien', 
				id: 3,
				answers: [11, 12]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Østafrika', 
				id: 4,
				answers: [13]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Centralafrika', 
				id: 5,
				answers: [14]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Sydamerika', 
				id: 6,
				answers: [15]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				text: 'Bøg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				text: 'Eg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				text: 'Europæisk ahorn',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				text: 'Ask',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				text: 'Birk',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				text: 'Fyr',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				text: 'Rød Eg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				text: 'Canadisk Ahorn',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				text: 'Valnød',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 10,
				text: 'Amerikansk Valnød',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 11,
				text: 'Merbau',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 12,
				text: 'Teak',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 13,
				text: 'Wenge',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 14,
				text: 'Iroko (Kambala)',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 15,
				text: 'Jatoba',
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 52,
		taskId: 'round-3b-angle-calculation',
		title: 'Vinkelberegning',
		image: 'triangleLetters',
		texts: [
			{
				bidId: '15b',
				text: `Figuren her kan hjælpe dig med at løse opgaven. </br>Vinklerne er: </br>B= 32 </br>C=90 </br>Hvor mange grader er vinkel A?`,
			},
			{
				bidId: '16b',
				text: `Figuren her kan hjælpe dig med at løse opgaven. </br>Vinklerne er: </br>B= 35 </br>C=90 </br>Hvor mange grader er vinkel A?`,
			},
			{
				bidId: '17b',
				text: `Figuren her kan hjælpe dig med at løse opgaven. </br>Vinklerne er: </br>B= 34 </br>C=90 </br>Hvor mange grader er vinkel A?`,
			},
			{
				bidId: '18b',
				text: `Figuren her kan hjælpe dig med at løse opgaven. </br>Vinklerne er: </br>B= 33 </br>C=90 </br>Hvor mange grader er vinkel A?`,
			},
			{
				bidId: '19b',
				text: `Figuren her kan hjælpe dig med at løse opgaven. </br>Vinklerne er: </br>B= 29 </br>C=90 </br>Hvor mange grader er vinkel A?`,
			},
			{
				bidId: '20b',
				text: `Figuren her kan hjælpe dig med at løse opgaven. </br>Vinklerne er: </br>B= 30 </br>C=90 </br>Hvor mange grader er vinkel A?`,
			},
			{
				bidId: '21b',
				text: `Figuren her kan hjælpe dig med at løse opgaven. </br>Vinklerne er: </br>B= 31 </br>C=90 </br>Hvor mange grader er vinkel A?`,
			},
		],
		unit: null,
		answers: [
			{
				bidId: '15b',
				answer: 58,
			},
			{
				bidId: '16b',
				answer: 25,
			},
			{
				bidId: '17b',
				answer: 56,
			},
			{
				bidId: '18b',
				answer: 57,
			},
			{
				bidId: '19b',
				answer: 61,
			},
			{
				bidId: '20b',
				answer: 60,
			},
			{
				bidId: '21b',
				answer: 59,
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 53,
		taskId: 'round-3b-angle-geometry',
		title: 'Længdeberegning',
		image: 'triangleLetters',
		texts: [
			{
				bidId: '15b',
				text: `Figuren her kan hjælpe dig med at løse opgaven: Pytagoras lærersætning kan også hjælpe jer: a<sup>2</sup>+b<sup>2</sup>=c<sup>2</sup> (Svaret skal angives uden decimaler)
				</br>Længderne er:
				</br>a=45
				</br>b=28
				</br>Hvor langt er linjestykket a?`,
			},
			{
				bidId: '16b',
				text: `Figuren her kan hjælpe dig med at løse opgaven: Pytagoras lærersætning kan også hjælpe jer: a<sup>2</sup>+b<sup>2</sup>=c<sup>2</sup> (Svaret skal angives uden decimaler)
				</br>Længderne er:
				</br>a=43
				</br>b=26
				</br>Hvor langt er linjestykket a?`,
			},
			{
				bidId: '17b',
				text: `Figuren her kan hjælpe dig med at løse opgaven: Pytagoras lærersætning kan også hjælpe jer: a<sup>2</sup>+b<sup>2</sup>=c<sup>2</sup> (Svaret skal angives uden decimaler)
				</br>Længderne er:
				</br>a=48
				</br>b=28
				</br>Hvor langt er linjestykket a?`,
			},
			{
				bidId: '18b',
				text: `Figuren her kan hjælpe dig med at løse opgaven: Pytagoras lærersætning kan også hjælpe jer: a<sup>2</sup>+b<sup>2</sup>=c<sup>2</sup> (Svaret skal angives uden decimaler)
				</br>Længderne er:
				</br>a=45
				</br>b=25
				</br>Hvor langt er linjestykket a?`,
			},
			{
				bidId: '19b',
				text: `Figuren her kan hjælpe dig med at løse opgaven: Pytagoras lærersætning kan også hjælpe jer: a<sup>2</sup>+b<sup>2</sup>=c<sup>2</sup> (Svaret skal angives uden decimaler)
				</br>Længderne er:
				</br>a=51
				</br>b=31
				</br>Hvor langt er linjestykket a?`,
			},
			{
				bidId: '20b',
				text: `Figuren her kan hjælpe dig med at løse opgaven: Pytagoras lærersætning kan også hjælpe jer: a<sup>2</sup>+b<sup>2</sup>=c<sup>2</sup> (Svaret skal angives uden decimaler)
				</br>Længderne er:
				</br>a=43
				</br>b=29
				</br>Hvor langt er linjestykket a?`,
			},
			{
				bidId: '21b',
				text: `Figuren her kan hjælpe dig med at løse opgaven: Pytagoras lærersætning kan også hjælpe jer: a<sup>2</sup>+b<sup>2</sup>=c<sup>2</sup> (Svaret skal angives uden decimaler)
				</br>Længderne er:
				</br>a=52
				</br>b=18
				</br>Hvor langt er linjestykket a?`,
			},
		],
		unit: null,
		answers: [
			{
				bidId: '15b',
				answer: 53,
			},
			{
				bidId: '16b',
				answer: 50,
			},
			{
				bidId: '17b',
				answer: 56,
			},
			{
				bidId: '18b',
				answer: 51,
			},
			{
				bidId: '19b',
				answer: 60,
			},
			{
				bidId: '20b',
				answer: 52,
			},
			{
				bidId: '21b',
				answer: 55,
			},
		]
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round3-medium-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};