import React from 'react';
import PropTypes from 'prop-types';
import {useDrop} from 'react-dnd';
import './order-dnd-container.scss';

const OrderDndContainer = ({containerIndex, handleSwitchItems, children}) => {
	/* Drop functionality */
	const [{isOver, canDrop}, drop] = useDrop({
		accept: 'item',
		drop(item) {
			if (handleSwitchItems) {				
				/* Ignore hovering over itself */
				if (item.index === containerIndex) return;

				/* Move card */
				handleSwitchItems(item.index, containerIndex);
			}
		},
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
				canDrop: monitor.canDrop(),
			};
		}
	});

	return (
		<div 
			ref={drop}
			className={'OrderDndContainer index-' + containerIndex + (isOver && canDrop ? ' hover' : '')}
		>
			{children}
		</div>
	);
};

OrderDndContainer.propTypes = {
	containerIndex: PropTypes.number.isRequired,
	handleSwitchItems: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
};

export default OrderDndContainer;
