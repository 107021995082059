const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 24,
		taskId: 'round-2b-measurement-irl',
		title: 'Hvad er målet i virkeligheden?',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Hvis du måler 8 cm på "Plan-billedet" på tegningen, Hvor mange cm er det så i virkeligheden?',
		unit: 'cm',
		answers: [
			{
				bidId: '8b',
				answer: 80
			},
			{
				bidId: '9b',
				answer: 80
			},
			{
				bidId: '10b',
				answer: 160
			},
			{
				bidId: '11b',
				answer: 40
			},
			{
				bidId: '12b',
				answer: 400
			},
			{
				bidId: '13b',
				answer: 160
			},
			{
				bidId: '14b',
				answer: 400
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 30,
		taskId: 'round-2b-total-weight',
		title: 'Totalvægt',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		text: 'På tegningen er der et liste med materialer, vægt og CO2 belastning. Brug denne til at svare på spørgsmålet.	Brug materialelisten til at beregne konstruktionens totale vægt (Svaret angives med 2 decimaler)',
		unit: 'kg',
		answers: [
			{
				bidId: '8b',
				answer: 20.08
			},
			{
				bidId: '9b',
				answer: 23.86
			},
			{
				bidId: '10b',
				answer: 142.08
			},
			{
				bidId: '11b',
				answer: 9.10
			},
			{
				bidId: '12b',
				answer: 611.46
			},
			{
				bidId: '13b',
				answer: 55.84
			},
			{
				bidId: '14b',
				answer: 2012
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 32,
		taskId: 'round-2b-calculate-area',
		title: 'Beregn areal',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		texts: [
			{
				bidId: '8b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningens Plan-billede er der afsat to mål, som kan bruges til at udregne hvor mange kvadratmeter møblet dækker. Hvor mange kvadratmeter dækker møblet? (Svaret angives med 2 decimaler)'
			},
			{
				bidId: '9b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningens Plan-billede er der afsat to mål, som kan bruges til at udregne hvor mange kvadratmeter møblet dækker. Hvor mange kvadratmeter dækker møblet? (Svaret angives med 2 decimaler)'
			},
			{
				bidId: '10b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningens Plan-billede er der afsat to mål, som kan bruges til at udregne hvor mange kvadratmeter møblet dækker. Hvor mange kvadratmeter dækker møblet? (Svaret angives med 2 decimaler)'
			},
			{
				bidId: '11b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningens Plan-billede er der afsat to mål, som kan bruges til at udregne hvor mange kvadratmeter møblet dækker. Hvor mange kvadratmeter dækker møblet? (Svaret angives med 2 decimaler)'
			},
			{
				bidId: '12b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningens Plan-billede er der afsat to mål, som kan bruges til at udregne hvor mange kvadratmeter møblet dækker. Hvor mange kvadratmeter dækker møblet? (Svaret angives med 2 decimaler)'
			},
			{
				bidId: '13b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningens Plan-billede er der afsat to mål, som kan bruges til at udregne hvor mange kvadratmeter møblet dækker. Hvor mange kvadratmeter dækker møblet? (Svaret angives med 2 decimaler)'
			},
			{
				bidId: '14b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På Tegningen Plan-billede er der afsat to mål, som kan bruges til at udregne stuens areal. Hvor mange kvadratmeter er stuen? (Svaret angives med 2 decimaler)'
			},
		],
		unit: 'm2',
		answers: [
			{
				bidId: '8b',
				answer: 0.54
			},
			{
				bidId: '9b',
				answer: 0.76
			},
			{
				bidId: '10b',
				answer: 1.62
			},
			{
				bidId: '11b',
				answer: 0.32
			},
			{
				bidId: '12b',
				answer: 10.8
			},
			{
				bidId: '13b',
				answer: 2.45
			},
			{
				bidId: '14b',
				answer: 11.29
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 33,
		taskId: 'round-2b-material-co2',
		type: 'multiple-choice',
		title: 'Hvilket materiale sviner mest',
		style: 'singleLine tall',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Byggebranchen står for ca. 35 % af hele Danmarks Co2 udledning. Derfor er det vigtigt at orientere sig om hvor meget Co2 de materialer man bruger udleder. Hvilket materiale i Den opgave I er i gang med udleder mest Co2?',
		optionsList: [
			{
				bidId: '8b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Ben',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Sarg',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Sprosse',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Sandpapir',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Olie/Maling',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Træskruer',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Siddestave',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Skråbånd',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '9b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Lange Ben',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Korte Ben',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Sarg',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Træskruer',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Sædde og Topstave',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Olie/Maling',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Skråbånd',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Sprosse',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '10b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Skabshængsler',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Samlebeslag',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Tilsætninger',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Skuffebeslag',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Maling',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Trappetrin',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Sidestykker',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Skabslåger',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '11b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Korte Ben',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Olie/Maling',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Sæde',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Ryglæn',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Lange Ben',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Sandpapir',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Træskruer',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '12b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Tømmer',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Trænagler',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Olie/Maling',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Tagsøm',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Træskruer',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Brædder',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Taglægter',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Tækkespån',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '13b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Olie/Maling',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Sandpapir',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Træskruer',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Sæde og Topstave',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Skråbånd',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Sarg',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Sprosse',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Ben',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '14b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Vindue inkl. indfatninger',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Lænestol',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Hospitalsseng',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Garderobeskab',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Dør inkl. indfatninger',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Spartelmasse og Maling',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Gipsplader',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Stålreklar',
						isCorrect: false,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 34,
		taskId: 'round-2b-order-materials',
		title: 'Arranger materialer',
		linkFile: 'materialepyramiden',
		text: 'Alle kender Madpyramiden, hvor de ting man helst ikke skal spise for meget af ligger øverst. Men hvad med Materialepyramiden? Den har samme princip: Det der ligger øverst er det der svinder mest. og derfor skal man overveje at bruge det så lidt som muligt. I skal udfra Materialepyramiden arrangere disse 6 isoleringsmaterialer sådan at det der udleder mest Co2 står øverst. og det der udleder mest står nederst',
		itemsList: [
			{
				bidId: '8b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'PUR isolering', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'EPS isolering', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Stenuld', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Glasuld', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Papiruld', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Træfiberisolering', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '9b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'PUR isolering', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'EPS isolering', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Stenuld', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Glasuld', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Papiruld', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Træfiberisolering', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '10b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'PUR isolering', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'EPS isolering', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Stenuld', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Glasuld', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Papiruld', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Træfiberisolering', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '11b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'PUR isolering', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'EPS isolering', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Stenuld', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Glasuld', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Papiruld', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Træfiberisolering', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '12b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'PUR isolering', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'EPS isolering', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Stenuld', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Glasuld', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Papiruld', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Træfiberisolering', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '13b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'PUR isolering', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'EPS isolering', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Stenuld', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Glasuld', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Papiruld', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Træfiberisolering', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '14b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'PUR isolering', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'EPS isolering', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Stenuld', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Glasuld', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Papiruld', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Træfiberisolering', correctOrderNumbers: [6]
					}),
				]
			},
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 35,
		taskId: 'round-2b-order-materials2',
		title: 'Arranger materialer',
		linkFile: 'materialepyramiden',
		text: 'Alle kender Madpyramiden, hvor de ting man helst ikke skal spise for meget af ligger øverst. Men hvad med Materialepyramiden? Den har samme princip: Det der ligger øverst er det der svinder mest. og derfor skal man overveje at bruge det så lidt som muligt. I skal udfra Materialepyramiden arrangere disse 6 beklædningsmaterialer sådan at det der udleder mest Co2 står øverst. og det der udleder mest står nederst',
		itemsList: [
			{
				bidId: '8b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluminiumsplade', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Kobberplade', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Skiffer', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Klinker', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Puds', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Grantræ', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '9b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluminiumsplade', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Kobberplade', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Skiffer', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Klinker', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Puds', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Grantræ', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '10b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluminiumsplade', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Kobberplade', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Skiffer', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Klinker', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Puds', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Grantræ', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '11b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluminiumsplade', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Kobberplade', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Skiffer', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Klinker', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Puds', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Grantræ', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '12b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluminiumsplade', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Kobberplade', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Skiffer', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Klinker', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Puds', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Grantræ', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '13b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluminiumsplade', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Kobberplade', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Skiffer', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Klinker', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Puds', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Grantræ', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '14b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluminiumsplade', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Kobberplade', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Skiffer', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Klinker', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Puds', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Grantræ', correctOrderNumbers: [6]
					}),
				]
			},
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 35,
		taskId: 'round-2b-order-materials3',
		title: 'Arranger materialer',
		linkFile: 'materialepyramiden',
		text: 'Alle kender Madpyramiden, hvor de ting man helst ikke skal spise for meget af ligger øverst. Men hvad med Materialepyramiden? Den har samme princip: Det der ligger øverst er det der svinder mest. og derfor skal man overveje at bruge det så lidt som muligt. I skal udfra Materialepyramiden arrangere disse 6 materialer sådan at det der udleder mest Co2 står øverst. og det der udleder mest står nederst',
		itemsList: [
			{
				bidId: '8b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluvindue', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Træ-Alu vindue', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Konstruktionsstål', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Trævindue', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Betontagsten', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Tagrør', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '9b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluvindue', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Træ-Alu vindue', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Konstruktionsstål', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Trævindue', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Betontagsten', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Tagrør', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '10b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluvindue', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Træ-Alu vindue', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Konstruktionsstål', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Trævindue', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Betontagsten', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Tagrør', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '11b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluvindue', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Træ-Alu vindue', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Konstruktionsstål', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Trævindue', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Betontagsten', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Tagrør', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '12b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluvindue', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Træ-Alu vindue', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Konstruktionsstål', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Trævindue', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Betontagsten', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Tagrør', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '13b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluvindue', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Træ-Alu vindue', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Konstruktionsstål', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Trævindue', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Betontagsten', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Tagrør', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: '14b',
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Aluvindue', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Træ-Alu vindue', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Konstruktionsstål', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Trævindue', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Betontagsten', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Tagrør', correctOrderNumbers: [6]
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-2b-match-tools',
		assignmentNumber: 39,
		title: 'Match Værktøj',
		type: 'match',
		answerType: 'tools',
		instructions: 'sæt de rigtige betegnelser på billederne',
		text: 'Match billederne med det rigtige værktøj',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Batteri Rundsav', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Bordrundsav', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Afkorter', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Stiksav', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Slagboremaskine', 
				id: 5,
				answers: [5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Skruemaskine', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Exender sliber', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Lasermåler', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Streglaser', 
				id: 9,
				answers: [9]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'Batteri Rundsav',
				imageSrc: 'tools/battery-circular-saw.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'Bordrundsav',
				imageSrc: 'tools/table-circular-saw.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'Afkorter',
				imageSrc: 'tools/miter-saw.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'Stiksav',
				imageSrc: 'tools/jigsaw.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'Slagboremaskine',
				imageSrc: 'tools/impact-drill.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'Skruemaskine',
				imageSrc: 'tools/screw-machine.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: 'Exender sliber',
				imageSrc: 'tools/eccentric-grinder.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'Lasermåler',
				imageSrc: 'tools/laser-meter.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'Streglaser',
				imageSrc: 'tools/line-laser.svg',
			}),
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-2b-match-tools2',
		assignmentNumber: 40,
		title: 'Match Værktøj',
		type: 'match',
		answerType: 'tools',
		instructions: 'sæt de rigtige betegnelser på billederne',
		text: 'Match billederne med det rigtige værktøj',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tommestok', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Listesaks', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Mejsel', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Mukkert', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Bidtang', 
				id: 5,
				answers: [5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Rørtang', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Topnøgle', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Unbraconøgle', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Skydelærer', 
				id: 9,
				answers: [9]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'Tommestok',
				imageSrc: 'tools/ruler.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'Listesaks',
				imageSrc: 'tools/list-scissors.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'Mejsel',
				imageSrc: 'tools/chisel.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'Mukkert',
				imageSrc: 'tools/dull-hammer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'Bidtang',
				imageSrc: 'tools/bite-forceps.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'Rørtang',
				imageSrc: 'tools/pipe-pliers.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: 'Topnøgle',
				imageSrc: 'tools/socket-wrench.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'Unbraconøgle',
				imageSrc: 'tools/allen-key.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'Skydelærer',
				imageSrc: 'tools/vernier-calliper.svg',
			}),
		],
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round2-medium-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};