import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { groupData, initialCapacity } from 'data/group-data';
import './group-data.scss';

const GroupData = ({groupCapacity = null, group}) => {
	const [groupInfo, setGroupInfo] = useState(null);
	
	const [groupStats, setGroupStats] = useState(null);

	useEffect (() => {
		if (group) {
			const g = groupData.find((data) => {return data.id === group.id;});
			if (g) {
				setGroupInfo(g);
				setGroupStats(g.groupStats);
			}
		}
	}, [group]);

	useEffect (() => {
		if (group && group.groupStats) {
			setGroupStats(group.groupStats);
		}
	}, [group]);

	return (
		<div className={'GroupData'}>
			<div className='GroupData-groupWrapper'>
				<div className={'GroupData-groupBox'}>
					<div className='GroupData-groupBoxOverlayWrapper'>
						<div className='GroupData-groupBoxOverlay'>
							<div className='GroupData-groupName'>
								<span>{groupInfo && groupInfo.name}</span>
							</div>

							<div className='GroupData-groupBoxOverlayContent'>
								<div className={'GroupData-groupBoxCornerWrapper'}>
									<div className={'GroupData-groupBoxCorner' + 
										(group ? ' groupBackground' + group.id : '')}/>
								</div>
								<div className={'GroupData-groupCapacityBox' + 
										(group ? ' groupBackground' + group.id : '')}>
									<span>
										{
											groupCapacity !== null ? 
												groupCapacity
												:
												initialCapacity
										}
									</span>
								</div>

								{groupStats ? groupStats.map((stat, index) => {
									return (
										<div key={index} className='GroupData-groupDetails'>
											<div className={'GroupData-groupDetailIcon ' + stat.id}/>
											<div className='GroupGox-groupDetailValue'>{stat.value}</div>
										</div>
									);
								}) : ''}
							</div>
						</div>
					</div>
					<div className='GroupData-boxBottom'/>
				</div>
			</div>
		</div>
	);
};

GroupData.propTypes = {
	groupCapacity: PropTypes.number,
	group: PropTypes.object.isRequired,
};

export default GroupData;