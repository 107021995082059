import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {pageUiTexts} from 'data/ui-texts';
import './spot-errors.scss';
import Button from 'components/ui/button/button';

const SpotErrors = (props) => {
	const {
		playerTaskData, 
		taskData,
		handleAnswer,
		handleCompleteTask,
	} = props;

	/* Check if completed already */
	let isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track available and selected options and countdown */
	// const [status, setStatus] = useState('playing'); // Can be: playing, popup, result
	const [spottedErrorIds, setSpottedErrorIds] = useState([]);

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSpottedErrorIds = () => {
		let errorIds = [];
		if (playerTaskData && playerTaskData.spottedErrorIds) {
			errorIds = playerTaskData.spottedErrorIds;
		}
		return errorIds;
	};

	/* New task: update status, spotted errors, and countdown  */
	useEffect(() => {
		setSpottedErrorIds(getSpottedErrorIds());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Spot error
	 * @param {string} errorId 
	 * @returns 
	 */
	const spotErrorId = (errorId) => {
		/* Already completed */
		if (isCompleted === true) return;		

		/* Already spotted */
		if (spottedErrorIds.indexOf(errorId) >= 0) return;

		/* Spot error */
		let newSpottedErrorIds = JSON.parse(JSON.stringify(spottedErrorIds));
		newSpottedErrorIds.push(errorId);
		setSpottedErrorIds(newSpottedErrorIds);

		/* Effects */
		let instantEffects = [{type: 'streak', isCorrectAnswer: true}];
		const spottedErrorData = taskData.errors.find((error) => {return error.id === errorId;});
		if (spottedErrorData && spottedErrorData.effects && spottedErrorData.effects.length > 0) {
			spottedErrorData.effects.forEach((effect) => {
				/* Effect: feedback (instant) */
				if (effect.type === 'feedback') {
					instantEffects.push(effect);
				}
			});
		}

		if (taskData.doneWhenAllErrorsSpotted && newSpottedErrorIds.length === taskData.errors.length) {
			/* Complete task automatically */
			completeTask(instantEffects);
		}
	};

	/**
	 * Click on background
	 */
	const backgroundClick = () => {
		/* Already completed */
		if (isCompleted === true) return;		

		handleAnswer('spot-error', false);
	};

	/**
	 * Complete task
	 */
	const completeTask = () => {
		/* Save completed task */
		handleCompleteTask(
			'spot-errors', 
			null, 
			null, 
			null,
			{spottedErrorIds: spottedErrorIds}
		);
	};

	return (
		<div className={'SpotErrors ' + (isCompleted ?  ' completed' : '')}>
			{taskData.text && <div className="SpotErrors-text"><span>{taskData.text}</span></div>}

			<div className={'SpotErrors-errors ' + taskData.image} onClick={() => {backgroundClick();}}>
				{taskData.errors.map((error) => {
					return (
						<div 
							key={error.id} 
							className={'SpotErrors-error ' + error.id 
								+ (spottedErrorIds.indexOf(error.id) >= 0 ? ' spotted' : '')}
							onClick={(e) => {e.stopPropagation(); spotErrorId(error.id);}} 
						/>
					);
				})}
			</div>
			
			<div className='SpotErrors-buttonWrapper'>
				{(!isCompleted && !taskData.doneWhenAllErrorsSpotted) && 
					<div className='SpotErrors-confirmBtn'>
						<Button
							text={pageUiTexts.deliver}
							classes= {['flatBlue']}
							onClick={(e) => {e.stopPropagation(); completeTask();}}>
						</Button>
					</div>
				}
			</div>
		</div>
	);
};

SpotErrors.propTypes = {
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleAnswer: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
};

export default SpotErrors;
