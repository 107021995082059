import { groupData } from 'data/group-data';
import dayjs from 'dayjs';

/**
 * Get game status
 * @param {object} game
 * @return {string} gameStatus
 */
function getGameStatus(game) {
	let gameStatus = 'statusNotStarted';
	const currentDate = dayjs(dayjs(new Date()).format('YYYY-MM-DD'));
	if (game.startDate && dayjs(game.startDate)) {
		if (currentDate.diff(dayjs(game.startDate)) >= 0) gameStatus = 'statusStarted';
	}
	if (game.stopDate && dayjs(game.stopDate)) {
		if (currentDate.diff(dayjs(game.stopDate)) > 0) gameStatus = 'statusFinished';
	}

	return gameStatus;
}

/**
 * Gets count of solved tasks for group
 * @param {object} group 
 * @returns number of solved tasks
 */
function getSolvedTaskCount(group) {
	let solvedTasks = 0;
	if (group.taskData) {
		solvedTasks = group.taskData.filter((task) => {return task.isCompleted;}).length;
	}

	return solvedTasks;
}

/**
 * Calculates a sum of group stats
 * @param {object} group
 * @returns total sum of group stats, including capacity
 */
function calculateGroupStatSum(group) {
	// If groupStats is not found, we use default from groupData
	const groupStat = group.groupStats ? group.groupStats : groupData.find((g) => {
		return g.id === group.id;
	}).groupStats;
	// Total is calculated from capacity and total group stats
	let groupStatTotal = group.capacity;
	groupStat.forEach((stat) => {
		groupStatTotal += stat.value;
	});

	return groupStatTotal;
}

export {getGameStatus, getSolvedTaskCount, calculateGroupStatSum};