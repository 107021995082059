import React from 'react';
import './logout-button.scss';
import Button from '../button/button';
import PropTypes from 'prop-types';
import { pageUiTexts } from 'data/ui-texts';

const LogoutButton = ({handleLogout}) => {

	return (
		<div className='LogoutButton'>
			<Button
				classes={['logout']}
				text={pageUiTexts.logout}
				onClick={() => {handleLogout();}}
			/>
		</div>
	);
};

LogoutButton.propTypes = {
	handleLogout: PropTypes.func.isRequired,
};


export default LogoutButton;