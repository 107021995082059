import React from 'react';
import { marked } from 'marked';

/**
 * Parse markdown text
 * @param {string} marked
 * @return {html}
 */
export function renderMarkdown(markdown) {
	if (typeof markdown === 'string' && markdown.length > 0) {
		let lexed = marked.lexer(markdown);
		let parsed = marked.parser(lexed);
		return <div dangerouslySetInnerHTML={{__html: parsed}} />;
	}
	return <div />;
}

/**
 * Formats number with dots every third digit
 * @param {Number} number 
 * @returns Returns dot formatted number, with dots every third digit
 */
export function dotformatNumber(number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}