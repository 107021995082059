import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './final-achievement.scss';
import { gameStepsData } from 'data/game-steps-data';
import GameCode from 'components/ui/game-code/game-code';
import Progressbar from 'components/ui/progressbar/progressbar';
import { pageUiTexts } from 'data/ui-texts';
import { groupData } from 'data/group-data';
import Button from 'components/ui/button/button';

const FinalAchievement = (props) => {
	const {
		handleGoToGameStep,
		setBackground,
		isFacilitator,
		groups,
		gameCode,
		game,
		updateGroup,
	} = props;

	const [roundNumber, setRoundNumber] = useState(0);
	const [sortedGroups, setSortedGroups] = useState([]);
	const [dataType, setDataType] = useState(null);

	/**
	 * Initiate component, and sort list of groups according to datatype
	 */
	useEffect(() => {
		setBackground('city');

		// Finding round number for progressbar
		const gameStep = gameStepsData.find((step) => {
			return step.id === game.gameStepId;
		});

		const newRoundNumber = gameStep.roundNumber;
		const type = gameStep.dataType;

		setRoundNumber(newRoundNumber);
		setDataType(type);

		const groupInfo = [];
		// Go through groups and save necessary information in list
		groups.forEach((group) => {
			let groupStats = groupData.find((data) => {
				return data.id === group.id;
			}).groupStats;
			if (group.groupStats) {
				groupStats = group.groupStats;
			}

			let value = null;
			switch (type) {
			case 'quality':
				value = groupStats.find((stat) => {return stat.id === 'quality';}).value;
				break;
			case 'sustainability': 
				value = groupStats.find((stat) => {return stat.id === 'sustainability';}).value;
				break;
			case 'workenvironment': 
				value = groupStats.find((stat) => {
					return stat.id === 'workenvironment';
				}).value;
				break;
			default: value = 0;
			}

			groupInfo.push({id: group.id, value: value, achievements: group.achievements});
		});

		const sortedGroupList = groupInfo.sort((current, next) => {
			return next.value - current.value;
		});

		setSortedGroups(sortedGroupList);
	}, [game.gameStepId, setBackground, groups]);

	/**
	 * Handles going to next step, and marks top groups for this category
	 */
	const handleGoToNextStep = () => {
		// We update all groups with top value for current category
		sortedGroups.forEach((group, index) => {
			if (index === 0 || (index !== 0 && group.value === sortedGroups[0].value)) {
				let groupAchievements = group.achievements ? group.achievements : [];
				switch (dataType) {
				case 'quality':
					groupAchievements.push('quality');
					break;
				case 'sustainability': 
					groupAchievements.push('sustainability');
					break;
				case 'workenvironment':
					groupAchievements.push('workenvironment');
					break;
				default: break;
				}
				updateGroup(game.code + '-' + group.id, {achievements: groupAchievements});	
			} else {
				// The list is sorted, so if current group does not have the same value as the top group
				// then we are done, and we end the loop early
				return;
			}
		});
		handleGoToGameStep('next');
	};

	return (
		<div className="FinalAchievement">
			<div className='FinalAchievement-headerWrapper'>
				<div className='FinalAchievement-gameCode'>
					<GameCode gameCode={gameCode}/>
				</div>
				<div className='FinalAchievement-progressbar'>
					<Progressbar roundNumber={roundNumber}/>
				</div>
			</div>
				
			<div className='FinalAchievement-scoreboardWrapper'>
				<div className='FinalAchievement-scoreboard'>
					<div className='FinalAchievement-scoreboardTitle'>
						<span>{pageUiTexts.best + ' ' + pageUiTexts.scoreboardTypes[dataType]}</span>
					</div>

					<div className='FinalAchievement-scoreboardHeader'>
						<div className='FinalAchievement-scoreboardHeaderTitle first'>
							<span>{'Rank'}</span>
						</div>
						<div className='FinalAchievement-scoreboardHeaderTitle start'>
							<span>{pageUiTexts.company}</span>
						</div>
						<div className='FinalAchievement-scoreboardHeaderTitle end'>
							<span>{pageUiTexts.score}</span>
						</div>
					</div>
					{dataType && sortedGroups.map((group, index) => {
						// Finding group default data
						const data = groupData.find((data) => {
							return data.id === group.id;
						});

						let achievements = [];
						if (group.achievements) {
							achievements = group.achievements;
						} 

						let rankClass = index === 0 || 
							(index !== 0 && group.value === sortedGroups[0].value) ? 'gold' : 'none';

						const groupName = data.name;

						return (
							<div key={index} className='FinalAchievement-groupScoreWrapper'>
								<div className={'FinalAchievement-groupRank ' + rankClass}>
									<span>{index + 1 + '.'}</span>
								</div>

								<div className='FinalAchievement-groupNameWrapper'>
									<div className='FinalAchievement-groupName'><span>{groupName}</span></div>
									{achievements.map((achievement, index) => {
										return (
											<div key={index} 
												className={'FinalAchievement-otherAchievements ' + achievement}
											/>
										);
									})}
								</div>

								<div className='FinalResult-groupStat'>
									<div className={'FinalResult-groupStatIcon ' + dataType + ' ' + rankClass}>
										<span>{group.value}</span>
									</div>
								</div>

								<div className='FinalAchievement-cornerWrapper'>
									<div className={'FinalAchievement-corner groupBackground' + group.id}/>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className='FinalAchievement-continueButton'>
				{isFacilitator &&
					<Button
						text={pageUiTexts.continue}
						classes={['pushable']}
						isOnRelease={true}
						onClick={() => {handleGoToNextStep();}}
					/>
				}
			</div>
		</div>
	);
};

FinalAchievement.propTypes = {
	handleGoToGameStep: PropTypes.func,
	setBackground: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool.isRequired,
	groups: PropTypes.array.isRequired,
	gameCode: PropTypes.string,
	game: PropTypes.object.isRequired,
	updateGroup: PropTypes.func.isRequired,
};

export default FinalAchievement;