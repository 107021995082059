const groupData = [
	{
		id: 1,
		name: 'Værkstedet I/S',
		text: ``,
		groupStats: [
			{name: 'Kvalitet', value: 1, id: 'quality'},
			{name: 'Arbejdsmiljø', value: 2, id: 'workenvironment'},
			{name: 'Bæredygtighed', value: 3, id: 'sustainability'}
		],
	},
	{
		id: 2,
		name: 'Peder Snedker PMV',
		text: ``,
		groupStats: [
			{name: 'Kvalitet', value: 1, id: 'quality'},
			{name: 'Arbejdsmiljø', value: 3, id: 'workenvironment'},
			{name: 'Bæredygtighed', value: 2, id: 'sustainability'}
		],
	},
	{
		id: 3,
		name: 'Træsjakket APS',
		text: ``,
		groupStats: [
			{name: 'Kvalitet', value: 2, id: 'quality'},
			{name: 'Arbejdsmiljø', value: 3, id: 'workenvironment'},
			{name: 'Bæredygtighed', value: 1, id: 'sustainability'}
		],
	},
	{
		id: 4,
		name: 'Tømmermændene APS',
		text: ``,
		groupStats: [
			{name: 'Kvalitet', value: 2, id: 'quality'},
			{name: 'Arbejdsmiljø', value: 1, id: 'workenvironment'},
			{name: 'Bæredygtighed', value: 3, id: 'sustainability'}
		],
	},	
	{
		id: 5,
		name: 'ProfTræ I/S',
		text: ``,
		groupStats: [
			{name: 'Kvalitet', value: 3, id: 'quality'},
			{name: 'Arbejdsmiljø', value: 1, id: 'workenvironment'},
			{name: 'Bæredygtighed', value: 2, id: 'sustainability'}
		],
	},
	{
		id: 6,
		name: 'Snedkermestrene I/S',
		text: ``,
		groupStats: [
			{name: 'Kvalitet', value: 3, id: 'quality'},
			{name: 'Arbejdsmiljø', value: 2, id: 'workenvironment'},
			{name: 'Bæredygtighed', value: 1, id: 'sustainability'}
		],
	}
];

const initialCapacity = 4;

module.exports = {groupData, initialCapacity};