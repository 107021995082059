import React, { useState } from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import Button from 'components/ui/button/button';
import GroupBox from 'components/ui/group-box/group-box';
import GameCode from 'components/ui/game-code/game-code';
import { groupData, initialCapacity } from 'data/group-data';
import { pageUiTexts } from 'data/ui-texts';
import './game-intro.scss';

const GameIntro = ({handleGoToGameStep, updateGroup, gameCode, groups, game, setPlayMusic}) => {
	const [isVideoOpen, setIsVideoOpen] = useState(false);

	// Initialize group fields and start first round.
	const startRound = () => {
		// Initialize capacity and earned money data for all groups
		groups.forEach((group) => {
			const newCapacity = initialCapacity;
			updateGroup(game.code + '-' + group.id, 
				{
					initialCapacityForRound: newCapacity,
					capacity: newCapacity,
					totalEarnedMoney: null,
				});
		});

		handleGoToGameStep('next');
	};

	return (
		<div className="GameIntro">
			<div className='GameIntro-startVideoButton'
				onClick={() => {
					setIsVideoOpen(!isVideoOpen);
					setPlayMusic(false);
				}}>
				<div className='GameIntro-videoIcon'/>
				<span>{pageUiTexts.seeVideo}</span>
			</div>

			{isVideoOpen && 
				<div className='GameIntro-videoPopup'>
					<div className='GameIntro-videoWrapper'>
						<div className='GameIntro-closeButton' onClick={() => {setIsVideoOpen(!isVideoOpen);}}/>
						<video className='GameIntro-video' controls>
							<source src={appConfig.video2StorageLink} type='video/mp4'/>
						</video>
					</div>
				</div>
			}

			<div className='GameIntro-pageWrapper'>
				<div className='GameIntro-gameCode'>
					<GameCode gameCode={gameCode}/>
				</div>
				<div className='GameIntro-groupsWrapper'>
					{/* Groups first row */}
					<div className='GameIntro-groups'>
						{groups === undefined ? '' : groups.map((group, index) => {
							let groupName = groupData.find((data) => {
								return data.id === group.id;
							}).name;

							return (
								<div key={index} className='GameIntro-group'>
									<GroupBox 
										key={index} 
										group={group} 
										groupName={groupName} 
										showReadyButton={true}
										isGroupLoginIndicator={true}
									/>
								</div>
							);
						})}
					</div>
				</div>
				<div className='GameIntro-startButton'>
					<Button
						text={pageUiTexts.start}
						classes={['pushable']}
						isOnRelease={true}
						onClick={() => {startRound();}}
					/>
				</div>
			</div>
		</div>
	);
};

GameIntro.propTypes = {
	handleGoToGameStep: PropTypes.func.isRequired,
	updateGroup: PropTypes.func.isRequired,
	gameCode: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	game: PropTypes.object.isRequired,
	setPlayMusic: PropTypes.func.isRequired,
};

export default GameIntro;