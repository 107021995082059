import React from 'react';
import PropTypes from 'prop-types';
import {pageUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';

const CreateUser = (props) => {
	const {
		isLoading,
		userCreated,
		email,
		password,
		passwordRepeat,
		feedback,
		handleInput,
		handleCreateUser,
		setFacilitatorBoxType
	} = props;

	if (userCreated) {
		return (
			<div className="LoginFacilitator">
				<div className='Login-createUser'>
					<div className="Login-info">{pageUiTexts.userCreated}</div>
					<div className="Login-info">{pageUiTexts.goBackToPlay}</div>
					<div className="Login-backToLoginButton">
						<Button
							classes={['flatBlue']}
							text={pageUiTexts.goToLoginBtn}
							onClick={()=>{setFacilitatorBoxType('login');}}
						/>
					</div>
				</div>
			</div>
		);
	}
	
	return (
		<div className="LoginFacilitator">
			<form className="Login-form" onSubmit={handleCreateUser}>
				<input
					name="email"
					type="text"
					placeholder={pageUiTexts.email}
					autoComplete="section-createUser email"
					value={email ? email : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<input
					name="password"
					type="password"
					placeholder={pageUiTexts.password}
					autoComplete="section-createUser password"
					value={password ? password : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<input
					name="passwordRepeat"
					type="password"
					placeholder={pageUiTexts.passwordRepeat}
					autoComplete="section-createUser repeatpassword"
					value={passwordRepeat ? passwordRepeat : ''}
					onChange={(event)=>{handleInput(event);}}
				/>
				<Button
					isLoading={isLoading}
					classes={['login']}
					text={pageUiTexts.createUserBtn}
					onClick={handleCreateUser}
				/>
				<p className="Login-errorMessage">{feedback}</p>
				<div 
					className="Login-userButton"
					onClick={()=>{setFacilitatorBoxType('login');}}
				>{pageUiTexts.goToLoginBtn}</div>
			</form>

		</div>
			
	);	
};

CreateUser.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	userCreated: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	passwordRepeat: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleCreateUser: PropTypes.func.isRequired,
	setFacilitatorBoxType: PropTypes.func.isRequired
};

export default CreateUser;