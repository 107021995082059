import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import { pageUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './admin.scss';
import Statistics from 'components/statistics/statistics';
import { gameStepsData } from 'data/game-steps-data';
import Loading from 'components/loading/loading';

const Admin = ({handleLogout, userId}) => {
	/* Games (from database) */
	const [gameStats, setGameStats] = useState([]);

	/* Subscriptions */
	const [unsubscribeUser, setUnsubscribeUser] = useState(null);
	const [unsubscribeGames, setUnsubscribeGames] = useState(null);
	const [unsubscribeStatistics, setUnsubscribeStatistics] = useState(null);
	
	/* Subscription timeout */
	const [subscribeTimeout, setSubscribeTimeout] = useState(null);

	const [isLoading, setIsLoading] = useState(true);

	/**
	 * Subscribe to user (facilitator) data
	 */
	const subscribeToUser = () => {
		if (unsubscribeUser) unsubscribeUser();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			const newUnsubscribeUser = db.collection(appConfig.usersDbName).doc(userId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get user data */
					let userData = {id: doc.id, ...doc.data()};

					/* Update state */
					setUnsubscribeUser(newUnsubscribeUser);
					resolve({ status: 'success', userData: userData });
				} else {
					console.error('user data not found');
					resolve({ status: 'error', error: 'user data not found'});
					setSubscribeTimeout(setTimeout(() => {handleLogout();}, 5000));
				}
			},
			(error) => {
				console.error('could not get user: ', error);
				resolve({ status: 'error', error: error});
				setSubscribeTimeout(setTimeout(() => {handleLogout();}, 5000));
			});
		});
	};

	const subscribeToData = () => {
		const db = firebase.firestore();
		const statistics = [];
		const lastGameStep = gameStepsData[gameStepsData.length - 1].id;
		let newUnsubscribeStat = null;
		let newUnsubscribeGames = null;

		newUnsubscribeStat = db.collection(appConfig.statisticsDbName).onSnapshot(
			(querySnapshot) => {
				querySnapshot.forEach((doc) => {
					const d = doc.data();
					const isFinalStep = d.finalStep === lastGameStep;

					statistics.push(
						{
							timeStamp: {value: d.created * 1000, show: false},
							created: new Date(d.created * 1000).toLocaleString(),
							finished: new Date(d.finished * 1000).toLocaleString(),
							finalStep: isFinalStep ? pageUiTexts.yes : pageUiTexts.no,
							groupsAmount: d.groupsAmount,
							name: d.name,
							facilitatorDomain: d.facilitatorDomain
						});
				});
				
				setGameStats(statistics);
				setUnsubscribeStatistics(newUnsubscribeStat);
			},
			(error) => {
				console.error('could not get statistics: ', error);
			}
		);

		newUnsubscribeGames = db.collection(appConfig.gamesDbName).onSnapshot(
			(querySnapshot) => {
				querySnapshot.forEach((doc) => {
					const data = doc.data();
					const groupsRef = db.collection(appConfig.groupsDbName).where('gameId', '==', data.code);
					let activeGroups = 0;

					groupsRef.get().then((groups) => {
						groups.forEach((group) => {
							const groupData = group.data();
							if (groupData.isPlaying) {
								activeGroups ++;
							}
						});

						const facilitatorRef = db.collection('users').doc(data.facilitatorId);
						facilitatorRef.get().then((facilitator) => {
							// Getting domain from facilitator email
							const domain = facilitator.data().email.split('@');

							statistics.push(
								{
									timeStamp: {value: data.created, show: false},
									created: new Date(data.created * 1000).toLocaleString(),
									finished: pageUiTexts.unfinished,
									finalStep: data.gameStepId === lastGameStep ? pageUiTexts.yes : pageUiTexts.no,
									groupsAmount: activeGroups,
									name: data.code,
									facilitatorDomain: domain[1],
								}
							);
						}).then(() => {
							setGameStats(statistics);
							setUnsubscribeGames(newUnsubscribeGames);
						});
					});
				});
			},
			(error) => {
				console.error('could not get games: ', error);
			}
		);
	};

	/**
	 * Component did mount / will unmount
	 */
	useEffect(() => {
		/* Get user data */
		subscribeToUser().then((response) => {
			/* Subscribe to games */
			if (response.status === 'success') {
				if (response.userData && response.userData.isAdmin === true) {
					/* Subscribe to users */
					Promise.all([
						subscribeToData()
					]).then(() => {
						setIsLoading(false);
					});
				} else {
					/* User is not admin */
					window.location.href = '/';
				}
			} else {
				setIsLoading(false);
			}
		});

		return () => {
			/* Cancel subscriptions */
			if (unsubscribeGames) {
				unsubscribeGames();
			}
			if (unsubscribeUser) {
				unsubscribeUser();
			}
			if (unsubscribeStatistics) {
				unsubscribeStatistics();
			}
			
			/* Clear timeout */
			if (subscribeTimeout) clearTimeout(subscribeTimeout);
		};
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		
		/* Loading */
		<div className="Admin">
			{isLoading ?
				<Loading 
					loadErrMsg={pageUiTexts.loading}
					handleLogout={handleLogout}
				/>
				:
				<div className='Admin-statisticsWrapper'>
					<div className='Admin-statistics'>
						<Statistics gameStats={gameStats}/>
					</div>
					<div className="Admin-logoutBtn">
						<Button 
							classes={['logout']} 
							text={pageUiTexts.logout} 
							onClick={() => {handleLogout();}}
						/>
					</div>
				</div>
			}
		</div>
	);
};

Admin.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
};

export default Admin;
