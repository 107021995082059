import { bids, secondaryPreferenceMultiplier } from 'data/bid-data';
import { groupData } from 'data/group-data';

/**
 * Calculates a list of bid winning groups, for all bids in round.
 * @param {Array} groups 
 * @param {Number} round 
 * @returns 
 */
function getBidWinners(groups, round, difficulty) {
	const bidWinners = [];

	let roundBids = getRoundBids(round, difficulty);
	roundBids = roundBids.sort((a, b) => {
		return b.value - a.value;
	});

	roundBids.forEach((bid) => {
		let currentBestBid = null;
		groups.forEach((group) => {
			if (group.bids !== undefined) {
				const previousWins = bidWinners.filter((winner) => {return winner.groupId === group.id;});
				if (previousWins && previousWins.length >= 2) {
					return;
				}
				
				// Find if group has made an offer on current bid
				const groupBid = group.bids.find((groupBid) => {
					return groupBid.id === bid.id;
				});

				// If group has not made an offer on current bid, we continue to next group.
				if (!groupBid) {
					return;
				}

				const data = groupData.find((data) => {
					return data.id === group.id;
				});

				if (!data) {
					console.error('Data for group with id not found. Group id:' + group.id);
				}

				let groupStats = data.groupStats;
				if (group.groupStats) {
					groupStats = group.groupStats;
				}

				// Getting primary multiplier, depending on bid preference.
				let primaryValue = 0;
				primaryValue = groupStats.find((stats) => {
					return stats.id === bid.preference;
				}).value;

				// Getting secondary multiplier, depending on bid preference.
				let secondaryValue = 0;
				secondaryValue += groupStats.find((stats) => {
					return stats.id === bid.secondPreference;
				}).value * secondaryPreferenceMultiplier;

				// Current group has made the current best bid IF:
				// There are no best bid yet
				// Their initial bid value is higher
				// Their primary value is higher than the current best
				// Their secondary value is higher than the current best
				if (currentBestBid === null ||
					currentBestBid.bidCount < groupBid.bidValue ||
					(groupBid.bidValue === currentBestBid.bidCount 
						&& currentBestBid.primary < primaryValue) ||
					(groupBid.bidValue === currentBestBid.bidCount 
						&& currentBestBid.primary === primaryValue 
						&& currentBestBid.secondary < secondaryValue)) {
					currentBestBid = {
						primary: primaryValue, 
						secondary: secondaryValue, 
						groupId: group.id, 
						bidCount: groupBid.bidValue
					};
				}
			}
		});

		// Set bid winner for current bid
		if (currentBestBid !== null) {
			bidWinners.push({groupId: currentBestBid.groupId, bidId: bid.id});
		}
	});

	return bidWinners;
}

/**
 * Gets the list of bids for the round and difficulty setting
 * @param {string} roundId 
 * @param {string} difficultyId 
 */
function getRoundBids(roundId, difficultyId) {
	let result = [];

	result = bids.filter((bid) => {
		return bid.round === roundId && bid.difficultyId === difficultyId;
	});

	return result;
}

export {getBidWinners, getRoundBids};