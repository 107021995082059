let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let cookieNavn = 'woodlabspillet_cookies_accepted';
let usersDbName = 'users';
let gamesDbName = 'games';
let groupsDbName = 'groups';
let statisticsDbName = 'statistics';
let video1StorageLink = 'https://firebasestorage.googleapis.com/v0/b/cgl-woodlab-test.appspot.com/o/Woodlab_L%C3%A6rer_01.mp4?alt=media&token=fa4c21e7-1d2c-43b2-a0f9-e36a1d3fc057';
let video2StorageLink = 'https://firebasestorage.googleapis.com/v0/b/cgl-woodlab-test.appspot.com/o/WoodlabFilm01.mp4?alt=media&token=a71c1e0c-43bf-46e3-9c7a-76deb983d322';
let video3StorageLink = 'https://firebasestorage.googleapis.com/v0/b/cgl-woodlab-test.appspot.com/o/T%C3%B8mrermester%20Pr%C3%A6senterer.mp4?alt=media&token=2dfff173-46a5-4c23-939d-eec37621099d';
let video4StorageLink = 'https://firebasestorage.googleapis.com/v0/b/cgl-woodlab-test.appspot.com/o/Woodlab%20spillet%20-%20Pr%C3%A6sentation.mp4?alt=media&token=16e4d1f1-4213-4d8b-ac7e-4969e146a4f7';
let gameUrl = 'localhost:8080';
let apiURL = 'http://localhost:8080/api/';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-woodlab-test.web.app';
	apiURL = 'https://cgl-woodlab-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-woodlab-demo.web.app';
		apiURL = 'https://cgl-woodlab-demo.web.app/api/';
		gamesDbName = 'games-demo';
		groupsDbName = 'groups-demo';
		statisticsDbName = 'statistics-demo';
	}
}

if (env === 'production') {
	gameUrl = 'cgl-woodlab-pro.web.app';
	apiURL = 'https://cgl-woodlab-pro.web.app/api/';
	video1StorageLink = 'https://firebasestorage.googleapis.com/v0/b/cgl-woodlab-pro.appspot.com/o/Woodlab_L%C3%A6rer_01.mp4?alt=media&token=ad503b61-49e6-4e3e-9dbd-8f6713a20c11';
	video2StorageLink = 'https://firebasestorage.googleapis.com/v0/b/cgl-woodlab-pro.appspot.com/o/WoodlabFilm01.mp4?alt=media&token=a6faa55d-802d-4d93-933b-b2d2ea86e525';
	video3StorageLink = 'https://firebasestorage.googleapis.com/v0/b/cgl-woodlab-pro.appspot.com/o/T%C3%B8mrermester%20Pr%C3%A6senterer.mp4?alt=media&token=e76c12b1-ffdf-4702-9055-33507a77bd94';
	video4StorageLink = 'https://firebasestorage.googleapis.com/v0/b/cgl-woodlab-pro.appspot.com/o/Woodlab%20spillet%20-%20Pr%C3%A6sentation.mp4?alt=media&token=e6abfc87-54e3-44d4-a0ce-538b796ab933';
}
let gameTitleMaxLength = 8;

const appConfig = {
	env: env,
	apiURL: apiURL,
	usersDbName: usersDbName,
	gamesDbName: gamesDbName,
	groupsDbName: groupsDbName,
	statisticsDbName: statisticsDbName,
	gameUrl: gameUrl,
	gameTitleMaxLength: gameTitleMaxLength,
	cookiesAcceptedCookieName: cookieNavn,
	video1StorageLink,
	video2StorageLink,
	video3StorageLink,
	video4StorageLink,
};

export default appConfig;
