import React from 'react';
import './background.scss';
import PropTypes from 'prop-types';
import {ReactComponent as City} from 'assets/images/background-city.svg';
import {ReactComponent as Workshop} from 'assets/images/background-workshop.svg';
import {ReactComponent as WorkshopBackdrop} from 'assets/images/background-workshopBackdrop.svg';
import { shopItems } from 'data/workshop-data';

function Background({ background, boughtItems, groupId = null }) {
	if (background === 'city') {
		return (
			<div className='Background'>
				<City className={'Background-city' + (groupId ? ' group-' + groupId : '')}/>
			</div>
		);
	} else if (background === 'workshop') {

		
		let className = '';

		if (boughtItems) {
			boughtItems.forEach((item) => {
				className += ' ' + shopItems[item].className;
			});
		}

		if (groupId) {
			className += ' group-' + groupId;
		}

		return (
			<div className='Background'>
				<div className='Background-workshopGradient'/>
				<div className='Background-overlay'/>
				<WorkshopBackdrop className='Background-backgroundBackdrop'/>
				<Workshop className={'Background-workshop ' + className}/>
				<div className='Background-floorColor'/>
			</div>
		);
	}
}

Background.propTypes = {
	background: PropTypes.string.isRequired,
	boughtItems: PropTypes.array,
	groupId: PropTypes.number,
};

export default Background;
