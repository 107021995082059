import round1EasyA1		from 'data/assignments/5-6-class-1/round-1/round-1-easy';
import round1HardA1 	from 'data/assignments/5-6-class-1/round-1/round-1-hard';
import round1MediumA1 	from 'data/assignments/5-6-class-1/round-1/round-1-medium';
import round2EasyA1 	from 'data/assignments/5-6-class-1/round-2/round-2-easy';
import round2HardA1 	from 'data/assignments/5-6-class-1/round-2/round-2-hard';
import round2MediumA1 	from 'data/assignments/5-6-class-1/round-2/round-2-medium';
import round3EasyA1 	from 'data/assignments/5-6-class-1/round-3/round-3-easy';
import round3HardA1 	from 'data/assignments/5-6-class-1/round-3/round-3-hard';
import round3MediumA1 	from 'data/assignments/5-6-class-1/round-3/round-3-medium';

import round1EasyB1 	from 'data/assignments/7-10-class-1/round-1/round-1-easy';
import round1HardB1 	from 'data/assignments/7-10-class-1/round-1/round-1-hard';
import round1MediumB1 	from 'data/assignments/7-10-class-1/round-1/round-1-medium';
import round2EasyB1 	from 'data/assignments/7-10-class-1/round-2/round-2-easy';
import round2HardB1 	from 'data/assignments/7-10-class-1/round-2/round-2-hard';
import round2MediumB1 	from 'data/assignments/7-10-class-1/round-2/round-2-medium';
import round3EasyB1 	from 'data/assignments/7-10-class-1/round-3/round-3-easy';
import round3HardB1 	from 'data/assignments/7-10-class-1/round-3/round-3-hard';
import round3MediumB1 	from 'data/assignments/7-10-class-1/round-3/round-3-medium';

import round1EasyB2 	from 'data/assignments/7-10-class-2/round-1/round-1-easy';
import round1HardB2 	from 'data/assignments/7-10-class-2/round-1/round-1-hard';
import round1MediumB2 	from 'data/assignments/7-10-class-2/round-1/round-1-medium';
import round2EasyB2 	from 'data/assignments/7-10-class-2/round-2/round-2-easy';
import round2HardB2 	from 'data/assignments/7-10-class-2/round-2/round-2-hard';
import round2MediumB2 	from 'data/assignments/7-10-class-2/round-2/round-2-medium';
import round3EasyB2 	from 'data/assignments/7-10-class-2/round-3/round-3-easy';
import round3HardB2 	from 'data/assignments/7-10-class-2/round-3/round-3-hard';
import round3MediumB2 	from 'data/assignments/7-10-class-2/round-3/round-3-medium';
import { pageUiTexts } from 'data/ui-texts';

export function getRoundAssignmentData(roundNumber, gameDifficulty) {
	// Getting all round task data in three lists, according to difficulty
	let totalAssignmentCount = 0;

	let easyTaskData = null;
	let mediumTaskData = null;
	let hardTaskData = null;

	switch (roundNumber) {
	// Rounds come in pairs, bid and build, fx. 1 and 2, 3 and 4, 5 and 6.
	case 1:
	case 2:
		if (gameDifficulty === 'a1') {
			easyTaskData = round1EasyA1.taskData;
			mediumTaskData = round1MediumA1.taskData;
			hardTaskData = round1HardA1.taskData;
		} else if (gameDifficulty === 'a2') {

		} else if (gameDifficulty === 'b1') {
			easyTaskData = round1EasyB1.taskData;
			mediumTaskData = round1MediumB1.taskData;
			hardTaskData = round1HardB1.taskData;
		} else if (gameDifficulty === 'b2') {
			easyTaskData = round1EasyB2.taskData;
			mediumTaskData = round1MediumB2.taskData;
			hardTaskData = round1HardB2.taskData;
		}

		break;

	case 3:
	case 4:
		if (gameDifficulty === 'a1') {
			easyTaskData = round2EasyA1.taskData;
			mediumTaskData = round2MediumA1.taskData;
			hardTaskData = round2HardA1.taskData;
		} else if (gameDifficulty === 'a2') {

		} else if (gameDifficulty === 'b1') {
			easyTaskData = round2EasyB1.taskData;
			mediumTaskData = round2MediumB1.taskData;
			hardTaskData = round2HardB1.taskData;
		} else if (gameDifficulty === 'b2') {
			easyTaskData = round2EasyB2.taskData;
			mediumTaskData = round2MediumB2.taskData;
			hardTaskData = round2HardB2.taskData;
		}

		break;

	case 5:
	case 6:
		if (gameDifficulty === 'a1') {
			easyTaskData = round3EasyA1.taskData;
			mediumTaskData = round3MediumA1.taskData;
			hardTaskData = round3HardA1.taskData;
		} else if (gameDifficulty === 'a2') {

		} else if (gameDifficulty === 'b1') {
			easyTaskData = round3EasyB1.taskData;
			mediumTaskData = round3MediumB1.taskData;
			hardTaskData = round3HardB1.taskData;
		} else if (gameDifficulty === 'b2') {
			easyTaskData = round3EasyB2.taskData;
			mediumTaskData = round3MediumB2.taskData;
			hardTaskData = round3HardB2.taskData;
		}

		break;

	default: console.error(pageUiTexts.roundNotFound + ' ' + roundNumber);
	}
	
	totalAssignmentCount = 
		easyTaskData.length +
		mediumTaskData.length +
		hardTaskData.length;

	return {
		assignmentCount: totalAssignmentCount, 
		easyTaskData: easyTaskData, 
		mediumTaskData: mediumTaskData, 
		hardTaskData: hardTaskData
	};
}

export function getRoundAssignmentCount(roundNumber, gameDifficulty) {
	let assignmentCount = 0;
	if (roundNumber > 0) {
		switch (roundNumber) {
		// Rounds come in pairs, bid and build, fx. 1 and 2, 3 and 4, 5 and 6.
		case 1:
		case 2:
			if (gameDifficulty === 'a1') {
				assignmentCount = 
					round1EasyA1.taskData.length +
					round1MediumA1.taskData.length +
					round1HardA1.taskData.length;
			} else if (gameDifficulty === 'b1') {
				assignmentCount = 
					round1EasyB1.taskData.length +
					round1MediumB1.taskData.length +
					round1HardB1.taskData.length;
			} else if (gameDifficulty === 'b2') {
				assignmentCount = 
					round1EasyB2.taskData.length +
					round1MediumB2.taskData.length +
					round1HardB2.taskData.length;
			}
			break;

		case 3:
		case 4:
			if (gameDifficulty === 'a1') {
				assignmentCount = 
					round2EasyA1.taskData.length +
					round2MediumA1.taskData.length +
					round2HardA1.taskData.length;
			} else if (gameDifficulty === 'b1') {
				assignmentCount = 
					round2EasyB1.taskData.length +
					round2MediumB1.taskData.length +
					round2HardB1.taskData.length;
			} else if (gameDifficulty === 'b2') {
				assignmentCount = 
					round2EasyB2.taskData.length +
					round2MediumB2.taskData.length +
					round2HardB2.taskData.length;
			}
			break;

		case 5:
		case 6:
			if (gameDifficulty === 'a1') {
				assignmentCount = 
					round3EasyA1.taskData.length +
					round3MediumA1.taskData.length +
					round3HardA1.taskData.length;
			} else if (gameDifficulty === 'b1') {
				assignmentCount = 
					round3EasyB1.taskData.length +
					round3MediumB1.taskData.length +
					round3HardB1.taskData.length;
			} else if (gameDifficulty === 'b2') {
				assignmentCount = 
					round3EasyB2.taskData.length +
					round3MediumB2.taskData.length +
					round3HardB2.taskData.length;
			}
			break;

		default: console.error(pageUiTexts.roundNotFound + ' ' + roundNumber);
		}
	}

	return assignmentCount;
}