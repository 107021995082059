const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 22,
		taskId: 'round-2b-construct-or-calculate',
		title: 'Konstruer eller beregn en vinkel',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		texts: [
			{
				bidId: '8b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningen er der markeret en vinkel med rødt. Vinkel kan beregnes med brug af jeres formelsamling. Eller den kan konstrueres i Geogebra, som kan hjælpe med at beregne vinklen. Du skal angive vinklen i hele antal grader.'
			},
			{
				bidId: '9b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningen er der markeret en vinkel med rødt. Vinkel kan beregnes med brug af jeres formelsamling. Eller den kan konstrueres i Geogebra, som kan hjælpe med at beregne vinklen. Du skal angive vinklen i hele antal grader.'
			},
			{
				bidId: '10b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningen er der markeret en vinkel med rødt. Vinkel kan beregnes med brug af jeres formelsamling. Eller den kan konstrueres i Geogebra, som kan hjælpe med at beregne vinklen. Du skal angive vinklen i hele antal grader.'
			},
			{
				bidId: '11b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningen er der markeret en vinkel med rødt. Vinkel kan beregnes med brug af jeres formelsamling. Eller den kan konstrueres i Geogebra, som kan hjælpe med at beregne vinklen. Du skal angive vinklen i hele antal grader.'
			},
			{
				bidId: '12b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningen er der markeret en vinkel med rødt. Vinkel kan beregnes med brug af jeres formelsamling. Eller den kan konstrueres i Geogebra, som kan hjælpe med at beregne vinklen. Du skal angive vinklen i hele antal grader.'
			},
			{
				bidId: '13b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningen er der markeret en vinkel med rødt. Vinkel kan beregnes med brug af jeres formelsamling. Eller den kan konstrueres i Geogebra, som kan hjælpe med at beregne vinklen. Du skal angive vinklen i hele antal grader.'
			},
			{
				bidId: '14b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. På tegningen er der markeret en vinkel med rødt. Vinkel kan beregnes med brug af jeres formelsamling. Eller den kan konstrueres i Geogebra, som kan hjælpe med at beregne vinklen. Du skal angive vinklen i hele antal grader.'
			}
		],
		unit: null,
		answers: [
			{
				bidId: '8b',
				answer: 24
			},
			{
				bidId: '9b',
				answer: 46
			},
			{
				bidId: '10b',
				answer: 54
			},
			{
				bidId: '11b',
				answer: 120
			},
			{
				bidId: '12b',
				answer: 80
			},
			{
				bidId: '13b',
				answer: 27
			},
			{
				bidId: '14b',
				answer: 6
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 25,
		taskId: 'round-2b-measurement-irl-2',
		title: 'Hvad er målet i virkeligheden?',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Hvis du opmåler 100 cm i virkeligheden, hvor meget pliver det så på tegningens "Plan-billede"?',
		unit: 'cm',
		answers: [
			{
				bidId: '8b',
				answer: 10
			},
			{
				bidId: '9b',
				answer: 10
			},
			{
				bidId: '10b',
				answer: 5
			},
			{
				bidId: '11b',
				answer: 20
			},
			{
				bidId: '12b',
				answer: 2
			},
			{
				bidId: '13b',
				answer: 5
			},
			{
				bidId: '14b',
				answer: 2
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 31,
		taskId: 'round-2b-total-co2',
		title: 'Total Co2 Belastning',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		text: 'På tegningen er der et liste med materialer, vægt og CO2 belastning. Brug denne til at svare på spørgsmålet.	Udfra informationerne på tegningen skal du udregne konstruktionens totale Co2 belastning. (Svaret angives med 2 decimaler)',
		unit: 'kg co2',
		answers: [
			{
				bidId: '8b',
				answer: -25.08
			},
			{
				bidId: '9b',
				answer: -34.34
			},
			{
				bidId: '10b',
				answer: -131.60
			},
			{
				bidId: '11b',
				answer: -12.70
			},
			{
				bidId: '12b',
				answer: -975.47
			},
			{
				bidId: '13b',
				answer: -85.77
			},
			{
				bidId: '14b',
				answer: 1227.28
			},
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-2b-match-materials',
		assignmentNumber: 37,
		title: 'Match materialerne',
		type: 'match',
		answerType: 'materials',
		instructions: 'sæt de rigtige betegnelser på billederne',
		text: 'Med hjælp fra materialepyramiden skal du matche disse 9 billeder med det rigtige materiale',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Stenuld', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Glasuld', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Træfiberisolering', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Beton', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Genbrugte mursten', 
				id: 5,
				answers: [5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Fibergips', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Konstruktionstræ', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Kobberplade', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Zinkplade', 
				id: 9,
				answers: [9]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'Stenuld',
				imageSrc: 'materials/rockwool.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'Glasuld',
				imageSrc: 'materials/glass-wool.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'Træfiberisolering',
				imageSrc: 'materials/wood-fiber-insulation.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'Beton',
				imageSrc: 'materials/concrete.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'Genbrugte mursten',
				imageSrc: 'materials/reused-bricks.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'Fibergips',
				imageSrc: 'materials/fiber-plaster.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: 'Konstruktionstræ',
				imageSrc: 'materials/construction-wood.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'Kobberplade',
				imageSrc: 'materials/copper-plate.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'Zinkplade',
				imageSrc: 'materials/zink-plate.svg',
			}),
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-2b-match-materials2',
		assignmentNumber: 38,
		title: 'Match materialerne',
		type: 'match',
		answerType: 'materials',
		instructions: 'sæt de rigtige betegnelser på billederne',
		text: 'Med hjælp fra materialepyramiden skal du matche disse 9 billeder med det rigtige materiale',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Konstruktionsstål', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tagsten', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tagrør', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Papiruld', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Fibercementplader', 
				id: 5,
				answers: [5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Linoleum', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '3-Lags glas', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Gasbeton', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tagpap', 
				id: 9,
				answers: [9]
			})
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'Konstruktionsstål',
				imageSrc: 'materials/construction-steel.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'Tagsten',
				imageSrc: 'materials/roof-tiles.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'Tagrør',
				imageSrc: 'materials/roof-reeds.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'Papiruld',
				imageSrc: 'materials/paper-wool.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'Fibercementplader',
				imageSrc: 'materials/fiber-cement-boards.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'Linoleum',
				imageSrc: 'materials/fiber-plaster.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: '3-Lags glas',
				imageSrc: 'materials/3-layer-glass.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'Gasbeton',
				imageSrc: 'materials/aerated-concrete.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'Tagpap',
				imageSrc: 'materials/roofing-felt.svg',
			}),
		],
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round2-hard-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};