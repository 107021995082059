const difficultyData = [
	{
		id: 'a1',
		text: '5-6 klasse 1',
	},
	{
		id: 'b1',
		text: '7-10 klasse 1',
	},
	{
		id: 'b2',
		text: '7-10 klasse 2',
	},
];

const roundLengthData = [
	15,
	20,
	25,
	30,
	35
];

module.exports = {
	difficultyData,
	roundLengthData
}