const taskTemplates = require('../../task-templates');

let taskData = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 1,
		taskId: 'round-1-multiple-choice-wood',
		title: 'Kend dit træ!',
		type: 'multiple-choice',
		image: null,
		texts: [
			{
				bidId: 1,
				text: 'Typen af træ man bruger til vinduer betyder meget for hvor godt de kan holde. Hvilke to eksempler er IKKE en type træ?'
			},
			{
				bidId: 2,
				text: 'Man kan bygge terrasser i mange forskellige træsorter. Hvilke to eksempler er IKKE en type træ?'
			},
			{
				bidId: 3,
				text: 'Man laver gulv af mange forskellige trætyper. Hvilke to eksempler er IKKE en type træ?'
			},
			{
				bidId: 4,
				text: 'Legehuse bliver typisk fremstillet i billige typer træ. Hvilke to eksempler er IKKE en type træ?'
			},
			{
				bidId: 5,
				text: 'Man kan lave isolering af mange materialer. Faktisk laves noget isolering af træ. Hvilke to eksempler er IKKE en type træ?'
			},
			{
				bidId: 6,
				text: 'Når I skal bygge en badebro, så er valg af trætype vigtigt. Hvilke to eksempler er IKKE en type træ?'
			},
			{
				bidId: 7,
				text: 'Hegnet I skal bygge kan bygges af mange typer træ. Hvilke to eksempler er IKKE en type træ?'
			},
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Jatoba', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Ipe', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Mahogni', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Fyr', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Pulp', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Teak', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 'Eternit', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: 'Gran', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-1-match-forms',
		title: 'Kend dine former!',
		type: 'match',
		answerType: 'former',
		instructions: 'Forbind formerne med deres navne',
		assignmentNumber: 2,
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Pentagon', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Cirkel', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Ellipse', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Rektangel', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Kvadrat', 
				id: 5,
				answers: [5]
			}),

			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Trapez', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Polygon', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Ligesidet trekant', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Retvinkel trekant', 
				id: 9,
				answers: [9]
			}),Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Trekant', 
				id: 10,
				answers: [10]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'Pentagon',
				imageSrc: 'pentagon.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'Cirkel',
				imageSrc: 'cirkel.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'Ellipse',
				imageSrc: 'elipse.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'Rektangel',
				imageSrc: 'rektangel.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'Kvadrat',
				imageSrc: 'kvadrat.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'Trapez',
				imageSrc: 'trapez.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: 'Polygon',
				imageSrc: 'polygon.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'Ligesidet trekant',
				imageSrc: 'ligesidet-trekant.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'Retvinkel trekant',
				imageSrc: 'retvinkel-trekant.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 10,
				isText: false,
				text: 'Trekant',
				imageSrc: 'trekant.svg',
			}),

			
		],
		texts: [
			{
				bidId: 1,
				text: 'Vinduer kan se meget forskellige ud, så det er vigtigt at kende til formerne, så man kan forstå, hvad kunden ønsker.'
			},
			{
				bidId: 2,
				text: 'Kunden ønsker en pentagonformet hyldeopsats.'
			},
			{
				bidId: 3,
				text: 'Rummene, der skal lægges gulv i, har nogle særlige former.'
			},
			{
				bidId: 4,
				text: 'Vinduerne i legehuset skal være pentagone. Kender I til de forskellige former?'
			},
			{
				bidId: 5,
				text: 'Huset har nogle skæve vægge og isoleringen skal derfor skæres ud i nogle anderledes former.'
			},
			{
				bidId: 6,
				text: 'Bropillerne er firkantede. Kender I forskel på de forskellige former?'
			},
			{
				bidId: 7,
				text: 'Hegnet, I skal bygge, skal sættes op i en cirkel. Kender I forskel på de forskellige former?'
			},
		],
	}),
	
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-1-match-tools',
		title: 'Identificer værktøjet',
		type: 'match',
		answerType: 'værktøj',
		instructions: 'sæt de rigtige betegnelser på billederne',
		assignmentNumber: 7,
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Fuksvans', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Lægtehammer', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Kløfthammer', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Bænkhammer', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Bænkvinkel', 
				id: 5,
				answers: [5]
			}),

			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Koben', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Vatterpas', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Kridtsnor', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Passer', 
				id: 9,
				answers: [9]
			}),Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tømrervinkel', 
				id: 10,
				answers: [10]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'Fuksvans',
				imageSrc: 'fuksvans.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'Lægtehammer',
				imageSrc: 'lægtehammer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'Kløfthammer',
				imageSrc: 'kløfthammer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'Bænkhammer',
				imageSrc: 'bænkhammer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'Bænkvinkel',
				imageSrc: 'bænkvinkel.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'Koben',
				imageSrc: 'koben.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: 'Vatterpas',
				imageSrc: 'vatterpas.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'Kridtsnor',
				imageSrc: 'kridtsnor.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'Passer',
				imageSrc: 'passer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 10,
				isText: false,
				text: 'Tømrervinkel',
				imageSrc: 'tømrervinkel.svg',
			}),

			
		],
		texts: [
			{
				bidId: 1,
				text: 'Som Tømrer arbejder man med mange forskellige slags værktøj...'
			},
			{
				bidId: 2,
				text: 'Som Tømrer arbejder man med mange forskellige slags værktøj...'
			},
			{
				bidId: 3,
				text: 'Som Tømrer arbejder man med mange forskellige slags værktøj...'
			},
			{
				bidId: 4,
				text: 'Som Tømrer arbejder man med mange forskellige slags værktøj...'
			},
			{
				bidId: 5,
				text: 'Som Tømrer arbejder man med mange forskellige slags værktøj...'
			},	
			{
				bidId: 6,
				text: 'Som Tømrer arbejder man med mange forskellige slags værktøj...'
			},
			{
				bidId: 7,
				text: 'Som Tømrer arbejder man med mange forskellige slags værktøj...'
			},
		],
	}),
	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 11,
		taskId: 'round-1-multiple-beregning',
		title: 'Beregning',
		type: 'multiple-choice',
		image: 'beregning',
		texts: [
			{
				bidId: 1,
				text: 'Efter udskæring af sadelhak i 100 mm tømmer skal der stå 3/5 tilbage - hvor mange mm er det?'
			},
			{
				bidId: 2,
				text: 'Efter udskæring af sadelhak i 100 mm tømmer skal der stå 3/5 tilbage - hvor mange mm er det?'
			},
			{
				bidId: 3,
				text: 'Efter udskæring af sadelhak i 100 mm tømmer skal der stå 3/5 tilbage - hvor mange mm er det?'
			},
			{
				bidId: 4,
				text: 'Efter udskæring af sadelhak i 100 mm tømmer skal der stå 3/5 tilbage - hvor mange mm er det?'
			},
			{
				bidId: 5,
				text: 'Efter udskæring af sadelhak i 100 mm tømmer skal der stå 3/5 tilbage - hvor mange mm er det?'
			},
			{
				bidId: 6,
				text: 'Efter udskæring af sadelhak i 100 mm tømmer skal der stå 3/5 tilbage - hvor mange mm er det?'
			},
			{
				bidId: 7,
				text: 'Efter udskæring af sadelhak i 100 mm tømmer skal der stå 3/5 tilbage - hvor mange mm er det?'
			},
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '30 mm', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '50 mm', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '60 mm', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '70 mm', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '80 mm', 
				isCorrect: false,
			}),
		],
	}),
	
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		assignmentNumber: 14,
		taskId: 'round-1-match-trash',
		title: 'Affaldssortering',
		type: 'match',
		answerType: 'trash',
		text: 'Ved alt håndværker arbejde bliver der akkumuleret noget affald...',
		instructions: 'Sorter affald i de rigtige fraktioner',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate, {
				option: 'Blød Plast', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate, {
				option: 'Hård Plast', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate, {
				option: 'Ubehandlet træ', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate, {
				option: 'Metal', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate, {
				option: 'Gips', 
				id: 5,
				answers: [5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate, {
				option: 'Deponi', 
				id: 6,
				answers: [6]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: null,
				imageSrc: 'trash/bloed-plast.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: null,
				imageSrc: 'trash/haard-plast.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: null,
				imageSrc: 'trash/ubehandlet-trae.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: null,
				imageSrc: 'trash/metal.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: null,
				imageSrc: 'trash/gips.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: null,
				imageSrc: 'trash/deponi.svg',
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 15,
		taskId: 'round-1-input-math',
		title: 'Matematik',
		texts: [
			{
				bidId: 1,
				text: 'Et vindue som har en bredde på 180 cm skal deles ind i 3 fag. Hvor bredde bliver fagene? Løs opgaven ved hjælp af division'
			},
			{
				bidId: 2,
				text: 'På en 12 meter lang væg, skal der etableres 15 reolsystemer. Hvor bredde skal reolerne være? Løs opgaven ved hjælp af division'
			},
			{
				bidId: 3,
				text: 'Kunden vil gerne have at brædderne kun har 2 samlinger i helt husets længde. Huset er 12 meter langt. Hvor lange skal brædderne være? Løs opgaven ved hjælp af division'
			},
			{
				bidId: 4,
				text: 'På et hus med en længde på 8 meter skal du montere spær med en maksimal afstand på 90 cm, hvor mange spær skal du bruge? Løs opgaven ved hjælp af division'
			},
			{
				bidId: 5,
				text: 'Der skal bruges 15 kubikmeter isolering til opgaven. Hver pakke isolering indeholder 0,3 m3 isolering. Hvor mange pakker isolering skal du bruge? Løs opgaven ved hjælp af division'
			},
			{
				bidId: 6,
				text: 'Dykkerbroens længde er 23 meter. vanddybden for enden af broen er 150 cm. Hvor mange cm stiger vandstanden gennemsnitligt pr. meter hvis broen starter 80 cm over havoverfladen? Løs opgaven ved hjælp af division'
			},
			{
				bidId: 7,
				text: 'For hver sektion hegn du skal lave skal du bruge 18 rafter, hvor mange rafter skal du bruge i alt hvis du skal lave 47 sektioner. Løs opgaven ved hjælp af multiplikation'
			},
		],
		answers: [
			{
				bidId: 1,
				answer: 60
			},
			{
				bidId: 2,
				answer: 80
			},
			{
				bidId: 3,
				answer: 4
			},
			{
				bidId: 4,
				answer: 9
			},
			{
				bidId: 5,
				answer: 50
			},
			{
				bidId: 6,
				answer: 10
			},
			{
				bidId: 7,
				answer: 846
			},
		],
		units: [
			{
				bidId: 1,
				unit: 'cm'
			},
			{
				bidId: 2,
				unit: 'cm'
			},
			{
				bidId: 3,
				unit: 'meter'
			},
			{
				bidId: 4,
				unit: null
			},
			{
				bidId: 5,
				unit: null
			},
			{
				bidId: 6,
				unit: null
			},
			{
				bidId: 7,
				unit: null
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 17,
		taskId: 'round-1-multiple-wage',
		title: 'Månedsløn for en Tømrer',
		type: 'multiple-choice',
		texts: [
			{
				bidId: 1,
				text: 'Hvor meget tjener en Tømrersvend i gennemsnit om måneden? kilde: lonstatistik.dk'
			},
			{
				bidId: 2,
				text: 'Hvor meget tjener en Tømrersvend i gennemsnit om måneden? kilde: lonstatistik.dk'
			},
			{
				bidId: 3,
				text: 'Hvor meget tjener en Tømrersvend i gennemsnit om måneden? kilde: lonstatistik.dk'
			},
			{
				bidId: 4,
				text: 'Hvor meget tjener en Tømrersvend i gennemsnit om måneden? kilde: lonstatistik.dk'
			},
			{
				bidId: 5,
				text: 'Hvor meget tjener en Tømrersvend i gennemsnit om måneden? kilde: lonstatistik.dk'
			},
			{
				bidId: 6,
				text: 'Hvor meget tjener en Tømrersvend i gennemsnit om måneden? kilde: lonstatistik.dk'
			},
			{
				bidId: 7,
				text: 'Hvor meget tjener en Tømrersvend i gennemsnit om måneden? kilde: lonstatistik.dk'
			},
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '22000',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '23000',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '24000',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '25000',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '26000',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '28000',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: '30000',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: '33000',
				isCorrect: false,
			}),
		],
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round1-easy-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};