const taskTemplates = require('../../task-templates');

let taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 5,
		taskId: 'round-1b-geometri-pricelist',
		title: 'Arealberegning',
		images: [
			{
				bidId: '1b',
				image: 'pricelist-1'
			},
			{
				bidId: '2b',
				image: 'pricelist-2'
			},
			{
				bidId: '3b',
				image: 'pricelist-3'
			},
			{
				bidId: '4b',
				image: 'pricelist-4'
			},
			{
				bidId: '5b',
				image: 'pricelist-5'
			},
			{
				bidId: '6b',
				image: 'pricelist-6'
			},
			{
				bidId: '7b',
				image: 'pricelist-6'
			},
		],
		texts: [
			{
				bidId: '1b',
				text: `Kunden vælger selv at male skuffefronterne og vil gerne vide hvor mange kvadratmeter der er ialt.
				<br/>Hvor mange kvadratmeter skuffefronter er der ialt?
				<br/>8 stk 40 x 10 cm
				<br/>4 stk 60 x 10 cm
				<br/>4 stk 60 x 20 cm
				<br/>4 stk 60 x 40 cm
				<br/>3 stk 80 x 10 cm
				<br/>3 stk 80 x 20 cm
				<br/>3 stk 80 x 40 cm
				<br/>6 stk 60 x 60 cm
				<br/>(Svar med 2 decimaler)`
			},
			{
				bidId: '2b',
				text: `Kunden beder jer oplyse hvor mange kvadratmeter lydplader der bliver indkøbt til projektet.
				<br/>Hvor mange kvadratmeter akustikplader er der ialt:
				<br/>4 stk 40 x 40 cm
				<br/>3 stk 40 x 80 cm
				<br/>5 stk 60 x 60 cm
				<br/>7 stk 80 x 100 cm
				<br/>2 stk 80 x 160 cm
				<br/>(Svar med 2 decimaler)`
			},
			{
				bidId: '3b',
				text: `Kunden beder jer oplyse hvor mange kvadratmeter dørene er ialt.
				<br/>Hvor mange kvadratmeter dækker dørene over ialt:
				<br/>Type 1 døre:
				<br/>1 stk 200 x 95 cm
				<br/>2 stk 230 x 110 cm
				<br/>
				<br/>Type 2 døre:
				<br/>2 stk 200 x 80 cm
				<br/>1 stk 215 x 95 cm
				<br/>1 stk 230 x 110 cm
				<br/>(Svar med 2 decimaler)`
			},
			{
				bidId: '4b',
				text: `Kunden vil gerne vide hvor mange meter beklædningsbrædder der bliver indkøbt ialt.
				<br/>Hvor mange meter bliver der indkøbt ialt?
				<br/>16 x 100 mm: 250 m
				<br/>25 x 100 mm: 80 m
				<br/>25 x 150 mm: 120 m
				<br/>32 x 125 mm: 100 m`
			},
			{
				bidId: '5b',
				text: `Kunden beder jer oplyse hvor mange kvadratmeter dørene er ialt.
				<br/>Hvor mange kvadratmeter dækker dørene over ialt:
				<br/>Type 1 døre:
				<br/>3 stk 200 x 95 cm
				<br/>2 stk 230 x 110 cm
				<br/>
				<br/>Type 2 døre:
				<br/>1 stk 200 x 80 cm
				<br/>3 stk 215 x 95 cm
				<br/>1 stk 230 x 110 cm
				<br/>(Svar med 2 decimaler)`
			},
			{
				bidId: '6b',
				text: `Kunden beder jeg oplyse hvor mange kvadratmeter hylder der bliver indkøbt til projektet ialt.
				<br/>Hvor mange kvadratmeter dækker hylderne over?
				<br/>Bestillingsseddel:
				<br/>Skrivebord type 1:
				<br/>1 stk Masiv Eg 80x160 cm
				<br/>1 stk Masiv Eg 80x120 cm
				<br/>Hylder type 1:
				<br/>3 stk 90x20 cm
				<br/>3 stk 160x30 cm
				<br/>8 stk 210x40 cm
				<br/>Hylder type 2:
				<br/>3 stk 210x30 cm
				<br/>2 stk 90x20 cm`
			},
			{
				bidId: '7b',
				text: `Kunden beder jeg oplyse hvor mange kvadratmeter hylder der bliver indkøbt til projektet ialt.
				<br/>Hvor mange kvadratmeter dækker hylderne over?
				<br/>Bestillingsseddel:
				<br/>Skrivebord type 1:
				<br/>1 stk Masiv Eg 80x160 cm
				<br/>1 stk Masiv Eg 80x120 cm
				<br/>Hylder type 1:
				<br/>3 stk 90x20 cm
				<br/>3 stk 160x30 cm
				<br/>8 stk 210x40 cm
				<br/>Hylder type 2:
				<br/>3 stk 210x30 cm
				<br/>2 stk 90x20 cm`
			},
		],
		answers: [
			{
				bidId: '1b',
				answer: 5.84
			},
			{
				bidId: '2b',
				answer: 11.56
			},
			{
				bidId: '3b',
				answer: 14.73
			},
			{
				bidId: '4b',
				answer: 550
			},
			{
				bidId: '5b',
				answer: 21.02
			},
			{
				bidId: '6b',
				answer: 10.95
			},
			{
				bidId: '7b',
				answer: 10.95
			},
		],
		units: [
			{
				bidId: '1b',
				unit: 'm2'
			},
			{
				bidId: '2b',
				unit: 'm2'
			},
			{
				bidId: '3b',
				unit: 'm2'
			},
			{
				bidId: '4b',
				unit: 'm'
			},
			{
				bidId: '5b',
				unit: 'm2'
			},
			{
				bidId: '6b',
				unit: 'm2'
			},
			{
				bidId: '7b',
				unit: 'm2'
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 6,
		taskId: 'round-1b-floor-offer',
		type: 'multiple-choice',
		titles: [
			{
				bidId: '1b',
				title: 'Gulv tilbud'
			},
			{
				bidId: '2b',
				title: 'Gulv tilbud'
			},
			{
				bidId: '3b',
				title: 'Gulv tilbud'
			},
			{
				bidId: '4b',
				title: 'Hvor stor skal den være'
			},
			{
				bidId: '5b',
				title: 'Gulv tilbud'
			},
			{
				bidId: '6b',
				title: 'Gulv tilbud'
			},
			{
				bidId: '7b',
				title: 'Gulv tilbud'
			},
		],
		images: [
			{
				bidId: '1b',
				image: 'floor-price'
			},
			{
				bidId: '2b',
				image: 'floor-price'
			},
			{
				bidId: '3b',
				image: 'floor-price'
			},
			{
				bidId: '4b',
				image: 'cushion-box3'
			},
			{
				bidId: '5b',
				image: 'floor-price'
			},
			{
				bidId: '6b',
				image: 'floor-price'
			},
			{
				bidId: '7b',
				image: 'floor-price'
			},
		],
		texts: [
			{
				bidId: '1b',
				text: 'Ved Køkkenrenoveringen skal du også udskifte en del gulv. Trælasten har dette tilbud på gulv. Men hvilket træsort og hvor tykt er gulvet?'
			},
			{
				bidId: '2b',
				text: 'Til Studiet køber I 7 pakker Gulv, og får dem leveret. Levering koster 520 kr. Hvad skal I betale ialt?'
			},
			{
				bidId: '3b',
				text: 'Du skal indkøbe gulv til et lille kontor, og har fundet dette gode tilbud: Hvor mange pakker skal du købe hvis du skal bruge 13 m2? (Husk at man kun kan købe hele pakker)'
			},
			{
				bidId: '4b',
				text: 'Som en del af garragen skal der bygges en hyndeboks. Kunden har udleveret denne tegning til jer. Hyndeboksen skal indeholde 16 hynder som på billedet. Hvad er rumfanget på de 16 hynder tilsammen?'
			},
			{
				bidId: '5b',
				text: 'Du skal indkøbe gulv til et lille kontor, og har fundet dette gode tilbud: Hvor mange pakker skal du købe hvis du skal bruge 13 m2? (Husk at man kun kan købe hele pakker)'
			},
			{
				bidId: '6b',
				text: 'Til Studiet køber I 7 pakker Gulv, og får dem leveret. Levering koster 520 kr. Hvad skal I betale ialt?'
			},
			{
				bidId: '7b',
				text: 'Onkel Frans vil gerne have lagt nyt gulv i sine stuer. Der skal bruges 120 m2. Gulvet kan kun købes i hele pakker Hvor mange pakker skal der købes?'
			},
		],
		optionsList: [
			{
				bidId: '1b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Bøg / 15 mm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Eg / 18 mm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Fyr / 22 mm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Ask / 13 mm',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Bambus / 13 mm',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '2b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '3145',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '3865',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '5154',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '4145',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '5945',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '3b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '4',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '5',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '6',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '7',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '9',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '10',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '4b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1.2',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1.8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '2.4',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1.6',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '1',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '2.1',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '5b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '4',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '5',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '6',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '7',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '9',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '10',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '6b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '3145',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '3865',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '5154',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '4145',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '5945',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '7b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '38',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '48',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '58',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '68',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '78',
						isCorrect: false,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 7,
		taskId: 'round-1b-floor-offer-2',
		titles: [
			{
				bidId: '1b',
				title: 'Gulv tilbud'
			},
			{
				bidId: '2b',
				title: 'Gulv tilbud'
			},
			{
				bidId: '3b',
				title: 'Gulv tilbud'
			},
			{
				bidId: '4b',
				title: 'Hvad skal du købe?'
			},
			{
				bidId: '5b',
				title: 'Hele vejen rundt'
			},
			{
				bidId: '6b',
				title: 'Hele vejen rundt'
			},
			{
				bidId: '7b',
				title: 'Hvad sparer frans'
			},
		],
		images: [
			{
				bidId: '1b',
				image: 'floor-price'
			},
			{
				bidId: '2b',
				image: 'floor-price'
			},
			{
				bidId: '3b',
				image: 'floor-price'
			},
			{
				bidId: '4b',
				image: 'cushion-box2'
			},
			{
				bidId: '5b',
				image: 'floor-price'
			},
			{
				bidId: '6b',
				image: 'floor-price'
			},
			{
				bidId: '7b',
				image: 'floor-price'
			},
		],
		texts: [
			{
				bidId: '1b',
				text: 'Ved Køkkenrenoveringen skal der bruges 5,4 m2 gulv Hvor mange pakker skal der købes? (Husk du kun kan købe hele pakker)'
			},
			{
				bidId: '2b',
				text: 'Du skal indkøbe 13 m2 gulv, og har fundet et godt tilbud. Hvor mange pakker skal du købe hvis du skal bruge 13 m2? (Husk at man kun kan købe hele pakker)'
			},
			{
				bidId: '3b',
				text: 'Til det lille kontor køber I 7 pakker Gulv, og får dem leveret. Levering koster 520 kr. Hvad skal I betale ialt?'
			},
			{
				bidId: '4b',
				text: 'Kunden har lavet en skitse af hyndeboksen, med materialer på. Hvor mange forskellige materialer skal der bruges til boksen?'
			},
			{
				bidId: '5b',
				text: 'Ved køb af mindst 50 m2 Parket gives 15% rabat. Hvad bliver rabatten i kroner for 50 m2?'
			},
			{
				bidId: '6b',
				text: 'Ved køb af mindst 50 m2 Parket gives 15% rabat. Hvad bliver rabatten i kroner for 50 m2?'
			},
			{
				bidId: '7b',
				text: 'Ved køb af mindst 50 m2 Parket gives 15% rabat. Hvad bliver rabatten i kroner for 50 m2?'
			},
		],
		answers: [
			{
				bidId: '1b',
				answer: 3
			},
			{
				bidId: '2b',
				answer: 7
			},
			{
				bidId: '3b',
				answer: 4145.44
			},
			{
				bidId: '4b',
				answer: 5
			},
			{
				bidId: '5b',
				answer: 1867.5
			},
			{
				bidId: '6b',
				answer: 1867.5
			},
			{
				bidId: '7b',
				answer: 1867.5
			},
		],
		units: [
			{
				bidId: '1b',
				unit: null
			},
			{
				bidId: '2b',
				unit: null
			},
			{
				bidId: '3b',
				unit: 'kr'
			},
			{
				bidId: '4b',
				unit: null
			},
			{
				bidId: '5b',
				unit: 'kr'
			},
			{
				bidId: '6b',
				unit: 'kr'
			},
			{
				bidId: '7b',
				unit: 'kr'
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 11,
		taskId: 'round-1b-hour-wage',
		type: 'multiple-choice',
		titles: [
			{
				bidId: '1b',
				title: 'Hvad skal kunden betale?'
			},
			{
				bidId: '2b',
				title: 'Hvad skal kunden betale?'
			},
			{
				bidId: '3b',
				title: 'Hvad skal kunden betale?'
			},
			{
				bidId: '4b',
				title: 'Hvad skal kunden give for boksen'
			},
			{
				bidId: '5b',
				title: 'Hvad skal kunden betale?'
			},
			{
				bidId: '6b',
				title: 'Hvad skal kunden betale?'
			},
			{
				bidId: '7b',
				title: 'Hvad skal kunden betale?'
			},
		],
		images: [
			{
				bidId: '1b',
				image: null
			},
			{
				bidId: '2b',
				image: null
			},
			{
				bidId: '3b',
				image: null
			},
			{
				bidId: '4b',
				image: 'cushion-box3'
			},
			{
				bidId: '5b',
				image: null
			},
			{
				bidId: '6b',
				image: null
			},
			{
				bidId: '7b',
				image: null
			},
		],
		texts: [
			{
				bidId: '1b',
				text: 'Du har lavet 9 timers ekstraarbejde på opgaven der skal faktureres. Hvad skal kunden betale for arbejdslønnen hvis en time koster 500 kroner?'
			},
			{
				bidId: '2b',
				text: 'Du har lavet 9 timers ekstraarbejde på opgaven der skal faktureres. Hvad skal kunden betale for arbejdslønnen hvis en time koster 500 kroner?'
			},
			{
				bidId: '3b',
				text: 'Du har lavet 9 timers ekstraarbejde på opgaven der skal faktureres. Hvad skal kunden betale for arbejdslønnen hvis en time koster 500 kroner?'
			},
			{
				bidId: '4b',
				text: 'Du regner med at det kommer til at tage 9 timer at bygge hyndeboksen. Hvad skal kunden betale for arbejdslønnen hvis en time koster 500 kroner?'
			},
			{
				bidId: '5b',
				text: 'Du har lavet 9 timers ekstraarbejde på opgaven der skal faktureres. Hvad skal kunden betale for arbejdslønnen hvis en time koster 500 kroner?'
			},
			{
				bidId: '6b',
				text: 'Du har lavet 9 timers ekstraarbejde på opgaven der skal faktureres. Hvad skal kunden betale for arbejdslønnen hvis en time koster 500 kroner?'
			},
			{
				bidId: '7b',
				text: 'Du har lavet 9 timers ekstraarbejde på opgaven der skal faktureres. Hvad skal kunden betale for arbejdslønnen hvis en time koster 500 kroner?'
			},
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,						
				text: '1500',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '5400',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '5500',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '3500',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '4500',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '2500',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: '3000',
				isCorrect: false,
			}),
		],
	}),
	
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-1-match-tools2',
		title: 'Identificer værktøjet',
		type: 'match',
		answerType: 'værktøj',
		instructions: 'sæt de rigtige betegnelser på billederne',
		assignmentNumber: 18,
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Fuksvans', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Lægtehammer', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Kløfthammer', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Bænkhammer', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Bænkvinkel', 
				id: 5,
				answers: [5]
			}),

			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Koben', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Vatterpas', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Kridtsnor', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Passer', 
				id: 9,
				answers: [9]
			}),Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tømrervinkel', 
				id: 10,
				answers: [10]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'Fuksvans',
				imageSrc: 'fuksvans.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'Lægtehammer',
				imageSrc: 'lægtehammer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'Kløfthammer',
				imageSrc: 'kløfthammer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'Bænkhammer',
				imageSrc: 'bænkhammer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'Bænkvinkel',
				imageSrc: 'bænkvinkel.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'Koben',
				imageSrc: 'koben.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: 'Vatterpas',
				imageSrc: 'vatterpas.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'Kridtsnor',
				imageSrc: 'kridtsnor.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'Passer',
				imageSrc: 'passer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 10,
				isText: false,
				text: 'Tømrervinkel',
				imageSrc: 'tømrervinkel.svg',
			}),
		],
		text: 'Som Tømrer arbejder man med mange forskellige slags værktøj...',
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round1-easy-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};