import React, { useState, useEffect } from 'react';
import './bid-box.scss';
import PropTypes from 'prop-types';
import { pageUiTexts } from 'data/ui-texts';

const BidBox = ({
	votingIsActive = true,
	group = {},
	bidInfo,
	capacity,
	updateBid,
	isFacilitator = false,
	isResultShown = false,
	bidWinner = null,
	groups = null
}) => {
	const [currentBid, setCurrentBid] = useState(null);
	const [groupsBids, setGroupsBids] = useState(null);

	useEffect(() => {
		if (isFacilitator && groups !== null && groups.length !== 0) {
			// Going through groups to find all relevant bids
			const data = groups.map((data) => {
				if (data.bids) {
					const bid = data.bids.find((bid) => {
						return bid.id === bidInfo.id;
					});
			
					if (bid !== undefined) {
						return {groupId: data.id, bid: bid.bidValue};
					}
				}
				return {groupId: data.id, bid: undefined};
			});

			// Sorting bid data bidWinner -> highest -> low -> undefined bidValue
			data.sort((a, b) => {
				// We want to show the bid winner first
				if (a.groupId === bidWinner) {
					return -1;
				} else if (b.groupId === bidWinner) {
					return 1;
				}

				// We want to show lack of bids last
				if (a.bid === undefined) {
					return 1;
				} else if (b.bid === undefined) {
					return -1;
				}

				// We want to show higher bids, before lower
				return b.bid - a.bid;
			});



			setGroupsBids(data);
		} else if (!isFacilitator) {
			let initialBid = 0;
			if (group.bids !== undefined && group.bids !== null) {
				// Check if group has a bid with this bidinfo's id already
				// If so, then set the initial bid to the database bid data value.
				// Else just keep the initial value as 0
				const groupBid = group.bids.find((bid) => {return bid.id === bidInfo.id;});
				initialBid = groupBid ? groupBid.bidValue : 0;
			}
			setCurrentBid(initialBid);
		}
	}, [group.bids, bidInfo.id, isFacilitator, groups, bidWinner, bidInfo.name]);

	const updateCurrentBid = (bidId, change) => {
		const newCurrentBid = currentBid + change;
		setCurrentBid(newCurrentBid);

		// flipping change to reflect that the bid was taken from the groups capacity
		updateBid(bidId, newCurrentBid, -change);
	};

	return (
		<div className='BidBox'>
			<div className={'BidBox-inner' 
				+ (currentBid > 0 ? ' hasBid' : '')  
				+ (bidWinner ? ' hasBidWinner groupBorderColor' + bidWinner : '')}>
				<div className='BidBox-interface'>
					{/* If facilitator we show bid indicators for all groups instead of for only one */}
					{isFacilitator ? 
						<div className={'BidBox-bidIndicators'}>
							{groupsBids ? groupsBids.map((groupBid, index) => {
								// Group has not voted on this bid, we show empty box then
								// We also only show results if the component is currently set to show them
								if (!isResultShown || groupBid.bid === undefined) {
									return <div key={index} className={'BidBox-bidIndicator noBid'} />;
								}

								return (
									<div key={index} className={'BidBox-bidIndicator ' + 
										(groupBid.bid > 0 ? 
											'groupBackground' + groupBid.groupId : 'noBid')}>
										<span>{groupBid.bid > 0 ? groupBid.bid : ''}</span>
									</div>
								);
							}) : ''}
						</div>
						: 
						<div className={'BidBox-bidIndicator ' + 
							(currentBid > 0 ? 'groupBackground' + group.id : 'noBid')}>
							<span>{currentBid > 0 ? currentBid : ''}</span>
						</div>
					}
					{/* logic for corner color differs between facilitator and group */}
					{/* Facilitator: if biggest bidder exists and we are on the right page */}
					{/* Group: if there currently is a bid on this bidBox */}
					{isFacilitator ?
						<div className='BidBox-cornerWrapper'>
							<div className={'BidBox-corner ' + 
								(bidWinner !== null && isResultShown ? 
									'checkMarked groupBackground' + bidWinner :
									'noBid')}/>
						</div>
						:
						<div className='BidBox-cornerWrapper'>
							<div className={'BidBox-corner ' +
								(currentBid > 0 ? 'groupBackground' + group.id : 'noBid')}/>
						</div>
					}
					<div className='BidBox-bidInfoWrapper'>
						<div className='BidBox-bidInfo underlined'>{bidInfo.name}</div>
						<div className='BidBox-bidInfo'>{bidInfo.assignment}</div>
					</div>
					<div className='BidBox-bidValueWrapper'>
						<div className='BidBox-bidValue'>{bidInfo.value} kr.</div>
					</div>

					<div className='BidBox-bidWrapper'>
						<div className='BidBox-bidPreferences'>
							<div className={'BidBox-bidPreference ' + bidInfo.preference} />
							<div className={'BidBox-bidPreference secondPriority ' + bidInfo.secondPreference} />
						</div>

						{/* Only show voting buttons if NOT facilitator */}
						{isFacilitator ? '' :
							<div className='BidBox-bidButtonWrapper'>
								<div className='BidBox-bidText'><span>{pageUiTexts.bid}</span></div>

								<div 
									className={'BidBox-bidButton' + 
										(!votingIsActive || currentBid === 0 ? '' : ' active')}
									onClick={() => {updateCurrentBid(bidInfo.id, -1);}}>
									<div className='BidBox-bidButtonIconMinus'/>
								</div>

								<div className={'BidBox-bidButton' + (votingIsActive && capacity > 0 ? ' active' : '')}
									onClick={() => {updateCurrentBid(bidInfo.id, 1);}}>
									<div className='BidBox-bidButtonIconPlus'/>
								</div>
							</div>
						}
					</div>
				</div>
				<div className='BidBox-grayBottom'/>
			</div>
		</div>
	);
};

BidBox.propTypes = {
	isFacilitator: PropTypes.bool,
	bidWinner: PropTypes.number,
	isResultShown: PropTypes.bool,
	groups: PropTypes.array,
	group: PropTypes.object,
	bidInfo: PropTypes.object.isRequired,
	capacity: PropTypes.number,
	updateBid: PropTypes.func,
	votingIsActive: PropTypes.bool,
};

export default BidBox;