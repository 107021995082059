const shopItems = [
	// Maskiner
	{
		id: 0,
		categoryId: 0,
		price: 250000,
		name: 'Bedre bil',
		upgrades: [
			{
				id: 'capacity',
				value: 1,
			},
			{
				id: 'workenvironment',
				value: 1,
			},
			{
				id: 'sustainability',
				value: 1,
			}
		],
		className: 'betterCar',
	},
	{
		id: 1,
		categoryId: 0,
		price: 400000,
		name: 'Ekstra bil',
		upgrades: [
			{
				id: 'capacity',
				value: 2,
			},
			{
				id: 'workenvironment',
				value: 3,
			}
		],
		className: 'extraCar',
	},
	{
		id: 2,
		categoryId: 0,
		price: 100000,
		name: 'Skærerobot',
		upgrades: [
			{
				id: 'capacity',
				value: 1,
			},
			{
				id: 'quality',
				value: 3,
			},
			{
				id: 'workenvironment',
				value: 3,
			}
		],
		className: 'cuttingRobot',
	},
	{
		id: 3,
		categoryId: 0,
		price: 50000,
		name: 'Exoskelet',
		upgrades: [
			{
				id: 'workenvironment',
				value: 5,
			},
			{
				id: 'sustainability',
				value: 1,
			}
		],
		className: 'exoskeleton',
	},
	{
		id: 4,
		categoryId: 0,
		price: 20000,
		name: 'Batteri værktøj',
		upgrades: [
			{
				id: 'quality',
				value: 2,
			},
			{
				id: 'workenvironment',
				value: 2,
			},
			{
				id: 'sustainability',
				value: 1,
			}
		],
		className: 'batteryTools',
	},
	{
		id: 5,
		categoryId: 0,
		price: 5000,
		name: 'Håndrundsav',
		upgrades: [
			{
				id: 'quality',
				value: 2,
			}
		],
		className: 'handCircularSaw',
	},
	{
		id: 6,
		categoryId: 0,
		price: 10000,
		name: 'Transportabel bordrundsav',
		quality: 2,
		upgrades: [
			{
				id: 'capacity',
				value: 1,
			},
			{
				id: 'quality',
				value: 1,
			},
		],
		className: 'portableTableSaw',
	},

	// Medarbejdere
	{
		id: 7,
		categoryId: 1,
		price: 100000,
		name: 'Lærling',
		upgrades: [
			{
				id: 'capacity',
				value: 1,
			},
			{
				id: 'workenvironment',
				value: 1,
			},
			{
				id: 'sustainability',
				value: 1,
			},
		],
		className: 'student',
	},
	{
		id: 8,
		categoryId: 1,
		price: 300000,
		name: 'Svend',
		capacity: 3,
		upgrades: [
			{
				id: 'capacity',
				value: 3,
			},
			{
				id: 'quality',
				value: 1,
			}
		],
		className: 'svend',
	},
	{
		id: 9,
		categoryId: 1,
		price: 200000,
		name: 'Arbejdsmand',
		upgrades: [
			{
				id: 'capacity',
				value: 2,
			},
		],
		className: 'worker',
	},

	// Uddannelse
	{
		id: 10,
		categoryId: 2,
		price: 60000,
		name: 'Bæredygtigt Byggeri',
		upgrades: [
			{
				id: 'quality',
				value: 2,
			},
			{
				id: 'sustainability',
				value: 5,
			}
		],
		className: 'sustainableConstruction',
	},
	{
		id: 11,
		categoryId: 2,
		price: 60000,
		name: 'Sikkerhed på byggepladsen',
		upgrades: [
			{
				id: 'workenvironment',
				value: 5,
			},
			{
				id: 'sustainability',
				value: 2,
			},
		],
		className: 'safetyOnTheConstructionSite',
	},
	{
		id: 12,
		categoryId: 2,
		price: 60000,
		name: 'Klimavenlige materialer',
		upgrades: [
			{
				id: 'quality',
				value: 2,
			},
			{
				id: 'sustainability',
				value: 5,
			},
		],
		className: 'climateNeutralConstruction',
	},
	{
		id: 13,
		categoryId: 2,
		price: 60000,
		name: 'Kvalitetssikringskursus',
		upgrades: [
			{
				id: 'quality',
				value: 5,
			},
			{
				id: 'sustainability',
				value: 1,
			},
		],
		className: 'qualityAssuranceCourse',
	},
];

const shopCategories = [
	{
		id: 0,
		name: 'Maskiner',
		imageId: 'machine',
	},
	{
		id: 1,
		name: 'Medarbejdere',
		imageId: 'coworker',
	},
	{
		id: 2,
		name: 'Uddannelse',
		imageId: 'education',
	},
];

module.exports = {
	shopCategories,
	shopItems
};
