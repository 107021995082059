import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './timer.scss';

const Timer = ({countDown}) => {
	// Showing minutes split in tens and without tens
	const [minutesTens, setMinutesTens] = useState(0);
	const [minutesOnes, setMinutesOnes] = useState(0);
	
	// Showing seconds split in tens and without tens
	const [secondsTens, setSecondsTens] = useState(0);
	const [secondsOnes, setSecondsOnes] = useState(0);

	/**
	 * Every second we update our current time using the initially calculated end time
	 * If we are past our end time, then we stop our interval.
	*/
	useEffect(() => {
		// Getting end time in milliseconds
		const endTime = new Date(new Date().getTime() + countDown * 60000);

		const interval = setInterval(() => {			
			// Finding current time difference
			const currentTime = new Date();

			if (endTime > currentTime) {
				const timeDifference = endTime - currentTime;
				const totalSecondsLeft = timeDifference / 1000;
				const secondsInterval = totalSecondsLeft % 60;
				const minutesLeft = totalSecondsLeft / 60;

				// Getting seconds split in tens and without tens
				const secTens = Math.floor(secondsInterval / 10);
				const secOnes = Math.floor(secondsInterval - (secTens * 10));

				// Getting minutes split in tens and without tens
				const minTens = Math.floor(minutesLeft / 10);
				const minOnes = Math.floor(minutesLeft - (minTens * 10));
	
				setMinutesTens(minTens);
				setMinutesOnes(minOnes);
				setSecondsTens(secTens);
				setSecondsOnes(secOnes);
			} else {
				setMinutesTens(0);
				setMinutesOnes(0);
				setSecondsTens(0);
				setSecondsOnes(0);
				clearInterval(interval);
			}

		}, 500);
		return () => {clearInterval(interval);};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='Timer'>
			<div className='Timer-box'><span>{minutesTens}</span></div>
			<div className='Timer-box'><span>{minutesOnes}</span></div>
			<div className='Timer-colon'/>
			<div className='Timer-box'><span>{secondsTens}</span></div>
			<div className='Timer-box'><span>{secondsOnes}</span></div>
		</div>
	);
};

Timer.propTypes = {
	countDown: PropTypes.number.isRequired,
};

export default Timer;