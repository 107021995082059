import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './result.scss';
import { gameStepsData } from 'data/game-steps-data';
import GameCode from 'components/ui/game-code/game-code';
import Progressbar from 'components/ui/progressbar/progressbar';
import { groupData, initialCapacity } from 'data/group-data';
import Button from 'components/ui/button/button';
import { pageUiTexts } from 'data/ui-texts';
import { calculateGroupStatSum, getSolvedTaskCount } from 'helpers/game-helper';
import { dotformatNumber } from 'helpers/text-helper';
import { getRoundAssignmentCount } from 'helpers/assignment-helper';

const Result = (props) => {
	const {
		setBackground,
		groups,
		handleGoToGameStep,
		gameCode,
		game,
		isFacilitator,
		updateGroup
	} = props;

	const [roundNumber, setRoundNumber] = useState(0);
	const [sortedGroups, setSortedGroups] = useState([]);
	const [totalAssignmentCount, setTotalAssignmentCount] = useState(0);

	useEffect(() => {
		setBackground('city');

		// Finding round number for progressbar
		const newRoundNumber = gameStepsData.find((step) => {
			return step.id === game.gameStepId;
		}).roundNumber;

		setRoundNumber(newRoundNumber);

	}, [game.gameStepId, setBackground]);

	useEffect(() => {
		const difficulty = groups.length > 0 ? groups[0].taskData[0].difficultyId : 'a1';
		const assignmentCount = getRoundAssignmentCount(roundNumber, difficulty);
		setTotalAssignmentCount(assignmentCount);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roundNumber]);

	useEffect(() => {
		// sort groups in order of earned cash -> solved tasks -> total upgrades
		const sortedGroupList = groups.sort((current, next) => {
			// If totalEarnedMoney is the same, then we sort by solved tasks
			if (current.totalEarnedMoney === next.totalEarnedMoney) {
				const currentSolvedTasks = getSolvedTaskCount(current);
				const nextSolvedTasks = getSolvedTaskCount(next);

				// If amount of solved tasks is the same, then we sort by total upgrades
				if (currentSolvedTasks === nextSolvedTasks) {
					const currentGameStatTotal = calculateGroupStatSum(current);
					const nextGameStatTotal = calculateGroupStatSum(next);
		
					return nextGameStatTotal - currentGameStatTotal;
				}
				
				return nextSolvedTasks - currentSolvedTasks;
			}

			return next.totalEarnedMoney - current.totalEarnedMoney;
		});

		setSortedGroups(sortedGroupList);
	}, [groups]);

	// Update group capacity and go to next round.
	const goToNextRound = () => {
		// Update capacity and earned money data for all groups
		// Clear round specific data
		groups.forEach((group) => {
			const newCapacity = group.capacity + initialCapacity;
			updateGroup(game.code + '-' + group.id, 
				{
					capacity: newCapacity,
					bids: [], 
					initialCapacityForRound: newCapacity,
					areBidsSaved: false,
				}
			);
		});

		handleGoToGameStep('next');
	};

	return (
		<div className="Result">
			<div className='Result-headerWrapper'>
				<div className='Result-gameCode'>
					<GameCode gameCode={gameCode}/>
				</div>
				<div className='Result-progressbar'>
					<Progressbar roundNumber={roundNumber}/>
				</div>
			</div>

			<div className='Result-scoreboardWrapper'>
				<div className='Result-scoreboard'>
					<div className='Result-scoreboardHeader'>
						<div className='Result-scoreboardHeaderTitle company'>
							<span>{pageUiTexts.company}</span>
						</div>
						<div className='Result-scoreboardHeaderTitle earnings'>
							<span>{pageUiTexts.earnings}</span>
						</div>
						<div className='Result-scoreboardHeaderTitle assignments'>
							<span>{pageUiTexts.solvedAssignments}</span>
						</div>
						<div className='Result-scoreboardHeaderTitle score'>
							<span>{pageUiTexts.score}</span>
						</div>
					</div>
					{sortedGroups.map((group, index) => {
						let groupStats = group.groupStats;

						// Finding group default data
						const data = groupData.find((data) => {
							return data.id === group.id;
						});

						if (!data) {
							console.error(pageUiTexts.noGroupWithID + ' ' + group.id);
						}

						// If groupstats are not found, we show default stats for this group
						if (groupStats === undefined) {
							groupStats = data.groupStats;
						}

						const groupName = data.name;

						// Finding data for group solved tasks
						let solvedTasksAmount = 0;
						if (group.taskData) {
							const solvedTasks = group.taskData.filter((task) => {
								return task.isCompleted && task.roundNumber === roundNumber;
							});

							solvedTasksAmount = solvedTasks.length;
						}

						return (
							<div key={index} className='Result-groupScoreWrapper'>
								<div className='Result-groupName'>
									{groupName}
								</div>
								<div className='Result-earnings'>
									<span>{dotformatNumber(group.totalEarnedMoney) + pageUiTexts.currency}</span>
								</div>
								<div className='Result-assignmentsSolved'>
									<span>{solvedTasksAmount + '/' + totalAssignmentCount}</span>
								</div>
								<div className='Result-groupStats'>
									<div className={'Result-groupCapacity groupBackground' + group.id}>
										<span>{group.capacity ? group.capacity : initialCapacity}</span>
									</div>
									{
										groupStats ? groupStats.map((stat, index) => {
											return (
												<div key={index} className='Result-groupStatWrapper'>
													<div className={'Result-groupStat ' + stat.id}/>
													<div className={'Result-groupStatNumber'}>
														<span>{stat.value}</span>
													</div>
												</div>
											);
										}) : ''
									}
								</div>

								<div className='Result-cornerWrapper'>
									<div className={'Result-corner groupBackground' + group.id}/>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className='Result-scoreboardNextRound'>
				{isFacilitator && roundNumber !== 6 ? 
					<Button
						text={pageUiTexts.nextRound}
						classes={['pushable']}
						isOnRelease={true}
						onClick={() => {goToNextRound();}}
					/> : ''
				}
			</div>
		</div>
	);
};

Result.propTypes = {
	handleGoToGameStep: PropTypes.func,
	setBackground: PropTypes.func.isRequired,
	isFacilitator: PropTypes.bool.isRequired,
	updateGroup: PropTypes.func.isRequired,
	groups: PropTypes.array.isRequired,
	gameCode: PropTypes.string,
	game: PropTypes.object.isRequired,
};

export default Result;