const pageUiTexts = { // pageTexts
	startUpper: 'START',
	teacherGuide: 'LÆRERGUIDE',
	finishRound: 'Afslut runden',
	done: 'Færdig',
	gameSubtitle: 'Make it or break it i byggebranchen',
	gameCode: 'Spilkode',
	startTimer: 'Start timer',
	groupReady: 'klar',
	groupNotReady: 'ikke klar',
	attention: 'Følg med på tavlen',
	capacity: 'Kapacitet',
	url: 'URL:',
	nextRound: 'Næste runde',
	seeResults: 'se resultater',
	gameOver: 'spillet er slut',
	fogsFond: 'Spillet er udviklet med økonomisk støtte fra',
	difficulty: 'Sværhedsgrad:',
	roundTime: 'Tid til opgaveløsning:',

	// adminUiTexts
	admin: 'Admin',
	gameId: 'Spil-id',
	gameIdAlreadyExists: 'Der eksisterer allerede et spil med dette id.',
	couldNotCreateGame: 'Kunne ikke oprette spil.',
	statistics: {
		created: 'Startdato',
		finished: 'Slutdato',
		finalStep: 'Gennemført',
		groupsAmount: 'Antal Grupper',
		name: 'Navn',
		facilitatorDomain: 'Email Domæne',
	},
	periods: 'perioder:',
	from: 'fra:',
	to: 'til:',
	gameAmounts: 'Antal spil i perioden:',
	groupAmounts: 'Antal grupper',
	games: 'Spil',
	yes: 'ja', 
	no: 'nej',
	unfinished: 'Ikke afsluttet',

	// errorUiTexts
	missingFields: 'Fejl: Husk at udfylde alle felter.',
	unknownError: 'Fejl: Ukendt fejl.',
	emailNotFound: 'Fejl: Der findes ingen bruger med denne email.',
	invalidEmail: 'Fejl: Din email er ikke gyldig.',
	invalidEmailPassword: 'Fejl: Check om din e-mail og password er korrekte.',
	noPasswordMatch: 'Password skal være ens i begge felter.',
	weakPassword: 'Dit password skal være på mindst 8 tegn.',
	noGameCodeMatch: 'Fejl: Ugyldig spilkode.',
	groupNameTooLong: 'Fejl: Gruppenavn må ikke være længere end 15 tegn.',
	groupNameTooShort: 'Fejl: Gruppenavn skal være på mindst 2 tegn.',
	duplicatePlayerEmail: 'Fejl: Du har allerede tilføjet en spiller med denne e-mail.',
	userDataNotFound: 'Error: User data not in database. Auto log out in 5 seconds.',
	couldNotGetUser: 'Error: %error%. Auto log out in 5 seconds.',
	roundNotFound: 'Round is not implemented! RoundNumber:',
	assignmentsNotFound: 'No assignments found for difficulty',
	bidtextNotFound: 'No text for bid!',
	unknownUpgrade: 'Unknown group upgrade!',
	noBidIdInData: 'BidId not found in data! BidId:',
	noGroupWithID: 'No Group found with ID:',
	noResourceWithID: 'No resource found with ID:',
	
	// biddingUiTexts
	companies: 'Firmaer',
	bid: 'Byd:',
	showResultsButton: 'Vis resultat',
	continueFromBidding: 'Sæt i gang!',
	currency: ' kr',

	// gameUiTexts
	best: 'Bedste',
	earnings: 'indtjening',
	assignments: 'Opgaver',
	shop: 'shop',
	buy: 'Køb',
	deliver: 'Aflever',
	company: 'Firma',
	solvedAssignments: 'Opgaver løst',
	score: 'score',
	minutes: 'min',
	default: ' - standard',
	finishGame: 'Afslut spil',

	scoreboardTypes: {
		quality: 'kvalitet',
		workenvironment: 'arbejdsmiljø',
		sustainability: 'bæredygtighed',
	},

	orderPopup: {
		title: `Du fik %correctAnswers%/%totalAnswers%.`,
		tryAgain: 'prøv igen',
	},
	
	// generalUiTexts
	lobby: 'Lobby',
	logout: 'Log ud',
	loading: 'Loading',
	popupTitle: 'Advarsel!',
	cancel: 'cancel',
	continue: 'Fortsæt',
	title: 'Lobby',
	start: 'start',
	settings: 'indstillinger',
	ok: 'ok',
	back: 'tilbage',
	seeVideo: 'SE VIDEO',

	// loginUiTexts
	studentLogin: 'Elev',
	teacherLogin: 'Lærer',
	login: 'Log ind',
	email: 'E-mail',
	password:'Kodeord',
	passwordRepeat: 'gentag password',
	createNewUser:'Opret ny bruger',
	forgotPassword: 'Glemt kodeord?',
	goToLoginBtn:'Tilbage',
	resetPassword: 'Indtast din e-mail for at nulstille dit password:',
	createUserBtn: 'Opret',
	reset:  'Nulstil',
	choose: 'Vælg',
	chooseGroup: 'Vælg gruppe',
	userCreated: 'Din bruger er blevet oprettet.',
	goBackToPlay: 'Gå tilbage og log ind for at spille.',
	gameCodeMessage: 'Når din lærer opretter et spilforløb, genereres en unik spilkode.',
	passwordResetFeedback: 'Du har modtaget et link til at nulstille dit password i din indbakke.',
	cookiesPopup: {
		text: `Vi bruger cookies til at forbedre din spiloplevelse.
				Ved at klikke på 'OK' eller logge ind accepterer du vores brug af cookies.`,	
		more: 'Læse mere',
		btn:  'Ok',
	},
	typeCode: 'Indtast spilkoden',

	// workshopUiTexts
	budget: 'Budget:',
	assignment: 'Opgave:',
	customer: 'Kunde:',
	difficultyLevels: [{
		id: 0, 
		name: 'let'
	}, 
	{
		id: 1, 
		name: 'mellem'
	}, 
	{
		id: 2, 
		name: 'svær'
	}],

	// popupsData
	consolationPopupTitle: 'Øv!',
	createGame: {
		title: 'Nyt spil',
		gameTitleLabel: '',
		gameTitlePlaceholder: 'Klassenavn',
		gamePlayDateLabel: '',
		create: 'opret spil',
		cancel: 'fortryd',
	},
	deleteGame: {
		text: 'Denne handling vil slette dit igangværende spil, vil du fortsætte?',
		title: 'Start nyt spil',
		delete: 'Start nyt spil',
		cancel: 'fortryd',
	},
	finalizeBidding: {
		title: 'Færdiggør bud',
		message: 'Er du sikker på at du vil færdiggøre dine bud? Du vil ikke kunne ændre dine bud bagefter.',
		cancel: 'fortryd',
	},
	showResults: {
		title: 'Se resultatet',
		message: 'Ikke alle grupper har placeret deres bud. Vil du fortsætte alligevel?',
		cancel: 'annuller',
	},
	wonBidsPopup: {
		title: 'Tillykke',
		messageExtra: 'Da I har vundet to udbud sætter I en underleverandør til at lave den ene. Det koster jer halvdelen af fortjenesten af denne opgave. I får fuld fortjeneste for den i selv arbejder på',
		cancel: 'start',
	},

	// settingsUiTexts
	continueGame: 'Fortsæt Spil',
	startGame: 'Start nyt Spil',
	materials: 'Download Materialer',
	tablePrintout: 'bordskilte',
	teachersGuide: 'Lærervejledning',
	assignments2: 'Tegningsmateriale',
	settingDialogueTitle: 'Start spil',
	settingsDialogueMessage: 'Vælg om du vil fortsætte igangværende spil eller starte et nyt.',
};

module.exports = {
	pageUiTexts,
};
