const bids = [
	// Bids are given during bidding rounds
	// Bidding rounds are roundnumbers, 1, 3 and 5.

	// BID SET 1
	{
		id: 1,
		round: 1,
		difficultyId: 'b1',
		name: 'Lægehuset Sundheds-guruerne',
		assignment: 'Vinduesudskiftning',
		value: 60000,
		preference: 'sustainability',
		secondPreference: 'quality',
		description: 'Lægehuset "Sundhedsguruerne" skal have udskiftet deres vinduer.',
		winText: 'Tillykke du har vundet Lægehuset Sundhedsguruernes vinduesenterprise. Kunden skal have udskiftet alle deres vinduer.',
	},
	{
		id: 2,
		round: 1,
		difficultyId: 'b1',
		name: 'Skobutikken',
		assignment: 'Butiksindretning',
		value: 55000,
		preference: 'quality',
		secondPreference: 'workenvironment',
		description: 'Skobutikken "Sko og Fødder" Har netop lejet nyt butikslokale på strøget, og har bedt om tilbud på indretning af butikken',
		winText: 'Tillykke du har vundet Skobutikken Sko og Fødders enterprise. De har netop lejet nyt butikslokale på strøget, og har skal have hjælp med indretningen.',
	},
	{
		id: 3,
		round: 1,
		difficultyId: 'b1',
		name: 'Æbelskov kommune',
		assignment: 'Gulv',
		value: 75000,
		preference: 'workenvironment',
		secondPreference: 'sustainability',
		description: 'Æbelskov kommune skal have lagt nyt gulv i Børnehaven Rumskibet.',
		winText: 'Tillykke! Du har vundet gulv-entreprisen for Gribskov kommune. De følgende opgaver skal løses for at opgaven bliver udført korrekt.',
	},
	{
		id: 4,
		round: 1,
		difficultyId: 'b1',
		name: 'Familien Trap',
		assignment: 'Legehus',
		value: 100000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: 'Familien Trap skal have bygget et luksus-legehus til børnebørnene. Legehuset skal have mindst tre rutsjebaner og centralvarme.',
		winText: 'Tillykke! Du skal bygge legehus for Familien Trap. De følgende opgaver skal løses for at opgaven bliver udført korrekt.'
	},
	{
		id: 5,
		round: 1,
		difficultyId: 'b1',
		name: 'Familien Usman',
		assignment: 'Efterisolering',
		value: 65000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: 'Efterisolering af boligen er en stor gevinst for mange familier. En af dine fast kunder har bedt om tilbud på efterisolering af deres hus.',
		winText: 'Tillykke I har vundet entreprisen med efterisolering de følgende opgaver skal løses for at opgaven bliver udført korrekt.',
	},
	{
		id: 6,
		round: 1,
		difficultyId: 'b1',
		name: 'Vinterbaderne og Dyk-kerklubben',
		assignment: 'Dykkerbro',
		value: 90000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: 'Vinterbaderne "Vikingerne" og Dykkerklubben "Havfolket" Skal have bygget en kombineret bade og dykkerbro med Sauna.',
		winText: 'Tillykke I skal bygge bro for "Vikingerne" og "Havfolket". Gennemfør de følgende opgaver så godt i kan for at få tilfredse kunder.',
	},

	// Bid Round 3
	{
		id: 8,
		round: 3,
		difficultyId: 'b1',
		name: 'Familien Løvenskjold',
		assignment: 'Badebro',
		value: 200000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: 'Familien Løvenskjold har bedt dig om at give tilbud på at etablere en badebro ved deres Sommerhus.',
		winText: 'Tillykke! Du vandt opgaven hos Familien Løvenskjold!! De følgende opgaver skal løses for at opgaven bliver udført tilfredsstillende.',
	},
	{
		id: 9,
		round: 3,
		difficultyId: 'b1',
		name: 'Famillien Pedersen',
		assignment: 'Tilbygning',
		value: 230000,
		preference: 'sustainability',
		secondPreference: 'quality',
		description: 'Famillien Pedersen har købt nyt hus og skal have lavet en tilbygning på 40 m2',
		winText: 'Familien har hørt om jeres gode rygte i forhold til valg af klimavenlige og bæredygtige materialer, og i har vundet opgaven. For at få mest muligt overskud på entreprisen, skal du svare på følgende spørgsmål.',
	},
	{
		id: 10,
		round: 3,
		difficultyId: 'b1',
		name: 'Børne og Ungeforvaltningen i Bjergkøbing Kommune',
		assignment: 'Skateboard Rampe',
		value: 220000,
		preference: 'workenvironment',
		secondPreference: 'sustainability',
		description: 'Børne og Ungeforvaltningen i Bjergkøbing Kommune har fået bevilget penge til en skateboardrampe.',
		winText: 'Tillykke! I har vundet opgaven med at etablere en rampe tilBjergkøbing Kommune. For at fuldføre opgaven korrekt skal i svare bedst muligt på følgende spørgsmål',
	},
	{
		id: 11,
		round: 3,
		difficultyId: 'b1',
		name: 'Café Huset i Strandgade',
		assignment: 'Bindingsværk',
		value: 250000,
		preference: 'quality',
		secondPreference: 'workenvironment',
		description: 'Huset i Strandgade, som er et af byens ældste huse trænger til at få udskiftet en del af det gamle bindingsværk som har holdt huset i 400 år. Du skal byde på at udskifte samtlige Bundremme i huset samt en del andet træværk som er angrebet af tømrersvamp.',
		winText: 'Tillykke! Jeres Gode rygte om kvalitet har givet pote, og i har vundet renoveringsopgaven i strandgade.',
	},
	{
		id: 12,
		round: 3,
		difficultyId: 'b1',
		name: 'Typehusfirmaet mi Casa',
		assignment: 'Typehus',
		value: 300000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: 'Typehusfirmaet mi Casa har tilbudt dig at være med på at bygge et af deres huse. Du skal byde ind på at opsætte alle de bærende vægge og tagkonstruktionen',
		winText: 'Opmærksomhed på det daglige arbejdsmiljø kan godt betale sig. Typehusfirmaet har valgt jer som deres underentreprenør, fordi de ved at i behandler jeres ansatte ordentligt og altid holder en ryddelig byggeplads.',
	},
	{
		id: 13,
		round: 3,
		difficultyId: 'b1',
		name: 'Vuggestuen Haletudsen',
		assignment: 'Træterrasse',
		value: 340000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: 'Vuggestuen Haletudsen skal have bygget en træterrasse med indbygget sandkasse, som børnene kan kravle rundt på. Vuggestuen lægger stor vægt på at de materialer der bliver brugt er bæredygtige og uden giftige tilsætningsstoffer',
		winText: 'Firmaets mange aktiviteter og oplysninger på SOME om vordan I bygger mere bæredygtigt har givet pote og i har vundet sagen for Vuggestuen Haletudsen. For at fuldføre opgaven korrekt skal i svare bedst muligt på følgende spørgsmål.',
	},

	// Bid Round 5
	{
		id: 15,
		round: 5,
		difficultyId: 'b1',
		name: '',
		assignment: 'Stråtag',
		value: 750000,
		preference: 'sustainability',
		secondPreference: 'quality',
		description: 'Du er gennem en samarbejdspartner som er Tækkemand blevet inviteret til at give tilbud på renovering af et nedslidt stråtag. Her er det primære fokus valg af klimvenlige materialer og god kvalitet i det arbejde du udfører.',
		winText: 'Tillykke du skal i gang med at udskifte tag med tækkemanden.',
	},
	{
		id: 16,
		round: 5,
		difficultyId: 'b1',
		name: '',
		assignment: 'Tilbygning',
		value: 730000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: 'Familien Trap har købt et kolonihavehus og skal have lavet en tilbygning til deres teenage-tvillinger, så de kan få deres eget værelse.',
		winText: 'Tillykke! I skal bygge kolonihave for familien Trap. Tvillingerne har lavet denne tegning som idé. I skal finde de bedste svar til de følgende spørgsmål for at kunderne bliver tilfredse med arbejdet.'
	},
	{
		id: 17,
		round: 5,
		difficultyId: 'b1',
		name: '',
		assignment: 'Tagudskiftning',
		value: 740000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: 'Skolen ved Kongevejen skal have renoveret deres tagkonstruktion. Du er indbudt til opgaven hvor komunen er bygherre. De lægger stor vægt på at arbejdsmiljøet for de håndværkere der skal foretage renoveringen er i orden.',
		winText: 'Tillykke! I har vundet opgaven for kongevejen skole. Løs de følgende opgaver for at tjene penge til de sidste opgraderinger i spillet',
	},
	{
		id: 18,
		round: 5,
		difficultyId: 'b1',
		name: '',
		assignment: 'Kontorindretning',
		value: 700000,
		preference: 'workenvironment',
		secondPreference: 'sustainability',
		description: 'Det nystartede "Gaming-company"  skal have indrettet et nyt kontor. Firmaet går meget op i valg af klimavenlige materialer og Arbejdsmiljøet for håndværkerne på byggepladsen',
		winText: 'Tillykke! I har vundet opgaven for "Gaming-Company". Løs de følgende opgaver for at tjene penge til de sidste opgraderinger i spillet',
	},
	{
		id: 19,
		round: 5,
		difficultyId: 'b1',
		name: '',
		assignment: 'Brandrenovering/sikring',
		value: 540000,
		preference: 'quality',
		secondPreference: 'workenvironment',
		description: 'Indskolingsafdelingen på Æbelskov komuneskole skal renoveres efter en brand',
		winText: 'Tillykke! I har vundet opgaven for Æbelskov komuneskole. Løs de følgende opgaver for at tjene penge til de sidste opgraderinger i spillet',
	},
	{
		id: 20,
		round: 5,
		difficultyId: 'b1',
		name: '',
		assignment: 'Multibane',
		value: 400000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: 'Fritidsklubben skal have bygget en ny multibane. Den skal bygges uden brug af Beton, da Fritidklubben går meget op i at bygge bæredygtigt.',
		winText: 'Tillykke! I har vundet opgaven for Fritidsklubben. Løs de følgende opgaver for at tjene penge til de sidste opgraderinger i spillet',
	},
	
	// BID SET 2
	{
		id: '1b',
		round: 1,
		difficultyId: 'b2',
		name: 'Familien Jensen',
		assignment: 'Køkkenrenovering',
		value: 60000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke i har vundet opgaven for familien Jensen. I skal nu i gang med Opmåling og bestilling af nye køkkenelementer. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},
	{
		id: '2b',
		round: 1,
		difficultyId: 'b2',
		name: 'Friskolen Grønne Hoveder',
		assignment: 'Podcast-studie',
		value: 55000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke du har vundet opgaven for Friskolen. Eleverne laver som en del af undervisningen Podcasts, og skal derfor have indrettet et Lydstudie på skolen. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},
	{
		id: '3b',
		round: 1,
		difficultyId: 'b2',
		name: 'Plejehjemmet Fredholm',
		assignment: 'Udskiftning af yderdøre',
		value: 75000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke I har vundet opgaven for Plejehjemmet. Der skal installeres nye yderdøre på hele plejehjemmet. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},
	{
		id: '4b',
		round: 1,
		difficultyId: 'b2',
		name: 'Byggefirmaet Bo Godt',
		assignment: 'Etablering af ny garage',
		value: 100000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke du skal bygge Garage Byggefirmaet Bo Godt har hyret dig til at bygge en Garage. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},
	{
		id: '5b',
		round: 1,
		difficultyId: 'b2',
		name: 'Familien Jørgensen',
		assignment: 'Udskiftning af indvendige døre',
		value: 65000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke med Opgaven for Familien Jørgensen. Familien vil gerne have udskifttet alle deres indvendige døre. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},
	{
		id: '6b',
		round: 1,
		difficultyId: 'b2',
		name: 'Rasmussen Consult',
		assignment: 'Indretning af hjemmekontor',
		value: 90000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke! Firmaet Rasmussen Consult har valgt jer til at indrette deres hjemmekontor. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},

	// Bid Round 3
	{
		id: '8b',
		round: 3,
		difficultyId: 'b2',
		name: 'Æbelskov Kommune',
		assignment: 'Havebænken',
		value: 200000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke I har vundet opgaven for Æbelskov Kommune. Der blandt andet skal have fremstillet bænke til deres nye sansehave. Husk at få udleveret det korekte tegningsmateriale så du kan løse de kommende opgaver.',
	},
	{
		id: '9b',
		round: 3,
		difficultyId: 'b2',
		name: 'Kunstmuseet Firkanten',
		assignment: 'Par-bænkene',
		value: 230000,
		preference: 'sustainability',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke! I har vundet opgaven for Kunstmuseet. De skal have jer til at fremstille nogle møbler til deres nye skulpturhave. Husk at få udleveret det korekte tegningsmateriale så du kan løse de kommende opgaver.',
	},
	{
		id: '10b',
		round: 3,
		difficultyId: 'b2',
		name: 'Familien Pedersen',
		assignment: 'Trappemøbel',
		value: 220000,
		preference: 'workenvironment',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke! I har vundet opgaven for Familien Pederesen, der skal have udført et trappemøbel til deres nyindrettede soveværelse. Husk at få udleveret det korekte tegningsmateriale så du kan løse de kommende opgaver.',
	},
	{
		id: '11b',
		round: 3,
		difficultyId: 'b2',
		name: 'Storcenteret "Shop Amok"',
		assignment: 'Leopold stolene',
		value: 250000,
		preference: 'quality',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke! I har vundet opgavern for storcenteret ""Shop Amok"" Der skal leveres stole til de trætte gæster. Husk at få udleveret det korekte tegningsmateriale så du kan løse de kommende opgaver.',
	},
	{
		id: '12b',
		round: 3,
		difficultyId: 'b2',
		name: 'Ungdomsklubben',
		assignment: 'Overdækket Bord-Bænke',
		value: 300000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke! I har vundet opgaven for Ungdomsklubben, med at bygge overdækkede bord-bænke. Husk at få udleveret det korekte tegningsmateriale så du kan løse de kommende opgaver.',
	},
	{
		id: '13b',
		round: 3,
		difficultyId: 'b2',
		name: 'Skolen ved kæret',
		assignment: 'Bord-bænke sæt',
		value: 340000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke ! I har vundet opgaven for ""Skolen ved Kæret"" der skal have bygget bord-bænke sæt. Sættene skal bygges sammen med eleverne på skolen. Husk at få udleveret det korekte tegningsmateriale så du kan løse de kommende opgaver.',
	},
	
	// Bid Round 5
	{
		id: '15b',
		round: 5,
		difficultyId: 'b2',
		name: 'Foreningen Bo Småt',
		assignment: 'Tinyhouse',
		value: 750000,
		preference: 'sustainability',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke! I har vundet opgaven for foreningen "Bo Småt". I skal bygge en prototype på et Tinyhouse der viser gode løsninger til at på småt. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},
	{
		id: '16b',
		round: 5,
		difficultyId: 'b2',
		name: 'Familien Herreskov',
		assignment: 'Husbåd',
		value: 730000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke! I skal bygge husbåd for Familien Herreskov. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.'
	},
	{
		id: '17b',
		round: 5,
		difficultyId: 'b2',
		name: 'Æbelskov Kommune',
		assignment: 'Tagrenovering',
		value: 740000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke! I skal lave tagrenovering for Æbelskov Kommune. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},
	{
		id: '18b',
		round: 5,
		difficultyId: 'b2',
		name: 'Børnehuset "Sneglehuset"',
		assignment: 'Udvendig Facadeisolering',
		value: 700000,
		preference: 'workenvironment',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke! I skal lave Udvendig Facadeisolering for Børnehuset "Sneglehuset" I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},
	{
		id: '19b',
		round: 5,
		difficultyId: 'b2',
		name: 'Andelsforeningen "Gammelgård"',
		assignment: 'Renovering af vinduer',
		value: 540000,
		preference: 'quality',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke! I skal renovere vinduer for Andelsforeningen "Gammelgård" I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},
	{
		id: '20b',
		round: 5,
		difficultyId: 'b2',
		name: 'Familien Lund',
		assignment: 'Terrasse',
		value: 400000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke! I skal Bygge terrasse for Familien Lund. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.',
	},

	// BID SET 3
	{
		id: '1a',
		round: 1,
		difficultyId: 'a1',
		name: 'Familien Andersen',
		assignment: 'Legehus',
		value: 60000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '2a',
		round: 1,
		difficultyId: 'a1',
		name: 'Brødrene Flick',
		assignment: 'Brændeskur',
		value: 55000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '3a',
		round: 1,
		difficultyId: 'a1',
		name: 'Skolen ved søen',
		assignment: 'Basketball bane',
		value: 75000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '4a',
		round: 1,
		difficultyId: 'a1',
		name: 'Børnehuset Skovmusen',
		assignment: 'Legeplads',
		value: 100000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '5a',
		round: 1,
		difficultyId: 'a1',
		name: 'Byggefirmaet Bo Grønt',
		assignment: 'Anneks',
		value: 65000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '6a',
		round: 1,
		difficultyId: 'a1',
		name: 'Familien Christensen',
		assignment: 'Legeværelse',
		value: 90000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '8a',
		round: 3,
		difficultyId: 'a1',
		name: 'Søstrene Samson',
		assignment: 'Hestefold',
		value: 200000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '9a',
		round: 3,
		difficultyId: 'a1',
		name: 'Familien Frisgård',
		assignment: 'Havehus',
		value: 230000,
		preference: 'sustainability',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '10a',
		round: 3,
		difficultyId: 'a1',
		name: 'Klub Kanon',
		assignment: 'Legeplads',
		value: 220000,
		preference: 'workenvironment',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '11a',
		round: 3,
		difficultyId: 'a1',
		name: 'Æbelskov Kommune',
		assignment: 'Shelter',
		value: 250000,
		preference: 'quality',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '12a',
		round: 3,
		difficultyId: 'a1',
		name: 'Sportsklubben',
		assignment: 'Gamer Rum',
		value: 300000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '13a',
		round: 3,
		difficultyId: 'a1',
		name: 'Gamer Station',
		assignment: 'Fitness Rum',
		value: 340000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '15a',
		round: 5,
		difficultyId: 'a1',
		name: 'Skolen ved Skæret',
		assignment: 'Nyt Tag',
		value: 750000,
		preference: 'sustainability',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '16a',
		round: 5,
		difficultyId: 'a1',
		name: 'Ungdomsskolen',
		assignment: 'Etablering af Multihal',
		value: 730000,
		preference: 'quality',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '17a',
		round: 5,
		difficultyId: 'a1',
		name: 'Videncenter for Gamification',
		assignment: '',
		value: 740000,
		preference: 'workenvironment',
		secondPreference: 'quality',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '18a',
		round: 5,
		difficultyId: 'a1',
		name: 'Ældrecenteret "Stæremsen"',
		assignment: 'Undervisning afdeling',
		value: 700000,
		preference: 'workenvironment',
		secondPreference: 'sustainability',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '19a',
		round: 5,
		difficultyId: 'a1',
		name: 'Ervhervsskolen "True South"',
		assignment: 'Etablering af skolehjem',
		value: 540000,
		preference: 'quality',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
	{
		id: '20a',
		round: 5,
		difficultyId: 'a1',
		name: 'Familien Jørgensen',
		assignment: 'Renovering af badeværelse',
		value: 400000,
		preference: 'sustainability',
		secondPreference: 'workenvironment',
		description: '',
		winText: 'Tillykke I har vundet opgaven. Løs opgaverne for at tjene penge og opgradere jeres firma',
	},
];

const consolationTasks = [
	// ConsolationTasks are given during build rounds
	// Build rounds are round, 2, 4 and 6.

	// BID SET 1
	{
		id: 7,
		round: 2,
		difficultyId: 'b1',
		name: 'Din faster',
		assignment: 'Hegn',
		value: 50000,
		message: 'Da du ikke har vundet nogle af de opgaver du har budt på får du tilbudt timearbejde for din faster der skal have bygget et hegn der kan holde hendes nyerhvervede hundehvalp inde i haven. Løs følgende opgaver bedst muligt for at holde gang i butikken'
	},
	{
		id: 14,
		round: 4,
		difficultyId: 'b1',
		name: 'En tidligere kollega',
		assignment: 'Gipsvægge',
		value: 200000,
		message: 'En tidligere Kollega har tilbudt dig arbejde med montage af gipsvægge, da hun ved du ikke har vundet de entrepriser du har budt på. Der er gode penge i jobbet men det er monotont arbejde.'
	},
	{
		id: 21,
		round: 6,
		difficultyId: 'b1',
		name: 'Firma der bygger luksushoteller',
		assignment: 'Rejsemontør',
		value: 500000,
		message: 'Da du ikke har vundet nogle af de entrepriser du har budt på, melder du dig som rejsemontør for et firma der bygger luksushoteler i hele verden. Du tjener godt men har ikke arbejde i det samme land mere end 3 uger af gangen.'
	},

	// BID SET 2
	{
		id: '7b',
		round: 2,
		difficultyId: 'b2',
		name: 'Onkel Frans',
		assignment: 'Opbevaringsrum',
		value: 50000,
		message: 'Da I ikke har vundet nogle af de entrepriser du har budt på, ringer du rundt til dit netværk og får en opgave for din onkel Frans. Han skal have etableret et opbevaringsrum i sin kælder. I skal løse de følgende opgaver for at udføre opgaven tilfredsstillende.'
	},
	{
		id: '14b',
		round: 4,
		difficultyId: 'b2',
		name: 'En tidligere kollega',
		assignment: 'Gips Entreprise',
		value: 240000,
		message: 'Da I desværre ikke har vundet nogle af de opgaver i har budt på ringer I rundt til alle jeres gamle kollegaer. En af dem har en stor Gips entreprise. Det er kedeligt arbejde men kvadratmeterprisen er god'
	},
	{
		id: '21b',
		round: 6,
		difficultyId: 'b2',
		name: 'Frilands museet',
		assignment: 'Renovering af bindingsværk',
		value: 500000,
		message: 'Da I desværre ikke har vundet nogle af de opgaver i har budt på er I så heldige at blive ringet op af Frilands museet som skal have jeres hjælp til at renovere gammelt bindingsværk på et af deres 500 år gamle huse.'
	},

	// BID SET 3
	{
		id: '7a',
		round: 2,
		difficultyId: 'a1',
		name: 'Et familiemedlem',
		assignment: 'Hønsegård',
		value: 50000,
		message: 'Da I ikke har vundet nogle at de opgaver i har budt på får i arbejde for et familiemedlem der skal have bygget en hønsegård.'
	},
	{
		id: '14a',
		round: 4,
		difficultyId: 'a1',
		name: 'Moster Maya',
		assignment: 'Kaningård',
		value: 240000,
		message: 'Da I ikke har vundet nogle at de opgaver i har budt på får i arbejde for et familiemedlem der skal have bygget en kaningård.'
	},
	{
		id: '21a',
		round: 6,
		difficultyId: 'a1',
		name: '',
		assignment: '',
		value: 500000,
		message: 'Da I ikke har vundet nogle at de opgaver i har budt på får i arbejde for et familiemedlem der skal have bygget en kaningård.'
	},
];

const secondaryPreferenceMultiplier = 0.5;

module.exports = {bids, consolationTasks, secondaryPreferenceMultiplier};