import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './final-result.scss';
import { gameStepsData } from 'data/game-steps-data';
import GameCode from 'components/ui/game-code/game-code';
import Progressbar from 'components/ui/progressbar/progressbar';
import { groupData } from 'data/group-data';
import { pageUiTexts } from 'data/ui-texts';
import { calculateGroupStatSum, getSolvedTaskCount } from 'helpers/game-helper';
import { dotformatNumber } from 'helpers/text-helper';
import ConfettiCannon from 'components/ui/confetti-cannon/confetti-cannon';
import Button from 'components/ui/button/button';

const FinalResult = (props) => {
	const {
		groups,
		gameCode,
		game,
		finishCurrentGame,
	} = props;

	const [roundNumber, setRoundNumber] = useState(0);
	const [sortedGroups, setSortedGroups] = useState([]);
	const [finishingGame, setFinishingGame] = useState(false);

	useEffect(() => {
		// Finding round number for progressbar
		const newRoundNumber = gameStepsData.find((step) => {
			return step.id === game.gameStepId;
		}).roundNumber;

		setRoundNumber(newRoundNumber);
	}, [game.gameStepId]);

	useEffect(() => {
		// sort groups in order of earned cash -> solved tasks -> total upgrades
		const sortedGroupList = groups.sort((current, next) => {
			// If totalEarnedMoney is the same, then we sort by solved tasks
			if (current.totalEarnedMoney === next.totalEarnedMoney) {
				const currentSolvedTasks = getSolvedTaskCount(current);
				const nextSolvedTasks = getSolvedTaskCount(next);

				// If amount of solved tasks is the same, then we sort by total upgrades
				if (currentSolvedTasks === nextSolvedTasks) {
					const currentGameStatTotal = calculateGroupStatSum(current);
					const nextGameStatTotal = calculateGroupStatSum(next);
		
					return nextGameStatTotal - currentGameStatTotal;
				}
				
				return nextSolvedTasks - currentSolvedTasks;
			}

			return next.totalEarnedMoney - current.totalEarnedMoney;
		});

		setSortedGroups(sortedGroupList);
	}, [groups]);

	return (
		<div className="FinalResult">
			<ConfettiCannon colors={['#EB1CB5', '#4DB4FC', '#60CB4D', '#F85454', '#E3A626', '#7D64A8']}/>
			<div className='FinalResult-headerWrapper'>
				<div className='FinalResult-gameCode'>
					<GameCode gameCode={gameCode}/>
				</div>
				<div className='FinalResult-progressbar'>
					<Progressbar roundNumber={roundNumber}/>
				</div>
			</div>

			<div className='FinalResult-scoreboardWrapper'>
				<div className='FinalResult-scoreboard'>
					<div className='FinalResult-scoreboardTitle'>
						<span>{pageUiTexts.best + ' ' + pageUiTexts.earnings}</span>
					</div>
					<div className='FinalResult-scoreboardHeader'>
						<div className='FinalResult-scoreboardHeaderTitle first'>
							<span>{'Rank'}</span>
						</div>
						<div className='FinalResult-scoreboardHeaderTitle start'>
							<span>{pageUiTexts.company}</span>
						</div>
						<div className='FinalResult-scoreboardHeaderTitle end'>
							<span>{pageUiTexts.score}</span>
						</div>
					</div>
					{sortedGroups.map((group, index) => {
						// Finding group default data
						const data = groupData.find((data) => {
							return data.id === group.id;
						});

						if (!data) {
							console.error(pageUiTexts.noGroupWithID + ' ' + group.id);
						}

						let rankClass = '';
						switch (index) {
						case 0: 
							rankClass = 'gold'; 
							break;
						default: 
							rankClass = 'none';
							break;
						}

						let achievements = [];
						if (group.achievements) {
							achievements = group.achievements;
						} 

						const groupName = data.name;

						return (
							<div key={index} className='FinalResult-groupScoreWrapper'>
								<div className={'FinalResult-groupRank ' + rankClass}>
									<span>{index + 1 + '.'}</span>
								</div>

								<div className='FinalResult-groupNameWrapper'>
									<div className='FinalResult-groupName'><span>{groupName}</span></div>
									{achievements.map((achievement, index) => {
										return (
											<div key={index} 
												className={'FinalResult-otherAchievements ' + achievement}
											/>
										);
									})}
								</div>

								<div className='FinalResult-totalEarnedMoney'>
									<div className={'FinalResult-trophy ' + rankClass}/>
									<span>{dotformatNumber(group.totalEarnedMoney) + pageUiTexts.currency}</span>
								</div>

								<div className='FinalResult-cornerWrapper'>
									<div className={'FinalResult-corner groupBackground' + group.id}/>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<div className='FinalResult-backToStart'>
				<Button
					isDisabled={finishingGame}
					isLoading={finishingGame}
					text={pageUiTexts.finishGame}
					classes={['pushable']}
					onClick={() => {setFinishingGame(true); finishCurrentGame();}}
				/>
			</div>
		</div>
	);
};

FinalResult.propTypes = {
	groups: PropTypes.array.isRequired,
	gameCode: PropTypes.string,
	game: PropTypes.object.isRequired,
	finishCurrentGame: PropTypes.func.isRequired,
};

export default FinalResult;