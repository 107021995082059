const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 44,
		taskId: 'round-3-price-diagram',
		title: 'Prisudvikling',
		text: 'Når man har sit eget firma skal man vide lidt om priser på materialerne. Find svaret i diagrammet. Hvor meget kostede en meter spærtræ i 2014?',
		answer: 25,
		image: 'price-diagram',
		unit: 'kr',
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 45,
		taskId: 'round-3-price-diagram-2',
		title: 'Prisudvikling',
		text: 'I skal indkøbe gulv til en opgave, og undrer jer over at prisen er så høj. Derfor vil i gerne kigge lidt på prisudviklingen. Brug diagrammet til at svare på spørgsmålet. Hvad kostede en kvadratmeter fyrretræsgulv i 2013',
		answer: 300,
		image: 'price-diagram-2',
		unit: 'kr',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate,{
		assignmentNumber: 47,
		taskId: 'round-3-multiple-choice-diagram-educations-entrances',
		title: 'Ungdomsuddannelse',
		text: 'Når man vælger en erhversuddannelse er der 4 forskellige indgange. Diagrammet her viser fordelingen af elever på de 4 forskellige indgange. Vælg de 4 forskellige indgange der er til en erhvervsuddannelse.',
		image: 'educations',
		style: 'singleLine',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 1,
				text: 'Teknologi, Byggeri og Transport', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 2,
				text: 'Psygologi, Historie og Samfund', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 3,
				text: 'Omsorg, Sundhed og Pædagogik', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 4,
				text: 'Komik, Drama og Billedkunst', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 5,
				text: 'Kontor, Handel og Forretningsservice', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 6,
				text: 'Sikkerhed, Forsvar og Inteligens', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 7,
				text: 'Fødevare, Jordbrug og Oplevelser', 
				isCorrect: true,
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 48,
		taskId: 'round-3-educations-choice-percentage',
		title: 'Ungdomsuddannelse',
		text: 'Når man vælger en erhversuddannelse er der 4 forskellige indgange. Diagrammet her viser fordelingen af elever på de 4 forskellige indgange. Hvor mange procent valgte Fødevare, Jordbrug og Oplevelser',
		answer: 13.8,
		image: 'educations',
		unit: '%',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate,{
		assignmentNumber: 49,
		taskId: 'round-3-multiple-choice-diagram-educations',
		title: 'Ungdomsuddannelse',
		text: 'De forskellige indgange til erhvervsuddannelser er mere eller mindre populære. Hvilken af de fire indgange til GF1 var den mest populære?',
		image: 'educations',
		style: 'singleLine',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 1,
				text: 'Teknologi, Byggeri og Transport', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 2,
				text: 'Omsorg, Sundhed og Pædagogik', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 3,
				text: 'Kontor, Handel og Forretningsservice', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate,{
				id: 4,
				text: 'Fødevare, Jordbrug og Oplevelser', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 51,
		taskId: 'round-3-educations-choice-diagram-educations-girls-2',
		title: 'Ungdomsuddannelse',
		text: 'Diagrammet her viser fordelingen af de piger der har søgt ind på en erhvervsuddannelse i 2023. Fordelt på de 4 forskellige Grundforløb. Hvor mange procent af pigerne søger ind på "Teknologi, Byggeri og Transport"?',
		answer: 18.1,
		image: 'educations-girls',
		unit: '%',
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 55,
		taskId: 'round-3-shopping-list',
		title: 'Skruer og beslag',
		text: 'Denne indkøbsliste viser hvad er skal købes ind af skruer og beslag til jeres projekt. Udregn, den totale pris for skruer og beslag',
		answer: 2351,
		image: 'shopping-list',
		unit: 'kr',
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 56,
		taskId: 'round-3-shopping-list-2',
		title: 'Træ og plader',
		text: 'Denne indkøbsliste viser hvad der skal købes ind af træ og plader til projektet. Udregn den totale pris for træ og plader',
		answer: 4205,
		image: 'shopping-list-2',
		unit: 'kr',
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 57,
		taskId: 'round-3-shopping-list-3',
		title: 'Maling og afdækning',
		text: 'Denne indkøbsliste viser hvad er skal købes ind af Maling og afdækning til jeres projekt. Udregn den totale pris for maling og afdækning',
		answer: 2397,
		image: 'shopping-list-3',
		unit: 'kr',
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 58,
		taskId: 'round-3-assignment-list',
		title: 'Arbejdsopgaver og timepriser',
		text: 'Denne Opgaveliste viser hvor lang tid de forskellige opgaver tager og hvad det koster i timen. Udregn den totale pris på opgverne ved at gange timerne med timeprisen og lægge det hele sammen',
		answer: 15520,
		image: 'assignment-list',
		unit: 'kr',
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 59,
		taskId: 'round-3-price-sum',
		title: 'Materialer og Timeløn',
		text: 'Denne liste viser de forskellige materialer og den samlede pris på arbejdsløn. Udregn den samlede pris på hele projektet udfra priserne på listen',
		answer: 24473,
		image: 'price-sum',
		unit: 'kr',
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round3-medium-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};