import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { groupData, initialCapacity } from 'data/group-data';
import BidBox from 'components/ui/bid-box/bid-box';
import './bid-result.scss';
import CornerLogo from 'components/ui/corner-logo/corner-logo';
import GroupBox from 'components/ui/group-box/group-box';
import GroupData from 'components/ui/group-data/group-data';
import { gameStepsData } from 'data/game-steps-data';
import { getRoundBids } from 'helpers/bidding-helper';

const BidResult = ({handleLogout, group, groups, game}) => {
	const [roundBids, setRoundBids] = useState([]);
	const [groupName, setGroupName] = useState('');
	
	useEffect(() => {
		const roundNumber = gameStepsData.find((step) => {
			return step.id === game.gameStepId;
		}).roundNumber;

		const currentBids = getRoundBids(roundNumber, game.difficulty);

		// Finding group name for GroupBox
		let groupName = groupData.find((data) => {
			return data.id === group.id;
		}).name;

		setGroupName(groupName);
		setRoundBids(currentBids);
	}, [game.gameStepId, game.difficulty, group.id]);

	return (
		<div className='BidResult'>
			<div className='BidResult-headerWrapper'>
				<div className='BidResult-logo'>
					<CornerLogo />
				</div>
				<div className='BidResult-sidebar'>
					<div className='BidResult-currentGroup'>
						<GroupBox currentCapacity={group.capacity} group={group} groupName={groupName}/>
					</div>
					
					<div className='BidResult-groups'>
						{groupData.map((data) => {
							const isCurrentGroup = data.id === group.id;
							const groupInfo = groups.find((g) => {return g.id === data.id;});

							let groupCapacity = initialCapacity;
							if (groupInfo !== undefined) {
								groupCapacity = groupInfo.capacity === undefined ? initialCapacity : groupInfo.capacity;
							}
							// If groupInfo was not found, we wont show the group
							if (!groupInfo) {
								return (<div key={data.id}/>);
							}
							return (
								<div key={data.id} className='BidResult-groupInfo'>
									{isCurrentGroup ? 
										'' : <GroupData
											groupCapacity={groupCapacity} 
											group={groupInfo}
										/>
									}
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className='BidResult-bids'>
				{roundBids.map((bid, index) => {
					let bidWinner = null;
					// Checking if there are currently any bid winners
					if (game.bidWinners) {
						// Finding bid winner for current bid
						const currentBidWinner = game.bidWinners.find((bidWinner) => {
							return bidWinner.bidId === bid.id;
						});

						// If there is a bid winner for current bid, then we get the group id
						if (currentBidWinner) {
							bidWinner = currentBidWinner.groupId;
						}
					}
					
					return (
						<div key={bid.id + '-' + index} className={'BidResult-bidBox position' + index}>
							<BidBox
								isFacilitator={true}
								groups={groups}
								bidInfo={bid}
								bidWinner={bidWinner}
								isResultShown={true}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

BidResult.propTypes = {
	handleLogout: PropTypes.func.isRequired,
	group: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	game: PropTypes.object.isRequired,
};

export default BidResult;