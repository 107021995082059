import React from 'react';
import PropTypes from 'prop-types';
import {pageUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './admin-login.scss';

const AdminLogin = (props) => {
	const {
		isLoading,
		email,
		password,
		feedback,
		handleInput,
		handleLogin
	} = props;

	return (
		<div className="AdminLogin">
			<div className="AdminLogin-logo">
				
			</div>
			<div className='AdminLogin-boxWrapper'>
				<div className="AdminLogin-box">
					<form className="AdminLogin-form" onSubmit={handleLogin}>
						<div className='AdminLogin-formHeader'>
							<p>{pageUiTexts.admin}</p>
						</div>
						<input
							name="inputEmail"
							type="text"
							placeholder={pageUiTexts.email} 
							autoComplete="section-adminLogin email"
							value={email ? email : ''} 
							onChange={(event)=>{handleInput(event);}}
						/>
						<input
							name="inputPassword"
							type="password"
							placeholder={pageUiTexts.password}
							autoComplete="section-adminLogin password"
							value={password ? password : ''} 
							onChange={(event)=>{handleInput(event);}}
						/>
						<div className='AdminLogin-button'>
							<Button 
								isLoading={isLoading}
								classes={['login', 'fullHeight', 'noTopMargin']}
								text={pageUiTexts.login} 
								onClick={handleLogin}
								type='submit'
							/>
						</div>

						<p className="AdminLogin-errorMessage">{feedback}</p>
					</form>
				</div>
			</div>
		</div>
	);
};

AdminLogin.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired	
};

export default AdminLogin;