const { pageUiTexts } = require('./ui-texts');

const materialsData = [
	{
		id: 'a1',
		text: '5-6 klasse 1',
		materialList: [
			{
				resourceId: 'gruppeskilte',
				text: pageUiTexts.tablePrintout
			},
			{
				resourceId: 'teachersGuide-5-6',
				text: pageUiTexts.teachersGuide
			}
		]
	},
	/*{
		id: 'a2',
		text: '5-6 klasse 2',
		materialList: [

		]
	},*/
	{
		id: 'b1',
		text: '7-10 klasse 1',
		materialList: [
			{
				resourceId: 'gruppeskilte',
				text: pageUiTexts.tablePrintout
			},
			{
				resourceId: 'teachersGuide',
				text: pageUiTexts.teachersGuide
			},
			{
				resourceId: 'assignmentsPrintouts',
				text: pageUiTexts.assignments2
			}
		]
	},
	{
		id: 'b2',
		text: '7-10 klasse 2',
		materialList: [
			{
				resourceId: 'gruppeskilte',
				text: pageUiTexts.tablePrintout
			},
			{
				resourceId: 'teachersGuide',
				text: pageUiTexts.teachersGuide
			},
			{
				resourceId: 'assignmentsPrintouts2',
				text: pageUiTexts.assignments2
			}
		]
	},
]

module.exports = {
	materialsData
}