import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './statistics.scss';
import { sortArrayByProperty } from 'helpers/array-helper';
import { pageUiTexts } from 'data/ui-texts';

const Statistics = ({gameStats}) => {
	const [shownGameStats, setShownGameStats] = useState(null);
	const [currentGameStat, setCurrentGameStat] = useState(null);
	const [currentFromDate, setCurrentFromDate] = useState(null);
	const [currentToDate, setCurrentToDate] = useState(null);
	const [currentIndex, setCurrentIndex] = useState(null);

	useEffect(() => {
		let filteredGameStats = gameStats;
		
		if (currentFromDate && !isNaN(currentFromDate)) {
			filteredGameStats = filteredGameStats.filter((stat) => {
				return new Date(stat.timeStamp.value) >= currentFromDate;
			});
		}

		if (currentToDate && !isNaN(currentToDate)) {
			filteredGameStats = filteredGameStats.filter((stat) => {
				return new Date(stat.timeStamp.value) <= currentToDate;
			});
		}
		
		setShownGameStats(filteredGameStats);
	}, [gameStats, currentFromDate, currentToDate]);

	const setCurrentGameStatData = (stats) => {
		if (!stats) {
			setCurrentGameStat(stats);
			return;
		}

		let newStats = [];
		let statObject = [];
		Object.keys(stats).forEach((key) => {
			if (stats[key].show !== undefined && !stats[key].show) {
				return;
			}

			statObject = {
				key: key,
				value: stats[key],
			};
			newStats.push(statObject);
		});

		sortArrayByProperty(newStats, 'key', 'ASC');
		setCurrentGameStat(newStats);
	};

	const handleInput = (event, isFrom) => {
		let newDateInput = new Date(event.target.value);

		if (isFrom) {
			setCurrentFromDate(newDateInput);
		} else {
			const midnight = newDateInput.setHours(23, 59);
			setCurrentToDate(new Date(midnight));
		}
	};

	return (
		<div className='Statistics'>
			<div className='Statistics-wrapper'>
				{currentGameStat && 
					<div className='Statistics-statPopup' 
						onClick={() => {setCurrentGameStatData(null); setCurrentIndex(null);}}
					>
						<div className='Statistics-statWindow'>
							{currentGameStat.map((stat, index) => {
								return (
									<div key={index} className='Statistics-statWrapper'>
										<div className='Statistics-statName'>
											<span>{pageUiTexts.statistics[stat.key]}:</span>
										</div>
										<div className='Statistics-statValue'>
											<span>{stat.value}</span>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				}

				<form className="Statistics-form">
					<div className='Statistics-searchTitle'>
						<span>{pageUiTexts.periods}</span>
					</div>

					<div className='Statistics-searchInput'>
						<span>{pageUiTexts.from}</span>
						<input
							type='date'
							onChange={(event)=>{handleInput(event, true);}}
						/>
					</div>
					<div className='Statistics-symbol'><span>{'>'}</span></div>
					<div className='Statistics-searchInput'>
						<span>{pageUiTexts.to}</span>
						<input
							type='date'
							onChange={(event)=>{handleInput(event, false);}}
							placeholder={''}
						/>
					</div>
				</form>

				{shownGameStats &&
					<div className='Statistics-gameAmount'>
						<span>{pageUiTexts.gameAmounts}</span>
						<div className='Stastitics-gameAmountNumber'>
							<span>{shownGameStats.length}</span>
						</div>
					</div>
				}

				<div className='Statistics-gameList'>
					<div className='Statistics-gameStat'>
						<div className='Statistics-gameStatHeader'>
							<div className='Statistics-gameStatHeaderTitle'>
								<span>{pageUiTexts.games}</span>
							</div>
							<div className='Statistics-gameStatHeaderTitle'>
								<span>{pageUiTexts.groupAmounts}</span>
							</div>
						</div>
						<div className='Statistics-gameStatsWrapper'>
							{shownGameStats && shownGameStats.map((stat, index) => {
								return (
									<div key={index} 
										className={'Statistics-game' + (currentIndex === index ? ' current' : '')}
									>
										<div className='Statistics-gameName'>
											<span>{stat.name}</span>
										</div>
										<div className='Statistics-icon' 
											onClick={() => {setCurrentGameStatData(stat); setCurrentIndex(index);}}
										/>
										<div className='Statistics-gameGroupAmount'>
											<span>{stat.groupsAmount}</span>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Statistics.propTypes = {
	gameStats: PropTypes.array.isRequired,
};

export default Statistics;