import React from 'react';
import './group-sidebar.scss';
import PropTypes from 'prop-types';
import GroupData from 'components/ui/group-data/group-data';
import { pageUiTexts } from 'data/ui-texts';

const GroupSidebar = ({showUpdatedCapacity = false, showingVoteFeedback, groups}) => {

	return (
		<div className='GroupSidebar'>
			<div className='GroupSidebar-sidebarTitle'>
				<span>{pageUiTexts.companies}</span>
			</div>
			
			<div className='GroupSidebar-groups'>
				{groups.map((group, index) => {
					const hasVoted = group.areBidsSaved;
					
					let groupCapacity;
					if (showUpdatedCapacity) {
						groupCapacity = group.capacity;
					} else {
						groupCapacity = group.initialCapacityForRound;
					}
					return (
						<div key={index} className={'GroupSidebar-groupInfo' + 
							(hasVoted || !showingVoteFeedback ? '' : ' notVoted')}>
							<GroupData 
								groupCapacity={groupCapacity} 
								group={group}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

GroupSidebar.propTypes = {
	showUpdatedCapacity: PropTypes.bool,
	showingVoteFeedback: PropTypes.bool.isRequired,
	groups: PropTypes.array.isRequired
};

export default GroupSidebar;