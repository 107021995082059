const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 43,
		taskId: 'round-3-input-geometry-43',
		title: 'Geometri',
		image: 'triangleAngle',
		text: 'Formlen her kan bruges til at beregne trekanters areal. Hvor stor er arealet af en trekant, der har grundlinjen 8 og højden 5?',
		unit: '',
		answer: 20
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		assignmentNumber: 44,
		taskId: 'round-3-match-opgave-44',
		title: 'Fagområder',
		type: 'match',
		instructions: 'Når man er i gang med en Entreprise er der ofte mange forskellige håndværksfag der er beskæftiget. Forbind de forskellige opgaver med de rigtige håndværksfag.',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tækkemand', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tømrer', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Snedker', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Murer', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Elektrikker', 
				id: 5,
				answers: [5]
			}),

			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Blikkenslager', 
				id: 6,
				answers: [6, 7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Glarmester', 
				id: 7,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Smed', 
				id: 8,
				answers: [9]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Maler', 
				id: 9,
				answers: [10]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: true,
				text: 'Lægge nye strå på taget "Tækkearbejde"',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: true,
				text: 'Etablering af tagkonstruktion',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: true,
				text: 'Fremstilling af vinduer',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: true,
				text: 'Etablering af gavlmur',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: true,
				text: 'El-installationer i ny tagkonstruktion',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: true,
				text: 'Etablering af Kobberrygning',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: true,
				text: 'montage af tagrender',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: true,
				text: 'Kitning af nye ruder i gamle vinduer',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: true,
				text: 'Etablering af bærende stålkonstruktion',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 10,
				isText: true,
				text: 'Spartling og maling af indvendige vægge',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 47,
		taskId: 'round-3-multiple-Kvinder-byggebranchen-46',
		title: 'Kvinder i byggebranchen',
		type: 'multiple-choice',
		text: 'Hvormange procent kvinder er beskæftiget i Tømrer- og snedkervirksomheder? kilde: https://www.dr.dk/ligetil/saa-mange-kvinder-arbejder-i-din-branche',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '1', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '3', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '5', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '12', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '15', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '25', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: '50', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 51,
		taskId: 'round-3-multiple-Uddannelse-51',
		title: 'Uddannelse',
		type: 'multiple-choice',
		style: 'singleLine',
		text: 'Hvad er en EUX uddannelse?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'En almindelig Erhvervsuddannelse', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'En almen gymnasial uddannelse', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'En ungdomsuddannelse hvor man både bliver uddannet håndværker og får en gymnasial uddannelse', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'En ungdomsuddannelse hvor man både bliver uddannet håndværker og får en gymnasial uddannelse', 
				isCorrect: true,
			}),
		],
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round3-easy-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};