const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 3,
		taskId: 'round-1-area-rectangle',
		title: 'Arealet af et rektangel',
		image: 'area-rectangle-1',
		text: 'Du skal udfra de givne mål på figuren udregne arealet af figuren',
		answer: 28,
		unit: 'm2'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 4,
		taskId: 'round-1-multiple-choice-geometri',
		title: 'Hvem fandt på formlen?',
		type: 'multiple-choice',
		image: null,
		texts: [
			{
				bidId: 1,
				text: 'Hvad kaldes denne formel? a2 + b2 = c2'
			},
			{
				bidId: 2,
				text: 'Hvad kaldes denne formel? a2 + b2 = c2'
			},
			{
				bidId: 3,
				text: 'Hvad kaldes denne formel? a2 + b2 = c2'
			},
			{
				bidId: 4,
				text: 'Hvad kaldes denne formel? a2 + b2 = c2'
			},
			{
				bidId: 5,
				text: 'Hvad kaldes denne formel? a2 + b2 = c2'
			},
			{
				bidId: 6,
				text: 'Hvad kaldes denne formel? a2 + b2 = c2'
			},
			{
				bidId: 7,
				text: 'Hvad kaldes denne formel? a2 + b2 = c2'
			},
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'Pyton', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'Trekantsformlen', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Pythagoras', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Plymoth', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'Pylantos', 
				isCorrect: false,
			})
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 5,
		taskId: 'round-1-range-material',
		title: 'Materialeberegning',
		texts: [
			{
				bidId: 1,
				text: 'Du skal montere 25 vinduer. Arkitekten vil vide hvor mange kvadratmeter glas der er ialt på projektet. Hvert vindue har et glasareal på 1,77 m2. Hvor mange m2 glas er der ialt'
			},
			{
				bidId: 2,
				text: 'Du skal bestille krydsfinér til at bygge 25 reoler. Du ved, at der skal bruges 5 m2 til hver reol. En Krydsfinérplade måler 122x244 cm. Hvor mange hele plader krydsfinér skal du bestille?'
			},
			{
				bidId: 3,
				text: 'Du skal bestille 65 m2 gulv, og ved at der er 1,52 m2 gulv i hver pakke. Hvor mange hele pakker skal du bestille?'
			},
			{
				bidId: 4,
				text: 'Du skal bestille beklædningsbrædder til legehuset. For hver kvadratmeter, du skal beklæde, skal du bruge 14,5 m brædder. Du skal i alt beklæde 14 m2. Hvor mange meter beklædningsbrædder skal du bestille'
			},
			{
				bidId: 5,
				text: 'Du skal bestille isolering til entreprisen. Du skal efterisolere 120 m2 vægkonstruktion. For hver kvadratmeter skal du bruge 1,4 pakke isolering. Hvor mange pakker skal du bestille?'
			},
			{
				bidId: 6,
				text: 'Du skal bestille gangbrædder til dykkerbroen. For hver kvadratmeter du skal beklæde skal du bruge 8,5 m brædder. Du skal i alt beklæde 22 m2. Hvor mange meter gangbrædder skal du bestille?'
			},
			{
				bidId: 7,
				text: 'Du skal bestille rafter til hegnet. For hver meter hegn skal der bruges 18 meter rafter. Du skal lave 34 m hegn ialt. Hvor mange meter rafter skal du bestille ialt?'
			}
		],
		units: [
			{
				bidId: 1,
				unit: 'm2'
			},
			{
				bidId: 2,
				unit: ''
			},
			{
				bidId: 3,
				unit: ''
			},
			{
				bidId: 4,
				unit: 'm'
			},
			{
				bidId: 5,
				unit: ''
			},
			{
				bidId: 6,
				unit: 'm'
			},
			{
				bidId: 7,
				unit: 'm'
			},
		],
		subtype: 'range',
		ranges: [
			{
				bidId: 1,
				min: 44,
				max: 45
			},
			{
				bidId: 2,
				min: 42,
				max: 43
			},
			{
				bidId: 3,
				min: 43,
				max: 44
			},
			{
				bidId: 4,
				min: 203,
				max: 204
			},
			{
				bidId: 5,
				min: 168,
				max: 169
			},
			{
				bidId: 6,
				min: 187,
				max: 188
			},
			{
				bidId: 7,
				min: 612,
				max: 613
			},
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 6,
		taskId: 'round-1-order-material',
		title: 'Materialevalg',
		linkFile: 'materialepyramiden',
		text: `Med hjælp fra "materialepyramiden" skal du sætte de følgende materialer i rækkefølge efter hvor meget CO2 de belaster klimaet med. Det materiale der udleder mest CO2 skal stå øverst`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Aluminiumsplader', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Rude, 3-lags glas', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Konstruktionsstål', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Tagpap', correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Beton', correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, text: 'Konstruktionstræ', correctOrderNumbers: [6]
			}),
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-2-match-wood',
		title: 'Identificer materialerne',
		type: 'match',
		answerType: 'materialelære',
		instructions: 'sæt de rigtige betegnelser på billederne',
		assignmentNumber: 8,
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Gevindstænger', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tagsten', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Mursten', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Mineraluld', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Fugemasse', 
				id: 5,
				answers: [5]
			}),

			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Hængsel', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tømmer', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Lister', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Beklædningsbrædder', 
				id: 9,
				answers: [9]
			}),Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Vinkelbeslag', 
				id: 10,
				answers: [10]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'Gevindstænger',
				imageSrc: 'gevindstænger.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'Tagsten',
				imageSrc: 'tagsten.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'Tegl',
				imageSrc: 'tegl.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'Mineraluld',
				imageSrc: 'mineraluld.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'Fugemasse',
				imageSrc: 'fugemasse.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'Hængsel',
				imageSrc: 'hængsel.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: 'Tømmer',
				imageSrc: 'tømmer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'Lister',
				imageSrc: 'lister.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'Beklædningsbrædder',
				imageSrc: 'beklædningsbrædder.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 10,
				isText: false,
				text: 'Vinkelbeslag',
				imageSrc: 'vinkelbeslag.svg',
			}),

			
		],
		texts: [
			{
				bidId: 1,
				text: 'Som håndværker er det også  vigtigt at kende de materialer man arbejder med....'
			},
			{
				bidId: 2,
				text: 'Som håndværker er det også  vigtigt at kende de materialer man arbejder med....'
			},
			{
				bidId: 3,
				text: 'Som håndværker er det også  vigtigt at kende de materialer man arbejder med....'
			},
			{
				bidId: 4,
				text: 'Som håndværker er det også  vigtigt at kende de materialer man arbejder med....'
			},
			{
				bidId: 5,
				text: 'Som håndværker er det også  vigtigt at kende de materialer man arbejder med....'
			},	
			{
				bidId: 6,
				text: 'Som håndværker er det også  vigtigt at kende de materialer man arbejder med....'
			},
			{
				bidId: 7,
				text: 'Som håndværker er det også  vigtigt at kende de materialer man arbejder med....'
			},
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 9,
		taskId: 'round-1-order-building-order',
		title: 'Rækkefølge i byggeri',
		text: `Sæt arbejdsopgaverne i den rigtige rækkefølge`,
		itemList: [
			{
				bidId: 1,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Opsætte stillads', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Demontere gammelt vindue', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Klargøre nyt vindue til montage', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Montere Nyt vindue', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Rydde op omkring nymonteret vindue', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Isolere mellem vindue og mur-/væg-konstruktion', correctOrderNumbers: [6]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 7, text: 'Fuge omkring vinduet', correctOrderNumbers: [7]
					}),
				]
			},
			{
				bidId: 2,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Afdækning af gulv for at passe på det', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Etablering af støvvæg mod resten af butikken', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Fremstilling og samling af Reoler', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Motere ophængslister til reolerne på eksisterende væg', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Ophængning af reoler', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'spartling og maling af reoler', correctOrderNumbers: [6]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 7, text: 'Oprydning og støvsugning', correctOrderNumbers: [7]
					}),
				]
			},
			{
				bidId: 3,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Kontrollere planhed af eksisterende betondæk', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Udjævne eventuelle ujævnheder', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Udrulle og udlægge dampspærre og gulv underlag', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Montere Gulvbrædder', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Montere fodpaneler', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Oprydning af pladsen', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: 4,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Fundament', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Bærende vægge', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Tagkonstruktion', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Montage af Tagsten', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Montage af udvendig beklædning på vægge', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Isolering af tag og vægge', correctOrderNumbers: [6]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 7, text: 'montage af indvendig beklædning', correctOrderNumbers: [7]
					}),
				]
			},
			{
				bidId: 5,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Opsætte stillads eller stige', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Bore huller til indblæsning af isolering', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Klargøre isoleringsmateriale til indblæsning', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Kontrollere for utætheder i konstruktionen', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Indblæse isolering', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Reetablere de udborede huller', correctOrderNumbers: [6]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 7, text: 'Oprydning på pladsen', correctOrderNumbers: [7]
					}),
				]
			},
			{
				bidId: 6,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Montage af stolper i sandbund', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Montage af underlag for Gangbrædder på stolperne', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Afskærring af Stolper i korrekt højde', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Afskæring áf Gangbrædder i korrekt længde', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Montage af Gangbrædder', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Oprydning af pladsen', correctOrderNumbers: [6]
					}),
				]
			},
			{
				bidId: 7,
				items: [
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 1, text: 'Opmærkning af hegn og hegnsstolper', correctOrderNumbers: [1]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 2, text: 'Boring af huller til hegnsstolper', correctOrderNumbers: [2]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 3, text: 'Nedstøbning af hegnsstolper i stolpebeton', correctOrderNumbers: [3]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 4, text: 'Tørretid for stolpebeton', correctOrderNumbers: [4]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 5, text: 'Montage af rafter på nedstøbte stolper', correctOrderNumbers: [5]
					}),
					Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
						id: 6, text: 'Oprydning af pladsen', correctOrderNumbers: [6]
					}),
				]
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 10,
		taskId: 'round-1-multiple-workplanning',
		title: 'Arbejdsopgaver',
		type: 'multiple-choice',
		style: 'singleLine',
		texts: [
			{
				bidId: 1,
				text: 'Vælg de processer/ opgaver der indgår i entreprisen'
			},
			{
				bidId: 2,
				text: 'Vælg de processer/ opgaver der indgår i entreprisen'
			},
			{
				bidId: 3,
				text: 'Vælg de processer/ opgaver der indgår i entreprisen'
			},
			{
				bidId: 4,
				text: 'Vælg de processer/ opgaver der indgår i entreprisen'
			},
			{
				bidId: 5,
				text: 'Vælg de processer/ opgaver der indgår i entreprisen'
			},
			{
				bidId: 6,
				text: 'Vælg de processer/ opgaver der indgår i entreprisen'
			},
			{
				bidId: 7,
				text: 'Vælg de processer/ opgaver der indgår i entreprisen'
			},
		],
		optionsList: 
		[	
			{
				bidId: 1,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Opsætte stillads',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Demontere gammelt vindue',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Klargøre nyt vindue til montage',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Montere Nyt vindue',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Rydde op omkring nymonteret vindue',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Isolere mellem vindue og mur-/væg-konstruktion',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Fuge omkring vinduet',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Tjekke TikTok, Snapchat og Instagram',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 2,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Afdækning af gulv for at passe på det',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Etablering af støvvæg mod resten af butikken',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Fremstilling og samling af Reoler',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Montere ophængslister til reolerne på eksisterende væg',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Ophængning af reoler',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'spartling og maling af reoler',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Oprydning og støvsugning',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Tjekke TikTok, Snapchat og Instagram',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 3,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Kontrollere planhed af eksisterende betondæk',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Udjævne eventuelle ujævnheder',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Udrulle og udlægge dampspærre og gulv underlag',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Montere Gulvbrædder',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Montere fodpaneler',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Oprydning af pladsen',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Tjekke TikTok, Snapchat og Instagram',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 4,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Fundament',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Bærende vægge',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Tagkonstruktion',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Montage af Tagsten',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Montage af udvendig beklædning på væge',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Isolering af tag og vægge',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'montage af indvendig beklædning',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Tjekke TikTok, Snapchat og Instagram',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 5,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Opsætte stillads eller stige',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Bore huller til indblæsning af isolering',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Klargøre isoleringsmateriale til indblæsning',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Kontrollere for utætheder i konstruktionen',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Indblæse isolering',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Reetablere de udborede huller',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Oprydning på pladsen',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Tjekke TikTok, Snapchat og Instagram',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 6,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Montage af stolper i sandbund',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Montage af underlag for Gangbrædder på stolperne',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Afskærring af Stolper i korrekt højde',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Afskæring áf Gangbrædder i korrekt længde',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Montage af Gangbrædder',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Oprydning af pladsen',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Tjekke TikTok, Snapchat og Instagram',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 7,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Opmærkning af hegn og hegnsstolper',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Boring af huller til hegnsstolper',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Nedstøbning af hegnsstolper i stolpebeton',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Tøretid for stolpebeton',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Montage af rafter på nedstøbte stolper',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Oprydning af pladsen',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Tjekke TikTok, Snapchat og Instagram',
						isCorrect: false,
					}),
				],
			}
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 12,
		taskId: 'round-1-multiple-angle',
		title: 'Beregning',
		image: 'assignment12Angle',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '30,89',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '8,54',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '50,45',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '20,35',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '10,39',
				isCorrect: true,
			})
		],
		texts: [
			{
				bidId: 1,
				text: 'Du kan bruge Sinus-relationen til at løse opgaven, eller du kan konstruere trekanten i geogebra og få udregnet vinklen. Hvor stor er højdeforskellen mellem punkt A og B, når hældningen skal være 30 grader?'
			},
			{
				bidId: 2,
				text: 'Du kan bruge Sinus-relationen til at løse opgaven, eller du kan konstruere trekanten i geogebra og få udregnet vinklen. Hvor stor er højdeforskellen mellem punkt A og B, når hældningen skal være 30 grader?'
			},
			{
				bidId: 3,
				text: 'Du kan bruge Sinus-relationen til at løse opgaven, eller du kan konstruere trekanten i geogebra og få udregnet vinklen. Hvor stor er højdeforskellen mellem punkt A og B, når hældningen skal være 30 grader?'
			},
			{
				bidId: 4,
				text: 'Du kan bruge Sinus-relationen til at løse opgaven, eller du kan konstruere trekanten i geogebra og få udregnet vinklen. Hvor stor er højdeforskellen mellem punkt A og B, når hældningen skal være 30 grader?'
			},
			{
				bidId: 5,
				text: 'Du kan bruge Sinus-relationen til at løse opgaven, eller du kan konstruere trekanten i geogebra og få udregnet vinklen. Hvor stor er højdeforskellen mellem punkt A og B, når hældningen skal være 30 grader?'
			},
			{
				bidId: 6,
				text: 'Du kan bruge Sinus-relationen til at løse opgaven, eller du kan konstruere trekanten i geogebra og få udregnet vinklen. Hvor stor er højdeforskellen mellem punkt A og B, når hældningen skal være 30 grader?'
			},
			{
				bidId: 7,
				text: 'Du kan bruge Sinus-relationen til at løse opgaven, eller du kan konstruere trekanten i geogebra og få udregnet vinklen. Hvor stor er højdeforskellen mellem punkt A og B, når hældningen skal være 30 grader?'
			},
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 13,
		taskId: 'round-1-input-calculations',
		title: 'Beregning',
		images: [
			{
				bidId: 1,
				image: 'fnugrulle'
			},
			{
				bidId: 2,
				image: null
			},
			{
				bidId: 3,
				image: 'trappe'
			},
			{
				bidId: 4,
				image: 'trappe'
			},
			{
				bidId: 5,
				image: 'efterisolering'
			},
			{
				bidId: 6,
				image: 'trappe'
			},
			{
				bidId: 7,
				image: 'hegn'
			},
		],
		texts: [
			{
				bidId: 1,
				text: 'Du skal udskifte 15 vinduer. For hver vindue skal du bruge 11 m fugefilt. Hver rulle indeholder 20 m fugefilt. Hvor mange hele ruller skal du bruge ialt?'
			},
			{
				bidId: 2,
				text: 'Butiksindehaveren regner med at kunne forøge sin indtjening med 8.000 om måneden efter at butikken har fået ny indretning. Entreprisen koster 55.000. Hvor mange måneder går der før Investeringen er tjent hjem?'
			},
			{
				bidId: 3,
				text: 'Mellem hvert trin i en trappe er der en højde på 180 mm - hvor bredt er trinnet, når 2 højder og 1 bredde skal give 610 mm?. hvor bredt er trinnet, når 2 højder og 1 bredde skal give 610 mm?'
			},
			{
				bidId: 4,
				text: 'Mellem hvert trin i en trappe er der en højde på 180 mm - hvor bredt er trinnet, når 2 højder og 1 bredde skal give 610 mm?. hvor bredt er trinnet, når 2 højder og 1 bredde skal give 610 mm?'
			},
			{
				bidId: 5,
				text: 'Ved efterisolering af et hus vil familien spare 4000 kr. om året på deres varmeregning. Efterisoleringen koster 22000. Hvor mange år går der før investeringen har tjent sig selv ind.'
			},
			{
				bidId: 6,
				text: 'Mellem hvert trin i en trappe er der en højde på 180 mm. hvor bredt er trinnet, når 2 højder og 1 bredde skal give 610 mm?'
			},
			{
				bidId: 7,
				text: 'Længden af det hegn du skal bygge er 140 m, Hver sektion i hegnet må maksimum være 210 cm. Hvor mange sektioner skal du bygge?'
			},
		],
		answers: [
			{
				bidId: 1,
				answer: 9
			},
			{
				bidId: 2,
				answer: 7
			},
			{
				bidId: 3,
				answer: 250
			},
			{
				bidId: 4,
				answer: 250
			},
			{
				bidId: 5,
				answer: 5.5
			},
			{
				bidId: 6,
				answer: 250
			},
			{
				bidId: 7,
				answer: 67
			},
		],
		units: [
			{
				bidId: 1,
				unit: null
			},
			{
				bidId: 2,
				unit: null
			},
			{
				bidId: 3,
				unit: 'mm'
			},
			{
				bidId: 4,
				unit: 'mm'
			},
			{
				bidId: 5,
				unit: null
			},
			{
				bidId: 6,
				unit: 'mm'
			},
			{
				bidId: 7,
				unit: 'stk'
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 20,
		taskId: 'round-1-input-geometri',
		title: 'Udregn omkredsen',
		image: 'hexagon', // Are the bestagons
		text: 'Du skal udfra de givne mål på figuren udregne omkredsen af figuren',
		answer: 1800,
		unit: 'cm'
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round1-medium-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};