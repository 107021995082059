import React from 'react';
import PropTypes from 'prop-types';
import './playButton.scss';

const PlayButton = ({setPlayMusic, musicIsPlaying}) => {

	const togglePlay = () => {
		setPlayMusic(!musicIsPlaying);
	};

	return (
		<div className={'PlayButton' + (musicIsPlaying ? ' isPlaying' : '')} onClick={() => {togglePlay();}}/>
	);
};

PlayButton.propTypes = {
	setPlayMusic: PropTypes.func.isRequired,
	musicIsPlaying: PropTypes.bool.isRequired
};

export default PlayButton;