import React from 'react';
import './game-over.scss';
import PropTypes from 'prop-types';
import Button from 'components/ui/button/button';
import GameCode from 'components/ui/game-code/game-code';
import { gameStepsData } from 'data/game-steps-data';
import Progressbar from 'components/ui/progressbar/progressbar';
import { pageUiTexts } from 'data/ui-texts';

const GameOver = ({game, gameCode, setBackground, handleGoToGameStep}) => {
	setBackground('city');
	
	const roundNumber = gameStepsData.find((step) => {
		return step.id === game.gameStepId;
	}).roundNumber;

	return (
		<div className='GameOver'>
			<div className='GameOver-headerWrapper'>
				<div className='GameOver-gameCode'>
					<GameCode gameCode={gameCode}/>
				</div>
				<div className='GameOver-progressbar'>
					<Progressbar roundNumber={roundNumber}/>
				</div>
				<div className='GameOver-spacer'/>
			</div>

			<div className='GameOver-infoWrapper'>
				<div className='GameOver-info'>
					<div className='GameOver-text'>
						<span>{pageUiTexts.gameOver}</span>
					</div>
				</div>
			</div>

			<Button
				text={pageUiTexts.seeResults}
				classes={['pushable']}
				isOnRelease={true}
				onClick={() => {handleGoToGameStep('next');}}
			/>
		</div>	
	);
};

GameOver.propTypes = {
	game: PropTypes.object.isRequired,
	gameCode: PropTypes.string.isRequired,
	setBackground: PropTypes.func.isRequired,
	handleGoToGameStep: PropTypes.func.isRequired,
};

export default GameOver;