const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 21,
		taskId: 'round-2-range-co2',
		title: 'CO2 belastning',
		images: [
			{
				bidId: 8,
				image: 'badebro'
			},
			{
				bidId: 9,
				image: 'tilbygning'
			},
			{
				bidId: 10,
				image: 'skaterRampe'
			},
			{
				bidId: 11,
				image: 'binding'
			},
			{
				bidId: 12,
				image: 'typehus'
			},
			{
				bidId: 13,
				image: 'terrasse'
			},
			{
				bidId: 14,
				image: 'gips'
			},
		],
		text: 'Brug informationer fra tegningsmaterialet til at udregne konstruktionens CO2-belastning',
		unit: '',
		subtype: 'range',
		ranges: [
			{
				bidId: 8,
				min: -982,
				max: -984
			},
			{
				bidId: 9,
				min: 1597,
				max: 1599
			},
			{
				bidId: 10,
				min: 886,
				max: 888
			},
			{
				bidId: 11,
				min: 516,
				max: 518
			},
			{
				bidId: 12,
				min: -7709,
				max: -7707
			},
			{
				bidId: 13,
				min: -2861,
				max: -2859
			},
			{
				bidId: 14,
				min: 743,
				max: 745
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 23,
		taskId: 'round-2-range-materialeberegning',
		title: 'Materialeberegning',
		images: [
			{
				bidId: 8,
				image: 'badebro2'
			},
			{
				bidId: 9,
				image: 'tilbygning2'
			},
			{
				bidId: 10,
				image: 'skaterRampe2'
			},
			{
				bidId: 11,
				image: 'binding2'
			},
			{
				bidId: 12,
				image: 'typehus2'
			},
			{
				bidId: 13,
				image: 'terrasse2'
			},
			{
				bidId: 14,
				image: 'gips2'
			},
		],
		texts: [
			{
				bidId: 8,
				text: 'De korte bjælker der forbinder stolperne 2 og 2, skal monteres med en m20 gevindstang ved hver stolpe. Hvor mange Gevindstænger skal der bruges. Udregn materialeforbrug'
			},
			{
				bidId: 9,
				text: 'Du skal beklæde tilbygningen med brædder. For hver kvadratmeter væg skal du bruge 8,2 m brædder. Du skal huske at regne med 10% spild. Hvor mange meter brædder skal der bruges ialt? Udregn materialeforbrug'
			},
			{
				bidId: 10,
				text: 'Du skal beklæde skateboardrampen med Støbekrydsfiner. Hver plade svarer til 2,98 m2. Hvor mange hele plader skal du indkøbe hvis du skal regne med 10% spild? Udregn materialeforbrug'
			},
			{
				bidId: 11,
				text: 'I skal selv fremstille 126 stk. nagler til alle tømrersamlingerne i bindingsværket. De skal fremstilles af Egetræ ligesom resten af konstruktionen. For hver meter brædt skal der fremstilles 12 nagler. Hvor mange meter brædder skal der bruges til at lave nagler ialt?'
			},
			{
				bidId: 12,
				text: 'Typehuset skal monteres på skruefundament for at undgå brug af beton i byggeriet. Hver skrue kan bære 230 kg. Hvor mange hele skruer skal der bruges til byggeriet? Husk: du har i en anden opgave udregnet hvor meget konstruktionen vejer.'
			},
			{
				bidId: 13,
				text: 'Du skal skrue terrassebrædderne fast til de underliggende strøer. Du skal bruge 30 skruer pr. m2, Hvor mange skruer skal du bruge ialt? Udregn materialeforbrug'
			},
			{
				bidId: 14,
				text: 'Under planlægning af opgaven finder I ud af, at det gulv I skal køre henover, når I skal i gang med at bygge, ikke helt kan holde til en hel palle gips ad gangen. En plade gips vejer 15 kg. I kan maks transportere 300 kg. hen over gulvet ad gangen. Hvor mange gipsplader kan I transportere ad gangen?'
			}
		],
		units: [
			{
				bidId: 8,
				unit: null
			},
			{
				bidId: 9,
				unit: 'm'
			},
			{
				bidId: 10,
				unit: null
			},
			{
				bidId: 11,
				unit: 'm'
			},
			{
				bidId: 12,
				unit: 'stk'
			},
			{
				bidId: 13,
				unit: null
			},
			{
				bidId: 14,
				unit: null
			},
		],
		subtype: 'range',
		ranges: [
			{
				bidId: 8,
				min: 10,
				max: 10
			},
			{
				bidId: 9,
				min: 604,
				max: 605
			},
			{
				bidId: 10,
				min: 5,
				max: 5
			},
			{
				bidId: 11,
				min: 10.5,
				max: 10.5
			},
			{
				bidId: 12,
				min: 52,
				max: 52
			},
			{
				bidId: 13,
				min: 1520,
				max: 1520
			},
			{
				bidId: 14,
				min: 20,
				max: 20
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 24,
		taskId: 'round-2-range-opgave-24',
		title: 'Opgave 24',
		images: [
			{
				bidId: 8,
				image: 'badebro2'
			},
			{
				bidId: 9,
				image: 'tilbygning2'
			},
			{
				bidId: 10,
				image: 'skaterRampe2'
			},
			{
				bidId: 11,
				image: 'binding2'
			},
			{
				bidId: 12,
				image: 'typehus2'
			},
			{
				bidId: 13,
				image: 'terrasse2'
			},
			{
				bidId: 14,
				image: 'gips2'
			},
		],
		texts: [
			{
				bidId: 8,
				text: 'Stolperne, der skal bankes ned i sandbunden, skal minimum bankes 200 cm ned i sandbunden. De inderste stolper står på en vanddybde på 15 cm. Sandbunden er vist på tegningsmaterialet. Hvor lange skal de yderste stolper være? Besvar med hjælp fra tegningsmaterialet'
			},
			{
				bidId: 9,
				text: 'Betondækket som Tilbygningen er bygget på er den mest CO2 tunge bygningsdel. Hvor mange kvadratmeter (m2) måler betondækket? Besvar med hjælp fra tegningsmaterialet'
			},
			{
				bidId: 10,
				text: 'Stigen som er monteret på Skateboardrampen er lavet af aluminium. Hvor Høj er stigen? Besvar med hjælp fra tegningsmaterialet'
			},
			{
				bidId: 11,
				text: 'Tagkonstruktionen er opbygget af 125x125 mm tømmer. Hvor langt er der imellem spærrene? Besvar med 1 decimal og hjælp fra tegningsmaterialet'
			},
			{
				bidId: 12,
				text: 'Tagkonstruktionen er opbygget af 47*325 mm spærtræ. Hvor langt er der mellem spærrene? Besvar med hjælp fra tegningsmaterialet'
			},
			{
				bidId: 13,
				text: 'Der skal være opklodsninger under terrassen, Det er de grå fliser på tegningen. For hver kvadratmeter terrasse skal der være 0,95 stk flise. Hvor mange fliser skal der bruges ialt? Besvar med hjælp fra tegningsmaterialet'
			},
			{
				bidId: 14,
				text: 'Hvor mange kvadratmeter (m2) dækker gipskonstruktionen over? Besvar med hjælp fra tegningsmaterialet'
			}
		],
		units: [
			{
				bidId: 8,
				unit: 'm'
			},
			{
				bidId: 9,
				unit: 'm2'
			},
			{
				bidId: 10,
				unit: 'm'
			},
			{
				bidId: 11,
				unit: 'm'
			},
			{
				bidId: 12,
				unit: 'm'
			},
			{
				bidId: 13,
				unit: 'stk'
			},
			{
				bidId: 14,
				unit: 'stk'
			},
		],
		subtype: 'range',
		ranges: [
			{
				bidId: 8,
				min: 5,
				max: 6
			},
			{
				bidId: 9,
				min: 35,
				max: 36
			},
			{
				bidId: 10,
				min: 1,
				max: 2
			},
			{
				bidId: 11,
				min: 1,
				max: 2
			},
			{
				bidId: 12,
				min: 0,
				max: 1
			},
			{
				bidId: 13,
				min: 53,
				max: 53
			},
			{
				bidId: 14,
				min: 101,
				max: 102
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 25,
		taskId: 'round-2-multiple-opgave-25',
		title: 'Opgave 25',
		type: 'multiple-choice',
		images: [
			{
				bidId: 8,
				image: 'badebro2'
			},
			{
				bidId: 9,
				image: 'tilbygning2'
			},
			{
				bidId: 10,
				image: 'skaterRampe2'
			},
			{
				bidId: 11,
				image: 'binding2'
			},
			{
				bidId: 12,
				image: 'typehus2'
			},
			{
				bidId: 13,
				image: 'terrasse2'
			},
			{
				bidId: 14,
				image: 'gips2'
			},
		],
		texts: [
			{
				bidId: 8,
				text: 'Hældningen på broen, som i også har beregnet kan sættes på formel. Vælg den rigtige formel til Broens kurve.'
			},
			{
				bidId: 9,
				text: 'Tagets hældning kan sættes på formel. Vælg den rigtige formel for tagets hældning'
			},
			{
				bidId: 10,
				text: 'Rampens kurve kan sættes på formel. Vælg den rigtige formel for rampens kurve'
			},
			{
				bidId: 11,
				text: 'Skråbåndets hældning i forhold til vandret kan sættes på formel. Vælg den rigtige formel for skråbåndets kurve'
			},
			{
				bidId: 12,
				text: 'Antallet af skuer pr. kvadratmeter gipsplade kan sættes på formel. Der skal bruges 16 skruer pr. m2. Vælg den rigtige formel for skruer pr. m2'
			},
			{
				bidId: 13,
				text: 'Stigningen på taget kan sættes på formel. Vælg den rigtige formel for tagets kurve'
			},
			{
				bidId: 14,
				text: 'Antallet af skuer pr. kvadratmeter gipsplade kan sættes på formel. Vælg den rigtige formel for skruer pr. m2'
			},
		],
		optionsList: [
			{
				bidId: 8,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'f(x)=1,0x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'f(x)=0,59246872x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'f(x)=0,0874886635259x', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'f(x)=0,023658793x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'f(x)=2,578691x', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 9,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'f(x)=1,0x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'f(x)=0,59246872x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'f(x)=0.26794919243112x', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'f(x)=0,023658793x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'f(x)=2,578691x', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 10,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'f(x)=1,0x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'f(x)=0,59246872x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'f(x)=0.53170943166148x', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'f(x)=0,023658793x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'f(x)=2,578691x', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 11,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'f(x)=1,0x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'f(x)=0,59246872x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'f(x)=2.1445069205096x', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'f(x)=0,023658793x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'f(x)=2,578691x', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 12,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'f(x)=1,0x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'f(x)=0,59246872x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'f(x)=16,00x', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'f(x)=0,023658793x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'f(x)=2,578691x', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 13,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'f(x)=1,0x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'f(x)=0,59246872x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'f(x)=0.17632698070846x', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'f(x)=0,023658793x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'f(x)=2,578691x', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 14,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'f(x)=1,0x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'f(x)=0,59246872x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'f(x)=16,00x', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'f(x)=0,023658793x', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'f(x)=2,578691x', 
						isCorrect: false,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		assignmentNumber: 29,
		taskId: 'round-2-match-opgave-29',
		title: 'Opgave 29',
		type: 'match',
		answerType: 'assignment29',
		instructions: 'Match billederne med de rigtige navne',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Stålreglar', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Gipsskruer', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Spånpladeskruer', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Krydsfinerplader', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Fuldtømmer', 
				id: 5,
				answers: [5]
			}),

			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Halvtømmer', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'OSB plader', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'MDF plader', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Isoleringsmåtter', 
				id: 9,
				answers: [9]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: null,
				imageSrc: 'assignment-29/staalreklar.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: null,
				imageSrc: 'assignment-29/gipsskruer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: null,
				imageSrc: 'assignment-29/spaapladeskruer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: null,
				imageSrc: 'assignment-29/krydsfinerplader.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: null,
				imageSrc: 'assignment-29/fuldtoemmer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: null,
				imageSrc: 'assignment-29/halvtoemmer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: null,
				imageSrc: 'assignment-29/OSBplader.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: null,
				imageSrc: 'assignment-29/MDFplader.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: null,
				imageSrc: 'assignment-29/isoleringsmaatter.svg',
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 30,
		taskId: 'round-2-range-opgave-30',
		title: 'Opgave 30',
		subtype: 'range',
		images: [
			{
				bidId: 8,
				image: 'badebro'
			},
			{
				bidId: 9,
				image: 'tilbygning'
			},
			{
				bidId: 10,
				image: 'skaterRampe'
			},
			{
				bidId: 11,
				image: 'binding'
			},
			{
				bidId: 12,
				image: 'typehus'
			},
			{
				bidId: 13,
				image: 'terrasse'
			},
			{
				bidId: 14,
				image: 'gips'
			},
		],
		text: 'Med hjælpe fra tegningsmaterialet skal du regne ud hvor meget konstruktionen kommer til at veje ialt. (Svaret angives i hele kg)',
		unit: 'kg',
		ranges: [
			{
				bidId: 8,
				min: 813,
				max: 814,
			},
			{
				bidId: 9,
				min: 19836,
				max: 19837,
			},
			{
				bidId: 10,
				min: 1231,
				max: 1232,
			},
			{
				bidId: 11,
				min: 7826,
				max: 7827,
			},
			{
				bidId: 12,
				min: 11946,
				max: 11947,
			},
			{
				bidId: 13,
				min: 3175,
				max: 3176,
			},
			{
				bidId: 14,
				min: 3497,
				max: 3498,
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 33,
		taskId: 'round-2-input-opgave-33',
		title: 'Opgave 33',
		images: [
			{
				bidId: 8,
				image: 'badebro3'
			},
			{
				bidId: 9,
				image: 'tilbygning3'
			},
			{
				bidId: 10,
				image: 'skaterRampe3'
			},
			{
				bidId: 11,
				image: 'binding3'
			},
			{
				bidId: 12,
				image: 'typehus3'
			},
			{
				bidId: 13,
				image: 'terrasse3'
			},
			{
				bidId: 14,
				image: 'gips3'
			},
		],
		texts: [
			{
				bidId: 8,
				text: 'Hvor mange skruer skal der bruge til at montere gangbrædderne til underbjælkerne, hvis der skal bruges 4 skruer til hver brædt?'
			},
			{
				bidId: 9,
				text: 'Hvor mange m2 gulvbrædder skal du bestille til byggeriet, hvis du skal bestille 10 % mere end du har areal?'
			},
			{
				bidId: 10,
				text: 'Hvor mange skruer skal der bruges til de to platforme hvis du skal bruge 10 skruer pr. m2?'
			},
			{
				bidId: 11,
				text: 'Hvad koster huset hvis kvadratmeter prisen er 15.000 kr.'
			},
			{
				bidId: 12,
				text: 'Hvor mange vinkelbeslag skal der bruges til at sætte væggene op, hvis der skal bruges 2 vinkelbeslag pr. meter stolpe?'
			},
			{
				bidId: 13,
				text: 'Hvor mange terrasseskruer skal der bruges til at montere terrassebrædderne, hvis der skal bruges 18 skruer pr. m2?'
			},
			{
				bidId: 14,
				text: 'Lejeprisen for det kommende kontormiljø du skal bygge er 1.200 pr. m2 pr. måned. Hvad kommer månedsprisen til at være for kontoret?'
			}
		],
		units: [
			{
				bidId: 8,
				unit: null
			},
			{
				bidId: 9,
				unit: null
			},
			{
				bidId: 10,
				unit: 'stk'
			},
			{
				bidId: 11,
				unit: null
			},
			{
				bidId: 12,
				unit: 'stk'
			},
			{
				bidId: 13,
				unit: null
			},
			{
				bidId: 14,
				unit: null
			},
		],
		subtype: 'range',
		ranges: [
			{
				bidId: 8,
				min: 575,
				max: 577
			},
			{
				bidId: 9,
				min: 33,
				max: 35
			},
			{
				bidId: 10,
				min: 12,
				max: 14
			},
			{
				bidId: 11,
				min: 725999,
				max: 726001
			},
			{
				bidId: 12,
				min: 611,
				max: 613
			},
			{
				bidId: 13,
				min: 912,
				max: 914
			},
			{
				bidId: 14,
				min: 119999,
				max: 120001
			},
		]
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round2-hard-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};