import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { groupData, initialCapacity } from 'data/group-data';
import './group-box.scss';
import { pageUiTexts } from 'data/ui-texts';

const GroupBox = ({
	group, 
	groupName, 
	currentCapacity = null, 
	isButton = false, 
	isGroupLoginIndicator = false, 
	buttonFunction
}) => {
	const [buttonClicked, setButtonClicked] = useState(false);
	const groupInfo = groupData.find((data) => {return data.id === group.id;});

	const [groupStats, setGroupStats] = useState(groupInfo.groupStats);

	useEffect (() => {
		if (group.groupStats) {
			setGroupStats(group.groupStats);
		}
	}, [group.groupStats]);

	return (
		<div className={'GroupBox' + 
			(isButton ? ' isButton' : '') + 
			(buttonClicked ? ' pressed' : '') +
			(isGroupLoginIndicator && !group.isPlaying ? ' transparent' : '') +
			(isGroupLoginIndicator && group.isPlaying ? ' bounce' : '')
		}
		onClick={() => {
			if (isButton && buttonFunction) {
				setButtonClicked(true);
				buttonFunction();
			}
		}}>
			<div className='GroupBox-groupWrapper'>
				<div className={'GroupBox-groupBox' + (isButton ? ' isButton' : '')}>
					<div className='GroupBox-groupBoxOverlayWrapper'>
						<div className='GroupBox-groupBoxOverlay'>
							<div className='GroupBox-groupName'>
								<span>{groupName}</span>
							</div>

							<div className='GroupBox-groupBoxOverlayContent'>
								<div className={'GroupBox-groupBoxCornerWrapper'}>
									<div className={'GroupBox-groupBoxCorner groupBackground' + group.id}/>
								</div>
								<div className={'GroupBox-groupCapacityBox groupBackground' + group.id}>
									<span>{currentCapacity !== null ? currentCapacity : initialCapacity}</span>
								</div>
								<div className='GroupBox-groupCapacityTitle'><span>{pageUiTexts.capacity}</span></div>

								{groupStats ? groupStats.map((stat, index) => {
									return (
										<div key={index} className='GroupBox-groupDetails'>
											<div className={'GroupBox-groupDetailIcon ' + stat.id}/>
											<span>{stat.name}</span>
											<div className='GroupGox-groupDetailValue'>{stat.value}</div>
										</div>
									);
								}) : ''}
							</div>
						</div>
					</div>
					<div className='GroupBox-boxBottom'/>
				</div>
			</div>
		</div>
	);
};

GroupBox.propTypes = {
	group: PropTypes.object.isRequired,
	groupName: PropTypes.string.isRequired,
	isGroupLoginIndicator: PropTypes.bool,
	currentCapacity: PropTypes.number,
	isButton: PropTypes.bool,
	buttonFunction: PropTypes.func,
};

export default GroupBox;