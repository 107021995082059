const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		assignmentNumber: 41,
		taskId: 'round-3-spot-errors-geometry-41', 
		title: 'Geometri',
		image: 'former',
		text: 'Klik på Trapez-formen',
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'billede6'
			}),
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 42,
		taskId: 'round-3-input-geometry-42',
		title: 'Geometri',
		image: 'polygon-circumference',
		text: 'Beregn omkreds af Polygon',
		unit: 'cm',
		answer: 910
	}),
	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 46,
		taskId: 'round-3-multiple-klimabelastning-46',
		title: 'Klimabelastning',
		type: 'multiple-choice',
		text: 'Hvor mange procent af den samlede danske CO2 udledning står byggebranchen for? kilde: https://www.bolius.dk/vi-skal-genbruge-mere-i-byggeriet-for-klimaets-skyld-89086',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '10%', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '25%', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '35%', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '40%', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '50%', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '55%', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 48,
		taskId: 'round-3-input-uddannelse-48',
		title: 'Uddannelse',
		type: 'multiple-choice',
		style: 'singleLine',
		text: 'Hvor lang tid tager det at blive udlært tømrer eller Snedker hvis man starter lige efter 9. klasse?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '2-3 år', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '3-4 år', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '4-5 år', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '5-6 år', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 49,
		taskId: 'round-3-multiple-oekonomi-49',
		title: 'Økonomi',
		type: 'multiple-choice',
		style: 'singleLine',
		text: 'Hvor meget tjener en førsteårslærling i gennemsnit om måneden, efter skat?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '2.500-3.250 kr.', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '3.500-4.250 kr.', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '4.000-4.750 kr.', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '5.500-6.250 kr.', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '6.250-7.000 kr.', 
				isCorrect: true,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 50,
		taskId: 'round-3-multiple-oekonomi-50',
		title: 'Økonomi',
		type: 'multiple-choice',
		style: 'singleLine',
		text: 'Hvad får man udbetalt som hjemmeboende i SU? (laveste sats)',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '300-600 kr.', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '500-800 kr.', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '700-1.000 kr.', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '1.000-1.300 kr.', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '1.200-1.500 kr.', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 52,
		taskId: 'round-3-multiple-oekonomi-52',
		title: 'Prisoverslag',
		type: 'multiple-choice',
		text: 'Din kunde har bedt om at få en fast pris på at lave nye Vindskeder. Du regner med at opgaven vil tage 8 timer og din timepris er 485,- ekskl. moms. Hvad kommer vindskederne til at koste inkl. moms?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '1212,5', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '3880', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '4850', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '5820', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 53,
		taskId: 'round-3-multiple-wage-53',
		title: 'Ekstra arbejde',
		type: 'multiple-choice',
		text: 'Du har lavet ekstraarbejde for en aftalt pris på 12.000,- arbejdet har taget kortere tid  end du regnede med. 19 timer. Hvad har du tjent i timen for ekstraarbejdet? (i hele kroner)',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '600', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '485', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '562', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '423', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '500', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '632', 
				isCorrect: true,
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 54,
		taskId: 'round-3-multiple-coordinate-system-54',
		title: 'Koordinatsystem',
		type: 'multiple-choice',
		image: 'koordinatsystem',
		text: 'I koordinatsystemet her er der 4 rette linjer l, m, n og o. Hvilken linje har hældningstallet ½?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'l', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'm', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'n', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'o', 
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 55,
		taskId: 'round-3-calculate-circumference',
		title: 'Udregn omkredsen',
		image: 'calculate-circumference-55',
		text: 'Du skal udfra de givne mål på figuren udregne omkredsen af figuren',
		unit: 'cm',
		subtype: 'range',
		range: {
			min: 461,
			max: 462
		}
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 56,
		taskId: 'round-3-calculate-circumference-56',
		title: 'Udregn omkredsen',
		image: 'circle-circumference',
		text: 'Du skal udfra de givne mål på figuren udregne omkredsen af figuren',
		unit: 'cm',
		subtype: 'range',
		range: {
			min: 1570,
			max: 1571
		}
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 57,
		taskId: 'round-3-input-geometry-57',
		title: 'Geometri',
		text: 'Du skal udfra de givne mål på figuren udregne omkredsen af figuren',
		image: 'square-circumference',
		unit: 'cm',
		answer: 1400
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 58,
		taskId: 'round-3-calculate-circumference-58',
		title: 'Udregn omkredsen',
		image: 'halfcircle-circumference',
		text: 'Du skal udfra de givne mål på figuren udregne omkredsen af figuren',
		unit: 'cm',
		subtype: 'range',
		range: {
			min: 1028,
			max: 1029
		}
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 59,
		taskId: 'round-3-calculate-circumference-59',
		title: 'Udregn omkredsen',
		image: 'quartercircle-circumference',
		text: 'Du skal udfra de givne mål på figuren udregne omkredsen af figuren',
		unit: 'cm',
		subtype: 'range',
		range: {
			min: 714,
			max: 715
		}
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 60,
		taskId: 'round-3-calculate-circumference-60',
		title: 'Udregn omkredsen',
		image: 'triangle-circumference',
		text: 'Du skal udfra de givne mål på figuren udregne omkredsen af figuren',
		unit: 'cm',
		subtype: 'range',
		range: {
			min: 1047,
			max: 1048
		}
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round3-medium-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};