import React, {useState, useEffect} from 'react';
import './assignments.scss';
import PropTypes from 'prop-types';
import { pageUiTexts } from 'data/ui-texts';
import Loading from 'components/loading/loading';
import { dotformatNumber } from 'helpers/text-helper';
// Modules
import MultipleChoice from 'components/game/modules/multiple-choice/multiple-choice';
import InputAnswer from 'components/game/modules/input-answer/input-answer';
import Order from 'components/game/modules/order/order';
import Match from 'components/game/modules/match/match';
import SpotErrors from 'components/game/modules/spot-errors/spot-errors';
import { pointData } from 'data/point-data';
import { getRoundAssignmentData } from 'helpers/assignment-helper';

const Assignments = ({bidData, roundNumber, updateGroup, group, game}) => {
	const [currentDifficultyId, setCurrentDifficultyId] = useState(1);
	const [currentAssignmentList, setCurrentAssignmentList] = useState(null);
	const [shownAssignment, setShownAssignment] = useState(null);
	const [taskData, setTaskData] = useState(null);

	const [taskDataEasy, setTaskDataEasy] = useState(null);
	const [taskDataMedium, setTaskDataMedium] = useState(null);
	const [taskDataHard, setTaskDataHard] = useState(null);

	const [assignmentValue, setAssignmentValue] = useState(0);
	const [shownAssignmentValue, setShownAssignmentValue] = useState(0);

	const [isLoading, setIsLoading] = useState(true);

	// Sets up component
	useEffect(() => {
		// checking how many tasks the group has completed
		let completedEasyTasks = 0;
		let completedMediumTasks = 0;
		let completedHardTasks = 0;
		if (group.taskData) {
			// Difficulty id 0 === easy
			// Difficulty id 1 === medium
			// Difficulty id 2 === hard
			completedEasyTasks = group.taskData.filter((data) => {
				return data.roundNumber === roundNumber && data.difficultyId === 0 && data.isCompleted;
			}).length;
			completedMediumTasks = group.taskData.filter((data) => {
				return data.roundNumber === roundNumber && data.difficultyId === 1 && data.isCompleted;
			}).length;
			completedHardTasks = group.taskData.filter((data) => {
				return data.roundNumber === roundNumber && data.difficultyId === 2 && data.isCompleted;
			}).length;
		}

		const taskData = getRoundAssignmentData(roundNumber, game.difficulty);
		if (!currentAssignmentList) {
			handleSetAssignmentList(taskData.mediumTaskData);
		}
		
		setTaskDataEasy({completedCount: completedEasyTasks, taskData: taskData.easyTaskData});
		setTaskDataMedium({completedCount: completedMediumTasks, taskData: taskData.mediumTaskData});
		setTaskDataHard({completedCount: completedHardTasks, taskData: taskData.hardTaskData});

		// Calculating assignment value, each assignment is worth equally much.
		// This is based on the total bid value and the amount of assignments.
		setAssignmentValue(Math.round(bidData.value / taskData.assignmentCount));
		setIsLoading(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roundNumber, bidData.value, group.taskData]);

	// Checks if any assignments, has bid specific title data, and sets it accordingly
	const handleSetAssignmentList = (assignmentList) => {
		// In case of multiple sets of titles we find the one for our current bid
		let assignments = assignmentList;
		assignments.forEach((assignment) => {
			if (assignment.titles && assignment.titles.length > 0) {
				assignment.title = assignment.titles.find((option) => {
					return option.bidId === bidData.id;
				}).title;
			}
		});
		setCurrentAssignmentList(assignments);
	};

	/**
	 * Updates group taskdata, and current task worth
	 * @param {string} moduleName 
	 * @param {bool} isCorrect 
	 * @param {Array} selectedOptions 
	 * @param {string} answer 
	 */
	const handleAnswer = (moduleName, isCorrect, selectedOption = null, answer = null, spottedErrorIds = null) => {
		let taskData = [];
		// Find group assignment taskData
		if (group.taskData) {
			taskData = group.taskData;
		}

		// Check if already has database object
		let assignmentData = taskData.find((data) => {return data.id === shownAssignment.id;});

		// If no existing object, build new database object
		if (assignmentData) {
			if (!isCorrect) assignmentData.errorCount ++;
			assignmentData.selectedOptionsIds.push(selectedOption);
			assignmentData.spottedErrorIds = spottedErrorIds;
			if (!assignmentData.answers) {
				assignmentData.answers.push(answer);
			} else {
				assignmentData.answers = [answer];
			}
		} else {
			const newData = {
				module: moduleName,
				id: shownAssignment.id, 
				roundNumber: roundNumber,
				difficultyId: currentDifficultyId,
				isCompleted: false, 
				errorCount: isCorrect ? 0 : 1,
				selectedOptionsIds: [selectedOption],
				answers: [answer],
				spottedErrorIds: spottedErrorIds,
			};

			assignmentData = newData;
			taskData.push(assignmentData);
		}

		// Calculate assignment value based on error count
		let currentAssignmentValue = shownAssignmentValue;
		if (!isCorrect) {
			const penaltyPercentage = pointData.penaltyPercentage;
			const penalty = assignmentData.errorCount * (assignmentValue / 100) * penaltyPercentage;
			currentAssignmentValue = Math.round(assignmentValue - penalty);

			const minimumValue = (assignmentValue / 100) * pointData.minimumPercentage;
			// We stop deducting value below 1000.
			if (currentAssignmentValue < minimumValue) {
				currentAssignmentValue = Math.round(minimumValue);
			}

			setShownAssignmentValue(currentAssignmentValue);
		}

		assignmentData.assignmentValue = currentAssignmentValue;

		setTaskData(assignmentData);
		updateGroup({taskData: taskData});
	};

	/**
	 * Completes tasks, updates database and awards group with cash reward.
	 * @param {string} moduleName 
	 * @param {Array} selectedOptions 
	 * @param {string} answer 
	 * @returns 
	 */
	const completeTask = (moduleName, selectedOptions = null, answer = null, spottedErrorIds = null) => {
		let newTaskData = null;

		// Build database object
		const newData = {
			module: moduleName,
			id: shownAssignment.id, 
			roundNumber: roundNumber,
			difficultyId: currentDifficultyId,
			isCompleted: true,
			errorCount: 0,
			selectedOptionsIds: selectedOptions,
			assignmentValue: shownAssignmentValue,
			answers: answer,
			spottedErrorIds: spottedErrorIds,
		};

		// Check if group has already answered ANY tasks
		// If not then we make a new taskData list
		if (!group.taskData) {
			newTaskData = [newData];
		} else {
			newTaskData = group.taskData;
			// Check if group has already answered task
			const currentTaskData = newTaskData.find((data) => {
				return data.id === shownAssignment.id;
			});
			
			// If yes, and its completed, then do nothing
			// If not completed, then update
			if (currentTaskData) {
				if (currentTaskData.isCompleted) return;

				currentTaskData.selectedOptionsIds = selectedOptions;
				currentTaskData.answers = answer;
				currentTaskData.spottedErrorIds = spottedErrorIds;
				currentTaskData.isCompleted = true;
			} else {
				// If no then add as new
				newTaskData.push(newData);
			}
		}

		// Update group money
		let groupMoney = shownAssignmentValue;
		if (group.money) {
			groupMoney += group.money;
		}

		let earnedMoney = shownAssignmentValue;
		if (group.totalEarnedMoney) {
			earnedMoney += group.totalEarnedMoney;
		}

		if (newTaskData !== null) {
			updateGroup({
				taskData: newTaskData,
				money: groupMoney,
				totalEarnedMoney: earnedMoney,
			});
			setTaskData(newData);
		}

		goToNextAssignment(shownAssignment.id);
	};

	/**
	 * Goes to next assignment in list, if there are any
	 * @param {number} currentAssignmentId 
	 * @returns 
	 */
	const goToNextAssignment = (currentAssignmentId) => {
		// find current assignment index
		const currentAssignmentIndex = currentAssignmentList.findIndex((assignment) => {
			return assignment.id === currentAssignmentId;
		});

		// Assignment not found in list
		if (!currentAssignmentIndex) {
			return;
		}
		// Check if it is last
		const isLast = currentAssignmentIndex === currentAssignmentList.length - 1;

		// if yes, do nothing
		if (isLast) {
			return;
		}

		// if no, then go to next assignment, if found
		const nextAssignment = currentAssignmentList[currentAssignmentIndex + 1];
		if (nextAssignment) {
			handleSetAssignment(nextAssignment);
		}
	};

	/**
	 * Finds assignment data for bid id and updates the current assignment
	 * @param {object} assignment
	 */
	const handleSetAssignment = (assignment) => {
		let currentAssignment = assignment;
		const bidId = bidData.id; // '15b'; // 

		// Some assignments contain texts that are bid specific here we find the text for the current bid
		const bidSpecificText = assignment.texts ? 
			assignment.texts.find((text) => {return text.bidId === bidId;}) 
			: null;

		if (!bidSpecificText 
			&& assignment.text === ''
			&& assignment.type !== 'match') {
			console.error(pageUiTexts.bidtextNotFound);
		}

		if (bidSpecificText) {
			currentAssignment.text = bidSpecificText.text;
		}

		let currentAssignmentValue = assignmentValue;

		// In case of multiple sets of options we find the one for our current bid
		if (assignment.optionsList && assignment.optionsList.length > 0) {
			currentAssignment.options = assignment.optionsList.find((option) => {
				return option.bidId === bidId;
			}).options;
		}

		// In case of multiple sets of options we find the one for our current bid
		if (assignment.itemsList && assignment.itemsList.length > 0) {
			currentAssignment.items = assignment.itemsList.find((item) => {
				return item.bidId === bidId;
			}).items;
		}

		// In case of multiple sets of images, we find the one for our current bid
		if (assignment.images && assignment.images.length > 0) {
			currentAssignment.image = assignment.images.find((image) => {
				return image.bidId === bidId;
			}).image;
		}

		if (assignment.type === 'order' && assignment.itemList && assignment.itemList.length > 0) {
			currentAssignment.items = assignment.itemList.find((items) => {
				return items.bidId === bidId;
			}).items;
		}

		// We filter errors out for our specific bid, in case of spot-errors
		if (assignment.type === 'spot-errors') {
			currentAssignment.errors = assignment.errors.filter((error) => {
				return error.bidId === bidId || error.bidId === 0; // If bidId === 0, its always added
			});
		}

		// In case of input-answers,
		// we check if there are multiple units and or ranges or answers, and set accordingly to bidId
		if (assignment.type === 'input-answer') {
			if (assignment.units && assignment.units.length > 0) {
				currentAssignment.unit = assignment.units.find((unit) => {
					return unit.bidId === bidId;
				}).unit;
			}
			if (assignment.subtype === 'range') {
				if (assignment.ranges && assignment.ranges.length > 0) {
					currentAssignment.range = assignment.ranges.find((range) => {
						return range.bidId === bidId;
					});
				}
			} else {
				if (assignment.answers && assignment.answers.length > 0) {
					currentAssignment.answer = assignment.answers.find((answer) => {
						return answer.bidId === bidId;
					}).answer;
				}
			}
		}

		// Getting current group taskData
		if (group.taskData) {
			// Check if group has already answered task
			const assignmentTaskData = group.taskData.find((data) => {
				return data.id === currentAssignment.id;
			});

			// If taskData exists for current assignment
			if (assignmentTaskData) {
				setTaskData(assignmentTaskData);
				currentAssignmentValue = assignmentTaskData.assignmentValue;
			} else {
				// Reset taskData in case nothing was found.
				setTaskData(null);
			}
		}

		setShownAssignmentValue(currentAssignmentValue);
		setShownAssignment(currentAssignment);
	};

	return (
		<div className='Assignments'>
			<div className='Assignments-overviewWrapper'>
				{/* Text showing bid information */}
				<div className='Assignments-overviewInfo'>
					<div className='Assignments-overviewTextWrapper'>
						<div className='Assignments-overviewCategory'>
							<span>{pageUiTexts.customer}</span>
						</div>
						<div className='Assignments-overviewText'>
							<span>{bidData.name}</span>
						</div>
					</div>
					<div className='Assignments-overviewTextWrapper'>
						<div className='Assignments-overviewCategory'>
							<span>{pageUiTexts.assignment}</span>
						</div>
						<div className='Assignments-overviewText'>
							<span>{bidData.assignment}</span>
						</div>
					</div>
					<div className='Assignments-overviewTextWrapper'>
						<span>{pageUiTexts.budget}</span>
						<div className='Assignments-overviewText'>
							<span>
								{dotformatNumber(bidData.value)}
							</span>
							<div className='Assignments-currency'>
								<span>{pageUiTexts.currency}</span>
							</div>
						</div>
					</div>
				</div>

				{/* Lists showing available and completed assignments */}
				<div className='Assignments-lists'>
					<div className='Assignments-difficultyWrapper'>
						{isLoading ? 
							<Loading /> 
							:
							pageUiTexts.difficultyLevels.map((difficulty, index) => {
								let isCurrent = false;
								if (currentDifficultyId === difficulty.id) {
									isCurrent = true;
								}

								let currentAssignments = null;
								if (difficulty.id === 0) {
									currentAssignments = taskDataEasy;
								} else if (difficulty.id === 1) {
									currentAssignments = taskDataMedium;
								} else if (difficulty.id === 2) {
									currentAssignments = taskDataHard;
								}

								if (currentAssignments === null) {
									console.error(pageUiTexts.assignmentsNotFound);
									return '';
								}

								return (
									<div key={index} 
										className={'Assignments-listDifficulty' + 
											(isCurrent ? ' isActive' : '') +
											(index + 1 === pageUiTexts.difficultyLevels.length ? '' : ' notLast')}
										onClick={() => {
											setCurrentDifficultyId(difficulty.id);
											handleSetAssignmentList(currentAssignments.taskData);
										}}>
										<span>
											{difficulty.name + ' ' +
											currentAssignments.completedCount + '/' +
											currentAssignments.taskData.length}
										</span>
									</div>
								);
							})
						}
					</div>
					<div className='Assignments-shownTasks'>
						{currentAssignmentList && currentAssignmentList.map((assignment, index) => {
							let isAssignmentCompleted = false;
							let currentAssignmentValue = assignmentValue;
							if (group.taskData) {
								const task = group.taskData.find((data) => {
									return data.id === assignment.id; 
								});

								if (task) {
									isAssignmentCompleted = task.isCompleted;
									currentAssignmentValue = task.assignmentValue;
								}
							}
							let isActiveAssignment = false;
							if (shownAssignment) {
								isActiveAssignment = shownAssignment.id === assignment.id;
							}

							return (
								<div key={index} 
									onClick={() => {handleSetAssignment(assignment);}} 
									className={'Assignments-task' + 
										(isActiveAssignment ? ' active' : '') +
										(isAssignmentCompleted ? ' done' : '')}>
									<div className='Assignments-taskTitle'>
										<span>{assignment.title}</span>
									</div>
									<div className='Assignment-taskData'>
										<div className={'Assignment-value' + (isAssignmentCompleted ? '' : ' active')}>
											<span>{currentAssignmentValue + pageUiTexts.currency}</span>
										</div>
										<div className={'Assignment-doneIndicator' + 
											(isAssignmentCompleted ? ' checkMarked' : '')}/>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			
			{/* Assignment Window */}
			<div className='Assignments-currentAssignment'>
				{shownAssignment ? 
					<div className='Assignments-assignmentWrapper'>
						<div className='Assignments-assignmentHeader'>
							<div className='Assignments-assignmentTitle'>
								<span>{shownAssignment.title}</span>
							</div>
							<div className='Assignments-assignmentValue'>
								<span>{dotformatNumber(shownAssignmentValue) + pageUiTexts.currency}</span>
							</div>
						</div>
						{shownAssignment && shownAssignment.type === 'multiple-choice' ?
							<MultipleChoice
								playerTaskData={taskData}
								taskData={shownAssignment} 
								handleAnswer={handleAnswer}
								handleCompleteTask={completeTask}/> : ''
						}
						{shownAssignment && shownAssignment.type === 'input-answer' ?
							<InputAnswer
								playerTaskData={taskData}
								taskData={shownAssignment} 
								handleAnswer={handleAnswer}
								handleCompleteTask={completeTask}/> : ''
						}
						{shownAssignment && shownAssignment.type === 'order' ?
							<Order
								playerTaskData={taskData}
								taskData={shownAssignment}
								handleAnswer={handleAnswer}
								handleCompleteTask={completeTask}/> : ''
						}

						{shownAssignment && shownAssignment.type === 'match' ?
							<Match
								playerTaskData={taskData}
								taskData={shownAssignment}
								handleAnswer={handleAnswer}
								handleCompleteTask={completeTask}/> : ''
						}
						{shownAssignment && shownAssignment.type === 'spot-errors' ?
							<SpotErrors
								playerTaskData={taskData}
								taskData={shownAssignment}
								handleAnswer={handleAnswer}
								handleCompleteTask={completeTask}/> : ''
						}
					</div>
					:
					''
				}
			</div>
		</div>
	);
};

Assignments.propTypes = {
	bidData: PropTypes.object.isRequired,
	roundNumber: PropTypes.number.isRequired,
	updateGroup: PropTypes.func.isRequired,
	group: PropTypes.object.isRequired,
	game: PropTypes.object.isRequired,
};

export default Assignments;