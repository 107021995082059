const gameStepsData = [
	{	// Game introduction
		id: 'gameIntro',
		facilitatorPage: 'gameIntro',
		studentPage: 'attention'
	},
	{
		id: 'bidOverview-1',
		roundNumber: 1,
		facilitatorPage: 'bidOverview',
		studentPage: 'bid',
	},
	{
		id: 'bidOverviewResult-1',
		roundNumber: 1,
		facilitatorPage: 'bidOverview',
		studentPage: 'bidResult',
	},
	{
		id: 'build-1',
		roundNumber: 2,
		facilitatorPage: 'buildTimer',
		studentPage: 'workshop'
	},
	{
		id: 'result-1',
		roundNumber: 2,
		facilitatorPage: 'result',
		studentPage: 'result'
	},

	// Round 2 begins
	{
		id: 'bidOverview-2',
		roundNumber: 3,
		facilitatorPage: 'bidOverview',
		studentPage: 'bid',
	},
	{
		id: 'bidOverviewResult-2',
		roundNumber: 3,
		facilitatorPage: 'bidOverview',
		studentPage: 'bidResult',
	},
	{
		id: 'build-2',
		roundNumber: 4,
		facilitatorPage: 'buildTimer',
		studentPage: 'workshop'
	},
	{
		id: 'result-2',
		roundNumber: 4,
		facilitatorPage: 'result',
		studentPage: 'result'
	},

	// Round 3 begins
	{
		id: 'bidOverview-3',
		roundNumber: 5,
		facilitatorPage: 'bidOverview',
		studentPage: 'bid',
	},
	{
		id: 'bidOverviewResult-3',
		roundNumber: 5,
		facilitatorPage: 'bidOverview',
		studentPage: 'bidResult',
	},
	{
		id: 'build-3',
		roundNumber: 6,
		facilitatorPage: 'buildTimer',
		studentPage: 'workshop'
	},
	{
		id: 'gameOver',
		roundNumber: 6,
		facilitatorPage: 'gameOver',
		studentPage: 'attention'
	},
	{
		id: 'achievement-1',
		roundNumber: 6,
		facilitatorPage: 'finalAchievement',
		studentPage: 'attention',
		dataType: 'quality'
	},
	{
		id: 'achievement-2',
		roundNumber: 6,
		facilitatorPage: 'finalAchievement',
		studentPage: 'attention',
		dataType: 'workenvironment'
	},
	{
		id: 'achievement-3',
		roundNumber: 6,
		facilitatorPage: 'finalAchievement',
		studentPage: 'attention',
		dataType: 'sustainability'
	},
	{
		id: 'result-3',
		roundNumber: 6,
		facilitatorPage: 'finalResult',
		studentPage: 'attention'
	},
];

module.exports = {gameStepsData};