const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		assignmentNumber: 8,
		taskId: 'round-1a-fractions-2',
		title: 'Brøker',
		type: 'match',
		answerType: 'fractions-a1',
		instructions: 'Det er godt at have styr på brøker og andele når man skal være håndværker.',
		text: 'Match decimaltalene med de rigtige billeder',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '0.5',
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '0.33', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '0.6', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '0.83', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '0.25', 
				id: 5,
				answers: [5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '0.66', 
				id: 6,
				answers: [6]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: '0.5',
				imageSrc: 'set-a-1/fractions/half.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: '0.33',
				imageSrc: 'set-a-1/fractions/two-sixths.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: '0.6',
				imageSrc: 'set-a-1/fractions/three-fifth.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: '0.83',
				imageSrc: 'set-a-1/fractions/five-sixth.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: '0.25',
				imageSrc: 'set-a-1/fractions/quater.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: '0.66',
				imageSrc: 'set-a-1/fractions/two-thirds.svg',
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 13,
		taskId: 'round-1a-input-area',
		title: 'Arealet af en figur',
		image: 'figure-area',
		text: 'Billedet viser en figur med rette vinkler. Hvor stort er arealet af figuren? (Angiv svaret med 2 decimaler)',
		unit: 'm2',
		answer: 44.23,
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 14,
		taskId: 'round-1a-input-circumference-2',
		title: 'Omkredsen af en figur',
		image: 'figure-area-2',
		text: 'Billedet viser en figur med rette vinkler. Hvor stor er omkredsen af figuren? (Angiv svaret med 2 decimaler)',
		unit: 'm',
		answer: 40.75,
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 15,
		taskId: 'round-1a-input-area-2',
		title: 'Arealet af en figur',
		image: 'figure-area-2',
		text: 'Billedet viser en figur med rette vinkler. Hvor stort er arealet af figuren? (Angiv svaret med 2 decimaler)',
		unit: 'm2',
		answer: 59.84,
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 19,
		taskId: 'round-1a-input-triangle-3',
		title: 'Arealet af en figur',
		image: 'figure-triangle-3',
		text: 'Billedet viser en retvinklet trekant. Hvor stort er arealet af trekanten?',
		unit: 'cm2',
		answer: 2700,
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 20,
		taskId: 'round-1a-input-triangle-4',
		title: 'Omkredsen af en figur',
		image: 'figure-triangle-4',
		text: 'Billedet viser en retvinklet trekant. Hvad er omkredsen af figuren',
		unit: 'cm',
		answer: 302,
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round1-hard-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};