import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './match.scss';
import TaskIntro from '../task-intro/task-intro';
import {shuffleArray} from 'helpers/array-helper';

const Match = ({playerTaskData, taskData, handleAnswer, handleCompleteTask}) => {

	/* Check if completed already */
	const [isCompleted, setIsCompleted] = useState(false);
	/* Track available options */
	const [optionIds, setOptionIds] = useState([]);
	/* Track available answers */
	const [answerIds, setAnswerIds] = useState([]);
	/* Track Completed options */
	const [completedAnswers, setCompletedAnswers] = useState([]);
	/* Track Current cliked option */
	const [currentOption, setCurrentOption] = useState(null);
	/* Track Current cliked answer */
	const [currentAnswer, setCurrentAnswer] = useState(null);

	/* Animate selected options */
	const [animateSelectedOptions, setAnimateSelectedOptions] = useState(false); 
	const [animationDelayTimer, setAnimationDelayTimer] = useState(null);

	/* Wrong answer animation */
	const [isWrongAnimation, setIsWrongAnimation] = useState(false);
	/* Is game paused */
	const [isPaused, setIsPaused] = useState(false);

	/* Update selected items if new task */
	useEffect(() => {
		let isTaskCompleted = playerTaskData && playerTaskData.isCompleted;
		setIsCompleted(isTaskCompleted);
		setCompletedAnswers(getSelectedOptionIds());
		setAnimateSelectedOptions(true);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id, playerTaskData]);

	/**
	 * Complete task
	 */
	const completeTask = (answers) => {
		/* Save completed task */
		handleCompleteTask(
			'match',
			answers
		);
	};
	/**
	 * onMount remove animation delay 
	 */
	useEffect(() => {
		return () => {
			clearTimeout(animationDelayTimer);
			setCompletedAnswers([]);
			setIsPaused(false);
		};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Get option and answer ids and shuffle them
	useEffect(() => {
		let optionIds = [];
		if (taskData.options && taskData.hasOwnProperty('shuffleOptions') && !taskData.shuffleOptions) {
			optionIds = taskData.options.map((option) => {return option.id;});
		} else {
			optionIds = shuffleArray(taskData.options.map((option) => {return option.id;}));
		}

		let answerIds = [];
		if (taskData.answers && taskData.hasOwnProperty('shuffleOptions') && !taskData.shuffleOptions) {
			answerIds = taskData.answers.map((answer) => {return answer.id;});
		} else {
			answerIds = shuffleArray(taskData.answers.map((answer) => {return answer.id;}));
		}
		/* update the new data and remove selected */
		setAnswerIds(answerIds);
		setOptionIds(optionIds);
		setCurrentAnswer(null);	
		setCurrentOption(null);
		
	}, [taskData]);

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	 const getSelectedOptionIds = () => {
		let optionIds = [];

		if (playerTaskData && playerTaskData.selectedOptionsIds) optionIds = playerTaskData.selectedOptionsIds;
		return optionIds;
	};
	
	/**
	 * Check when an new option or answer if they match
	 */
	 useEffect(() => {
		checkMatch();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentAnswer, currentOption]); 

	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	 const selectOptionId = (optionId) => {
		/* Already selected */
		if (currentOption === optionId) return;

		/* update current option */
		setCurrentOption(optionId);
	};

	/**
	 * Select answer
	 * @param {number} answerId 
	 * @returns 
	 */
	const selectAnswerId = (answerId) => {
		/* Already selected */
		if (currentAnswer === answerId) return;

		/* update current option */
		setCurrentAnswer(answerId);
	};

	/**
	 * Select answer
	 * @param {number}  
	 * @returns 
	 */
	 const checkMatch = () => {

		/* Nothing to match to */
		if (!currentOption) return;
		if (!currentAnswer) return;

		/* get the current option using the id */
		const currentOptionData = taskData.options.find((option) => {return option.id === currentOption;});

		/* get the current answer using the id */
		const currentAnswerData = taskData.answers.find((answer) => {return answer.id === currentAnswer;});

		/* Check if option match to answer */
		const isMatching = currentOptionData.answers.includes(currentAnswerData.id);
		setIsPaused(true);
		if (isMatching) {
			/* if it has a match update completed answers state */
			setCompletedAnswers([...completedAnswers, {id: currentAnswer, isCorrect: isMatching}]);

			/** on match deselect answer and option */
			const delay = setTimeout(function() {
				setCurrentAnswer(null);
				setCurrentOption(null);
				setIsPaused(false);
			}, 500);

			setAnimationDelayTimer(delay);

		} else {
			/** only deselect answer when not mathcing*/
			setIsWrongAnimation(true);
			// We wait for animations to finish before we complete task
			const delay = setTimeout(function() {
				setCurrentAnswer(null);
				setIsWrongAnimation(false);
				setIsPaused(false);
			}, 500);

			setAnimationDelayTimer(delay);
		
		}
		/** check if task is complete if not handleAnswer */
		if (isTaskCompleted([...completedAnswers, {id: currentAnswer, isCorrect: isMatching}])) {
			/* Complete task */
			completeTask([...completedAnswers, {id: currentAnswer, isCorrect: isMatching}]);
		} else {
			// We save data for selected answer
			handleAnswer('match', isMatching, {id: currentAnswer, isCorrect: isMatching});
		}
	};
	
	/** 
	 * Check if the option has all answers complete
	*/
	const isOptionCompleted = (option, allAnswers) => {
		return option.answers.every((answer) => {
			return allAnswers.find((a)=>{
				if (a.id === answer && a.isCorrect) {
					return true;
				}
				return false;
			});
		});
	};

	/**
	 * Check if the task is completed
	 */
	const isTaskCompleted = (allAnswers) => {
		return taskData.options.every((option) => {
			return isOptionCompleted(option, allAnswers);
		});
	};

	return (
		<div className={'Match ' + (taskData.layout ? ' ' + taskData.layout : '')}
		>
			<div id="taskIntro" className="Match-intro">
				<TaskIntro
					text={taskData.text}
					image={taskData.image}
					intruction={taskData.instructions}
					file={taskData.linkFile}
				/>
			</div>
			<div className='Match-contentWrapper'>
				<div className="Match-optionsWrap">
					{optionIds.map((optionId) => {
						const optionData = taskData.options.find((option) => {return option.id === optionId;});
						if (!optionData) return null;
						const isSelected = (currentOption === optionData.id);
						
						/* if all the options answers is completed */
						const isAnswered = isOptionCompleted(optionData, completedAnswers);
						
						let optionClass = 'Match-option';
						if (isSelected) {
							optionClass += ' selected';
						} else if (!isSelected && !isCompleted && !isAnswered) {
							optionClass += ' notSelected';
						} else if (!isSelected && isCompleted) {
							optionClass += ' completed';
						} 
						if (isAnswered) optionClass += ' answered';
						if (isSelected && !isCompleted) {
							if (isAnswered) {
								optionClass += ' ' + (animateSelectedOptions ? 'animateCorrect' : 'correct');
							} 
							if (isWrongAnimation) {
								optionClass += ' ' + (animateSelectedOptions ? 'animateWrong' : 'wrong');
							}
						}
						optionClass += ' ' + taskData.answerType + ' option-' + optionData.id;
						return (
							<div 
								key={optionData.id}
								className={optionClass}
								onClick={() => {
									if (isAnswered) return;
									if (isPaused) return;
									if (optionData.id === currentOption) {
										setCurrentOption(null);
										return;
									}
									selectOptionId(optionData.id);
								}}
							>
								<span>{optionData.option}</span>
							</div>
						);
					})}
				</div>
				<div className="Match-answersWrap">
					{answerIds.map((answerId) => {
						const answerData = taskData.answers.find((answer) => {return answer.id === answerId;});
						if (!answerData) return null;
						const isSelected = (currentAnswer === answerData.id);

						const isAnswered = completedAnswers.find((answer)=>{
							if (answer.id === answerData.id && answer.isCorrect) {
								return true;
							}
							return false;
						});

						let answerClass = 'Match-answer ' + (taskData.answerType);
						if (isSelected) {
							answerClass += ' selected';
						} else if (!isSelected && !isCompleted) {
							answerClass += ' notSelected';
						} else if (!isSelected && isCompleted) {
							answerClass += ' completed';
						} 

						
						if (isAnswered) answerClass += ' answered';
				
						if (isSelected && !isCompleted) {
							if (isAnswered) {
								answerClass += ' ' + (animateSelectedOptions ? 'animateCorrect' : 'correct');
							} 
							if (isWrongAnimation) {
								answerClass += ' ' + (animateSelectedOptions ? 'animateWrong' : 'wrong');
							}
						}

						answerClass += ' answer-' + answerId;
						if (!answerData.isText) answerClass += ' image';
						return (
							<div 
								key={answerId}
								className={answerClass} 
								onClick={() => {
									if (isAnswered) return;
									if (isPaused) return;
									if (answerData.id === currentAnswer) {
										setCurrentAnswer(null);
										return;
									}
									selectAnswerId(answerData.id);
								}}
							>
								{
									answerData.isText ? <span>{answerData.text}</span> :
										<img src={require('assets/images/assignments/' 
											+ answerData.imageSrc).default}
										alt={'answer img'}
										/>
								}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

Match.propTypes = {
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleAnswer: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
};

export default Match;
