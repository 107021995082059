import PropTypes from 'prop-types';
import './task-intro.scss';
import MaterialePyramiden from 'assets/images/assignments/material-pyramid.pdf';
import Round1MultipleBeregning from 'assets/images/assignments/round-1-multiple-beregning.svg';
import WoodHardness from 'assets/images/assignments/materials/wood-hardness.svg';
import Employment from 'assets/pdfs/dst-beskæftigede.pdf';
import Tabel from 'assets/images/assignments/tabel-tegningshoved.pdf';

const fileMap = {
	materialepyramiden:				{file: MaterialePyramiden, text: 'materialepyramiden'},
	round1MultipleChoiceBeregning: 	{file: Round1MultipleBeregning, text: 'opgave illustration'},
	woodHardness:					{file: WoodHardness, text: 'Træ hårdhed'},
	employment:						{file: Employment, text: 'Beskæftigede ved bygge og anlæg'},
	tabelTegningshoved:				{file: Tabel, text: 'Tabel og tegningshoved'}
};

const TaskIntro = ({showNumberOfAnswersToSelect, numberOfCorrectAnswers, text, image, file, instruction}) => {

	const showRequiredNumberOfAnswers = (
		showNumberOfAnswersToSelect && 
		numberOfCorrectAnswers && 
		(numberOfCorrectAnswers > 0) ? true : false
	);

	// Checking if file exists in map
	let fileLink = null;
	if (fileMap.hasOwnProperty(file)) {
		fileLink = fileMap[file].file;
	}

	return (
		<div className={'TaskIntro' + (image ? ' ' + image : '')}>
			<div className="TaskIntro-text">
				<div dangerouslySetInnerHTML={{__html: instruction ? text + ' ' + instruction : text}}/>
				<div>{fileLink ? 
					<a href={fileLink} target="_blank" rel="noreferrer">
						{fileMap[file].text}
					</a>
					:
					''
				}</div>
				{showRequiredNumberOfAnswers && <span>{'Vælg'} {numberOfCorrectAnswers}:</span>}
			</div>
			{image && <div className="TaskIntro-image" />}
		</div>
	);
};

TaskIntro.propTypes = {
	showNumberOfAnswersToSelect: PropTypes.bool,
	numberOfCorrectAnswers: PropTypes.number,
	text: PropTypes.string,
	image: PropTypes.string,
	file: PropTypes.string,
	instruction: PropTypes.string,
};

export default TaskIntro;
