import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './frontpage.scss';
import Button from 'components/ui/button/button';
import { pageUiTexts } from 'data/ui-texts';
import PlayButton from 'components/ui/playButton/playButton';
import GuidePopup from 'components/ui/guide-popup/guide-popup';

const FrontPage = ({startGame, setPlayMusic, musicIsPlaying}) => {
	const [showTeacherGuide, setShowTeacherGuide] = useState(false);

	return (
		<div className="Frontpage">

			<div className='Frontpage-titleWrapper'>
				<div className='Frontpage-title'/>
				<div className='Frontpage-startButton'>
					<Button
						classes={['pushable', 'medium']} 
						text={pageUiTexts.startUpper} 
						isOnRelease={true}
						onClick={() => {startGame();}}
					/>
				</div>
				<div className='Frontpage-guideButton'>
					<Button
						classes={['pushable', 'medium']} 
						text={pageUiTexts.teacherGuide} 
						isOnRelease={true}
						onClick={() => {setShowTeacherGuide(true);}}
					/>
				</div>
			</div>

			<div className='Frontpage-logos'>
				<div className='Frontpage-logoUnord' onClick={() => {window.open('https://unord.dk/', '_blank', 'noopener,noreferrer');}}/>
				<div className='Frontpage-logoCGL' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>
				<div className='Frontpage-fogsFondWrapper' onClick={() => {window.open('https://fogfond.dk/', '_blank', 'noopener,noreferrer');}}>
					<span>{pageUiTexts.fogsFond}</span>
					<div className='Frontpage-fogsFond'/>
				</div>
			</div>
			<PlayButton setPlayMusic={setPlayMusic} musicIsPlaying={musicIsPlaying}/>

			{showTeacherGuide &&
				<GuidePopup closePopup={() => {setShowTeacherGuide(false);}}/>
			}
		</div>
	);
};

FrontPage.propTypes = {
	startGame: PropTypes.func.isRequired,
	setPlayMusic: PropTypes.func.isRequired,
	musicIsPlaying: PropTypes.bool.isRequired,
};

export default FrontPage;