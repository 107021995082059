import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './input-answer.scss';
import TaskIntro from '../task-intro/task-intro';
import Button from 'components/ui/button/button';
import { pageUiTexts } from 'data/ui-texts';

const InputAnswer = ({playerTaskData, taskData, handleAnswer, handleCompleteTask}) => {
	const [answer, setAnswer] = useState('');

	/* Check if completed already */
	const [isCompleted, setIsCompleted] = useState(false);

	/* Used to animate button when answer was wrong */
	const [wasWrong, setWasWrong] = useState(false);
	const [wrongAnswerTimer, setWrongAnswerTimer] = useState(null);
	
	/* Update selected items if new task */
	useEffect(() => {
		let isTaskCompleted = playerTaskData && playerTaskData.isCompleted;
		setIsCompleted(isTaskCompleted);
	}, [taskData.id, playerTaskData]);

	useEffect(() => {
		return () => {
			clearTimeout(wrongAnswerTimer);
		};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Handles input of answer,
	 * checks if correct, then completes task
	 * @param {string} answer
	 */
	const handleAnswerInput = (event) => {
		if (event) event.preventDefault();
		
		const isCorrect = checkIfCorrect(answer);

		if (isCorrect) {
			let answers = (playerTaskData && playerTaskData.answers) ? playerTaskData.answers : null;
			if (!answers) {
				answers = [answer];
			} else {
				answers.push(answer);
			}

			completeTask(answers);
		} else {
			// We save data for selected answer
			handleAnswer('input-answers', false, null, answer);
			setWasWrong(true);
			
			// We wait for animations to finish then switch button back
			const delay = setTimeout(function() {
				/* Save completed task */
				setWasWrong(false);
			}, 750);

			setWrongAnswerTimer(delay);
		}
	};

	/**
	 * Checks if answer is correct, either within range if subtype range, or if equal to answer.
	 * @param {number} inputAnswer 
	 * @returns 
	 */
	const checkIfCorrect = (inputAnswer) => {
		let comparedAnswer = inputAnswer;
		comparedAnswer = parseFloat(inputAnswer.replace(',', '.'));
		if (taskData.subtype === 'range') {
			return comparedAnswer >= taskData.range.min && comparedAnswer <= taskData.range.max;
		}

		return parseFloat(comparedAnswer) === taskData.answer;
	};

	/**
	 * saves input
	 * @param {object} event 
	 */
	const handleInput = (event) => {
		let value = event.target.value;

		setAnswer(value);
	};

	/**
	 * Complete task
	 */
	const completeTask = (answers) => {
		/* Save completed task */
		handleCompleteTask(
			'input-answers',
			null,
			answers
		);
	};

	return (
		<div className={'InputAnswer ' + (taskData.layout ? ' ' + taskData.layout : '')}
		>
			<div id="taskIntro" className="InputAnswer-intro">
				<TaskIntro
					text={taskData.text}
					image={taskData.image}
					file={taskData.linkFile}
				/>
			</div>
			<div className='InputAnswer-footer'>
				<div className='InputAnswer-inputWrapper'>
					<form className='InputAnswer-form' onSubmit={handleAnswerInput}>
						<div className={'InputAnswer-input' + (isCompleted ? ' disabled' : '')}>
							<span>{'Facit:'}</span>
							<input
								disabled={isCompleted}
								name='answer'
								type='text'
								placeholder={''}
								autoComplete='section-inputAnswer answer'
								value={answer}
								onChange={(event)=>{handleInput(event);}}
							/>
							<span>{taskData.unit}</span>
						</div>
						<div className='InputAnswer-answerButtonWrapper'>
							<div className='InputAnswer-answerButton'>
								<Button
									isDisabled={isCompleted}
									text={pageUiTexts.deliver}
									classes={['flatBlue ' 
										+ (wasWrong ? 'animateWrong' : '') 
										+ (isCompleted ? 'animateCorrect' : '')]}
									onClick={() => {handleAnswerInput();}}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

InputAnswer.propTypes = {
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleAnswer: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
};

export default InputAnswer;
