import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { renderMarkdown } from 'helpers/text-helper';
import { guideTexts } from 'data/teaching-guide-data';
import { pageUiTexts } from 'data/ui-texts';
import MaterialsWindow from '../materials-window/materials-window';
import './guide-popup.scss';

const GuidePopup = ({closePopup}) => {

	return (
		<div className='GuidePopup' onClick={() => {closePopup();}}>
			<div className='GuidePopup-window' onClick={(e) => {e.stopPropagation();}}>
				<div className='GuidePopup-content'>
					<div className='GuidePopup-header'>
						<span>{pageUiTexts.teacherGuide}</span>
					</div>

					<div className='GuidePopup-text'>
						{renderMarkdown(guideTexts.text1)}
					</div>

					{/* VIDEO 1 */}
					<video className='GuidePopup-video' controls>
						<source src={appConfig.video1StorageLink} type='video/mp4'/>
					</video>

					<div className='GuidePopup-sectionDivision'>
						<div className='GuidePopup-sectionDivisionLeft'>
							<div className='GuidePopup-textHeader'>
								{renderMarkdown(guideTexts.text2.header)}
							</div>
							<div className='GuidePopup-text'>
								{renderMarkdown(guideTexts.text2.text)}
							</div>
						</div>
						<div className='GuidePopup-materialInterface'>
							<MaterialsWindow title={guideTexts.materialInterface.title}/>
						</div>
					</div>

					<div className='GuidePopup-textHeader'>
						{renderMarkdown(guideTexts.text3.header)}
					</div>
					<div className='GuidePopup-text'>
						{renderMarkdown(guideTexts.text3.text)}
					</div>

					{/* VIDEO 2 */}
					<video className='GuidePopup-video' controls>
						<source src={appConfig.video2StorageLink} type='video/mp4'/>
					</video>

					<div className='GuidePopup-textHeader'>
						{renderMarkdown(guideTexts.text4.header)}
					</div>
					<div className='GuidePopup-text'>
						{renderMarkdown(guideTexts.text4.text)}
					</div>

					<div className='GuidePopup-textHeader'>
						{renderMarkdown(guideTexts.text5.header)}
					</div>
					<div className='GuidePopup-text'>
						{renderMarkdown(guideTexts.text5.text)}
					</div>

					{/* VIDEO 3 */}
					<video className='GuidePopup-video' controls>
						<source src={appConfig.video3StorageLink} type='video/mp4'/>
					</video>

					<div className='GuidePopup-textHeader'>
						{renderMarkdown(guideTexts.text6.header)}
					</div>

					{/* VIDEO 4 */}
					<video className='GuidePopup-video' controls>
						<source src={appConfig.video4StorageLink} type='video/mp4'/>
					</video>

					<div className='GuidePopup-textHeader'>
						{renderMarkdown(guideTexts.text7.header)}
					</div>
					<div className='GuidePopup-text'>
						{renderMarkdown(guideTexts.text7.text)}
					</div>

					<div className='GuidePopup-line'/>

					<div className='GuidePopup-textHeader'>
						{renderMarkdown(guideTexts.text8.header)}
					</div>
					<div className='GuidePopup-text'>
						{renderMarkdown(guideTexts.text8.text)}
					</div>
					
					<div className='GuidePopup-logo'>
						<div className='GuidePopup-logoUnord' />
						<div className='GuidePopup-logoGamelab' />
						<div className='GuidePopup-logoFogs' />
					</div>
				</div>

				<div className='GuidePopup-closeButton' onClick={() => {closePopup();}}/>
			</div>
		</div>
	);
};

GuidePopup.propTypes = {
	closePopup: PropTypes.func.isRequired,
};

export default GuidePopup;