import React, { useState } from 'react';
import './game-settings-popup.scss';
import PropTypes from 'prop-types';
import { pageUiTexts } from 'data/ui-texts';
import Button from '../button/button';
import { difficultyData, roundLengthData } from 'data/settings-data';

const GameSettingsPopup = ({setGameSettings}) => {
	const [currentDifficultySettings, setCurrentDifficultySettings] = useState(null);
	const [currentRoundTimeSettings, setCurrentRoundTimeSettings] = useState(null);
	const [combinedGameTime, setCombinedGameTime] = useState(55);

	/**
	 * Calculates combined game time, and sets current round time setting choice
	 * @param {number} time 
	 */
	const handleSetCurrentRoundTime = (time) => {
		const combinedTime = time * 3 + 10;

		setCombinedGameTime(combinedTime);
		setCurrentRoundTimeSettings(time);
	};

	/**
	 * Handles settings game settings.
	 */
	const handleSetGameSettings = () => {
		const combinedGameSettings = {
			difficulty: currentDifficultySettings,
			roundTime: currentRoundTimeSettings
		};

		if (currentDifficultySettings === null) {
			combinedGameSettings.difficulty = difficultyData[0].id;
		}

		if (currentRoundTimeSettings === null) {
			combinedGameSettings.roundTime = roundLengthData[0];
		}

		setGameSettings(combinedGameSettings);
	};

	return (
		<div className='GameSettingsPopup'>
			<div className='GameSettingsPopup-dialogueWrapper'>
				<div className='GameSettingsPopup-dialogue'>
					{/* Dialogue Title */}
					<div className='GameSettingsPopup-dialogueTitle'>
						<span>{pageUiTexts.settings}</span>
					</div>

					{/* Difficulty */}
					<div className='GameSettingsPopup-settings'>
						<div className='GameSettingsPopup-settingsTitle'>
							<span>{pageUiTexts.difficulty}</span>
						</div>
						{difficultyData.map((difficulty, index) => {
							let isCurrentChoice = false;

							if (currentDifficultySettings) {
								isCurrentChoice = difficulty.id === currentDifficultySettings;
							} else if (index === 0) {
								isCurrentChoice = true;
							}

							return (
								<div key={index} 
									className={'GameSettingsPopup-setting' + (isCurrentChoice ? ' current' : '')}
									onClick={() => {setCurrentDifficultySettings(difficulty.id);}}
								>
									<span>{difficulty.text}</span>
								</div>
							);
						})}
						
						{/* Time */}
						<div className='GameSettingsPopup-settingsTitle'>
							<span>{pageUiTexts.roundTime}</span>
						</div>
						{roundLengthData.map((time, index) => {
							let isCurrentChoice = false;

							if (currentRoundTimeSettings) {
								isCurrentChoice = time === currentRoundTimeSettings;
							} else if (index === 0) {
								isCurrentChoice = true;
							}

							let standard = '';
							if (index === 0) {
								standard = pageUiTexts.default;
							}

							return (
								<div key={index} 
									className={'GameSettingsPopup-setting' + (isCurrentChoice ? ' current' : '')}
									onClick={() => {handleSetCurrentRoundTime(time);}}
								>
									<span>{time + ' ' + pageUiTexts.minutes + standard}</span>
								</div>
							);
						})}

						<div className='GameSettingsPopup-combinedTime'>
							<span>{'Samlet spilletid ca. ' + combinedGameTime + ' min'}</span>
						</div>
					</div>

					<div className='GameSettingsPopup-button'>
						<Button 
							text={pageUiTexts.ok}
							classes={['flatBlue']}
							onClick={() => {handleSetGameSettings();}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

GameSettingsPopup.propTypes = {
	setGameSettings: PropTypes.func.isRequired,
};

export default GameSettingsPopup;