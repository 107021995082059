import Timer from 'components/ui/timer/timer';
import React from 'react';
import './build-timer.scss';
import PropTypes from 'prop-types';
import GameCode from 'components/ui/game-code/game-code';
import Progressbar from 'components/ui/progressbar/progressbar';
import GroupSidebar from 'components/ui/group-sidebar/group-sidebar';
import { gameStepsData } from 'data/game-steps-data';
import Button from 'components/ui/button/button';
import { pageUiTexts } from 'data/ui-texts';
import { roundLengthData } from 'data/settings-data';

const BuildTimer = ({handleGoToGameStep, setBackground, gameCode, groups, game}) => {
	setBackground('city');
	
	const roundNumber = gameStepsData.find((step) => {
		return step.id === game.gameStepId;
	}).roundNumber;

	return (
		<div className="BuildTimer">
			<div className='BuildTimer-headerWrapper'>
				<div className='BuildTimer-gameCode'>
					<GameCode gameCode={gameCode}/>
				</div>
				<div className='BuildTimer-progressbar'>
					<Progressbar roundNumber={roundNumber}/>

					<div className='BuildTimer-timer'>
						<Timer countDown={game.roundTime ? game.roundTime : roundLengthData[0]}/>
					</div>
				</div>
				<div className='BuildTimer-sidebar'>
					{/* We only show the updated capacities for the groups, once the results are shown */}
					<GroupSidebar showUpdatedCapacity={true} showingVoteFeedback={false} groups={groups}/>
				</div>
			</div>

			<Button
				text={pageUiTexts.finishRound}
				classes={['pushable']}
				isOnRelease={true}
				onClick={() => {handleGoToGameStep('next');}}
			/>
		</div>
	);
};

BuildTimer.propTypes = {
	game: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	gameCode: PropTypes.string.isRequired,
	setBackground: PropTypes.func.isRequired,
	handleGoToGameStep: PropTypes.func.isRequired,
};


export default BuildTimer;