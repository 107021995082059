const taskTemplates = require('../../task-templates');

let taskData = [
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 21,
		taskId: 'round-2b-who-and-when',
		type: 'multiple-choice',
		title: 'Hvem og Hvornår?',
		style: 'singleLine tall',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		texts: [
			{
				bidId: '8b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Hvem har designet bænken, og hvornår er vedkomne født?'
			},
			{
				bidId: '9b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Hvem har designet bænken, og hvornår er vedkomne født?'
			},
			{
				bidId: '10b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Hvem har designet møblet, og hvornår er vedkomne født?'
			},
			{
				bidId: '11b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Hvem har designet møblet, og hvornår er vedkomne født?'
			},
			{
				bidId: '12b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Hvem har designet møblet, og hvornår er vedkomne født?'
			},
			{
				bidId: '13b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Hvem har designet bænken, og hvornår er vedkomne født?'
			},
			{
				bidId: '14b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Hvem har designet bænken, og hvornår blev grundlæggeren født?'
			},
		],
		optionsList: [
			{
				bidId: '8b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Grete Jalk, 1920',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Jacob Pedersen, 1983',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Tove Kindt-Larsen, 1906',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Ukendt designer (RTS), 2020',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Vilhelm Lauritzen Arhcitects, 1894',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Henning Larsen Architects, 1925',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Hans Olsen, 1919',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Nanna Ditzel, 1923',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Jesper Trap, 1974',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: 'Aldo Leopold, 1887',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '9b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Grete Jalk, 1920',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Jacob Pedersen, 1983',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Tove Kindt-Larsen, 1906',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Ukendt designer (RTS), 2020',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Vilhelm Lauritzen Arhcitects, 1894',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Henning Larsen Architects, 1925',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Hans Olsen, 1919',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Nanna Ditzel, 1923',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Jesper Trap, 1974',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: 'Aldo Leopold, 1887',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '10b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Grete Jalk, 1920',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Jacob Pedersen, 1983',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Tove Kindt-Larsen, 1906',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Ukendt designer (RTS), 2020',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Vilhelm Lauritzen Arhcitects, 1894',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Henning Larsen Architects, 1925',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Hans Olsen, 1919',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Nanna Ditzel, 1923',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Jesper Trap, 1974',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: 'Aldo Leopold, 1887',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '11b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Grete Jalk, 1920',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Jacob Pedersen, 1983',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Tove Kindt-Larsen, 1906',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Ukendt designer (RTS), 2020',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Vilhelm Lauritzen Arhcitects, 1894',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Henning Larsen Architects, 1925',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Hans Olsen, 1919',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Nanna Ditzel, 1923',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Jesper Trap, 1974',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: 'Aldo Leopold, 1887',
						isCorrect: true,
					}),
				],
			},
			{
				bidId: '12b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Grete Jalk, 1920',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Jacob Pedersen, 1983',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Tove Kindt-Larsen, 1906',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Ukendt designer (RTS), 2020',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Vilhelm Lauritzen Arhcitects, 1894',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Henning Larsen Architects, 1925',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Hans Olsen, 1919',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Nanna Ditzel, 1923',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Jesper Trap, 1974',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: 'Aldo Leopold, 1887',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '13b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Grete Jalk, 1920',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Jacob Pedersen, 1983',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Tove Kindt-Larsen, 1906',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Ukendt designer (RTS), 2020',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Vilhelm Lauritzen Arhcitects, 1894',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Henning Larsen Architects, 1925',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Hans Olsen, 1919',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Nanna Ditzel, 1923',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Jesper Trap, 1974',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: 'Aldo Leopold, 1887',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '14b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: 'Grete Jalk, 1920',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: 'Jacob Pedersen, 1983',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: 'Tove Kindt-Larsen, 1906',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: 'Ukendt designer (RTS), 2020',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: 'Vilhelm Lauritzen Arhcitects, 1894',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: 'Henning Larsen Architects, 1925',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: 'Hans Olsen, 1919',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: 'Nanna Ditzel, 1923',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: 'Jesper Trap, 1974',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: 'Aldo Leopold, 1887',
						isCorrect: false,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 23,
		taskId: 'round-2b-what-measure',
		type: 'multiple-choice',
		title: 'Hvilket målestoksforhold?',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		texts: [
			{
				bidId: '8b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave. Tegningen er målfast! Det betyder at man kan regne med at de mål man tager på tegningen også passer med produktet når det er blevet lavet. Men hvad er målestoksforholdet på tegningen?'
			},
			{
				bidId: '9b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave.  Tegningen er målfast! Det betyder at man kan regne med at de mål man tager på tegningen også passer med produktet når det er blevet lavet. Men hvad er målestoksforholdet på tegningen?'
			},
			{
				bidId: '10b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave.  Tegningen er målfast! Det betyder at man kan regne med at de mål man tager på tegningen også passer med produktet når det er blevet lavet. Men hvad er målestoksforholdet på tegningen?'
			},
			{
				bidId: '11b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave.  Tegningen er målfast! Det betyder at man kan regne med at de mål man tager på tegningen også passer med produktet når det er blevet lavet. Men hvad er målestoksforholdet på tegningen?'
			},
			{
				bidId: '12b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave.  Tegningen er målfast! Det betyder at man kan regne med at de mål man tager på tegningen også passer med produktet når det er blevet lavet. Men hvad er målestoksforholdet på tegningen?'
			},
			{
				bidId: '13b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave.  Tegningen er målfast! Det betyder at man kan regne med at de mål man tager på tegningen også passer med produktet når det er blevet lavet. Men hvad er målestoksforholdet på tegningen?'
			},
			{
				bidId: '14b',
				text: 'På Tegningsmaterialet du har fået udleveret kan du finde værdifuld information som kan hjælpe dig med at løse denne opgave.  Tegningen er målfast! Det betyder at man kan regne med at de mål man tager på tegningen også passer med produktet når det er blevet lavet. Men hvad er målestoksforholdet på tegningen?'
			},
		],
		optionsList: [
			{
				bidId: '8b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10 og 1:20',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20 og 1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:5 og 1:10',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '1:20',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '9b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10 og 1:20',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20 og 1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:5 og 1:10',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '1:20',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '10b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10 og 1:20',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20 og 1:50',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:5 og 1:10',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '1:20',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '11b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10 og 1:20',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20 og 1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:5 og 1:10',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '1:20',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '12b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10 og 1:20',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20 og 1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:5 og 1:10',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '1:20',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '13b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10 og 1:20',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20 og 1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:5 og 1:10',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '1:20',
						isCorrect: true,
					}),
				],
			},
			{
				bidId: '14b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10 og 1:20',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20 og 1:50',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:5 og 1:10',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '1:20',
						isCorrect: false,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 26,
		taskId: 'round-2b-creation-date',
		type: 'multiple-choice',
		title: 'Oprettelsesdato',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		text: 'I tegningshovedet i nederste højre hjørne kan du se information om tegningen. Hvilken dato er Tegningen oprettet?',
		options: [			
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '13/12/2022',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '22/2/20023',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '15/3/2023',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '13/2/2023',
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 27,
		taskId: 'round-2b-artist',
		type: 'multiple-choice',
		title: 'Tegner',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		text: 'I tegningshovedet i nederste højre hjørne kan du se information om tegningen. Hvem har tegnet tegningen?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'JTR',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'JES',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'LRS',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'OBS',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'JCP',
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 28,
		taskId: 'round-2b-paper-format',
		type: 'multiple-choice',
		title: 'Papirformat',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		text: 'I tegningshovedet i nederste højre hjørne kan du se information om tegningen. Hvilket papirformat skal tegningen printes i?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'A5',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'A4',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'A3',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'A2',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'A1',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 29,
		taskId: 'round-2b-material-count',
		title: 'Antal materialer',
		images: [
			{
				bidId: '8b',
				image: 'garden-bench'
			},
			{
				bidId: '9b',
				image: 'couples-bench'
			},
			{
				bidId: '10b',
				image: 'stair-furniture'
			},
			{
				bidId: '11b',
				image: 'leopold-chair'
			},
			{
				bidId: '12b',
				image: 'table-bench-covered'
			},
			{
				bidId: '13b',
				image: 'table-bench-set'
			},
			{
				bidId: '14b',
				image: 'new-hospital'
			},
		],
		text: 'På tegningen er der et liste med materialer, vægt og CO2 belastning. Brug denne til at svare på spørgsmålet.	Hvor mange forskellige materialer er der på listen?',
		unit: null,
		answer: 8,
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round2-easy-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};