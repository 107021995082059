const taskTemplates = require('../../task-templates');

let taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 22,
		taskId: 'round-2-input-geometry',
		title: 'Geometri',
		text: 'Hvor mange symmetriakser er der i figuren?',
		image: 'geometry',
		answer: 8,
		unit: ''
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 31,
		taskId: 'round-2-multiple-opgave-31',
		title: 'Opgave 31',
		type: 'multiple-choice',
		images: [
			{
				bidId: 8,
				image: 'badebro2'
			},
			{
				bidId: 9,
				image: 'tilbygning2'
			},
			{
				bidId: 10,
				image: 'skaterRampe2'
			},
			{
				bidId: 11,
				image: 'binding2'
			},
			{
				bidId: 12,
				image: 'typehus2'
			},
			{
				bidId: 13,
				image: 'terrasse2'
			},
			{
				bidId: 14,
				image: 'gips2'
			},
		],
		text: 'Hvis du printer tegningen ud på et stykke A3 papir og måler 12 cm på tegningen. Hvor mange cm er det så i virkeligheden?',
		optionsList: [
			{
				bidId: 8,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '60 cm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '600 mm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '6 m',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '50 cm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '500 mm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '5 m',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '600 cm',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '500 cm', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 9,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '60 cm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '600 mm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '6 m',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '50 cm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '500 mm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '5 m',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '600 cm',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '500 cm', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 10,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '24 cm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '240 mm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '2.4 m',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '12 cm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '120 mm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '1.2m',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '2400 cm',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '120 cm', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 11,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '120 cm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1200 mmm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '12 m',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '60 cm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '600 mm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '6 m',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '1200 cm',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '60 cm', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 12,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '120 cm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1200 mmm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '12 m',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '60 cm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '600 mm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '6 m',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '1200 cm',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '60 cm', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 13,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '60 cm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '600 mm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '6 m',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '50 cm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '500 mm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '5 m',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '600 cm',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '500 cm', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 14,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '120 cm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1200 mm', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '12 m',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '60 cm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '600 mm',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '6 m',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '1200 cm',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '60 cm', 
						isCorrect: false,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 34,
		taskId: 'round-2-multiple-opgave-34',
		title: 'Opgave 34',
		type: 'multiple-choice',
		images: [
			{
				bidId: 8,
				image: 'badebro'
			},
			{
				bidId: 9,
				image: 'tilbygning'
			},
			{
				bidId: 10,
				image: 'skaterRampe'
			},
			{
				bidId: 11,
				image: 'binding'
			},
			{
				bidId: 12,
				image: 'typehus'
			},
			{
				bidId: 13,
				image: 'terrasse'
			},
			{
				bidId: 14,
				image: 'gips'
			},
		],
		text: 'Hvornår er tegningen blevet redigeret?',
		optionsList: [
			{
				bidId: 8,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '25 maj 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '12 juli 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '16. oktober 2021', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '18. marts 2020', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '15. August 2022', 
						isCorrect: true,
					}),
				],
			},
			{
				bidId: 9,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '25 maj 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '12 juli 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '16. oktober 2021', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '18. marts 2020', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '15. August 2022', 
						isCorrect: true,
					}),
				],
			},
			{
				bidId: 10,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '25 maj 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '12 juli 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '16. oktober 2021', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '18. marts 2020', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '15. August 2022', 
						isCorrect: true,
					}),
				],
			},
			{
				bidId: 11,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '25 maj 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '12 juli 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '16. oktober 2021', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '18. marts 2020', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '15. August 2022', 
						isCorrect: true,
					}),
				],
			},
			{
				bidId: 12,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '25 maj 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '12 juli 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '16. oktober 2021', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '18. marts 2020', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '15. August 2022', 
						isCorrect: true,
					}),
				],
			},
			{
				bidId: 13,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '25 maj 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '12 juli 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '16. oktober 2021', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '18. marts 2020', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '15. August 2022', 
						isCorrect: true,
					}),
				],
			},
			{
				bidId: 14,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '25 maj 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '12 juli 2022', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '16. oktober 2021', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '18. marts 2020', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '15. August 2022', 
						isCorrect: true,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 35,
		taskId: 'round-2-multiple-opgave-35',
		title: 'Opgave 35',
		type: 'multiple-choice',
		images: [
			{
				bidId: 8,
				image: 'badebro'
			},
			{
				bidId: 9,
				image: 'tilbygning'
			},
			{
				bidId: 10,
				image: 'skaterRampe'
			},
			{
				bidId: 11,
				image: 'binding'
			},
			{
				bidId: 12,
				image: 'typehus'
			},
			{
				bidId: 13,
				image: 'terrasse'
			},
			{
				bidId: 14,
				image: 'gips'
			},
		],
		text: 'Hvilket målestoksforhold er tegningen tegnet i?',
		optionsList: [
			{
				bidId: 8,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:100', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '04:20', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 9,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:100', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '04:20', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 10,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:100', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '04:20', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 11,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:100', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '04:20', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 12,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:100', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '04:20', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 13,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:100', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '04:20', 
						isCorrect: false,
					}),
				],
			},
			{
				bidId: 14,
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '1:10', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '1:20', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '1:50', 
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '1:100', 
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '04:20', 
						isCorrect: false,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		assignmentNumber: 37,
		taskId: 'round-2-spot-errors-drawing-info', 
		title: 'Tegningshovedet',
		images: [
			{
				bidId: 8,
				image: 'badebro'
			},
			{
				bidId: 9,
				image: 'tilbygning'
			},
			{
				bidId: 10,
				image: 'skaterRampe'
			},
			{
				bidId: 11,
				image: 'binding'
			},
			{
				bidId: 12,
				image: 'typehus'
			},
			{
				bidId: 13,
				image: 'terrasse'
			},
			{
				bidId: 14,
				image: 'gips'
			},
		],
		texts: [
			{
				bidId: 8,
				text: 'Hvor på tegningen sidder tegningshovedet, med informationer om tegningen?'
			},
			{
				bidId: 9,
				text: 'Hvor på tegningen sidder tegningshovedet, med informationer om tegningen?'
			},
			{
				bidId: 10,
				text: 'Hvor på tegningen sidder tegningshovedet, med informationer om tegningen?'
			},
			{
				bidId: 11,
				text: 'Hvor på tegningen sidder tegningshovedet, med informationer om tegningen?'
			},
			{
				bidId: 12,
				text: 'Hvor på tegningen sidder tegningshovedet, med informationer om tegningen?'
			},
			{
				bidId: 13,
				text: 'Hvor på tegningen sidder tegningshovedet, med informationer om tegningen?'
			},
			{
				bidId: 14,
				text: 'Hvor på tegningen sidder tegningshovedet, med informationer om tegningen?'
			},
		],
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'tegningshoved'
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 39,
		taskId: 'round-2-input-geometry-39',
		title: 'Geometri',
		type: 'multiple-choice',
		image: 'squares',
		text: 'Hvor mange kvadrater er der på billedet',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '4', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '6', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '10', 
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '12', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '14', 
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '8',  
				isCorrect: false,
			}),
		],
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round2-easy-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};