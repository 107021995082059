import React from 'react';
import PropTypes from 'prop-types';
import './game-code.scss';
import { pageUiTexts } from 'data/ui-texts';
import CornerLogo from '../corner-logo/corner-logo';

const GameCode = ({gameCode}) => {
	return (
		<div className='GameCode'>
			<CornerLogo/>
			{gameCode ? 
				<div className='GameCode-code'>
					<span>{pageUiTexts.gameCode + ': '}</span>
					<div className='GameCode-codeText'><span>{gameCode}</span></div>
				</div> : ''
			}
		</div>
	);
};

GameCode.propTypes = {
	gameCode: PropTypes.string,
};

export default GameCode;