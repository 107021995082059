/* Multiple choice */
const multipleChoiceTemplate = {
	mainTemplate: {
		assignmentNumber: null,
		title: '',
		bidId: 0,
		type: 'multiple-choice',
		isSolveToContinue: true,
		subtype: null, // null, images
		showNumberOfAnswersToSelect: true, // set to false to hide the "choose x answers"
		layout: null, // used if subtype is 'image'
		shuffleOptions: true,
		image: null,
		text: '',
		texts: [], // List of objects containing text and bid id
		options: [],
		optionsList: null,
		style: 'multiline', // singleLine
	},
	optionTemplate: {
		text: '', 
		isCorrect: false,
		effects: []
	}
};

/* Input-answer */
const inputAnswerTemplate = {
	mainTemplate: {
		assignmentNumber: null,
		taskId: '',
		title: '',
		type: 'input-answer',
		image: null,
		text: '',
		answer: null, // number
		unit: '',
		units: null, // null [{bidId: number, unit: string}]
		subtype: null, // null, range
		range: null, // null {min, max}
		ranges: null // null [{bidId: number, range: {min, max}}]
	}
}

/* Order */
const orderTemplate = {
	mainTemplate: {
		assignmentNumber: null,
		title: '',
		bidId: 0,
		type: 'order',
		isSolveToContinue: true,
		image: null,
		pdf: null,
		text: '',
		items: [],
		itemList: null // [{ bidId: null, items: [] }]
	},
	itemTemplate: {
		text: '',
		correctOrderNumbers: []
	}
};

/* Spot errors */
const spotErrorsTemplate = {
	mainTemplate: {
		assignmentNumber: null,
		title: '',
		type: 'spot-errors',
		isSolveToContinue: true,
		doneWhenAllErrorsSpotted: true,
		showDefaultResultPopup: true,
		image: null,
		images: [],
		linkFiles: [],
		texts: [],
		errors: [],
	},
	errorTemplate: {
		bidId: 0, // All bids
		effects: []
	}
};

/* match */
const matchTemplate = {
	mainTemplate: {
		taskId: '',
		title: '',
		type: 'match',
		options: [],
		answers: [],
		texts: [],
		instructions: '',
		text: '',
		assignmentNumber: 0,
		answerType: 'former', // former or værktøj
	},
	optionTemplate: {
		option: '', 
		id: 0,
		answers: []
	},
	answerTemplate: {
		id: 0,
		isText: true,
		text: '',
		imageSrc: '',
	}
}


export {
	multipleChoiceTemplate,
	orderTemplate,
	spotErrorsTemplate,
	inputAnswerTemplate,
	matchTemplate,
};
