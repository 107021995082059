import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDrag} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import './order-dnd-item.scss';

const OrderDndItem = ({isDraggable, itemIndex, itemData, classes}) => {
	/* Drag functionality */
	const [{ isDragging }, drag, preview] = useDrag({
		type: 'item',
		item: {type: 'item', index: itemIndex},
		canDrag() {return (isDraggable);},
		isDragging(monitor) {return (itemIndex === monitor.getItem().index);},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},
	});

	/* Hide browser-drawn drag preview image when dragging */
	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	});

	/* Opacity (invisible if dragging) */
	const opacity = (isDragging ? 0 : 1);

	/* Class name */
	let className = 'OrderDndItem';
	if (classes && classes.length > 0) {classes.forEach((c) => {className += ' ' + c;});}
	
	return (
		<div

			className={className}
			style={{opacity}}
		>
			{(itemData && itemData.text) && <span>{itemData.text}</span>}
			<div
				ref={drag}
				className={'OrderDndItem-handle' + (!isDraggable ? ' disabled' : '')}
			/>
		</div>
	);
};

OrderDndItem.propTypes = {
	isDraggable: PropTypes.bool.isRequired,
	itemIndex: PropTypes.number.isRequired,
	itemData: PropTypes.object.isRequired,
	classes: PropTypes.array,

};

export default OrderDndItem;
