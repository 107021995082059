import React from 'react';
import PropTypes from 'prop-types';
import './popup.scss';
import Button from '../button/button';
import { pageUiTexts } from 'data/ui-texts';

const Popup = ({
	title = pageUiTexts.popupTitle, 
	message = '',
	messageClass = '',
	onContinueButtonClick = (() => {}), 
	onCancelButtonClick, 
	showContinue = true,
	cancelButtonText = pageUiTexts.cancel,
	textAlign = 'center',
}) => {
	return (
		<div className='Popup'>
			<div className='Popup-overlay'/>
			<div className='Popup-dialogue'>
				<div className={'Popup-dialogueBox ' + textAlign}>
					<div className='Popup-title'>{title}</div>
					<div className={'Popup-message ' + messageClass} dangerouslySetInnerHTML={{__html: message}}/>
					{showContinue ? 
						<div className='Popup-buttons'>
							<Button
								classes={['popup']}
								text={cancelButtonText}
								onClick={() => {onCancelButtonClick();}}
							/>
							<Button
								classes={['popup empty']}
								text={pageUiTexts.continue}
								onClick={() => {onContinueButtonClick();}}
							/>
						</div>
						:
						<div className='Popup-button'>
							<Button
								classes={['popup']}
								text={cancelButtonText}
								onClick={() => {onCancelButtonClick();}}
							/>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

Popup.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string.isRequired,
	onContinueButtonClick: PropTypes.func,
	onCancelButtonClick: PropTypes.func.isRequired,
	showContinue: PropTypes.bool,
	cancelButtonText: PropTypes.string,
	textAlign: PropTypes.string,
	messageClass: PropTypes.string,
};

export default Popup;