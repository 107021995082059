const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 1,
		taskId: 'round-1b-area-calculation',
		title: 'Arealberegning',
		image: 'reactangle-area',
		texts: [
			{
				bidId: '1b',
				text: 'I skal bestille gulvbelægning til køkkenet og har brug for at vide hvor mange kvadratmeter der er. Du skal udfra de givne mål på plantegningen udregne Arealet'
			},
			{
				bidId: '2b',
				text: 'Da der skal laves et lydisoleret loft, har I brug for at vide hvor mange kvadratmeter studiet bliver. Du skal udfra de givne mål på plantegningen udregne Arealet'
			},
			{
				bidId: '3b',
				text: 'En del af entreprisen indebærer ny gulvbelægning i et af rummene. Du skal udfra de givne mål på plantegningen udregne Arealet'
			},
			{
				bidId: '4b',
				text: 'I garagen skal der indrettes et mindre skur. Du skal udfra de givne mål på plantegningen udregne Arealet'
			},
			{
				bidId: '5b',
				text: 'En del af entreprisen indebærer ny gulvbelægning i et af rummene. Du skal udfra de givne mål på plantegningen udregne Arealet'
			},
			{
				bidId: '6b',
				text: 'Hjemmekontoret skal have nyt gulv, til det skal der bestilles materialer. Du skal udfra de givne mål på plantegningen udregne Arealet'
			},
			{
				bidId: '7b',
				text: 'Opbevaringsrummet skal måles op inden I går i gang med opgaven. Du skal udfra de givne mål på plantegningen udregne Arealet'
			},
		],
		answer: 13,
		unit: 'm2'
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 2,
		taskId: 'round-1b-circle-area-calculation',
		title: 'Køkkenrenovering',
		image: 'circle-area',
		texts: [
			{
				bidId: '1b',
				text: 'En af møblerne i det nye køkken er en rund bordplade. I skal vide hvor mange kvadratmeter bordet er. Udregn arealet af cirklen udfra de givne mål. Pi fastsættes til 3,14 og facit angives med 2 decimaler'
			},
			{
				bidId: '2b',
				text: 'I studiet skal der laves en lydisoleret cirkel. Men hvor mange kvadratmeter er den? Udregn arealet af cirklen udfra de givne mål. Pi fastsættes til 3,14 og facit angives med 2 decimaler'
			},
			{
				bidId: '3b',
				text: 'En af dørene skal monteres i et rundt hul i facaden. Udregn arealet af cirklen udfra de givne mål. Pi fastsættes til 3,14 og facit angives med 2 decimaler'
			},
			{
				bidId: '4b',
				text: 'I Garragen skal etableres et rund ovenlys. Hvor mange kvadratmeter er hullet i taget? Udregn arealet af cirklen udfra de givne mål. Pi fastsættes til 3,14 og facit angives med 2 decimaler'
			},
			{
				bidId: '5b',
				text: 'En ad dørene skal monteres ind til et rundt rum. Hvoir mange kvadratmeter er rummet? Udregn arealet af cirklen udfra de givne mål. Pi fastsættes til 3,14 og facit angives med 2 decimaler'
			},
			{
				bidId: '6b',
				text: 'I den store væg skal der etableres et rund vindueshul. Hvor mange kvadratmeter er hullet? Udregn arealet af cirklen udfra de givne mål. Pi fastsættes til 3,14 og facit angives med 2 decimaler'
			},
			{
				bidId: '7b',
				text: 'Et gammelt trappehul ned til kælderen skal dækkes til. Hvor mange kvadratemeter er hullet? Udregn arealet af cirklen udfra de givne mål. Pi fastsættes til 3,14 og facit angives med 2 decimaler'
			},
		],
		answer: 12.56,
		unit: 'm2'
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 4,
		taskId: 'round-1b-price-list2',
		title: 'Hvad koster det',
		type: 'multiple-choice',
		style: 'singleLine tall',
		images: [
			{
				bidId: '1b',
				image: 'pricelist-1b'
			},
			{
				bidId: '2b',
				image: 'pricelist-2b'
			},
			{
				bidId: '3b',
				image: 'pricelist-3b'
			},
			{
				bidId: '4b',
				image: 'pricelist-4b'
			},
			{
				bidId: '5b',
				image: 'pricelist-5b'
			},
			{
				bidId: '6b',
				image: 'pricelist-6b'
			},
			{
				bidId: '7b',
				image: 'pricelist-6b'
			},
		],
		texts: [
			{
				bidId: '1b',
				text: 'Kunden er interresseret i prisforskellen hvis de vælger Alufronter i istedet for Træ/Linoleum-fronter. Hvor meget sparer kunden hvis de vælger alufronter istedet for træ/linoleum?'
			},
			{
				bidId: '2b',
				text: 'Kunden er interresseret i prisforskellen hvis de vælger Hvide plader i istedet for de træ-beklædte. Hvor meget sparer kunden hvis de vælger Hvide plader istedet for de træ-beklædte?'
			},
			{
				bidId: '3b',
				text: 'Kunden vil gerne spare lidt penge og have leveret dørene med ubehendlet overflade. Hvor meget spare kunden ved at få leveret dørene ubehandlede?'
			},
			{
				bidId: '4b',
				text: 'Kunden beder dig oplyde hvor meget de vil spare på at vælge ubehandlet fyr istedet for termobehandlet. Hvad er prisforskellen?'
			},
			{
				bidId: '5b',
				text: 'Kunden vil gerne spare lidt penge og have leveret dørene med ubehendlet overflade. Hvor meget sparer kunden ved at få leveret dørene ubehandlede?'
			},
			{
				bidId: '6b',
				text: 'Kunden beder dig oplyse hvor meget de vil spare ved at vælge laminat skriveborde istedet for massiv eg. Hvor meget sparer kunden ved at vælge skrivebord type 1 laminat istedet for Massiv eg?'
			},
			{
				bidId: '7b',
				text: 'Kunden beder dig oplyse hvor meget de vil spare ved at vælge laminat skriveborde istedet for massiv eg. Hvor meget sparer kunden ved at vælge skrivebord type 1 laminat istedet for Massiv eg?'
			},
		],
		optionsList: [
			{
				bidId: '1b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '9500',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '15500',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '16500',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '18500',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '6500',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '22780',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '18780',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '16780',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: '9780',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: '15780',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '2b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '11650',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '10500',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '12670',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '8760',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '10760',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '13760',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '15450',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '12300',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: '16300',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: '11760',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '3b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '12459,8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '13459,8',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '5549,7',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '20000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '14000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '12000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '10689,8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '20045,5',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: '13500',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: '15256',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '4b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '12459,8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '13459,8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '8630',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '20000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '14000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '12000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '10689,8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '20045,5',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: '13500',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: '15256',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '5b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '12459,8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '13459,8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '5549,7',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '8000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '8024,8',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '12000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '10689,8',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '20045,5',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 9,
						text: '13500',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 10,
						text: '15256',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '6b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '8000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '9000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '5000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '6000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '8500',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '10000',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '13000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '12000',
						isCorrect: false,
					}),
				],
			},
			{
				bidId: '7b',
				options: [
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 1,
						text: '8000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 2,
						text: '9000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 3,
						text: '5000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 4,
						text: '6000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 5,
						text: '8500',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 6,
						text: '10000',
						isCorrect: true,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 7,
						text: '13000',
						isCorrect: false,
					}),
					Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
						id: 8,
						text: '12000',
						isCorrect: false,
					}),
				],
			},
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 8,
		taskId: 'round-1b-big-enough',
		titles: [
			{
				bidId: '1b',
				title: 'Rumfang'
			},
			{
				bidId: '2b',
				title: 'Rumfang'
			},
			{
				bidId: '3b',
				title: 'Rumfang'
			},
			{
				bidId: '4b',
				title: 'Er den stor nok'
			},
			{
				bidId: '5b',
				title: 'Rumfang'
			},
			{
				bidId: '6b',
				title: 'Rumfang'
			},
			{
				bidId: '7b',
				title: 'Rumfang'
			},
		],
		images: [
			{
				bidId: '1b',
				image: 'lidless-box'
			},
			{
				bidId: '2b',
				image: 'lidless-box'
			},
			{
				bidId: '3b',
				image: 'lidless-box'
			},
			{
				bidId: '4b',
				image: 'cushion-box'
			},
			{
				bidId: '5b',
				image: 'lidless-box'
			},
			{
				bidId: '6b',
				image: 'lidless-box'
			},
			{
				bidId: '7b',
				image: 'lidless-box'
			},
		],
		texts: [
			{
				bidId: '1b',
				text: `Hvad er det indvendige rumfang af figuren?
				<br />(Svaret angives med 1 decimal)`
			},
			{
				bidId: '2b',
				text: `Hvad er det indvendige rumfang af figuren?
				<br />(Svaret angives med 1 decimal)`
			},
			{
				bidId: '3b',
				text: `Hvad er det indvendige rumfang af figuren?
				<br />(Svaret angives med 1 decimal)`
			},
			{
				bidId: '4b',
				text: `Hvad er det indvendige rumfang i hyndeboksen?
				<br />(Svaret angives med 1 decimal)`
			},
			{
				bidId: '5b',
				text: `Hvad er det indvendige rumfang af figuren?
				<br />(Svaret angives med 1 decimal)`
			},
			{
				bidId: '6b',
				text: `Hvad er det indvendige rumfang af figuren?
				<br />(Svaret angives med 1 decimal)`
			},
			{
				bidId: '7b',
				text: `Hvad er det indvendige rumfang af figuren?
				<br />(Svaret angives med 1 decimal)`
			},
		],
		answers: [
			{
				bidId: '1b',
				answer: 0.7
			},
			{
				bidId: '2b',
				answer: 0.7
			},
			{
				bidId: '3b',
				answer: 0.7
			},
			{
				bidId: '4b',
				answer: 1.3
			},
			{
				bidId: '5b',
				answer: 0.7
			},
			{
				bidId: '6b',
				answer: 0.7
			},
			{
				bidId: '7b',
				answer: 0.7
			},
		],
		unit: 'm3'
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 9,
		taskId: 'round-1b-calculate-costs',
		title: 'Hvad koster materialerne?',
		image: 'cushion-box2',
		text: 'For at give din kunde en pris er du nød til at vide hvad materialerne koster. Udregn den samlede pris for materialerne, på denne liste: (Svaret angivet i hele kroner)',
		answer: 4923,
		unit: 'kr'
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 10,
		taskId: 'round-1b-calculate-costs-materials',
		title: 'Hvad skal kunden give for materialerne?',
		image: 'cushion-box2',
		text: 'Du vil gerne tjene 20 procent på materialerne når du leverer dem. Hvad skal kunden give for materialerne',
		answer: 5907.612,
		unit: 'kr'
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 12,
		taskId: 'round-1b-area-calculation-2',
		title: 'Arealberegning',
		image: 'triangle-area',
		text: 'Du skal udfra de givne mål på figuren udregne Arealet af figuren',
		answer: 18,
		unit: 'm2'
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 13,
		taskId: 'round-1b-area-calculation-3',
		title: 'Arealberegning',
		image: 'triangle-area2',
		text: 'Du skal udfra de givne mål på figuren udregne Arealet af figuren',
		answer: 8,
		unit: 'm2'
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 16,
		taskId: 'round-1b-cost-of-blinds',
		title: 'Persienner',
		image: null,
		texts: [
			{
				bidId: '1b',
				text: 'Køkkenet har to vinduer, hver af dem er 92 cm bred og 122 cm høj. Kunden ønsker at installere persienner, der koster 242,- kr. per kvadratmeter. Hvor meget vil det koste at købe persienner til begge vinduer? (Svar i hele kroner)'
			},
			{
				bidId: '2b',
				text: 'I Studiet skal der installeres persienner i to vinduer. Vinduerne har målene 92x122 cm. De persienner kunden ønsker koster 242 kr. pr. kvadratmeter. Hvor meget vil det koste at købe persienner til begge vinduer? (Svar i hele kroner)'
			},
			{
				bidId: '3b',
				text: '4 af dørene, har et vindue, med målene 50x70 cm. Der skal installere 1 persienne på hver dør. Persiennerne koster 242 pr. kvadratmeter. Hvor meget vil det koste at købe de 4 persienner? (Svar i hele kroner)'
			},
			{
				bidId: '4b',
				text: 'I garagen skal der installeres persienner i to vinduer. Vinduerne har målene 92x122 cm. De persienner kunden ønsker koster 242 kr. pr. kvadratmeter. Hvor meget vil det koste at købe persienner til begge vinduer? (Svar i hele kroner)'
			},
			{
				bidId: '5b',
				text: '4 af dørene, har et vindue, med målene 50x70 cm. Der skal installere 1 persienne på hver dør. Persiennerne koster 242 pr. kvadratmeter. Hvor meget vil det koste at købe de 4 persienner? (Svar i hele kroner)'
			},
			{
				bidId: '6b',
				text: 'I kontoret skal der installeres persienner i to vinduer. Vinduerne har målene 92x122 cm. De persienner kunden ønsker koster 242 kr. pr. kvadratmeter. Hvor meget vil det koste at købe persienner til begge vinduer? (Svar i hele kroner)'
			},
			{
				bidId: '7b',
				text: 'I kontoret skal der installeres persienner i to vinduer. Vinduerne har målene 92x122 cm. De persienner kunden ønsker koster 242 kr. pr. kvadratmeter. Hvor meget vil det koste at købe persienner til begge vinduer? (Svar i hele kroner)'
			},
		],
		answers: [
			{
				bidId: '1b',
				answer: 543
			},
			{
				bidId: '2b',
				answer: 543
			},
			{
				bidId: '3b',
				answer: 339
			},
			{
				bidId: '4b',
				answer: 543
			},
			{
				bidId: '5b',
				answer: 339
			},
			{
				bidId: '6b',
				answer: 543
			},
			{
				bidId: '7b',
				answer: 543
			},
		],
		unit: 'kr'
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 17,
		taskId: 'round-1b-cost-of-tabletop',
		title: 'Bordplade',
		image: null,
		texts: [
			{
				bidId: '1b',
				text: 'Køkkenet har en kvadratisk ø med en sidelængde på 120 cm. Kunden ønsker at installere en granitbordplade, der koster 1200 kr. per kvadratmeter. I tager 400 kr pr. kvadratmeter for at installere bordpladen. Hvor meget vil det koste at købe og installere granitbordpladen til øen?'
			},
			{
				bidId: '2b',
				text: 'I Studiet er der en kvadratisk bordplade med en sidelængde på 120 cm. Kunden ønsker at installere en Hårdtræsbordplade der koster 1200 kr. pr. kvadratmeter. I tager 400 kr. pr kvadratmeter for at installere og måle op til pladen. Hvor meget vil det koste kunden at få købt og installeret bordpladen?'
			},
			{
				bidId: '3b',
				text: 'Kunden har bedt om pris på en ekstra opgave. Som er levering og montering af en kvadratisk bordplade med en sidelængde på 120 cm. Kunden ønsker at installere en Hårdtræsbordplade der koster 1200 kr. pr. kvadratmeter. I tager 400 kr. pr kvadratmeter for at installere og måle op til pladen. Hvor meget vil det koste kunden at få købt og installeret bordpladen?'
			},
			{
				bidId: '4b',
				text: 'I garagen er der en kvadratisk bordplade med en sidelængde på 120 cm. Kunden ønsker at installere en Hårdtræsbordplade der koster 1200 kr. pr. kvadratmeter. I tager 400 kr. pr kvadratmeter for at installere og måle op til pladen. Hvor meget vil det koste kunden at få købt og installeret bordpladen?'
			},
			{
				bidId: '5b',
				text: 'Kunden har bedt om pris på en ekstra opgave. Som er levering og montering af en kvadratisk bordplade med en sidelængde på 120 cm. Kunden ønsker at installere en Hårdtræsbordplade der koster 1200 kr. pr. kvadratmeter. I tager 400 kr. pr kvadratmeter for at installere og måle op til pladen. Hvor meget vil det koste kunden at få købt og installeret bordpladen?'
			},
			{
				bidId: '6b',
				text: 'I kontoret er der en kvadratisk bordplade med en sidelængde på 120 cm. Kunden ønsker at installere en Hårdtræsbordplade der koster 1200 kr. pr. kvadratmeter. I tager 400 kr. pr kvadratmeter for at installere og måle op til pladen. Hvor meget vil det koste kunden at få købt og installeret bordpladen?'
			},
			{
				bidId: '7b',
				text: 'I kælderen er der en kvadratisk bordplade med en sidelængde på 120 cm. Kunden ønsker at installere en Hårdtræsbordplade der koster 1200 kr. pr. kvadratmeter. I tager 400 kr. pr kvadratmeter for at installere og måle op til pladen. Hvor meget vil det koste kunden at få købt og installeret bordpladen?'
			},
		],
		answer: 2304,
		unit: 'kr'
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate,{
		taskId: 'round-2-match-wood2',
		title: 'Identificer materialerne',
		type: 'match',
		answerType: 'materialelære',
		instructions: 'sæt de rigtige betegnelser på billederne',
		assignmentNumber: 19,
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Gevindstænger', 
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tagsten', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Mursten', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Mineraluld', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Fugemasse', 
				id: 5,
				answers: [5]
			}),

			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Hængsel', 
				id: 6,
				answers: [6]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Tømmer', 
				id: 7,
				answers: [7]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Lister', 
				id: 8,
				answers: [8]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Beklædningsbrædder', 
				id: 9,
				answers: [9]
			}),Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: 'Vinkelbeslag', 
				id: 10,
				answers: [10]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: 'Gevindstænger',
				imageSrc: 'gevindstænger.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: 'Tagsten',
				imageSrc: 'tagsten.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: 'Tegl',
				imageSrc: 'tegl.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: 'Mineraluld',
				imageSrc: 'mineraluld.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: 'Fugemasse',
				imageSrc: 'fugemasse.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: 'Hængsel',
				imageSrc: 'hængsel.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 7,
				isText: false,
				text: 'Tømmer',
				imageSrc: 'tømmer.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 8,
				isText: false,
				text: 'Lister',
				imageSrc: 'lister.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 9,
				isText: false,
				text: 'Beklædningsbrædder',
				imageSrc: 'beklædningsbrædder.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 10,
				isText: false,
				text: 'Vinkelbeslag',
				imageSrc: 'vinkelbeslag.svg',
			}),
		],
		text: 'Som håndværker er det også  vigtigt at kende de materialer man arbejder med....',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 20,
		taskId: 'round-1b-power-cost-2',
		type: 'multiple-choice',
		image: 'energysticker',
		titles: [
			{
				bidId: '1b',
				title: 'Nyt Køleskab'
			},
			{
				bidId: '2b',
				title: 'Studiets elforbrug'
			},
			{
				bidId: '3b',
				title: 'Hvad sparer kunden?'
			},
			{
				bidId: '4b',
				title: 'Hvad sparer kunden?'
			},
			{
				bidId: '5b',
				title: 'Hvad sparer kunden?'
			},
			{
				bidId: '6b',
				title: 'Hvad sparer kunden?'
			},
			{
				bidId: '7b',
				title: 'Hvad sparer kunden?'
			},
		],
		texts: [
			{
				bidId: '1b',
				text: 'Kunden ønsker at udskifte det gamle køleskab. Det nye køleskab har et årligt forbrug på 450 kilowatt-timer, hvor det gamle køleskab brugte 600 kilowatt-timer om året. Hvor mange penge vil kunden spare om året, hvis en kilowatt-time koster 3,5 kr.'
			},
			{
				bidId: '2b',
				text: 'Kunden har fundet to forskellige sæt A/V udstyr til studiet. Det dyreste sæt har et årligt EL-forbrug på 450 kilowatt-timer. Det billigere sæt har et årligt EL-forbrug på 600 kilowatt-timer. Hvor mange penge vil kunden spare om året ved at købe det dyreste sæt, hvis en kilowatt-time koster 3,5 kr?'
			},
			{
				bidId: '3b',
				text: 'På nogle af dørene skal der installeres dørpumper der åbner dørene automatisk. De dyre dørpumper har et årligt strømforbrug på 450 kilowatt-timer, hvor de billige har et årligt strømforbrug på 600 kilowatt-timer om året. Hvor mange penge vil kunden spare om året, ved at købe de dyre dørpumper, hvis en kilowatt-time koster 3,5 kr.'
			},
			{
				bidId: '4b',
				text: 'Kunden ønsker at få installeret en portåbner, og har fundet to modeller. Den dyre model har et årligt forbrug på 450 kilowatt-timer, hvor den lidt billigere model bruger 600 kilowatt-timer om året. Hvor mange penge vil kunden spare om året, ved køb af den dyre model, hvis en kilowatt-time koster 3,5 kr.'
			},
			{
				bidId: '5b',
				text: 'På nogle af dørene skal der installeres dørpumper der åbner dørene automatisk. De dyre dørpumper har et årligt strømforbrug på 450 kilowatt-timer, hvor de billige har et årligt strømforbrug på 600 kilowatt-timer om året. Hvor mange penge vil kunden spare om året, hved at købe de dyre dørpumper, hvis en kilowatt-time koster 3,5 kr.'
			},
			{
				bidId: '6b',
				text: 'Kunden ønsker at udskifte et gammelt køleskab. Det nye køleskab har et årligt forbrug på 450 kilowatt-timer, hvor det gamle køleskab brugte 600 kilowatt-timer om året. Hvor mange penge vil kunden spare om året, hvis en kilowatt-time koster 3,5 kr.'
			},
			{
				bidId: '7b',
				text: 'Kunden ønsker at udskifte et gammelt køleskab. Det nye køleskab har et årligt forbrug på 450 kilowatt-timer, hvor det gamle køleskab brugte 600 kilowatt-timer om året. Hvor mange penge vil kunden spare om året, hvis en kilowatt-time koster 3,5 kr.'
			},
		],
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '425',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '325',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '125',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '525',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '538',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '725',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: '925',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: '625',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 9,
				text: '400',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 10,
				text: '500',
				isCorrect: false,
			}),
		],
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round1-medium-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};