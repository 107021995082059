import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {getCookie, setCookie} from 'helpers/cookie-helper';
import Login from './login';
import FacilitatorController from 'components/users/facilitator-controller';
import GroupController from 'components/users/group-controller';
import PropTypes from 'prop-types';
import './login.scss';

class LoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isLoggedIn: false,
			activeLoginBox: 'group',
			userId: null,
			isFacilitator: false,
			isAdmin: false,
			email: null,
		};
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Check if logged in */
		this.checkIfLoggedIn();
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	}

	/**
	 * Check if user is logged in
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user) {
				/* Ok to cookies */
				const cookieConsent = getCookie(appConfig.cookiesAcceptedCookieName);
				if (!cookieConsent) {
					setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
				}

				const isFacilitator = user.email !== null;
				if (isFacilitator) {
					/* Logged in as facilitator */
					this.setState({
						isLoggedIn: true,
						isLoading: false,
						userId: user.uid,
						isFacilitator: isFacilitator,
						email: user.email
					});
				} else {
					/* Logged in as group */
					this.setState({isLoggedIn: true, isLoading: false, userId: user.uid});
				}
			} else {
				this.setState({
					isLoading: false,
					isLoggedIn: false,
					userId: null,
					isFacilitator: false,
					isAdmin: false,
					email: null,
				});
			}
		});	
	}

	/**
	 * Logout
	 */
	handleLogout = () => {
		this.props.setBackground('city');
		this.props.setBackgroundGroupId(null);
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false,
			userId: null,
			isFacilitator: false,
			isAdmin: false,
			email: null,
		});
	};

	/**
	 * Switch between facilitator / group login box
	 * @param {string} activeLoginBox 
	 */
	setActiveLoginBox = (activeLoginBox) => {
		this.setState({activeLoginBox});
	}

	/**
 	* Render component
 	*/
	render() {	
		/* User is not loggged in */
		if (!this.state.isLoggedIn) {
			return (
				<Login 
					activeLoginBox={this.state.activeLoginBox}
					setActiveLoginBox={this.setActiveLoginBox}
					setGame={this.setGame}
					setBackgroundGroupId={this.props.setBackgroundGroupId}
					setPlayMusic={this.props.setPlayMusic}
					musicIsPlaying={this.props.musicIsPlaying}
				/>
			);
		}

		/* User is logged in, get controller component for facilitator / group */
		let UserComponent = (this.state.isFacilitator ? FacilitatorController : GroupController);
		return (
			<UserComponent 
				setBackground={this.props.setBackground}
				userId={this.state.userId}
				handleLogout={this.handleLogout}
				setBoughtItems={this.props.setBoughtItems}
				setBackgroundGroupId={this.props.setBackgroundGroupId}
				setPlayMusic={this.props.setPlayMusic}
				musicIsPlaying={this.props.musicIsPlaying}
			/>
		);
	}
}

LoginController.propTypes = {
	setBackground: PropTypes.func.isRequired,
	setBoughtItems: PropTypes.func.isRequired,
	setBackgroundGroupId: PropTypes.func.isRequired,
	setPlayMusic: PropTypes.func.isRequired,
	musicIsPlaying: PropTypes.bool.isRequired,
};

export default LoginController;