const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 5,
		taskId: 'round-1a-boards',
		title: 'Hvor mange brædder',
		unit: '',
		answers: [
			{
				bidId: '1a',
				answer: 80
			},
			{
				bidId: '2a',
				answer: 100
			},
			{
				bidId: '3a',
				answer: 84
			},
			{
				bidId: '4a',
				answer: 80
			},
			{
				bidId: '5a',
				answer: 48
			},
			{
				bidId: '6a',
				answer: 60
			},
			{
				bidId: '7a',
				answer: 92
			},
		],
		texts: [
			{
				bidId: '1a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. På hver side af legehuset skal der bruges 20 meter brædder. Der er 4 sider på huset. Hvor mange meter brædder skal det bruges i alt?'
			},
			{
				bidId: '2a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. På hver side af Brændeskuret skal der bruges 25 meter brædder. Der er 4 sider på huset. Hvor mange meter brædder skal det bruges i alt?'
			},
			{
				bidId: '3a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. Der skal laves et lille hegn bag ved de to mål. Der skal bruges 42 meter brædder til hvert hegn. Hvor mange meter brædder skal det bruges ialt?'
			},
			{
				bidId: '4a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. På hver side af legehuset skal der bruges 20 meter brædder. Der er 4 sider på huset. Hvor mange meter brædder skal det bruges i alt?'
			},
			{
				bidId: '5a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. På legepladsen skal der bygges et lille legehus. På hver side af legehuset skal der bruges 12 meter brædder. Der er 4 sider på huset. Hvor mange meter brædder skal det bruges i alt?'
			},
			{
				bidId: '6a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. I legeværelset skal der monteres klatregreb på alle væggene. På hver væg skal der monteres 15 greb. Der er 4 vægge i rummet. Hvor mange Greb skal der monteres ialt?'
			},
			{
				bidId: '7a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. På hver side af hønsehuset skal der bruges 23 meter brædder. Der er 4 sider på huset. Hvor mange meter brædder skal det bruges i alt?'
			}
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 6,
		taskId: 'round-1a-screws',
		title: 'Hvor mange skruer',
		unit: '',
		answers: [
			{
				bidId: '1a',
				answer: 160
			},
			{
				bidId: '2a',
				answer: 200
			},
			{
				bidId: '3a',
				answer: 168
			},
			{
				bidId: '4a',
				answer: 160
			},
			{
				bidId: '5a',
				answer: 96
			},
			{
				bidId: '6a',
				answer: 120
			},
			{
				bidId: '7a',
				answer: 184
			},
		],
		texts: [
			{
				bidId: '1a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. For hver meter brædt der skal bruges skal der bruge 2 skruer. Der skal bruges 80 meter brædder. Hvor mange skruer skal der bruges ialt?'
			},
			{
				bidId: '2a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. For hver meter brædt der skal bruges skal der bruge 2 skruer. Der skal bruges 100 meter brædder. Hvor mange skruer skal der bruges ialt?'
			},
			{
				bidId: '3a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. For hver meter brædt der skal bruges skal der bruge 2 skruer. Der skal bruges 84 meter brædder. Hvor mange skruer skal der bruges ialt?'
			},
			{
				bidId: '4a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. For hver meter brædt der skal bruges skal der bruge 2 skruer. Der skal bruges 80 meter brædder. Hvor mange skruer skal der bruges ialt?'
			},
			{
				bidId: '5a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. For hver meter brædt der skal bruges skal der bruge 2 skruer. Der skal bruges 48 meter brædder. Hvor mange skruer skal der bruges ialt?'
			},
			{
				bidId: '6a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. Til hvert klatregreb skal der bruges 2 skruer. Der er 60 greb. Hvor mange skruer skal der bruges ialt?'
			},
			{
				bidId: '7a',
				text: 'I skal regne ud hvor mange materialer der skal bruges til opgaven. For hver meter brædt der skal bruges skal der bruge 2 skruer. Der skal bruges 92 meter brædder. Hvor mange skruer skal der bruges ialt?'
			}
		],
	}),
	Object.assign({}, taskTemplates.matchTemplate.mainTemplate, {
		assignmentNumber: 7,
		taskId: 'round-1a-fractions',
		title: 'Brøker',
		type: 'match',
		answerType: 'fractions-a1',
		instructions: 'Det er godt at have styr på brøker og andele når man skal være håndværker.',
		text: 'Match brøkerne med de rigtige billeder',
		options: [
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '1/2',
				id: 1,
				answers: [1]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '2/6', 
				id: 2,
				answers: [2]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '3/5', 
				id: 3,
				answers: [3]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '5/6', 
				id: 4,
				answers: [4]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '1/4', 
				id: 5,
				answers: [5]
			}),
			Object.assign({}, taskTemplates.matchTemplate.optionTemplate,{
				option: '2/3', 
				id: 6,
				answers: [6]
			}),
		],
		answers: [
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 1,
				isText: false,
				text: '1/2',
				imageSrc: 'set-a-1/fractions/half.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 2,
				isText: false,
				text: '2/6',
				imageSrc: 'set-a-1/fractions/two-sixths.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 3,
				isText: false,
				text: '3/5',
				imageSrc: 'set-a-1/fractions/three-fifth.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 4,
				isText: false,
				text: '5/6',
				imageSrc: 'set-a-1/fractions/five-sixth.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 5,
				isText: false,
				text: '1/4',
				imageSrc: 'set-a-1/fractions/quater.svg',
			}),
			Object.assign({}, taskTemplates.matchTemplate.answerTemplate, {
				id: 6,
				isText: false,
				text: '2/3',
				imageSrc: 'set-a-1/fractions/two-thirds.svg',
			}),
		],
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 9,
		taskId: 'round-1a-multiple-diagrams',
		title: 'Aflæs søjlediagram',
		type: 'multiple-choice',
		image: 'girls-diagram',
		text: 'På Teknisk skole kan man blive uddannet Håndværker efter 9 klasse. Brug brug diagrammet her til at svare på spørgsmålet. Hvor mange piger startede på Grundforløbet på Hillerød Tekniske skole i August 2020',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 2,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 9,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 38,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 11,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 12,
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 13,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: 15,
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: 18,
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 10,
		taskId: 'round-1-order-pricing-boards',
		title: 'Priser pr m',
		text: `Til opgaven skal der inkøbes brædder. Men I skal hjælpe kunden med at finde ud af hvad der er billigst. Opsæt de forskellige bræddder sådan at de billigste står øverst og de dyreste står nederst.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Forskallingsbrædder 545 øre pr. m', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: 'Rupløjede brædder: 10,95 kr. pr m.', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: 'Blokhusbrædder: 12,95 kr. pr. m', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Klinkbeklædningsbrædder: 1695 øre pr. m', correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Cumaru Beklædningslister: 35,- pr. m', correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, text: 'Sortmalet Gran: 54,95 kr. pr. m', correctOrderNumbers: [6]
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 11,
		taskId: 'round-1-order-pricing-screws',
		title: 'Priser pr. stk.',
		text: `Til opgaven skal der inkøbes skruer. Men hvilke skruer er billigst. Opsæt de forskellige skruer sådan at de billigste står øverst og de dyreste står nederst.`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: '3.5x41 mm Gipsskruer: 13 øre pr. stk', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: '5.0x60 mm Spånskruer: 68 øre pr stk', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: '5.0x100 mm Spånskruer: 1 krone pr stk', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: '5.2X56 mm Rustfri terrasseskruer: 1,1 kroner pr stk', correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: '5x50 mm Beslagskruer: 102 øre pr. stk', correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, text: '10x200 mm Franske skruer: 11.37 kroner pr. stk', correctOrderNumbers: [6]
			}),
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 12,
		taskId: 'round-1a-input-circumference',
		title: 'Omkredsen af en figur',
		image: 'figure-area',
		text: 'Billedet viser en figur med rette vinkler. Hvor stor er omkredsen af figuren? (Angiv svaret med 2 decimaler)',
		unit: 'm',
		answer: 30.26,
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 17,
		taskId: 'round-1a-input-triangle',
		title: 'Arealet af en figur',
		image: 'figure-triangle',
		text: 'Billedet viser en retvinklet trekant. Hvor stort er arealet af trekanten?',
		unit: 'cm2',
		answer: 1800,
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 18,
		taskId: 'round-1a-input-triangle-2',
		title: 'Omkredsen af en figur',
		image: 'figure-triangle-2',
		text: 'Billedet viser en retvinklet trekant. Omkredsen af trekanten?',
		unit: 'cm',
		answer: 204,
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round1-medium-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};