const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 3,
		taskId: 'round-1b-price-list',
		title: 'Hvad koster det',
		images: [
			{
				bidId: '1b',
				image: 'pricelist-1'
			},
			{
				bidId: '2b',
				image: 'pricelist-2'
			},
			{
				bidId: '3b',
				image: 'pricelist-3'
			},
			{
				bidId: '4b',
				image: 'pricelist-4'
			},
			{
				bidId: '5b',
				image: 'pricelist-5'
			},
			{
				bidId: '6b',
				image: 'pricelist-6'
			},
			{
				bidId: '7b',
				image: 'pricelist-6'
			},
		],
		texts: [
			{
				bidId: '1b',
				text: `Når man laver køkken skal der bestilles fronter til skuffer og skabe. I skal beregne den samlede pris på følgend fronter udfra skemaet herunder.
				<br/>Bestillingsseddel:
				<br/>Alle fronter er Træ/Linoleum-front:
				<br/>8 stk 40 x 10 cm
				<br/>4 stk 60 x 10 cm
				<br/>4 stk 60 x 20 cm
				<br/>4 stk 60 x 40 cm
				<br/>3 stk 80 x 10 cm
				<br/>3 stk 80 x 20 cm
				<br/>3 stk 80 x 40 cm
				<br/>6 stk 60 x 60 cm`
			},
			{
				bidId: '2b',
				text: `Til Studiet skal der indkøbes Akustikplader der forbedrer lyden i rummet. I skal beregne den samlede pris på plader udfra prislisten her.
				<br/>Bestillingsseddel:
				<br/>Alle pladerne skal være træ-beklædt:
				<br/>4 stk 40 x 40 cm
				<br/>3 stk 40 x 80 cm
				<br/>5 stk 60 x 60 cm
				<br/>7 stk 80 x 100 cm
				<br/>2 stk 80 x 160 cm`
			},
			{
				bidId: '3b',
				text: `I denne entreprise er der to forskellige døre der skal bestilles, og de er i forskellige mål. Beregn prisen udfra denne prisliste og denne bestillingsseddel:
				<br/>Bestillingsseddel:
				<br/>Type 1 døre:
				<br/>1 stk 200 x 95 cm
				<br/>2 stk 230 x 110 cm
				<br/>
				<br/>Type 2 døre:
				<br/>2 stk 200 x 80 cm
				<br/>1 stk 215 x 95 cm
				<br/>1 stk 230 x 110 cm`
			},
			{
				bidId: '4b',
				text: `På den nyetablerede garage skal der monteres beklædningsbrædder. Der er flere forskellige profilder der skal indkøbes. Beregn den totale pris for alle brædderne udfra denne prisliste og denne bestillingsseddel.
				<br/>Bestillingsseddel:
				<br/>Alle brædder skal være termobehandlede:
				<br/>16 x 100 mm: 250 m
				<br/>25 x 100 mm: 80 m
				<br/>25 x 150 mm: 120 m
				<br/>32 x 125 mm: 100 m`
			},
			{
				bidId: '5b',
				text: `I denne entreprise er der to forskellige døre der skal bestilles, og de er i forskellige mål. Beregn prisen udfra denne prisliste og denne bestillingsseddel:
				<br/>Betillingsseddel:
				<br/>Type 1 døre:
				<br/>3 stk 200 x 95 cm
				<br/>2 stk 230 x 110 cm

				<br/>Type 2 døre:
				<br/>1 stk 200 x 80 cm
				<br/>3 stk 215 x 95 cm
				<br/>1 stk 230 x 110 cm`
			},
			{
				bidId: '6b',
				text: `Til hjemmekontoret skal der indkøbes, både hylder og et par skriveborde. Beregn den samlede pris udfra denne prisliste og denne bestillingsseddel:
				<br/>Bestillingsseddel:
				<br/>Skrivebord type 1:
				<br/>1 stk Masiv Eg 80x160 cm
				<br/>1 stk Masiv Eg 80x120 cm
				<br/>Hylder type 1:
				<br/>3 stk 90x20 cm
				<br/>3 stk 160x30 cm
				<br/>8 stk 210x40 cm
				<br/>Hylder type 2:
				<br/>3 stk 210x30 cm
				<br/>2 stk 90x20 cm`
			},
			{
				bidId: '7b',
				text: `Opbevaringsrummet skal indrettes med hylder.
					<br/>Beregn den samlede pris udfra denne prisliste og denne bestillingsseddel:
					<br/>Bestillingsseddel:
					<br/>Skrivebord type 1: 
					<br/>1 stk Masiv Eg 80x160 cm
					<br/>1 stk Masiv Eg 80x120 cm
					<br/>Hylder type 1:
					<br/>3 stk 90x20 cm
					<br/>3 stk 160x30 cm
					<br/>8 stk 210x40 cm
					<br/>Hylder type 2:
					<br/>3 stk 210x30 cm
					<br/>2 stk 90x20 cm`
			},
		],
		answers: [
			{
				bidId: '1b',
				answer: 47450
			},
			{
				bidId: '2b',
				answer: 26560
			},
			{
				bidId: '3b',
				answer: 67299
			},
			{
				bidId: '4b',
				answer: 15160
			},
			{
				bidId: '5b',
				answer: 40124
			},
			{
				bidId: '6b',
				answer: 21840
			},
			{
				bidId: '7b',
				answer: 21840
			},
		],
		unit: 'm2'
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 14,
		taskId: 'round-1b-purchase-packages',
		titles: [
			{
				bidId: '1b',
				title: 'Flisebeklædning'
			},
			{
				bidId: '2b',
				title: 'Lydpanel'
			},
			{
				bidId: '3b',
				title: 'Sparkeplader'
			},
			{
				bidId: '4b',
				title: 'Flisebeklædning'
			},
			{
				bidId: '5b',
				title: 'Sparkeplader'
			},
			{
				bidId: '6b',
				title: 'Lydpanel'
			},
			{
				bidId: '7b',
				title: 'Flisebeklædning'
			},
		],
		image: null,
		texts: [
			{
				bidId: '1b',
				text: 'Kunden ønsker at installere en ny flisevæg bag vasken i køkkenet. Væggen er 3,3 m lang og 55 cm høj. De fliser, de har valgt, kommer i pakker af 10, og hver pakke dækker 0,8 kvadratmeter. Hvor mange pakker fliser skal de købe? (Husk man kun kan købe hele pakker)'
			},
			{
				bidId: '2b',
				text: 'I Podcast-studiet skal der intalleres et lydabsorberende panel. Panelet måler 330x55 cm. Lydpanelerne kommer i pakker af 10, og hver pakke dækker 0.8 kvadratmeter. Hvor mange pakker skal der købes? (Husk man kun kan købe hele pakker)'
			},
			{
				bidId: '3b',
				text: '4 af dørene skal have installere en rustfri plade i bunden af døren. Pladerne kommer i pakker af 5 stk i hver. Der skal installere på begge sider af de 4 døre. Hvor mange pakker skal der købes ind? (Husk man kun kan købe hele pakker)'
			},
			{
				bidId: '4b',
				text: 'En af væggene skal have installeret fliser. Området er 3,3 m lang og 55 cm høj. De fliser, de har valgt, kommer i pakker af 10, og hver pakke dækker 0,8 kvadratmeter. Hvor mange pakker fliser skal de købe? (Husk man kun kan købe hele pakker)'
			},
			{
				bidId: '5b',
				text: '4 af dørene skal have installere en rustfri plade i bunden af døren. Pladerne kommer i pakker af 5 stk i hver. Der skal installere på begge sider af de 4 døre. Hvor mange pakker skal der købes ind? (Husk man kun kan købe hele pakker)'
			},
			{
				bidId: '6b',
				text: 'I Hjemmekontoret skal der intalleres et lydabsorberende panel. Panelet måler 330x55 cm. Lydpanelerne kommer i pakker af 10, og hver pakke dækker 0.8 kvadratmeter. Hvor mange pakker skal der købes? (Husk man kun kan købe hele pakker)'
			},
			{
				bidId: '7b',
				text: 'En af væggene skal have installeret fliser. Området er 3,3 m lang og 55 cm høj. De fliser, de har valgt, kommer i pakker af 10, og hver pakke dækker 0,8 kvadratmeter. Hvor mange pakker fliser skal de købe? (Husk man kun kan købe hele pakker)'
			},
		],
		answers: [
			{
				bidId: '1b',
				answer: 3
			},
			{
				bidId: '2b',
				answer: 3
			},
			{
				bidId: '3b',
				answer: 2
			},
			{
				bidId: '4b',
				answer: 3
			},
			{
				bidId: '5b',
				answer: 2
			},
			{
				bidId: '6b',
				answer: 3
			},
			{
				bidId: '7b',
				answer: 3
			},
		],
		unit: 'pakker'
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 15,
		taskId: 'round-1b-cost-of-electricity',
		title: 'Hvad koster det at tænde lyset?',
		image: null,
		texts: [
			{
				bidId: '1b',
				text: 'Kunden ønsker at installere ny belysning i køkkenet. Hver lysarmatur kræver 60 watt strøm. Hvis køkkenet har 8 lysarmaturer, og elektricitet koster 3,5 kr per kilowatt-time. Hvad vil det koste at køre lysene i 5 timer om dagen i en måned? (En måned sættes til 30 dage) (Svaret angives med en decimal)'
			},
			{
				bidId: '2b',
				text: 'Der skal installleres lys i studiet. Hver lysarmatur kræver 60 watt strøm. Studiet har 8 lysarmaturer, og elektricitet koster 3,5 kr per kilowatt-time. Hvad vil det koste at køre lysene i 5 timer om dagen i en måned? (En måned sættes til 30 dage) (Svaret angives med en decimal)'
			},
			{
				bidId: '3b',
				text: 'En del af entreprisen er at installere lys omkring dørene. Hver lysarmatur kræver 60 watt strøm. Der skal installeres 8 lysarmaturer, og elektricitet koster 3,5 kr per kilowatt-time.  Hvad vil det koste at køre lysene i 5 timer om dagen i en måned? (En måned sættes til 30 dage) (Svaret angives med en decimal)'
			},
			{
				bidId: '4b',
				text: 'I garagen skal der installeres lyskilder. Hver lysarmatur kræver 60 watt strøm. Der skal installeres 8 lysarmaturer, og elektricitet koster 3,5 kr per kilowatt-time.  Hvad vil det koste at køre lysene i 5 timer om dagen i en måned? (En måned sættes til 30 dage) (Svaret angives med en decimal)'
			},
			{
				bidId: '5b',
				text: 'En del af entreprisen er at installere lys omkring dørene. Hver lysarmatur kræver 60 watt strøm. Der skal installeres 8 lysarmaturer, og elektricitet koster 3,5 kr per kilowatt-time.  Hvad vil det koste at køre lysene i 5 timer om dagen i en måned? (En måned sættes til 30 dage) (Svaret angives med en decimal)'
			},
			{
				bidId: '6b',
				text: 'En del af entreprisen er at installere lys i kontoret. Hver lysarmatur kræver 60 watt strøm. Der skal installeres 8 lysarmaturer, og elektricitet koster 3,5 kr per kilowatt-time.  Hvad vil det koste at køre lysene i 5 timer om dagen i en måned? (En måned sættes til 30 dage) (Svaret angives med en decimal)'
			},
			{
				bidId: '7b',
				text: 'En del af entreprisen er at installere lys omkring dørene. Hver lysarmatur kræver 60 watt strøm. Der skal installeres 8 lysarmaturer, og elektricitet koster 3,5 kr per kilowatt-time.  Hvad vil det koste at køre lysene i 5 timer om dagen i en måned? (En måned sættes til 30 dage) (Svaret angives med en decimal)'
			},
		],
		answers: [
			{
				bidId: '1b',
				answer: 31.5
			},
			{
				bidId: '2b',
				answer: 31.5
			},
			{
				bidId: '3b',
				answer: 31.5
			},
			{
				bidId: '4b',
				answer: 31.5
			},
			{
				bidId: '5b',
				answer: 31.5
			},
			{
				bidId: '6b',
				answer: 31.5
			},
			{
				bidId: '7b',
				answer: 31.5
			},
		],
		unit: 'kr'
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round1-hard-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};