const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 47,
		taskId: 'round-3b-concrete-and-cement',
		title: 'Beskæftigelse i byggeri',
		linkFile: 'employment',
		images: null,
		text: `Danmarks Statistik er den centrale myndighed for dansk statistik. Deres opgave er at indsamle, bearbejde og offentliggøre statistiske oplysninger om det danske samfund. Læs denne artikel og svar på spørgsmålet. Hvad var "byggebeskæftigelsen ved udgangen af 2021?"`,
		unit: null,
		answer: 190100
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 51,
		taskId: 'round-3b-percentage-calculation',
		title: 'Momsregnskab',
		images: null,
		text: 'Moms er en forkortelse for meromsætningsafgift, dog bruges ordet merværdiafgift i momsloven. Dette er en dansk afgift på alle varer solgt i Danmark. Afgiften er på 25% af varens eller ydelsen. På denne opgave skal der faktureres 215 timer Timeprisen er 385 ekskl. moms Hvad er den fakturerede pris inkl. moms? (Svaret skal angives med 2 decimaler)',
		unit: 'kr',
		answer: 103468.75
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 55,
		taskId: 'round-3b-geometry',
		title: 'Rumfang',
		image: 'boxGeometry',
		text: 'Udfra denne figur skal du svare på spørgsmålet. Hvad er figurens rumfang? (Svaret angives i cm3)',
		unit: 'cm3',
		answer: 857375
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 56,
		taskId: 'round-3b-geometry2',
		title: 'Rumfang',
		image: 'cylinderGeometry',
		text: 'Udfra denne figur skal du svare på spørgsmålet.	Hvad er figurens rumfang? (Svaret angives i m3, med 2 decimaler)',
		unit: 'm3',
		answer: 1.53
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 57,
		taskId: 'round-3b-statistics',
		type: 'multiple-choice',
		title: 'Aflæs kurve',
		image: 'statistics',
		text: 'Illustrationen viser omsætningen inden for byggeri og Anlæg. Den er fordelt i 4 sektorer: Nybbyggeri, Anlægsvirksomhed, Reparation og vedligehold og Anden virksomhed. Hvor stor var omsætningen inden for nybyggeri i 4. kvt 2020?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '20.000 mio. kr.',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '30.000 mio. kr.',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '40.000 mio. kr.',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '50.000 mio. kr.',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '60.000 mio. kr.',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '70.000 mio. kr.',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 58,
		taskId: 'round-3b-statistics2',
		type: 'multiple-choice',
		title: 'Aflæs kurve',
		image: 'statistics',
		text: 'Illustrationen viser omsætningen inden for byggeri og Anlæg. Den er fordelt i 4 sektorer: Nybbyggeri, Anlægsvirksomhed, Reparation og vedligehold og Anden virksomhed. Hvor stor var omsætningen inden for Reparation og vedligehold i 2. kvt. 2022',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '20.000 mio. kr.',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '30.000 mio. kr.',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '40.000 mio. kr.',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '50.000 mio. kr.',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '60.000 mio. kr.',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '70.000 mio. kr.',
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 59,
		taskId: 'round-3b-education-sites',
		type: 'multiple-choice',
		title: 'Uddannelsessteder',
		image: 'educationMap',
		style: 'singleLine',
		text: 'På uddannesesguiden kan man finde diverse information omkring mange forskellige uddannelser. gå på ug.dk og find svaret på dette spørgsmål. Hvor i landet bliver tagdækker-uddannelsen udbudt?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: 'U/Nord Hillerød og Tech College Ålborg',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: 'CELF Nykøbing og UC Syd Esbjerg',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: 'Skive College og Svendborg Erhvervsskole',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: 'Syddansk Erhvervsskole og EUC Syd Aabenraa',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: 'NEXT Uddannelse København og Aarhus Tech',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: 'Learnmark Tech og EUC Nordvestsjælland',
				isCorrect: true,
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 60,
		taskId: 'round-3b-education-sites2',
		type: 'multiple-choice',
		title: 'Uddannelsessteder',
		image: 'educationMap',
		text: 'På uddannesesguiden kan man finde diverse information omkring mange forskellige uddannelser. Gå på ug.dk og find svaret på dette spørgsmål. Hvor mange skoler i Danmark udbyder Grundforløb i Træfagenes Byggeuddannelse (Tømrer)?',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '4',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '42',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '25',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '36',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '55',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '45',
				isCorrect: false,
			}),
		]
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round3-hard-2-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};