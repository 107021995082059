import React, {useState} from 'react';
import CookieConsentController from 'components/cookie-consent/cookie-consent-controller';
import LoginFacilitatorController from './login-facilitator-controller';
import RequestPasswordController from './request-password-controller';
import CreateUserController from './create-user-controller';
import LoginGroupController from './login-group-controller';
import './login.scss';
import PlayButton from 'components/ui/playButton/playButton';
import PropTypes from 'prop-types';

const Login = ({setPlayMusic, musicIsPlaying}) => {
	/* Get active box component for facilitator box */
	let [facilitatorBoxType, setFacilitatorBoxType] = useState('login');

	let [choosingGroup, setChoosingGroup] = useState(false);

	let [loginAsFacilitator, setLoginAsFacilitator] = useState(true);

	let FacilitatorBoxComponent = LoginFacilitatorController;
	if (facilitatorBoxType === 'createUser') FacilitatorBoxComponent = CreateUserController;
	if (facilitatorBoxType === 'resetPassword') FacilitatorBoxComponent = RequestPasswordController;

	if (choosingGroup) {
		setPlayMusic(false);
	}

	return (
		<div className="Login">
			{!choosingGroup ?
				<div className='Login-titleWrapper'>
					<div className='Login-title'/>
				</div> : ''
			}

			<div className="Login-box">
				{loginAsFacilitator && !choosingGroup ?
					<FacilitatorBoxComponent 
						setLoginAsFacilitator={setLoginAsFacilitator} 
						setFacilitatorBoxType={setFacilitatorBoxType}
					/>
					:
					<LoginGroupController 
						setLoginAsFacilitator={setLoginAsFacilitator} 
						setChoosingGroups={setChoosingGroup}
					/>
				}
			</div>

			<CookieConsentController />
			{loginAsFacilitator &&
				<PlayButton setPlayMusic={setPlayMusic} musicIsPlaying={musicIsPlaying}/>
			}
		</div>
	);
};

Login.propTypes = {
	setPlayMusic: PropTypes.func.isRequired,
	musicIsPlaying: PropTypes.bool.isRequired,
};

export default Login;