import React from 'react';
import PropTypes from 'prop-types';
import {pageUiTexts} from 'data/ui-texts';

import Button from 'components/ui/button/button';
import './delete-game-popup.scss';

const DeleteGamePopup = ({isDeletingGame, gameId, toggleDeleteGamePopup, handleDeleteGame}) => {
	let text = pageUiTexts.deleteGame.text;

	return (
		<div className="DeleteGamePopup" onClick={() => {toggleDeleteGamePopup(null);}}>
			<div className="DeleteGamePopup-content" onClick={(e) => {e.stopPropagation();}}>
				<div className="DeleteGamePopup-header">
					<div className="DeleteGamePopup-title">
						{pageUiTexts.deleteGame.title}
					</div>
				</div>
				<div className="DeleteGamePopup-body">
					<div className="DeleteGamePopup-text">{text}</div>
					<div className="DeleteGamePopup-buttons">
						<Button
							classes={['flatBlue']}
							text={pageUiTexts.deleteGame.cancel}
							onClick={() => {toggleDeleteGamePopup(null);}}
						/>
						<Button
							classes={['popup empty']}
							isDisabled={isDeletingGame}
							isLoading={isDeletingGame}
							text={pageUiTexts.deleteGame.delete}
							onClick={() => {handleDeleteGame(gameId);}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

DeleteGamePopup.propTypes = {
	isDeletingGame: PropTypes.bool.isRequired,
	gameId: PropTypes.string,
	toggleDeleteGamePopup: PropTypes.func.isRequired,
	handleDeleteGame: PropTypes.func.isRequired,
};

export default DeleteGamePopup;
