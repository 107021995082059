import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { pageUiTexts } from 'data/ui-texts';
import DeleteGamePopup from './delete-game-popup';
import './settings.scss';
import Button from 'components/ui/button/button';
import MaterialsPopup from 'components/ui/materials-popup/materials-popup';
import GameSettingsPopup from 'components/ui/game-settings-popup/game-settings-popup';
import PlayButton from 'components/ui/playButton/playButton';

const Settings = (props) => {
	let {
		showDeleteGamePopup,
		isDeletingGame,
		gameId,
		currentGame,
		toggleDeleteGamePopup,
		handleCreateGame,
		handleDeleteGame,
		handleGoToGame,
		handleLogout,
		setPlayMusic,
		musicIsPlaying,
	} = props;

	const [materialsPopupIsOpen, setMaterialsPopupIsOpen] = useState(false);

	const [gameSettingsPopupIsOpen, setGameSettingsPopupIsOpen] = useState(false);

	const handleStartNewGame = () => {
		// If a game already exists, popup is opened warning about game deletion
		if (currentGame) {
			toggleDeleteGamePopup(currentGame.id);
			return;
		}
		createNewGame();
	};

	const createNewGame = () => {
		// We make a new game, and start it upon success
		handleCreateGame('new Game').then(
			(success) => {
				// Success
				setGameSettingsPopupIsOpen(true);
			}
		);
	};

	const handleSetGameSettings = (settings) => {
		setGameSettingsPopupIsOpen(false);
		handleGoToGame(currentGame.id, settings);
	};

	return (
		<div className="Settings">
			<div className='Settings-titleWrapper'>
				<div className='Settings-title'/>
			</div>

			{materialsPopupIsOpen && <MaterialsPopup closePopup={() => {setMaterialsPopupIsOpen(false);}}/>}

			<div className='Settings-dialogueWrapper'>
				<div className='Settings-gameWrapper'>
					<div className='Settings-game'>
						<div className='Settings-gameHeader'>
							<div className='Settings-gameHeaderTitle'>
								<span>{pageUiTexts.settingDialogueTitle}</span>
							</div>
							<div className='Settings-gameHeaderText'>
								<span>{pageUiTexts.settingsDialogueMessage}</span>
							</div>
						</div>

						<div className='Settings-startGameButton'>
							{/* Start game */}
							<Button
								text={pageUiTexts.startGame}
								classes={['flatBlue']}
								onClick={() => {handleStartNewGame();}}
							/>
						</div>
						<div className='Settings-newGameButton'>
							{/* New Game */}
							<Button
								isDisabled={currentGame === null || currentGame === undefined}
								text={pageUiTexts.continueGame}
								classes={['popup empty']}
								onClick={() => {if (currentGame) handleGoToGame(currentGame.id);}}
							/>
						</div>
						<div className='Settings-materialsButton'>
							{/* Materials */}
							<Button
								text={pageUiTexts.materials}
								classes={['popup empty']}
								onClick={() => {setMaterialsPopupIsOpen(true);}}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='Settings-navigation'>
				<Button
					text={pageUiTexts.logout}
					classes={['logout']}
					onClick={() => {handleLogout();}}
				/>
			</div>

			{/* Game settings popup */}
			{gameSettingsPopupIsOpen && 
				<GameSettingsPopup setGameSettings={handleSetGameSettings}/>
			}

			{/* Delete game popup */}
			{(showDeleteGamePopup && gameId) && 
				<DeleteGamePopup 
					isDeletingGame={isDeletingGame}
					gameId={gameId}
					toggleDeleteGamePopup={toggleDeleteGamePopup}
					handleDeleteGame={() => {handleDeleteGame(gameId); createNewGame();}}
				/>}
			<PlayButton setPlayMusic={setPlayMusic} musicIsPlaying={musicIsPlaying}/>
		</div>
	);
};

Settings.propTypes = {
	showDeleteGamePopup: PropTypes.bool.isRequired,
	isDeletingGame: PropTypes.bool.isRequired,
	gameId: PropTypes.string,
	currentGame: PropTypes.object,
	toggleDeleteGamePopup: PropTypes.func.isRequired,
	handleCreateGame: PropTypes.func.isRequired,
	handleDeleteGame: PropTypes.func.isRequired,
	handleGoToGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	setPlayMusic: PropTypes.func.isRequired,
	musicIsPlaying: PropTypes.bool.isRequired,
};

export default Settings;
