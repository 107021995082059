import React from 'react';
import PropTypes from 'prop-types';
import './confetti-cannon.scss';

const ConfettiCannon = ({colors = null, confettiAmount = 100}) => {
	let confettiArray = [];

	// Generates confetti up until the amount given. 
	// All confetti variables are random, except color IF colors have been defined (colors array)
	for (let i = 0; i < confettiAmount; i++) {
		if (!colors) {
			confettiArray.push({
				key: i, 
				positionX: Math.random(), 
				randomDelay: Math.random(),
				randomDuration: Math.random(),
				randomR: parseInt(Math.random() * 255),
				randomG: parseInt(Math.random() * 255),
				randomB: parseInt(Math.random() * 255)
			});
		} else {
			confettiArray.push({
				key: i, 
				positionX: Math.random(), 
				randomDelay: Math.random(),
				randomDuration: Math.random()
			});
		}
	}

	return (
		<div className='ConfettiCannon'>
			<div className='ConfettiCannon-confettiWrapper'>
				{confettiArray.map((confetti) => {
					let randomColorIndex = null;
					if (colors) {
						randomColorIndex = parseInt(Math.random() * colors.length);
					}

					return (
						<div key={confetti.key} 
							className='ConfettiCannon-fallingConfetti'
							style={{
								left: confetti.positionX * 98 + '%', 
								animationDuration: confetti.randomDuration * 5 + 2 + 's',
								animationDelay: confetti.randomDelay * 5 + 's',
							}}>
							<div 
								className='ConfettiCannon-rotatingConfetti'
								style={{
									animationDelay: confetti.randomDelay * 1 + 's',
									animationDuration: confetti.randomDuration + 0.3 + 's',
									backgroundColor: randomColorIndex ? colors[randomColorIndex] :
										'rgb(' + 
										confetti.randomR + ',' + 
										confetti.randomG + ',' + 
										confetti.randomB + ')'
								}}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

ConfettiCannon.propTypes = {
	// colors takes an array of hex values, example: ['#DDA926', '#97B5BA', '#BC977B']
	// If no colors are given, then random colors are generated instead.
	colors: PropTypes.array,
	confettiAmount: PropTypes.number
};

export default ConfettiCannon;