const imagesData = [
	// IMAGES
	'cloud.svg',
	'colon.svg',
	'progress-bar.svg',
	'woodlabLogo.svg',
	'woodlabTitle.svg',

	// ICONS
	'icons/cart1.svg',
	'icons/cart2.svg',
	'icons/icon-arrow-cross.svg',
	'icons/icon-checkmark.svg',
	'icons/icon-checkmark2.svg',
	'icons/icon-checkmark3.svg',
	'icons/icon-close.svg',
	'icons/icon-download.svg',
	'icons/icon-loading.svg',
	'icons/icon-logout.svg',
	'icons/icon-magnefyingglass.svg',
	'icons/icon-minus.svg',
	'icons/icon-pause.svg',
	'icons/icon-play.svg',
	'icons/icon-plus.svg',
	'icons/icon-quality-gold.svg',
	'icons/icon-quality-none.svg',
	'icons/icon-quality.svg',
	'icons/icon-startVideo.svg',
	'icons/icon-sustainability-gold.svg',
	'icons/icon-sustainability-none.svg',
	'icons/icon-sustainability.svg',
	'icons/icon-workenvironment-gold.svg',
	'icons/icon-workenvironment-none.svg',
	'icons/icon-workenvironment.svg',
	'icons/trophy-gold.svg',
	'icons/van1.svg',
	'icons/van2.svg',

	// LOGOS
	'logos/cphgamelab_Logo.svg',
	'logos/fogs-fond-logo.svg',
	'logos/unord_logo.svg',

	// Assignment
	'assignments/trash/bloed-plast.svg',
	'assignments/trash/deponi.svg',
	'assignments/trash/gips.svg',
	'assignments/trash/haard-plast.svg',
	'assignments/trash/metal.svg',
	'assignments/trash/ubehandlet-trae.svg',
	'assignments/area-rectangle-1.svg',
	'assignments/assignment-12-angle.svg',
	'assignments/badebro.svg',
	'assignments/bænkhammer.svg',
	'assignments/bænkvinkel.svg',
	'assignments/beklædningsbrædder.svg',
	'assignments/bindingsvaerk.svg',
	'assignments/butiksindretning.svg',
	'assignments/geometry/calculate-circumference-55.svg',
	'assignments/geometry/geometry.svg',
	'assignments/geometry/triangle-angle.svg',
	'assignments/cirkel.svg',
	'assignments/efterisolering.svg',
	'assignments/elipse.svg',
	'assignments/fnugrulle.svg',
	'assignments/fugemasse.svg',
	'assignments/fuksvans.svg',
	'assignments/gevindstænger.svg',
	'assignments/gipsvaegge.svg',
	'assignments/gulv.svg',
	'assignments/hængsel.svg',
	'assignments/hegn.svg',
	'assignments/geometry/hexagon.svg',
	'assignments/icon-check-mark-assignment.svg',
	'assignments/icon-error-check-mark-assignment.svg',
	'assignments/kløfthammer.svg',
	'assignments/koben.svg',
	'assignments/kridtsnor.svg',
	'assignments/kvadrat.svg',
	'assignments/lægtehammer.svg',
	'assignments/ligesidet-trekant.svg',
	'assignments/lister.svg',
	'assignments/mineraluld.svg',
	'assignments/passer.svg',
	'assignments/pentagon.svg',
	'assignments/polygon.svg',
	'assignments/rektangel.svg',
	'assignments/retvinkel-trekant.svg',
	'assignments/round-1-multiple-beregning.svg',
	'assignments/screws.svg',
	'assignments/skateboardrampe.svg',
	'assignments/tagsten.svg',
	'assignments/tegl.svg',
	'assignments/tilbygning.svg',
	'assignments/tømmer.svg',
	'assignments/tømrervinkel.svg',
	'assignments/traeterrasse.svg',
	'assignments/trapez.svg',
	'assignments/trappe.svg',
	'assignments/trekant.svg',
	'assignments/typehus.svg',
	'assignments/vatterpas.svg',
	'assignments/vinduesudskiftning.svg',
	'assignments/vinkelbeslag.svg',
];

export default imagesData;