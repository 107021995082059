import React from 'react';
import PropTypes from 'prop-types';
import {pageUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import { groupData } from 'data/group-data';
import GroupBox from 'components/ui/group-box/group-box';
import CornerLogo from 'components/ui/corner-logo/corner-logo';

const LoginGroup = (props) => {
	const {
		isLoading,
		code,
		feedback,
		groups,
		game,
		handleInput,
		handleFindGame,
		handleGetLoginToken,
		setLoginAsFacilitator
	} = props;

	/* Input game code */
	if (groups === null) {
		return (
			<div className="LoginGroup">
				<div className='Login-formTabs'>
					<div className='Login-formTab active' onClick={() => {setLoginAsFacilitator(false);}}>
						{pageUiTexts.studentLogin}
					</div>
					<div className='Login-formTab' onClick={() => {setLoginAsFacilitator(true);}}>
						{pageUiTexts.teacherLogin}
					</div>
				</div>
				<form className="Login-form" onSubmit={handleFindGame}>
					<div className="Login-header">
						<span>{pageUiTexts.typeCode}</span>
						<div className='Login-inputWrapper'>
							<input
								name="code"
								type="text"
								placeholder={pageUiTexts.gameCode} 
								autoComplete="section-grouplogin code"
								value={code ? code : ''}
								onChange={(event)=>{handleInput(event);}}
							/>
						</div>
					</div>
					<div className='Login-spacing'/>
					<Button
						isLoading={isLoading}
						classes={['login']}
						text={pageUiTexts.login} 
						onClick={handleFindGame} 
					/>
					<div className="Login-errorMessage">{feedback}</div>
					<div className='Login-gameCodeMessage'>
						{pageUiTexts.gameCodeMessage}
					</div>
				</form>
			</div>
		);
	}
	
	/* Select group */
	return (
		<div className="LoginChooseGroup">
			<div className='LoginChooseGroup-cornerLogo'><CornerLogo/></div>
			<div className='LoginChooseGroup-title'><span>{pageUiTexts.chooseGroup}</span></div>
			<div className='LoginChooseGroup-groupsWrapper'>
				<div className="Login-groups">
					{groups.map((group, index)=>{
						if (game && game.gameStepId !== 'gameIntro' && !group.isPlaying) {
							return (<div key={index}/>);
						}

						const groupObject = groupData.find((data) => {
							return data.id === group.id;
						});

						return (
							<div key={index} className='Login-groupSelect'>
								<div className='Login-groupBox'>
									<GroupBox 
										group={group} 
										groupName={groupObject.name} 
										isButton={true} 
										buttonFunction={() => {handleGetLoginToken(group.docId);}}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

LoginGroup.propTypes = {
	game: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	code: PropTypes.any,
	feedback: PropTypes.string,
	groups: PropTypes.any,
	handleInput: PropTypes.func.isRequired,
	handleFindGame: PropTypes.func.isRequired,
	handleGetLoginToken: PropTypes.func.isRequired,
	setLoginAsFacilitator: PropTypes.func.isRequired,
};

export default LoginGroup;