const taskTemplates = require('../../task-templates');

const taskData = [
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 21,
		taskId: 'round-2-order-wall-construction',
		title: 'Rækkefølge',
		image: 'wooden-construction',
		text: `Billedet viser en vægkonstruktion. I skal opstille materialerne i den rigtige rækkefølge. Fra Maling + Spartelmasse til Regnskærm af fyrretræ`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Spartelmasse + maling', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: '2x13 mm gips', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: '70 mm mineraluld + træforskalling', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Dampspærre', correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: '245 mm Mineraluld + konstruktionstræ', correctOrderNumbers: [5]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 6, text: '9 mm vindspærreplade', correctOrderNumbers: [6]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 7, text: 'Træforskalling (ventilleret hulrum)', correctOrderNumbers: [7]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 8, text: 'Regnskærm Fyrrretræ', correctOrderNumbers: [8]
			}),
		]
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		assignmentNumber: 23,
		taskId: 'round-2-order-wall-construction-2',
		title: 'Rækkefølge',
		image: 'concrete-construction',
		text: `Billedet viser en vægkonstruktion. I skal opstille materialerne i den rigtige rækkefølge. Fra Puds + kalk-gips til Regnskærm tegl`,
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1, text: 'Puds + kalk-gips', correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2, text: '200 mm armeret beton', correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3, text: '275 mm mineraluld + træforskalling', correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4, text: 'Træforskalling (ventilleret hulrum)', correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5, text: 'Regnskærm tegl', correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 26,
		taskId: 'round-2-scale-ratio',
		title: 'Hvad er målestoksforhold?',
		text: 'Hvis målestoksforholdet på en tegning er 1:20 betyder det at 1 centimeter på tegningen er 20 centimeter i virkeligheden. Hvor mange cm er 25 cm målt på tegningen så i virkeligheden?',
		answer: 500,
		unit: 'cm',
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 29,
		taskId: 'round-2-scale-ratio-2',
		title: 'Hvad er målet?',
		text: 'Hvis målestoksforholdet på en tegning er 1:50 betyder det at 1 centimeter på tegningen er 50 centimeter i virkeligheden. Hvis du måler 8,5 cm på tegningen hvor mange cm svarer det så til i virkeligheden?',
		answer: 425,
		unit: 'cm',
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 30,
		taskId: 'round-2-multiple-choice-read-and-understand',
		title: 'Læs og Forstå',
		text: 'Tabellerne her er et udsnit fra en teknisk tegning. Brug informationerne i tabellerne til at svare på spørgsmålet. Hvilke målestoksforhold er tegningen tegnet i?',
		linkFile: 'tabelTegningshoved',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '1:10',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '1:5',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '1:10 og 1:20',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '1:5 og 1:10',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '1:50 og 1:200',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '1:20',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: '1:100',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 8,
				text: '1:50',
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 33,
		taskId: 'round-2-wood-screw-amount',
		title: 'Tegningsforståelse',
		text: 'Tabellerne her er et udsnit fra en teknisk tegning. Brug informationerne i tabellerne til at svare på spørgsmålet. Hvor mange Træskruer (5,0x45 mm) bliver der brugt?',
		linkFile: 'tabelTegningshoved',
		answer: 6,
		unit: null,
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		assignmentNumber: 34,
		taskId: 'round-2-multiple-choice-long-leg-weight',
		title: 'Tegningsforståelse',
		text: 'Tabellerne her er et udsnit fra en teknisk tegning. Brug informationerne i tabellerne til at svare på spørgsmålet. Hvad vejer de Lange Ben (25x125x845) pr stk?',
		linkFile: 'tabelTegningshoved',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: '1.12',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: '1.1',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: '1',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: '6.69',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: '1.98',
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: '0.015',
				isCorrect: false,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7,
				text: '2.04',
				isCorrect: false,
			}),
		],
	}),
	Object.assign({}, taskTemplates.inputAnswerTemplate.mainTemplate, {
		assignmentNumber: 38,
		taskId: 'round-2-the-sum-2',
		title: 'Summen',
		text: 'Se på denne talrække: 2, 5, 8, 12, 15, 19, 22, 35, 40. Hvad er Summen af de lige tal i denne talrække?',
		answer: 84,
		unit: null,
	}),
];

let taskIdsArr = [];
taskData.forEach((task, index) => {
	/* Assign unique id used for navigation */
	task.id = 'round2-medium-' + (index + 1 < 10 ? '0' : '') + (index + 1);

	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});

export default {
	taskData
};