import React from 'react';
import PropTypes from 'prop-types';
import './attention.scss';
import { pageUiTexts} from 'data/ui-texts';
import CornerLogo from 'components/ui/corner-logo/corner-logo';

const Attention = ({handleLogout}) => {
	return (
		<div className="Attention">
			<div className='Attention-cornerLogo'>
				<CornerLogo/>
			</div>
			<div className='Attention-textWrapper'>
				<div className='Attention-text'>
					<span>{pageUiTexts.attention}</span>
				</div>
			</div>
		</div>
	);
};

Attention.propTypes = {
	handleLogout: PropTypes.func.isRequired,
};

export default Attention;