import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};
/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyAo_ESO1JqnlZgGGi5o6J2CnYOcqQNetfI',
		authDomain: 'cgl-woodlab-test.firebaseapp.com',
		projectId: 'cgl-woodlab-test',
		storageBucket: 'cgl-woodlab-test.appspot.com',
		messagingSenderId: '817252858185',
		appId: '1:817252858185:web:8eaeaceb4627c9e9484298'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyADozcgwU8kEW9S76rcEvbvcpLwgZNwPB0',
		authDomain: 'cgl-woodlab-pro.firebaseapp.com',
		projectId: 'cgl-woodlab-pro',
		storageBucket: 'cgl-woodlab-pro.appspot.com',
		messagingSenderId: '466194840816',
		appId: '1:466194840816:web:71a143d67269c90357d635'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;