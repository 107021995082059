import React, {useState, useEffect} from 'react';
import './app.scss';
import firebaseInit from 'firebase-init';
import LoginController from 'components/login/login-controller';
import FrontPage from './frontpage/frontpage';
import ImageLoader from './image-loader/image-loader';
import Background from './ui/background/background';
import AdminLoginController from './admin/admin-login-controller';
import woodlabConstruction from '../assets/audio/woodlab-construction.wav';
import { AudioContext } from 'standardized-audio-context';

function App() {
	const [gameIsStarted, setGameIsStarted] = useState(false);
	const [background, setBackground] = useState('city');
	const [boughtItems, setBoughtItems] = useState([]);
	const [backgroundGroupId, setBackgroundGroupId] = useState(null);
	const [playMusic, setPlayMusic] = useState(false);

	const [audioContext, setAudioContext] = useState(null);
	const [audio, setAudio] = useState(null);
	
	useEffect(() => {
		// If audioContext is already set, we dont need to do it again
		if (!audioContext) {
			const context = new AudioContext();
			setAudioContext(context);

			const audio = new Audio(woodlabConstruction);
			audio.loop = true;
			const source = context.createMediaElementSource(audio);
			source.connect(context.destination);
			setAudio(audio);
		}
		/* Initialize firebase */
		firebaseInit();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleMusic = (setPlaying) => {
		setPlayMusic(setPlaying);
		if (audioContext.state === 'suspended') {
			audioContext.resume();
		}

		if (setPlaying) {
			audio.play();
		} else {
			audio.pause();
		}
	};

	const updateBackground = (newBackground) => {
		// This fixes 'Cannot update a component while rendering a different component' error
		setTimeout(() => {setBackground(newBackground);}, 0);
	};
	const updateBoughtItems = (newItems) => {
		// This fixes 'Cannot update a component while rendering a different component' error
		setTimeout(() => {setBoughtItems(newItems);}, 0);
	};
	const updateBackgroundGroupId = (newId) => {
		// This fixes 'Cannot update a component while rendering a different component' error
		setTimeout(() => {setBackgroundGroupId(newId);}, 0);
	};

	if (window.location.pathname.toLowerCase().includes('admin')) {
		return (
			<div className="App">
				<AdminLoginController />
			</div>
		);
	}

	return (
		<div className="App">
			<Background background={background} boughtItems={boughtItems} groupId={backgroundGroupId}/>
			{gameIsStarted ? 
				<LoginController 
					setBackground={updateBackground} 
					setBoughtItems={updateBoughtItems} 
					setBackgroundGroupId={updateBackgroundGroupId}
					setPlayMusic={toggleMusic}
					musicIsPlaying={playMusic}
				/> 
				:
				<FrontPage 
					startGame={() => {setGameIsStarted(true);}} 
					setPlayMusic={toggleMusic}
					musicIsPlaying={playMusic}
				/>
			}
			<ImageLoader/>
		</div>
	);
}

export default App;
