const guideTexts = {
	text1: `Woodlabspillet er et læringsspil der kan benyttes i Matematik eller i Innovation og Entreprenørskab. Spillet har variabel sværhedsgrad og kan derfor spilles på både mellemtrinnet og udskolingen. 
	<br /><br />
	Spillets formål er at give en motiverende spil-ramme som introduktion til tømrer- og snedkerfaget.
	<br /><br />
	I den nedenstående video kan du høre om hvad spillet kan og hvad det kræver fra dig som lærer.`,
	text2: {
		header: 'Tre trin og så er du klar til at spille:',
		text: `&#8291;1. Download materiale til det pågældende klassetrin (Der findes to forskellige 7-10 klasses moduler, så man kan spille spillet flere gange) 
		<br /><br />
		&#8291;2. Læs denne lærerguide
		<br /><br />
		&#8291;3. Se videoerne foroven og forneden`
	},
	text3: {
		header: 'Regelvideo',
		text: `Denne regelvideo ser eleverne i starten af spillet. I langt de fleste tilfælde forstår eleverne spillet ud fra videoen. Så normalt skal du blot svare på faglige spørgsmål og ikke spiltekniske spørgsmål.`
	},
	text4: {
		header: 'Yderligere information',
		text: `Spillet kan afvikles som i én køre. Det tager så knap 90 minutter. Men da spillet gemmer stillingen i spillet, kan du også dele spillet op i flere afviklinger. Spillet er inddelt i 3 runder, som hver kan afvikles på omkring 30 minutter. Spillet kan på den måde bruges i slutningen af en lektion eller når man har 30 minutter til overs i et undervisningsforløb. 
		<br /><br />
		Konkurrenceelementerne og samarbejdet på holdene fungere bedst, når der er seks hold med 3-5 elever på hvert hold. Spillet kan dog godt afvikles med færre hold eller med færre spillere pr hold.`
	},
	text5: {
		header: 'Svære termer og intro til snedker/tømrer-faget',
		text: `I spillet bliver der konkurreret på parametrene: Kvalitet, Arbejdsmiljø og Bæredygtighed. Det kan være en god idé at lave en fælles gennemgang af de tre emner og deres relation til byggeri inden spillet startes.
		<br /><br />
		Det kan give god mening at vise eleverne den nedenstående video inden spillet afvikles, da det viser hvordan spillet relaterer til virkeligheden som snedker eller tømrer og perspektiverer spillets termer Kvalitet, Arbejdsmiljø og Bæredygtighed.`
	},
	text6: {
		header: 'Hvad synes andre'
	},
	text7: {
		header: 'Lærerne, der har prøvet det, siger:',
		text: `4,5 stjerner ud af 5!
		<br /><br />
		Eleverne var engagerede og deltog aktivt.
		<br /><br />
		Eleverne kunne se et formål med de opgaver de skulle løse.
		<br /><br />
		Fantastisk forløb der skaber succes og lyst til at prøve igen.<br />
		Tak for to gode timer.
		<br /><br />
		Et godt bud på at vise eleverne hvordan faglighed spiller sammen med EUD uddannelserne.
		<br /><br />
		Et velfungerende spil, som de fleste - uanset faglige niveau - kunne deltage i.`
	},
	text8: {
		header: 'Hvem står bag spillet',
		text: `Spillet er udviklet i et samarbejde mellem U/NORD og Copenhagen Game Lab og finansieret af Fogs Fond.`
	},
	materialInterface: {
		title: 'Udskriv følgende inden afvikling alt efter valgte'
	}
};

module.exports = {
	guideTexts
};
