import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import './multiple-choice.scss';
import TaskIntro from '../task-intro/task-intro';

const MultipleChoice = (props) => {
	const {
		playerTaskData, 
		taskData,
		handleAnswer,
		handleCompleteTask,
	} = props;

	/* Check if completed already */
	const [isCompleted, setIsCompleted] = useState(false);

	/* Animate selected options */
	const [animateSelectedOptions, setAnimateSelectedOptions] = useState(false); 
	const [animationDelayTimer, setAnimationDelayTimer] = useState(null);

	/* Get number of correct answers */
	const numberOfCorrectAnswers = taskData.options ? taskData.options.filter((o) => {
		return o.isCorrect === true;
	}).length : 0;

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSelectedOptionIds = () => {
		let optionIds = [];

		if (playerTaskData && playerTaskData.selectedOptionsIds) optionIds = playerTaskData.selectedOptionsIds;
		return optionIds;
	};

	/* Track available and selected options */
	const [optionIds, setOptionIds] = useState([]);
	const [selectedOptionIds, setSelectedOptionIds] = useState([]);
	
	/* Update selected items if new task */
	useEffect(() => {
		let isTaskCompleted = playerTaskData && playerTaskData.isCompleted;
		setIsCompleted(isTaskCompleted);

		setSelectedOptionIds(getSelectedOptionIds());
		setAnimateSelectedOptions(true);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id, playerTaskData]);

	useEffect(() => {
		return () => {
			clearTimeout(animationDelayTimer);
		};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Get option ids and shuffle them
	useEffect(() => {
		let optionIds = [];
		if (taskData.options && taskData.hasOwnProperty('shuffleOptions') && !taskData.shuffleOptions) {
			optionIds = taskData.options.map((option) => {return option.id;});
		} else {
			optionIds = shuffleArray(taskData.options.map((option) => {return option.id;}));
		}

		setOptionIds(optionIds);
	}, [taskData]);

	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	const selectOptionId = (optionId) => {
		/* Already completed */
		if (isCompleted) return;

		const optionData = taskData.options.find((option) => {return option.id === optionId;});
		const isCorrect = optionData.isCorrect;

		/* Already selected */
		const optionIndex = selectedOptionIds.indexOf(optionId);
		if (optionIndex >= 0) return;

		/* Enable animation */
		setAnimateSelectedOptions(true);

		/* Select option */
		let newSelectedOptionIds = JSON.parse(JSON.stringify(selectedOptionIds));
		newSelectedOptionIds.push(optionId);
		setSelectedOptionIds(newSelectedOptionIds);

		/* Check if task is completed */
		const taskIsCompleted = (taskData.subtype === 'dilemma' 
			? taskData.numberOfAnswersToSelect === newSelectedOptionIds.length
			: checkIfAllCorrectOptionsAreSelected(newSelectedOptionIds)
		);

		if (taskIsCompleted) {
			/* Complete task */
			completeTask(newSelectedOptionIds);
		} else {
			// We save data for selected answer
			handleAnswer('multiple-choice', isCorrect, optionId);
		}
	};

	/**
	 * Check if all correct options are selected (i.e. if task is complete)
	 * @param {array} newSelectedOptionIds 
	 * @returns 
	 */
	const checkIfAllCorrectOptionsAreSelected = (newSelectedOptionIds) => {
		let allCorrectOptionsAreSelected = true;
		taskData.options.forEach((optionData) => {
			if (optionData.isCorrect === true && newSelectedOptionIds.indexOf(optionData.id) < 0) {
				allCorrectOptionsAreSelected = false;
			}
		});
		return allCorrectOptionsAreSelected;
	};

	/**
	 * Complete task
	 * @param {array} newSelectedOptionIds
	 */
	const completeTask = (newSelectedOptionIds) => {
		// We wait for animations to finish before we complete task
		const delay = setTimeout(function() {
			/* Save completed task */
			handleCompleteTask(
				'multiple-choice',
				newSelectedOptionIds
			);
		}, 250);

		setAnimationDelayTimer(delay);
	};

	return (
		<div className={'MultipleChoice '
			+ (taskData.subtype ? ' ' + taskData.subtype : '') 
			+ (taskData.layout ? ' ' + taskData.layout : '')}
		>
			<div id="taskIntro" className="MultipleChoice-intro">
				<TaskIntro
					showNumberOfAnswersToSelect={taskData.showNumberOfAnswersToSelect}
					numberOfCorrectAnswers={numberOfCorrectAnswers} 
					text={taskData.text}
					image={taskData.image}
					file={taskData.linkFile}
				/>
			</div>
			<div id="multipleChoiceOptions" className="MultipleChoice-options">
				<div className={'MultipleChoice-optionsWrap ' + taskData.style}>
					{optionIds.map((optionId, index) => {
						const optionData = taskData.options.find((option) => {return option.id === optionId;});
						if (!optionData) return null;
						const isSelected = selectedOptionIds.indexOf(optionData.id) >= 0;

						let optionClass = 'MultipleChoice-option';
						if (isSelected) {
							optionClass += ' selected';
						} else if (!isSelected && !isCompleted) {
							optionClass += ' notSelected';
						} else if (!isSelected && isCompleted) {
							optionClass += ' completed';
						}
						if (isSelected && optionData.hasOwnProperty('isCorrect')) {
							if (optionData.isCorrect) {
								optionClass += ' ' + (animateSelectedOptions ? 'animateCorrect' : 'correct');
							} else {
								optionClass += ' ' + (animateSelectedOptions ? 'animateWrong' : 'wrong');
							}
						}
						if (taskData.layout) optionClass += ' option-' + optionData.id + ' position-' + (index + 1);

						return (
							<div 
								key={optionData.id}
								className={optionClass}
								onClick={() => {selectOptionId(optionData.id);}}
							>
								<span>{optionData.text}</span>
								<span></span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

MultipleChoice.propTypes = {
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	handleAnswer: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
};

export default MultipleChoice;
