import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './button.scss';

class Button extends Component {
	/**
	 * Handle button click
	 * @param {object} event 
	 */
	handleClick = (event) => {
		if (this.props.isDisabled) event.preventDefault();
		else if (this.props.onClick) {
			event.persist();
			this.props.onClick(event);
		}
	}
	
	/**
	 * Render button
	 */
	render() {
		let className = 'Button';
		if (this.props.classes && this.props.classes.length > 0) {
			this.props.classes.forEach((c) => {className = className + ' ' + c;});
		}
			
		if (this.props.isLoading) {className += ' loading';}
		if (this.props.isDisabled) {className += ' disabled';}

		
		if (this.props.type === 'a' && this.props.href) {
			return <a className={className} href={this.props.href} target={'_blank'} rel='noopener noreferrer'>
				{this.props.text}
			</a>;
		}

		// buttons with submit type is necessary with forms with multiple inputs, for enter key to work.
		if (this.props.onClick && this.props.type !== 'submit') {
			return <div className={className} onClick={this.handleClick}><span>{this.props.text}</span></div>;
		} else if (this.props.onClick && this.props.type === 'submit') {
			if (this.props.isOnRelease) {
				return ( 
					<button type={this.props.type} className={className + ' ' + this.props.type} 
						onMouseUp={this.handleClick}
					>
						<span>{this.props.text}</span>
					</button>
				);
			}
			return ( 
				<button type={this.props.type} className={className + ' ' + this.props.type} 
					onClick={this.handleClick}
				>
					<span>{this.props.text}</span>
				</button>
			);
		}

		return null;
	}
}

Button.defaultProps = {
	isLoading: false,
	isDisabled: false,
	classes: null,
	type: 'button',
	isOnRelease: false,
};

Button.propTypes = {
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	text: PropTypes.string.isRequired,
	classes: PropTypes.array,
	type: PropTypes.string,
	href: PropTypes.string,
	onClick: PropTypes.func,
	isOnRelease: PropTypes.bool,
};


export default Button;