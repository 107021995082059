import React, { useState, useEffect } from 'react';
import './bid.scss';
import Button from 'components/ui/button/button';
import PropTypes from 'prop-types';
import CornerLogo from 'components/ui/corner-logo/corner-logo';
import GroupBox from 'components/ui/group-box/group-box';
import GroupData from 'components/ui/group-data/group-data';
import BidBox from 'components/ui/bid-box/bid-box';
import { pageUiTexts } from 'data/ui-texts';
import Popup from 'components/ui/popup/popup';
import { gameStepsData } from 'data/game-steps-data';
import { groupData } from 'data/group-data';
import { getRoundBids } from 'helpers/bidding-helper';

const Bid = ({group, updateGroup, groups, game}) => {
	const [isPopupShown, setIsPopupShown] = useState(false);
	const [votesSubmitted, setVotesSubmitted] = useState(false);
	const [currentCapacity, setCurrentCapacity] = useState(0);
	const [currentBids, setCurrentBids] = useState(null);
	const [currentRoundNumber, setCurrentRoundNumber] = useState(1);
	const [roundBids, setRoundBids] = useState([]);
	const [groupName, setGroupName] = useState('');

	useEffect(() => {
		setCurrentBids(group.bids);

		// Finding round number for progressbar
		const newRoundNumber = gameStepsData.find((step) => {
			return step.id === game.gameStepId;
		}).roundNumber;

		setCurrentRoundNumber(newRoundNumber);

		// Finding current bids for the round, based on the round number
		const currentRoundBids = getRoundBids(newRoundNumber, game.difficulty);
		setRoundBids(currentRoundBids);

		let capacity = group.capacity;

		// Group.areaBidsSaved can be undefined, in which case we just want to keep votesSubmitted false.
		if (group.areBidsSaved) {
			setVotesSubmitted(group.areBidsSaved);
		}

		// Finding group name for GroupBox
		let groupName = groupData.find((data) => {
			return data.id === group.id;
		}).name;

		setGroupName(groupName);

		setCurrentCapacity(capacity);
	}, [
		group.capacity, 
		group.areBidsSaved, 
		group.bids, 
		game.difficulty, 
		game.gameStepId, 
		currentRoundNumber, 
		group.id
	]);

	// Updates the groups current bids, and sets their current capacity
	const updateCurrentBid = (bidId, bidValue, change) => {
		let newBidList = currentBids;
		// No bids have been set yet, we initialize the list
		if (newBidList === null || newBidList === undefined) {
			if (bidValue !== 0 ) {
				newBidList = [];
				newBidList.push({id: bidId, bidValue: bidValue});
			}
		} else {
			// We check to see if the bid is already in the list
			let bidIndex = newBidList.findIndex((bid) => {
				return bid.id === bidId;
			});


			if (bidIndex !== -1) {
				if (bidValue !== 0) {
					// We update the bid if it is found
					newBidList[bidIndex].bidValue = bidValue;
				} else {
					// We remove the bid if its found
					newBidList.splice(bidIndex, 1);
				}
			} else if (bidValue !== 0) {
				// We add the bid if its not in the list.
				newBidList = currentBids;
				newBidList.push({id: bidId, bidValue: bidValue});
			}
		}

		setCurrentBids(newBidList);
		setCurrentCapacity(currentCapacity + change);
	};

	// Updates database group data, with current bids and remaining capacity
	const finishBid = () => {
		updateGroup({capacity: currentCapacity, bids: currentBids ? currentBids : [], areBidsSaved: true});
	};

	return (
		<div className='Bid'>
			{isPopupShown && <Popup
				title={pageUiTexts.finalizeBidding.title}
				message={pageUiTexts.finalizeBidding.message}
				cancelButtonText={pageUiTexts.finalizeBidding.cancel}
				onContinueButtonClick={() => {finishBid(); setIsPopupShown(false);}}
				onCancelButtonClick={() => {setIsPopupShown(false);}}
				showContinue={true}
			/>}

			<div className='Bid-cornerLogo'>
				<CornerLogo/>
			</div>

			<div className='Bid-sidebar'>
				<div className='Bid-sidebarTitle'>
					<span>{pageUiTexts.companies}</span>
				</div>

				<div className='Bid-currentGroup'>
					<GroupBox currentCapacity={currentCapacity} group={group} groupName={groupName}/>
				</div>
				
				<div className='Bid-groups'>
					{groups.map((data, index) => {
						const isCurrentGroup = data.id === group.id;
						
						return (
							<div key={index} className='Bid-groupInfo'>
								{isCurrentGroup ? 
									'' : <GroupData group={data}/>
								}
							</div>
						);
					})}
				</div>
			</div>

			<div className='Bid-bids'>
				{roundBids.map((bid, index) => {
					return (
						<div key={index} className={'Bid-bidBox position' + index}>
							<BidBox
								group={group}
								bidInfo={bid}
								capacity={currentCapacity}
								updateBid={updateCurrentBid}
								votingIsActive={!votesSubmitted} // Voting is only active if votes are not submitted yet
							/>
						</div>
					);
				})}
			</div>

			<div className='Bid-finishButton'>
				<Button
					isDisabled={votesSubmitted}
					text={pageUiTexts.done}
					classes={['pushable']}
					isOnRelease={true}
					onClick={() => {setIsPopupShown(true);}}
				/>
			</div>
		</div>
	);
};

Bid.propTypes = {
	groups: PropTypes.array.isRequired,
	group: PropTypes.object,
	game: PropTypes.object.isRequired,
	updateGroup: PropTypes.func,
};

export default Bid;