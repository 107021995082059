import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';
import Gruppeskilte from '../../../assets/pdfs/Gruppeskilte.pdf';
import TeachersGuide from '../../../assets/pdfs/Lærervejledning.pdf';
import TeachersGuide56 from '../../../assets/pdfs/Lærervejledning-5-6.pdf';
import AssignmentsPrintouts from '../../../assets/pdfs/assignment-printouts.pdf';
import AssignmentsPrintouts2 from '../../../assets/pdfs/assignment-printouts2.pdf';
import { pageUiTexts } from 'data/ui-texts';
import { difficultyData } from 'data/settings-data';
import { materialsData } from 'data/materials-data';
import './materials-window.scss';

const MaterialsWindow = ({title}) => {
	const [currentDifficultyData, setCurrentDifficultyData] = useState(null);

	/**
	 * Gets data for difficulty
	 * @param {string} difficultyId 
	 */
	const getDifficultyData = (difficultyId) => {
		const newData = materialsData.find((material) => {
			return material.id === difficultyId;
		});

		if (newData) {
			setCurrentDifficultyData(newData);
		}
	};

	/**
	 * Gets resources based on id
	 * @param {string} resourceId 
	 * @returns 
	 */
	const getResource = (resourceId) => {
		let resource = null;

		switch (resourceId) {
		case 'gruppeskilte':
			resource = Gruppeskilte;
			break;
		case 'teachersGuide':
			resource = TeachersGuide;
			break;
		case 'teachersGuide-5-6':
			resource = TeachersGuide56;
			break;
		case 'assignmentsPrintouts':
			resource = AssignmentsPrintouts;
			break;
		case 'assignmentsPrintouts2':
			resource = AssignmentsPrintouts2;
			break;

		default:
			console.error(pageUiTexts.noResourceWithID + ' ' + resourceId);
			break;
		}

		return resource;
	};

	/**
	 * Closes popup
	 */
	const handleClose = () => {
		setCurrentDifficultyData(null);
	};

	return (
		<div className='MaterialsWindow'>
			{currentDifficultyData && 
				<div className='MaterialsWindow-close' onClick={() => {handleClose();}}/>
			}
			<div className='MaterialsWindow-title'>
				<span>{title}</span>
			</div>
			{currentDifficultyData ?
				<div className='MaterialsWindow-dialogueWrapper'>
					{currentDifficultyData.materialList.map((data, index) => {
						// get resource from id
						const resource = getResource(data.resourceId);

						// if no resource was found with id
						if (!resource) {
							return (
								<div key={index}></div>
							);
						}

						return (
							<div key={index} className='MaterialsWindow-button'>
								<Button 
									type={'a'}
									href={resource}
									text={data.text}
									classes={['flatBlue download']}
								/>
							</div>
						);
					})}
				</div>
				:
				<div className='MaterialsWindow-dialogueWrapper'>
					{difficultyData.map((data, index) => {
						return (
							<div key={index} className='MaterialsWindow-difficultyList'>
								<div className='MaterialsWindow-button'>
									<Button 
										text={data.text}
										classes={['flatBlue']}
										onClick={() => {getDifficultyData(data.id);}}
									/>
								</div>
							</div>
						);	
					})}

				</div>
			}
		</div>
	);
};

MaterialsWindow.propTypes = {
	title: PropTypes.string.isRequired
};

export default MaterialsWindow;